export const GET_APP_CONFIGURATION = "GET_APP_CONFIGURATION";
export const GET_TERMS_OF_USE_DETAILS = "GET_TERMS_OF_USE_DETAILS";

const mainLayoutSagaActions = {
  getAppConfiguration: () => {
    return {
      type: GET_APP_CONFIGURATION,
    };
  },

  getPrivacyNoticeDetailsById: () => {
    return {
      type: GET_TERMS_OF_USE_DETAILS,
    };
  },
};

export default mainLayoutSagaActions;
