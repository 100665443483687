import { createSlice } from "@reduxjs/toolkit";

import {
  ViewInspectionQuestionHistoryProps,
  ViewInspectionQuestionHistoryType,
} from "./ViewInspectionQuestionHistory.types";

// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY - REDUCER ||============================== //
const initialState = {
  InspectionQuestionId: 0,
  Open: false,
  Ref: null,
  onCloseBtnClick() {},
} as ViewInspectionQuestionHistoryType;

export const viewInspectionQuestionHistorySlice = createSlice({
  name: "viewInspectionQuestionHistory",
  initialState: initialState,
  reducers: {
    viewInspectionQuestionHistoryDetails: (
      state: ViewInspectionQuestionHistoryType,
      action: { payload: ViewInspectionQuestionHistoryProps; type: string }
    ) => {
      return {
        ...state,
        InspectionQuestionId: action.payload.InspectionQuestionId,
        Ref: action.payload.Ref,
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    setInspectionQuestionHistoryDetails: (
      state: ViewInspectionQuestionHistoryType,
      action: { payload: ViewInspectionQuestionHistoryType; type: string }
    ) => {
      return {
        ...state,
        InspectionQuestionHistory: action.payload.InspectionQuestionHistory,
        Open: true,
      };
    },
    resetInspectionQuestionHistory: () => {
      return initialState;
    },
  },
});

export const viewInspectionQuestionHistorySliceReducerActions =
  viewInspectionQuestionHistorySlice.actions;
export const viewInspectionQuestionHistoryReducer =
  viewInspectionQuestionHistorySlice.reducer;
