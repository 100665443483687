// assets
import StandardIco from "../../../icons/standard.svg";

import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { GETSTANDARDLIST } from "./StandardsPage.action";
import { getStandardList } from "./StandardsPage.api";
import {
  StandardSmeType,
  StandardsPageInitialState,
} from "./StandardsPage.types";
import { AnyAction } from "redux";
import {
  setLoading,
  setPagination,
  setStandardList,
} from "./StandardsPage.reducer";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import {
  SearchParameterDTO,
  StandardDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { EnumListShowRowCountList } from "../../../common/enums/common.enums";

// ==============================|| VIEWS - PAGES - STANDARD - SAGA ||============================== //

const getStandardListWatcher = function* (): any {
  // constant
  const icons = { StandardIco };

  yield takeEvery(GETSTANDARDLIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    //Clear state
    yield put(
      setPagination({
        ItemList: [] as Array<PaginationListItemType>,
        ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        TotalRecordsCount: -1,
        SelectedPage: 1,
        StatusSummaryCount: {} as StatusSummaryCountDTO,
      } as HPRListPaginationType)
    );
    yield put(setStandardList({} as StandardsPageInitialState));

    const searchPara: SearchParameterDTO = action.payload;
    const result: StandardsPageInitialState = yield call(() =>
      getStandardList(searchPara)
    );
    if (result == null && !result) {
      // TODO document why this block is empty
    } else {
      yield put(
        setPagination({
          ItemList: result.Data?.Data?.map((standard: StandardDTO) => {
            return {
              Icon: icons.StandardIco,
              ShowCommonListIcon: true,
              Id: standard.Id,
              ItemHeading: standard.Name,
              Status: standard.StatusName,
              StatusID: standard.Status,
              Version: standard.Version?.toFixed(1),
              TotalQuestions: standard.TotalQuestion,
              PublishedQuestions: standard.PublishedQuestionCount,
              PrimarySME: standard.PrimarySME,
              SecondarySME: standard.SecondarySME,
              ModifiedBy: standard.ModifiedBy,
              ModifiedDate: standard.ModifiedDate,
              SecondarySMEToolTips: standard.SecondarySME?.map(
                (sme: StandardSmeType) => {
                  return sme?.Sme?.DisplayName;
                }
              ).join("; "),
            } as PaginationListItemType;
          }),
          ItemShowCount: result.Data?.PageSize,
          TotalRecordsCount: result.Data?.TotalRecordsCount,
          StatusSummaryCount: result.Data?.StatusSummaryCount,
          SelectedPage: result.Data?.SelectedPage,
        } as HPRListPaginationType)
      );
      yield put(setStandardList(result));
    }
    yield put(setLoading(false));
  });
};

const standardListSaga = function* () {
  yield all([getStandardListWatcher()]);
};

export default standardListSaga;
