import { all, call, put, select, takeEvery } from "@redux-saga/core/effects";

import { AnyAction } from "redux";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSubSystemDTO,
} from "../../../common/types/common.dto.types";

import {
  setPageMode,
  setPagination,
  setStandardSubSystem,
  setStandardSubSystemList,
  setSubSystemModalOpen,
} from "./StandardSubSystemPage.reducer";
import {
  DELETESTANDARDSUBSYSTEM,
  GETSTANDRDSUBSYSTEMLIST,
  SAVESTANDARDRESUBSYSTEM,
  UPDATESTANDARDSUBSYSTEM,
} from "./StandardSubSystemPage.action";
import {
  deleteStandardSubSystem,
  getStandardSubSystemList,
  saveStandardSubSystem,
  updateStandardSubSystem,
} from "./StandardSubSystemPage.api";
import { StandardSubSystemPageType } from "./StandardSubSystemPage.types";
import { RootState } from "../../../core/store";
import { ActionResponseType } from "../../../common/types/common.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";

import { PopupProps } from "../../../components/Popups/PopupProps.types";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";

const getStandardSubSystemListWatcher = function* (): any {
  yield takeEvery(GETSTANDRDSUBSYSTEMLIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // get data from backend
    try {
      const standardId: number = action.payload;
      const subSystemSearchParam: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getStandardSubSystemList(subSystemSearchParam)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No Sub-system data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
        } else {
          const paginationtype: StandardSubSystemPageType = {
            Data: result.Data,
          } as StandardSubSystemPageType;
          yield put(
            setPagination({
              Data: paginationtype.Data.Data,
              TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
              SelectedPage: paginationtype.Data.SelectedPage,
            } as PaginationDTO)
          );
          yield put(setStandardSubSystemList(paginationtype));
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const saveStandardSubSystemWatcher = function* (): any {
  yield takeEvery(SAVESTANDARDRESUBSYSTEM, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    try {
      // save subSystem data from backend
      const standardSubSystemPageType: StandardSubSystemPageType =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        saveStandardSubSystem(standardSubSystemPageType.StandardSubSystem)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //set saved data
        if (
          standardSubSystemPageType.ButtonModeType === EnumButtonModeType.Save
        ) {
          yield put(setStandardSubSystem(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          standardSubSystemPageType.ButtonModeType ===
          EnumButtonModeType.SaveAndContinue
        ) {
          yield put(
            setStandardSubSystem({
              Id: 0,
              Name: "",
              StandardId:
                standardSubSystemPageType.StandardSubSystem.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSubSystemDTO)
          );
          yield put(setPageMode(EnumPageMode.AddMode));
        } else if (
          standardSubSystemPageType.ButtonModeType ===
          EnumButtonModeType.SaveAndClose
        ) {
          yield put(
            setStandardSubSystem({
              Id: 0,
              Name: "",
              StandardId:
                standardSubSystemPageType.StandardSubSystem.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSubSystemDTO)
          );

          yield put(setPageMode(EnumPageMode.Default));
          yield put(setSubSystemModalOpen(false));
        }
        //get SubSystem List
        const standardSubSystemState: StandardSubSystemPageType = yield select(
          (s: RootState) => s.standardSubSystemReducer
        );
        const listResult: ActionResponseType = yield call(() =>
          getStandardSubSystemList(
            standardSubSystemPageType.StandardSubSystemSearchParameter
          )
        );

        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No Sub-system data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            // if (
            //   standardSubSystemPageType.ButtonModeType ===
            //   EnumButtonModeType.SaveAndContinue
            // ) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "Successfully saved",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
            // }
            const paginationtype: StandardSubSystemPageType = {
              Data: listResult.Data,
            } as StandardSubSystemPageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardSubSystemList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const updateStandardSubSystemWatcher = function* (): any {
  //
  yield takeEvery(UPDATESTANDARDSUBSYSTEM, function* (action: AnyAction): any {
    yield put(setLoading(true));
    try {
      // save SubSystem data from backend
      const standardSubSystemPageType: StandardSubSystemPageType =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        updateStandardSubSystem(standardSubSystemPageType.StandardSubSystem)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when updating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (
          standardSubSystemPageType.ButtonModeType === EnumButtonModeType.Update
        ) {
          yield put(setStandardSubSystem(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          standardSubSystemPageType.ButtonModeType ===
          EnumButtonModeType.UpdateAndClose
        ) {
          yield put(
            setStandardSubSystem({
              Id: 0,
              Name: "",
              StandardId:
                standardSubSystemPageType.StandardSubSystem.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSubSystemDTO)
          );
          yield put(setPageMode(EnumPageMode.Default));
          yield put(setSubSystemModalOpen(false));
        }

        //get SubSystem List
        const standardSubSystemState: StandardSubSystemPageType = yield select(
          (s: RootState) => s.standardSubSystemReducer
        );

        const listResult: ActionResponseType = yield call(() =>
          getStandardSubSystemList(
            standardSubSystemPageType.StandardSubSystemSearchParameter
          )
        );

        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No Sub-system data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            /*yield put(
                            openPopup({
                                Open: true,
                                BodyText: "Successfully Updated",
                                HeaderText: "Success",
                                PopupType: EnumPopupType.SuccessPopup,
                                // okBtnClick:  ()=>navigate("/standards", {}),
                            } as PopupProps)
                        );
    */
            const paginationtype: StandardSubSystemPageType = {
              Data: listResult.Data,
            } as StandardSubSystemPageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardSubSystemList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when updating data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const deleteStandardSubSystemWatcher = function* (): any {
  //
  yield takeEvery(DELETESTANDARDSUBSYSTEM, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    try {
      // save SubSystem data from backend
      const standardSubSystemPageType: StandardSubSystemPageType =
        action.payload;

      const result: ActionResponseType = yield call(() =>
        deleteStandardSubSystem(standardSubSystemPageType.StandardSubSystem.Id)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when deactivating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const standardSubSystemState: StandardSubSystemPageType = yield select(
          (s: RootState) => s.standardSubSystemReducer
        );
        yield put(
          setStandardSubSystem({
            Id: 0,
            Name: "",
            StandardId: standardSubSystemState.StandardSubSystem.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSubSystemDTO)
        );
        yield put(setPageMode(EnumPageMode.Default));
        yield put(setSubSystemModalOpen(false));

        //get SubSystem List
        const listResult: ActionResponseType = yield call(() =>
          getStandardSubSystemList({
            ...standardSubSystemPageType.StandardSubSystemSearchParameter,
            SelectedPage: 1,
            GridPageSize:
              standardSubSystemState.StandardSubSystemSearchParameter
                .GridPageSize,
          } as SearchParameterDTO)
        );
        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when deleting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No Sub-system data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const paginationtype: StandardSubSystemPageType = {
              Data: listResult.Data,
            } as StandardSubSystemPageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardSubSystemList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when deleting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const standardSubSystemSaga = function* () {
  yield all([
    getStandardSubSystemListWatcher(),
    saveStandardSubSystemWatcher(),
    updateStandardSubSystemWatcher(),
    deleteStandardSubSystemWatcher(),
  ]);
};

export default standardSubSystemSaga;
