import { createSlice } from "@reduxjs/toolkit";
import { EnumPopupType } from "./Popup.enum";
import { PopupProps, PopupPropsPartial } from "./PopupProps.types";

const initialState = {
  BodyText: "",
  HeaderText: "",
  Open: false,
  PopupType: EnumPopupType.ErrorPopup,
} as PopupProps;
export const popupSlice = createSlice({
  name: "popup",
  initialState: initialState,
  reducers: {
    openPopup: (
      state: PopupProps,
      action: { payload: PopupProps; type: string }
    ) => {
      return {
        ...state,
        Open: action.payload.Open,
        BodyText: action.payload.BodyText,
        HeaderText: action.payload.HeaderText,
        PopupType: action.payload.PopupType,
        InputText: action.payload.InputText,
        LableText: action.payload.LableText,
        Required: action.payload.Required,
        InputTextCount: action.payload.InputTextCount,
        InputEvents: action.payload.InputEvents,
        ErrorMessage: action.payload.ErrorMessage,
        YesButtonText: action.payload.YesButtonText,
        NoButtonText: action.payload.NoButtonText,
        OkButtonText: action.payload.OkButtonText,
        CancelButtonText: action.payload.CancelButtonText,
        OkBtnClick: action.payload.OkBtnClick,
        YesBtnClick: action.payload.YesBtnClick,
        NoBtnClick: action.payload.NoBtnClick,
        CancelBtnClick: action.payload.CancelBtnClick,
        ActionBtnClick: action.payload.ActionBtnClick,
      };
    },
    updatePopupProps: (state, action: { payload: PopupPropsPartial }) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { openPopup, updatePopupProps } = popupSlice.actions;

export const popupReducer = popupSlice.reducer;
