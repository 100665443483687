import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState, useMemo } from "react";
import { DropDownDTO } from "../../../common/types/common.dto.types";
import { getDataFromBackend } from "./HPRDropDownSingleSelect.api";
import { HPRDropDownProps } from "./HPRDropDownSingleSelect.types";
import { ArrowDropDownCircle } from "@mui/icons-material";

// ==============================|| COMPONENTS - COMMON - HPRDropDown ||============================== //
interface InitialState {
  SelectedValue: string;
  SelectOptions: Array<DropDownDTO>;
}

const HPRDropDownSingleSelect = (props: HPRDropDownProps) => {
  const initialState = {
    SelectedValue: "",
    SelectOptions: [] as Array<DropDownDTO>,
  } as InitialState;

  const [SingleSelect, setSingleSelect] = useState(initialState);
  const dataFetchedRef = useRef(false);
  useEffect(() => {
    // If menuitem list received
    if (props.Items && props.Items.length > 0) {
      setSingleSelect((values) => {
        return {
          ...values,
          SelectOptions: props.Items ? props.Items : ([] as Array<DropDownDTO>),
        };
      });
    }
    // If menuitem list not received
    else {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      if (props.Url && props.Url !== "") {
        getDataFromBackend(props.Url ? props.Url : "").then((response) => {
          setSingleSelect((values) => {
            return { ...values, SelectOptions: response };
          });
        });
      }
    }
  }, [props.Items]);

  const onSelectionChange = (event: any, value) => {
    if (props.onDropDownChange)
      props.onDropDownChange(
        props.Items != null && props.Items.length
          ? props.Items
          : SingleSelect.SelectOptions,
        value ? value.Value : "",
        props.Name
      );
  };

  // On focusout event
  const onBlurChange = (e: React.FocusEvent) => {
    if (props.onBlurChange)
      props.onBlurChange(
        props.Items != null && props.Items.length
          ? props.Items
          : SingleSelect.SelectOptions,
        e.target["value"] ? e.target["value"] : "",
        props.Name
      );
  };

  const SearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    //return if Searchable With Add disable
    if (!props.IsSearchableWithAdd) return;

    let selectedValue = SingleSelect.SelectOptions?.find(
      (item) => item.Value.toString() === "-2"
    );

    if (selectedValue === undefined || selectedValue === null) {
      SingleSelect.SelectOptions?.unshift({
        Value: "-2",
        Label: event.target.value,
      });

      if (props.onDropDownChange) {
        props.onDropDownChange(SingleSelect.SelectOptions, "-2", props.Name);
      }
    } else {
      if (event.target.value === "") {
        setSingleSelect((values) => {
          return {
            ...values,
            SelectOptions: values.SelectOptions.filter(
              (filter) => filter.Value !== "-2"
            ),
          };
        });

        let NewSingleSelectList = SingleSelect.SelectOptions?.filter(
          (filter) => filter.Value !== "-2"
        );

        if (props.onDropDownChange) {
          props.onDropDownChange(NewSingleSelectList, "-2", props.Name);
        }
      } else {
        let NewSingleSelectList = SingleSelect.SelectOptions?.map((item) => {
          if (item.Value === "-2") {
            item.Label = event.target.value;
          }
          return item;
        });

        if (props.onDropDownChange) {
          props.onDropDownChange(NewSingleSelectList, "-2", props.Name);
        }
      }
    }
  };

  const isOptionEqualToValue = (option, value) => {
    return option?.Value?.toString() === value[0]?.toString();
  };

  const selectedValue = useMemo(
    () => [props.Select ?? "0"],
    [props.Select, SingleSelect.SelectOptions]
  );

  return (
    <>
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        className="hpr-singleselect-dropdown"
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
          required={props.Required}
          disabled={props.Disabled}
        >
          {props.Label}
        </InputLabel>
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          id={props.Id && props.Id !== "" ? props.Id : props.Name}
          disabled={props.Disabled}
          options={
            SingleSelect.SelectOptions && SingleSelect.SelectOptions.length > 0
              ? SingleSelect.SelectOptions
              : ([] as Array<DropDownDTO>)
          }
          getOptionLabel={(option: DropDownDTO) => {
            const selectedValue = SingleSelect.SelectOptions?.find(
              (item) => item.Value.toString() === option.toString()
            );
            return option?.Label !== undefined
              ? option?.Label
              : selectedValue?.Label !== undefined
              ? selectedValue?.Label
              : "";
          }}
          renderOption={(props, option: DropDownDTO) => {
            return (
              <li {...props} key={option.Value}>
                {option?.Label}
              </li>
            );
          }}
          //freeSolo
          forcePopupIcon
          autoComplete
          value={selectedValue}
          filterSelectedOptions
          onChange={onSelectionChange}
          onBlur={onBlurChange}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              // placeholder={props.Label}
              className={` ${props.Disabled ? "Mui-disabled" : ""}`}
              InputLabelProps={{ shrink: true }}
              onChange={SearchTextChange}
              inputProps={{
                ...inputProps,
                readOnly:
                  !props.IsSearchable && !props.IsSearchableWithAdd
                    ? true
                    : false,
              }}
              // onKeyPress={onsearchDropDownKeyPress}
              // onBlur={onBlurSearchTextBox}
            />
          )}
          noOptionsText={"No record(s) found"}
          disableClearable={props.DisableClearable}
        />
        {/* <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
          required={props.Required}
        >
          {props.Label}
        </InputLabel>
        <Select
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: 2,
                "& .MuiMenuItem-root": {
                  padding: 2,
                },
              },
            },
          }}
          labelId="demo-simple-select-helper-label"
          id={props.Id}
          value={props.Select === 0 ? "" : props.Select}
          label={props.Required ? props.Label + " *" : props.Label}
          onChange={onSelectionChange}
          disabled={props.Disabled}
          className="hpr-multiselect-dropdown"
        >
          {SingleSelect.SelectOptions &&
          SingleSelect.SelectOptions.length > 0 ? (
            SingleSelect.SelectOptions.map(
              (option: DropDownDTO, index: number) => (
                <MenuItem key={index} value={option.Value}>
                  {option.Label}
                </MenuItem>
              )
            )
          ) : (
            <MenuItem>No items</MenuItem>
          )}
        </Select> */}
        {props.HelperText && (
          <FormHelperText>{props.HelperText}</FormHelperText>
        )}
        {props.IsEnableValidator ? (
          props.Validator?.message(
            props.Name,
            props.Select ? props.Select : "",
            props.Rules ? props.Rules : ""
          )
        ) : (
          <></>
        )}
      </FormControl>
    </>
  );
};

export default HPRDropDownSingleSelect;
