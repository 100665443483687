import { createSlice } from "@reduxjs/toolkit";
import {
  EnumInspectionSetSortType,
  EnumInspectionSortType,
  EnumListShowRowCountList,
  EnumSortTypes,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  InspectionSetDTO,
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { InspectionSetPageInitialState } from "./InspectionSetsPage.types";

// ==============================|| VIEWS - PAGES - INSPECTIONSET - REDUCER ||============================== //

const initialState = {
  Data: {} as PaginationDTO,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  InspectionSetSearchParameter: {
    InspectionSetSearchParams: {
      Name: "",
      Status: EnumStatus.Published,
      SortBy: {
        Label: "Name",
        Value: EnumInspectionSetSortType.Name.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
    } as InspectionSetDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as InspectionSetPageInitialState;

export const inspectionSetSlice = createSlice({
  name: "inspectionSetList",
  initialState: initialState,
  reducers: {
    setInspectionSetInitState: (
      state: InspectionSetPageInitialState,
      action: { payload: InspectionSetPageInitialState; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload.ListPagination,
        InspectionSetSearchParameter:
          action.payload.InspectionSetSearchParameter,
        Data: action.payload.Data,
      };
    },
    setInspectionSetList: (
      state: InspectionSetPageInitialState,
      action: { payload: InspectionSetPageInitialState; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.Data,
      };
    },
    setPagination: (
      state: InspectionSetPageInitialState,
      action: { payload: HPRListPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setInspectionSetSearchParameter: (
      state: InspectionSetPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        InspectionSetSearchParameter: {
          ...state.InspectionSetSearchParameter,
          InspectionSetSearchParams: action.payload.InspectionSetSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
        } as SearchParameterDTO,
      };
    },
  },
});

export const {
  setInspectionSetList,
  setPagination,
  setInspectionSetSearchParameter,
  setInspectionSetInitState,
} = inspectionSetSlice.actions;
export const InspectionSetsReducer = inspectionSetSlice.reducer;
