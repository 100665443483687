import { takeEvery, put, call, select, fork } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { GETREMEDIATIONTRACKERTASKSHISTORYDETAILS } from "./ViewRemediationTrackerTaskHistory.action";
import { viewRemediationTrackerTaskHistorySliceReducerActions } from "./ViewRemediationTrackerTaskHistory.reducer";
import { getRemediationTrackerTaskHistoryDetails } from "./ViewRemediationTrackerTaskHistory.api";
import { ViewRemediationTrackerTaskHistoryType } from "./ViewRemediationTrackerTaskHistory.types";

// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY - SAGA ||============================== //

const getViewRemediationTrackerTaskHistoryWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASKSHISTORYDETAILS,
    function* (action: AnyAction): any {
      // set page loading on
      //yield put(setLoading(true));

      const state: ViewRemediationTrackerTaskHistoryType = yield select(
        (s: RootState) => s.viewRemediationTrackerTaskHistoryReducer
      );

      // get data from backend
      try {
        const remediationTrackerTaskId: number = action.payload;
        const result: ActionResponseType = yield call(() =>
          getRemediationTrackerTaskHistoryDetails(remediationTrackerTaskId)
        );
        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            // TODO
          } else {
            const remediationTrackerTaskHistory: any = result.Data;
            yield put(
              viewRemediationTrackerTaskHistorySliceReducerActions.setRemediationTrackerTaskHistoryDetails(
                {
                  ...state,
                  RemediationTrackerTaskHistory: remediationTrackerTaskHistory,
                }
              )
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading on
      //yield put(setLoading(false));
    }
  );
};

const viewRemediationTrackerTaskHistorySaga = function* () {
  yield fork(getViewRemediationTrackerTaskHistoryWatcher);
};

export default viewRemediationTrackerTaskHistorySaga;
