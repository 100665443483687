import SimpleReactValidator from "simple-react-validator";
import {
  EnumDataTransferStatus,
  EnumPageMode,
  EnumRemediationTrackerTaskStatus,
} from "../../../common/enums/common.enums";
import {
  CapexRequiredOptionDTO,
  CapexStatusDTO,
  DropDownDTO,
  InspectionQuestionDTO,
  RemediationTrackerTaskCommentDTO,
  RemediationTrackerTaskDTO,
  RemediationTrackerTaskHistoryDTO,
  RemediationTrackerTaskPriorityDTO,
  RemediationTrackerTaskQuestionDTO,
  RemediationTrackerTaskStatusDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { createSlice } from "@reduxjs/toolkit";
import { RemediationTrackerTaskPageType } from "./RemediationTrackerTaskPage.types";
import produce from "immer";

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    IsShowDeactivateBtn: false,
    IsShowSubmitBtn: false,
    PageMode: EnumPageMode.AddMode,
  } as PageModeType,

  RemediationTrackerTask: {
    TaskName: "",
    ActionOwner: "",
    Status: EnumRemediationTrackerTaskStatus.New,
    Piority: null,
    DueDate: "",
    RemediationTrackerTaskComments:
      [] as Array<RemediationTrackerTaskCommentDTO>,
    RemediationTrackerTaskQuestions:
      [] as Array<RemediationTrackerTaskQuestionDTO>,
    UploadFiles: [] as Array<UploadFileDTO>,
    StatusNavigation: {
      StatusName: "New",
      Description: "New",
    } as RemediationTrackerTaskStatusDTO,
    PiorityNavigation: {
      PriorityName: "",
    } as RemediationTrackerTaskPriorityDTO,
    SelectedActionOwnerId: "0",
    SelectedPriorityId: 0,
    ActionOwnerDropDownList: [] as Array<DropDownDTO>,
    PriorityDropDownList: [] as Array<DropDownDTO>,
    CapexStatusDropDownList: [] as Array<DropDownDTO>,
    IsCapexRequiredDropDownList: [] as Array<DropDownDTO>,
    AreaOwnerTypeDropDownList: [] as Array<DropDownDTO>,
    Version: 0.01,
    AreaOwner: null,
    Approver: "",
    IsCapexrequired: null,
    CapexamountRequired: 0.0,
    CapexnumberAndName: "",
    Capexstatus: null,
    CompletedDate: "",
    CompletedBy: "",
    CapexstatusNavigation: {
      StatusName: "",
    } as CapexStatusDTO,
    IsCapexrequiredNavigation: {
      TypeName: "",
    } as CapexRequiredOptionDTO,
    IsMergeTask: false,
    IsInApprovalProcess: false,
    CreatedBy: "",
    CreatedDate: "",
    ShowApprovalButton: false,
    ShowRejectButton: false,
    IsSMEApproved: false,
    IsSiteExecutiveApproved: false,
    IsAdmin: false,
    ShowEditButton: false,
    RemediationTrackerTaskHistoryList:
      [] as Array<RemediationTrackerTaskHistoryDTO>,
    CapexplannedYear: null,
    CapexPlannedYearDropDownList: [] as Array<DropDownDTO>,
    PendingApproverDropDownList: [] as Array<DropDownDTO>,
    HistoryMessage: "",
    Ernumber: "",
    ClosureReason: "",
  } as RemediationTrackerTaskDTO,
  TaskComment: "",
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  FilesToBeUploaded: [] as Array<UploadFileDTO>,
  FilesToBeRemoved: [] as Array<number>,
  SaveTaskRunning: false,
  RunSaveTask: false,
  TempTaskToSave: {},
  UpdateThroughFileUpload: false,
  UpdateThroughCommentToggle: false,
  UpdateThroughHistoryToggle: false,
  TempFileIdList: [] as Array<number>,
  EditInAddMode: false,
  IsAdmin: false,
  IsUser: false,
  TaskDeleted: false,
} as RemediationTrackerTaskPageType;

export const remediationTrackerTaskSlice = createSlice({
  name: "remediationTrackerTask",
  initialState: initialState,
  reducers: {
    setRemediationTrackerTask: (
      state: RemediationTrackerTaskPageType,
      action: {
        payload: {
          RemediationTrackerTask: RemediationTrackerTaskDTO;
          SaveByFileUpload: boolean;
        };
        type: string;
      }
    ) => {
      return {
        ...state,
        IsDisable: true,
        RemediationTrackerTask: action.payload.RemediationTrackerTask,
        SaveByFileUpload: action.payload.SaveByFileUpload,
        //TempTaskToSave: null,
        SaveTaskRunning: false,
        RunSaveTask: false,
        UpdateThroughFileUpload: false,
        UpdateThroughCommentToggle: false,
        UpdateThroughHistoryToggle: false,
        TempFileIdList: [],
        EditInAddMode: false,
        TaskDeleted: false,
      };
    },
    changePageMode: (
      state: RemediationTrackerTaskPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (action.payload === EnumPageMode.ViewMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowSaveBtn: false,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowEditBtn: true,
            IsShowPublishedBtn: false,
            IsShowDeactivateBtn: false,
            IsCompleteBtn: false,
            IsShowInProgressBtn: false,
            IsShowNewBtn: false,
            IsShowSubmitBtn: false,
            IsShowApproveBtn: true,
            IsShowRejectBtn: true,
          },
        };
      } else if (action.payload === EnumPageMode.AddMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: true,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: false,
            IsShowEditBtn: false,
            IsShowPublishedBtn: false,
            IsShowDeactivateBtn: false,
            IsCompleteBtn: false,
            IsShowInProgressBtn: false,
            IsShowNewBtn: false,
            IsShowSubmitBtn: true,
            IsShowApproveBtn: false,
            IsShowRejectBtn: false,
          },
        };
      } else if (action.payload === EnumPageMode.EditMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowSaveBtn: true,
            IsShowCloseBtn: true,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: false,
            IsShowEditBtn: false,
            IsShowPublishedBtn: false,
            IsShowDeactivateBtn: false,
            IsCompleteBtn: false,
            IsShowInProgressBtn: true,
            IsShowNewBtn: false,
            IsShowSubmitBtn: true,
            IsShowApproveBtn: true,
            IsShowRejectBtn: true,
          },
        };
      }
    },
    changeUploadFileListData: (
      state: RemediationTrackerTaskPageType,
      action: { payload: RemediationTrackerTaskDTO; type: String }
    ) => {
      return {
        ...state,
        RemediationTrackerTask: {
          ...state.RemediationTrackerTask,
          UploadFiles: action.payload.UploadFiles,
          RemovedFileIdList: action.payload.RemovedFileIdList,
        },
        UpdateThroughFileUpload: true,
        UpdateThroughCommentToggle: false,
        UpdateThroughHistoryToggle: false,
      };
    },
    updateUploadFileList: (
      state: RemediationTrackerTaskPageType,
      action: { payload: Array<UploadFileDTO>; type: string }
    ) => {
      return {
        ...state,
        RemediationTrackerTask: {
          ...state.RemediationTrackerTask,
          UploadFiles: action.payload,
        },
        UpdateThroughFileUpload: true,
        UpdateThroughCommentToggle: false,
        UpdateThroughHistoryToggle: false,
      };
    },
    updateUploadFileStatus: (
      state: RemediationTrackerTaskPageType,
      action: { payload: UploadFileDTO; type: string }
    ) => {
      return produce(state, (draftState) => {
        draftState.RemediationTrackerTask.UploadFiles.map((file) => {
          if (file.UploadStatus === EnumDataTransferStatus.InProgress) {
            file.UploadStatus = EnumDataTransferStatus.Finished;
            file.Id = action.payload.Id;
            file.FilePreview = action.payload.FilePreview;
            file.File = action.payload.FilePreview;
            file.FilePath = action.payload.FilePath;
          }
          return file;
        });
        return draftState;
      });
    },
    resetRemediationTrackerTask: (state: RemediationTrackerTaskPageType) => {
      return {
        ...state,
        IsDisable: true,
        RemediationTrackerTask: initialState.RemediationTrackerTask,
        TaskComment: "",
        PageMode: {
          ...state.PageMode,
          IsDisable: true,
        },
        TempFileIdList: [],
      };
    },
    updateFileName: (
      state,
      action: { payload: { Id: number; Name: string } }
    ) => {
      return produce(state, (draftState) => {
        draftState.RemediationTrackerTask.UploadFiles.map((file) => {
          if (file.Id === action.payload.Id) {
            file.FileName = action.payload.Name;
          }
          return file;
        });
        return draftState;
      });
    },
    updateDownloadFileStatus: (state, action: { payload: UploadFileDTO }) => {
      return produce(state, (draftState) => {
        draftState.RemediationTrackerTask.UploadFiles.map((file) => {
          if (file.Id === action.payload.Id) {
            file.DownloadStatus = action.payload.DownloadStatus;
          }
          return file;
        });
        return draftState;
      });
    },

    updateTaskSaveRunningState: (
      state: RemediationTrackerTaskPageType,
      action: {
        payload: {
          saveTaskRunning: boolean;
          remediationTrackerTask: RemediationTrackerTaskDTO;
        };
        type: string;
      }
    ) => {
      return {
        ...state,
        SaveTaskRunning: action.payload.saveTaskRunning,
        TempTaskToSave: action.payload.remediationTrackerTask,
      };
    },

    updateRunSaveTaskState: (
      state: RemediationTrackerTaskPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        RunSaveTask: action.payload,
      };
    },

    updateCommentToggleState: (
      state: RemediationTrackerTaskPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        UpdateThroughCommentToggle: action.payload,
        UpdateThroughHistoryToggle: false,
      };
    },
    updateHistoryToggleState: (
      state: RemediationTrackerTaskPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        UpdateThroughHistoryToggle: action.payload,
        UpdateThroughCommentToggle: false,
      };
    },
    updateTempFileIdList: (
      state: RemediationTrackerTaskPageType,
      action: { payload: number; type: string }
    ) => {
      return {
        ...state,
        TempFileIdList: [...state.TempFileIdList, action.payload],
      };
    },
    navigateToRemediationTrackerTasks: (
      state,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        TaskDeleted: action.payload,
      };
    },
  },
});

export const remediationTrackerTaskReducer =
  remediationTrackerTaskSlice.reducer;

export const remediationTrackerTaskReducerActions =
  remediationTrackerTaskSlice.actions;
