import { createSlice } from "@reduxjs/toolkit";
import { EnumReportType } from "../../common/enums/common.enums";
import { InspectionDTO } from "../../common/types/common.dto.types";
import {
  ReportViewerDownloadStatusType,
  ReportViewerType,
} from "./ReportViewer.types";

// ==============================|| VIEWS - PAGES - REPORT PAGE - REDUCER ||============================== //

const initialState = {
  isLoading: true,
  ReportType: EnumReportType.SummeryReport,
  Inspection: {
    Id: 0,
    Name: "",
  } as InspectionDTO,
  IsReady: false,
  ReportDownloadStatus: [],
} as ReportViewerType;

export const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setLoadingReport: (
      state: ReportViewerType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, isLoading: action.payload };
    },
    setSummaryReport: (
      state: ReportViewerType,
      action: { payload: InspectionDTO; type: string }
    ) => {
      return { ...state, Inspection: action.payload };
    },
    setStandardReport: (
      state: ReportViewerType,
      action: { payload: InspectionDTO; type: string }
    ) => {
      return { ...state, Inspection: action.payload };
    },
    setReportData: (
      state: ReportViewerType,
      action: { payload: any; type: string }
    ) => {
      return { ...state, ReportData: action.payload };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, IsReady: action.payload };
    },

    setReportStatus: (
      state,
      action: { payload: ReportViewerDownloadStatusType }
    ) => {
      const tempList = [...state.ReportDownloadStatus];
      let index = -1;
      if (action.payload.ReportType === EnumReportType.SummeryReport) {
        index = tempList.findIndex(
          (report) =>
            report.InspectionId === action.payload.InspectionId &&
            report.ReportType === action.payload.ReportType
        );
      } else if (action.payload.ReportType === EnumReportType.StandardReport) {
        index = tempList.findIndex(
          (report) =>
            report.InspectionId === action.payload.InspectionId &&
            report.StandardId === action.payload.StandardId &&
            report.StandardName === action.payload.StandardName &&
            report.ReportType === action.payload.ReportType
        );
      } else if (
        action.payload.ReportType ===
        EnumReportType.RemediationTrackerTasksReport
      ) {
        index = tempList.findIndex(
          (report) => report.ReportType === action.payload.ReportType
        );
      }

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            ReportType: action.payload.ReportType,
            InspectionId: action.payload.InspectionId,
            StandardId: tempList[index].StandardId,
            StandardName: action.payload.StandardName,
            IsDownloading: action.payload.IsDownloading,
          } as ReportViewerDownloadStatusType),
        };
      } else {
        tempList.push({
          ReportType: action.payload.ReportType,
          InspectionId: action.payload.InspectionId,
          StandardId: action.payload.StandardId,
          StandardName: action.payload.StandardName,
          IsDownloading: action.payload.IsDownloading,
        } as ReportViewerDownloadStatusType);
      }

      return { ...state, ReportDownloadStatus: tempList };
    },
  },
});

export const {
  setSummaryReport,
  setLoadingReport,
  setStandardReport,
  setIsReportReady,
  setReportData,
  setReportStatus,
} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
