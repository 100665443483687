import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { GET_REMEDIATION_TRACKER_EXCEL_REPORT_DATA } from "./RemediationTrackerTasksReport.actions";
import RemediationTrackerTasksReportApi from "./RemediationTrackerTasksReport.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import {
  RemediationTrackerTasksDownloadStatusType,
  RemediationTrackerTasksReportType,
} from "./RemediationTrackerTasksReport.types";
import { remediationTrackerTasksReportReducerActions } from "./RemediationTrackerTasksReport.reducer";
import dayjs from "dayjs";
import store from "../../../core/store/index";
import { EnumReportType } from "../../../common/enums/common.enums";

const excelWorkerRemediationTrackerTasks: Worker = new Worker(
  new URL("./ExcelWorkerRemediationTrackerTasksReport.ts", import.meta.url)
);

excelWorkerRemediationTrackerTasks.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);

  let downloadFileName = "";

  if (event.data.reportType === EnumReportType.RemediationTrackerTasksReport) {
    downloadFileName = `Remediation Tracker Tasks Report -  ${dayjs(
      new Date()
    ).format("DD-MMM-YYYY HH_mm")}`;
  } else if (
    event.data.reportType === EnumReportType.PendingApprovalsByRemediationTask
  ) {
    downloadFileName = `Task(s) Pending Approver(s) Report -  ${dayjs(
      new Date()
    ).format("DD-MMM-YYYY HH_mm")}`;
  } else if (
    event.data.reportType ===
    EnumReportType.RemediationTrackerTaskBasicStatisticReport
  ) {
    downloadFileName = `Basic Statistic Report -  ${dayjs(new Date()).format(
      "DD-MMM-YYYY HH_mm"
    )}`;
  }

  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension != null && extension != "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    remediationTrackerTasksReportReducerActions.setRemediationTrackerTasksReportStatus(
      {
        ExportFullReport: event.data.exportFullReport,
        IsDownloading: false,
        ReportType: EnumReportType.RemediationTrackerTasksReport,
      }
    )
  );

  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerRemediationTrackerTasks.terminate();
  };
};

const remediationTrackerTasksReportWatcher = function* () {
  yield takeEvery(
    GET_REMEDIATION_TRACKER_EXCEL_REPORT_DATA,
    function* (action: AnyAction): any {
      const reportSearchParameter = action.payload as ReportViewerType;
      const exportFullReport =
        reportSearchParameter.ReportSearchParameter == null ? true : false;
      //yield put(setLoading(true));
      try {
        const result: ActionResponseType = yield call(() =>
          RemediationTrackerTasksReportApi.getRemediationTrackerTasksReport(
            reportSearchParameter
          )
        );
        if (result.IsSuccess) {
          const data = {
            RemediationTrackerTasksReport: {
              ReportData: result.Data,
              ReportType: reportSearchParameter?.ReportType,
            },
          } as RemediationTrackerTasksReportType;

          excelWorkerRemediationTrackerTasks.postMessage(data);

          // yield put(
          //   remediationTrackerTasksReportReducerActions.setRemediationTrackerTasksReportData(
          //     {
          //       RemediationTrackerTasksReport: {
          //         ReportData: result.Data,
          //         IsReady: true,
          //       },
          //     } as RemediationTrackerTasksReportType
          //   )
          // );
          // yield put(
          //   remediationTrackerTasksReportReducerActions.setIsReportReady(true)
          // );
        } else {
          yield put(
            remediationTrackerTasksReportReducerActions.setRemediationTrackerTasksReportStatus(
              {
                IsDownloading: false,
                ExportFullReport: exportFullReport,
                ReportType: reportSearchParameter?.ReportType,
              } as RemediationTrackerTasksDownloadStatusType
            )
          );
          yield put(
            openPopup({
              Open: true,
              BodyText:
                "Sorry, An error occured while generating the excel report!",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick() {},
            } as PopupProps)
          );
          excelWorkerRemediationTrackerTasks.terminate();
        }
      } catch {
        yield put(
          remediationTrackerTasksReportReducerActions.setRemediationTrackerTasksReportStatus(
            {
              IsDownloading: false,
              ExportFullReport: exportFullReport,
              ReportType: reportSearchParameter?.ReportType,
            } as RemediationTrackerTasksDownloadStatusType
          )
        );
        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
        excelWorkerRemediationTrackerTasks.terminate();
      }

      //yield put(setLoading(false));
    }
  );
};
const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const remediationTrackerTasksReportSaga = function* () {
  yield all([remediationTrackerTasksReportWatcher()]);
};

export default remediationTrackerTasksReportSaga;
