import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

const client = new ApiClient();

export const getAppConfiguration = async (): Promise<ActionResponseType> => {
  try {
    const URL: string = "AppConfiguration/GetAppConfiguration";
    let data = {} as ActionResponseType;

    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
      });
    return data;
  } catch (error) {
    // console.log(error);
  }
};

export const GetPrivacyNoticeDetailsById = async (
  //userId: Number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `TermsOfUse/GetTermsOfUseDetails`;
    let result = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        result = {} as ActionResponseType;
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
