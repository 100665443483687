import LocationIco from "../../../icons/location.svg";
import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { GETLOCATIONSLIST } from "./LocationsPage.action";
import {
  setLoading,
  setLocationsList,
  setLocationsSearchParameter,
  setPagination,
} from "./LocationsPage.reducer";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { EnumListShowRowCountList } from "../../../common/enums/common.enums";
import { LocationsPageInitialState } from "./LocationsPage.types";
import {
  SearchParameterDTO,
  SiteDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { getLocationsList } from "./LocationsPage.api";
const getLocationsListWatcher = function* (): any {
  // constant
  const icons = { LocationIco };

  yield takeEvery(GETLOCATIONSLIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    //Clear state
    yield put(
      setPagination({
        ItemList: [] as Array<PaginationListItemType>,
        ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        TotalRecordsCount: 0,
        SelectedPage: 1,
        StatusSummaryCount: {} as StatusSummaryCountDTO,
      } as HPRListPaginationType)
    );
    yield put(setLocationsList({} as LocationsPageInitialState));

    const searchPara: SearchParameterDTO = action.payload;
    const result: LocationsPageInitialState = yield call(() =>
      getLocationsList(searchPara)
    );
    if (result == null && !result) {
    } else {
      yield put(
        setPagination({
          ItemList: result.Data?.Data?.map((location: SiteDTO) => {
            return {
              Icon: icons.LocationIco,
              ShowCommonListIcon: true,
              Id: location.Id,
              ItemHeading: location.SiteName,
              Status: location.StatusName,
              StatusID: location.Status,
              Region: location.Region,
              Country: location.Country,
              Version: location.Version?.toFixed(1),
              ModifiedBy: location.ModifiedBy,
              ModifiedDate: location.ModifiedDate,
            } as PaginationListItemType;
          }),
          ItemShowCount: result.Data?.PageSize,
          TotalRecordsCount: result.Data?.TotalRecordsCount,
          StatusSummaryCount: result.Data?.StatusSummaryCount,
          SelectedPage: result.Data?.SelectedPage,
        } as HPRListPaginationType)
      );
      yield put(setLocationsList(result));
    }
    yield put(setLocationsSearchParameter(action.payload));
    yield put(setLoading(false));
  });
};

const locationsListSaga = function* () {
  yield all([getLocationsListWatcher()]);
};

export default locationsListSaga;
