import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { GET_INSPECTION_SET_REPORT_EXCEL } from "./InspectionSetReport.action";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import inspectionSetReportApi from "./InspectionSetReport.api";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { InspectionSetReportReducerActions } from "./InspectionSetReport.reducer";
import {
  InspectionSetReportDownloadStatusType,
  InspectionSetReportType,
} from "./InspectionSetReport.type";

import dayjs from "dayjs";
import store from "../../../core/store/index";

const excelWorkerInspectionSetReport: Worker = new Worker(
  new URL("./ExcelWorkerInspectionSetReport.ts", import.meta.url)
);

excelWorkerInspectionSetReport.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  let downloadFileName = `${event.data.name} - Questions - ${dayjs(
    new Date()
  ).format("DD-MMM-YYYY HH_mm")}`;
  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension != null && extension != "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    InspectionSetReportReducerActions.setExcelReportStatus({
      InspectionSetId: event.data.inspectionSetId,
      IsDownloading: false,
    } as InspectionSetReportDownloadStatusType)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerInspectionSetReport.terminate();
  };
};

const inspectionSetReportWatcher = function* () {
  yield takeEvery(
    GET_INSPECTION_SET_REPORT_EXCEL,
    function* (action: AnyAction): any {
      const data = action.payload as ReportViewerType;

      const inspectionSetId =
        data?.ReportSearchParameter?.InspectionSetSearchParams?.Id;

      const inspectionSetName =
        data?.ReportSearchParameter?.InspectionSetSearchParams?.Name;
      //yield put(setLoading(true));
      try {
        const result: ActionResponseType = yield call(() =>
          inspectionSetReportApi.getInspectionSetReport(data)
        );
        if (result.IsSuccess) {
          const data = {
            reportData: {
              InspectionSetReport: {
                ReportData: result.Data,
              },
            } as InspectionSetReportType,
            inspectionSetId: inspectionSetId,
            inspectionSetName: inspectionSetName,
          };
          excelWorkerInspectionSetReport.postMessage(data);
          // yield put(
          //   InspectionSetReportReducerActions.setInspectionSetReportData({
          //     InspectionSetReport: {
          //       ReportData: result.Data,
          //       IsReady: true,
          //     },
          //   } as InspectionSetReportType)
          // );
        } else {
          yield put(
            InspectionSetReportReducerActions.setExcelReportStatus({
              InspectionSetId: inspectionSetId,
              IsDownloading: false,
            } as InspectionSetReportDownloadStatusType)
          );

          yield put(
            openPopup({
              Open: true,
              BodyText:
                "Sorry, An error occured while generating the excel report!",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick() {},
            } as PopupProps)
          );

          excelWorkerInspectionSetReport.terminate();
        }
      } catch (error) {
        yield put(
          InspectionSetReportReducerActions.setExcelReportStatus({
            InspectionSetId: inspectionSetId,
            IsDownloading: false,
          } as InspectionSetReportDownloadStatusType)
        );

        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );

        excelWorkerInspectionSetReport.terminate();
      }
      //yield put(setLoading(false));
    }
  );
};

const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const inspectionSetReportSaga = function* () {
  yield all([inspectionSetReportWatcher()]);
};

export default inspectionSetReportSaga;
