import { createSlice } from "@reduxjs/toolkit";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import {
  InspectionSetReportDownloadStatusType,
  InspectionSetReportType,
} from "./InspectionSetReport.type";

const initialState = {
  InspectionSetReport: {} as ReportViewerType,
  InspectionSetExcelReportStatus: [],
} as InspectionSetReportType;

const inspectionSetReportPageSlice = createSlice({
  name: "InspectionSetReport",
  initialState: initialState,
  reducers: {
    setInspectionSetReportData: (
      state,
      action: { payload: InspectionSetReportType }
    ) => {
      return {
        ...state,
        InspectionSetReport: action.payload?.InspectionSetReport,
      };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },

    setExcelReportStatus: (
      state,
      action: { payload: InspectionSetReportDownloadStatusType }
    ) => {
      const tempList = [...state.InspectionSetExcelReportStatus];
      const index = tempList.findIndex(
        (inspectionSet) =>
          inspectionSet.InspectionSetId === action.payload.InspectionSetId
      );

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            InspectionSetId: tempList[index].InspectionSetId,
            IsDownloading: action.payload.IsDownloading,
          } as InspectionSetReportDownloadStatusType),
        };
      } else {
        tempList.push({
          InspectionSetId: action.payload.InspectionSetId,
          IsDownloading: action.payload.IsDownloading,
        } as InspectionSetReportDownloadStatusType);
      }

      return { ...state, InspectionSetExcelReportStatus: tempList };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const InspectionSetReportReducerActions =
  inspectionSetReportPageSlice.actions;
export const inspectionSetReportReducer = inspectionSetReportPageSlice.reducer;
