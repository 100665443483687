import { createSlice } from "@reduxjs/toolkit";
import {
  QuestionnaireSummaryPageInitialState,
  ReassessedResponse,
} from "./QuestionnaireSummaryPage.types";

const initialState = {
  Loading: false,
  Questions: 0,
  AnsweredWithoutGaps: 0,
  AnsweredWithGaps: 0,
  Skipped: 0,
  TotalGaps: 0,
  HasError: false,
  Error: "",
  Reassessed: null,
  SiteInspectionId: 0,
  StandardId: 0,
  InspectionStandardId: 0,
  AnsweredAsNA: 0,
} as QuestionnaireSummaryPageInitialState;

const questionnaireSummaryPageSlice = createSlice({
  name: "questionnaireSummary",
  initialState: initialState,
  reducers: {
    setSummary: (
      state,
      action: { payload: QuestionnaireSummaryPageInitialState }
    ) => {
      return { ...state, ...action.payload };
    },
    setLoading: (state, action: { payload: boolean }) => {
      return { ...state, Loading: action.payload };
    },
    setError: (state, action: { payload: string }) => {
      return { ...state, HasError: true, Error: action.payload };
    },
    clearError: (state) => {
      return { ...state, HasError: false, Error: "" };
    },
    setReassessed: (state, action: { payload: ReassessedResponse }) => {
      return { ...state, Reassessed: action.payload };
    },
    clearReassessed: (state) => {
      return { ...state, Reassessed: null };
    },
    clearPageState: () => {
      return initialState;
    },
  },
});

export const questionnaireSummaryPageReducerActions =
  questionnaireSummaryPageSlice.actions;
export const questionnaireSummaryPageReducer =
  questionnaireSummaryPageSlice.reducer;
