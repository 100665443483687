// ==============================|| COMPONENTS - COMMON - HPRFILTERNODE ||============================== //

import { FormControlLabel, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { HPRFilterNodeProps } from "./HPRFilterNode.types";

const HPRFilterNode = (props: HPRFilterNodeProps) => {
  return (
    <>
      <Tooltip title={props.Label}>
        <FormControlLabel
          id={props.Id}
          name={props.Name}
          value={props.Value}
          label={props.Label}
          disabled={props.Disabled ? props.Disabled : false}
          control={<></>}
          className={props.ClassName}
        ></FormControlLabel>
      </Tooltip>
    </>
  );
};

export default HPRFilterNode;
