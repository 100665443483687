import { AnyAction } from "redux";
import { all, call, put, select, takeEvery } from "@redux-saga/core/effects";

import {
  FETCHSYSTEMGROUPBYID,
  GETAZUREGROUPSITELIST,
  SAVEAZUREGROUPSITELIST,
  SAVE_USERROLE_ADGROUP,
} from "./UserManagementLocations.actions";

import {
  getAzureGroupSiteList,
  getSystemGroupById,
  saveAzureGroupSiteList,
  saveUserRoleManagementAdgroup,
} from "./UserRoleManagementLocations.api";

import {
  SaveAzureGroupSiteListDTO,
  UserRoleManagementLocationsPaginationDTO,
  UserRoleManagementLocationsType,
} from "./UserRoleManagementLocationstypes";
import {
  setSystemGroup,
  setUserRoleManagementLocationList,
} from "./UserRoleManagementLocations.reducer";
import { setLoading } from "../../../layouts/main-layout/MainLayout.reducer";
import {
  SearchParameterDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../../common/types/common.types";
import { openPopup } from "../../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../../components/Popups/PopupProps.types";

const getAzureGruopSiteListWatcher = function* (): any {
  yield takeEvery(GETAZUREGROUPSITELIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // get data from backend
    try {
      const systemGroupsSearchParams: SearchParameterDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        getAzureGroupSiteList(systemGroupsSearchParams)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No Building data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const paginationtype: UserRoleManagementLocationsType = {
            PagedData: result.Data as UserRoleManagementLocationsPaginationDTO,
          } as UserRoleManagementLocationsType;
          yield put(setUserRoleManagementLocationList(paginationtype));
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const saveAzureGroupSiteListWatcher = function* (): any {
  yield takeEvery(SAVEAZUREGROUPSITELIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    try {
      const azureGroupList: SaveAzureGroupSiteListDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        saveAzureGroupSiteList(azureGroupList)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );

        const resultList: ActionResponseType = yield call(() =>
          getAzureGroupSiteList(azureGroupList.SearchParams)
        );
        if (!resultList.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (resultList === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "System Group Site List Data Show", //"No Building data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
              } as PopupProps)
            );
          } else {
            const paginationtype: UserRoleManagementLocationsType = {
              PagedData:
                resultList.Data as UserRoleManagementLocationsPaginationDTO,
            } as UserRoleManagementLocationsType;
            yield put(setUserRoleManagementLocationList(paginationtype));
          }
        }
      } else {
        //set saved data
        if (result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "AD Group Name(s) updated successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        }
        const resultList: ActionResponseType = yield call(() =>
          getAzureGroupSiteList(azureGroupList.SearchParams)
        );
        if (!resultList.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (resultList === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "System Group Site List Data Show", //"No Building data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
              } as PopupProps)
            );
          } else {
            const paginationtype: UserRoleManagementLocationsType = {
              PagedData:
                resultList.Data as UserRoleManagementLocationsPaginationDTO,
            } as UserRoleManagementLocationsType;
            yield put(setUserRoleManagementLocationList(paginationtype));
          }
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const saveUserRoleManagementADGroupWatcher = function* (): any {
  yield takeEvery(SAVE_USERROLE_ADGROUP, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    try {
      const systemGroup: SystemGroupDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        saveUserRoleManagementAdgroup(systemGroup)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //set saved data
        if (result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "AD Group Name(s) updated successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        }
        yield put(setSystemGroup(result.Data));
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const fetchSystemGroupByIdWatcher = function* (): any {
  //const { t } = useTranslation();
  yield takeEvery(FETCHSYSTEMGROUPBYID, function* (action: AnyAction): any {
    const groupId: number = action.payload;
    yield put(setLoading(true));

    try {
      const result: ActionResponseType = yield call(() =>
        getSystemGroupById(groupId)
      );

      if (result.IsSuccess) {
        yield put(setSystemGroup(result.Data));
      }
    } catch (e) {
      console.log(e);
    }
    yield put(setLoading(false));
  });
};

const userRoleManagementLocationsSaga = function* (): any {
  yield all([
    getAzureGruopSiteListWatcher(),
    saveAzureGroupSiteListWatcher(),
    saveUserRoleManagementADGroupWatcher(),
    fetchSystemGroupByIdWatcher(),
  ]);
};

export default userRoleManagementLocationsSaga;
