// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY  - ACTION ||============================== //

export const GETINSPECTIONQUESTIONHISTORYDETAILS =
  "GETINSPECTIONQUESTIONHISTORYDETAILS";

export const getInspectionQuestionHistoryDetails = (
  inspectionQuestionId: number
) => {
  return {
    type: GETINSPECTIONQUESTIONHISTORYDETAILS,
    payload: inspectionQuestionId,
  };
};
