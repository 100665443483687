import { SearchParameterDTO } from "../../../common/types/common.dto.types";

export const GETSITEINSPECTIONSLIST = "GETSITEINSPECTIONSLIST";
export const GETSITEINSPECTIONSTATAUS = "GETSITEINSPECTIONSTATAUS";

const siteInspectionsSagaActions = {
  getSiteInspectionsList: (searchParameter: SearchParameterDTO) => {
    return {
      type: GETSITEINSPECTIONSLIST,
      payload: searchParameter,
    };
  },

  checkSiteInspectionsStatus: (inspectionIds: Array<number>) => {
    return {
      type: GETSITEINSPECTIONSTATAUS,
      payload: inspectionIds,
    };
  },
};

export default siteInspectionsSagaActions;
