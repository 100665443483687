import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_INSPECTION_EXCEL_REPORT_DATA =
  "GET_INSPECTION_EXCEL_REPORT_DATA";

const inspectionReportSagaActions = {
  getInspectionSummary: (parameters: ReportViewerType) => {
    return {
      type: GET_INSPECTION_EXCEL_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default inspectionReportSagaActions;
