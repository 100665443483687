// project imports
import {
  SearchParameterDTO,
  StandardSystemDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

export const getStandardSystemList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSystem/GetStandardSystemList`;
    let standardSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        standardSystem = response;
      })
      .catch((error) => {
        // console.log(error);
        standardSystem = {} as ActionResponseType;
      });
    return standardSystem;
  } catch (error) {
    throw error;
  }
};

export const saveStandardSystem = async (
  Params: StandardSystemDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSystem/SaveStandardSystem`;
    let standardSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSystem = response;
      })
      .catch((error) => {
        standardSystem = {} as ActionResponseType;
        return error;
      });
    return standardSystem;
  } catch (error) {
    throw error;
  }
};

export const updateStandardSystem = async (
  Params: StandardSystemDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSystem/UpdateStandardSystem`;
    let standardSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSystem = response;
      })
      .catch((error) => {
        standardSystem = {} as ActionResponseType;
        return error;
      });
    return standardSystem;
  } catch (error) {
    throw error;
  }
};

export const deleteStandardSystem = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSystem/DeleteStandardSystemById/${id}`;
    let standardSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response) => {
        standardSystem = response;
      })
      .catch((error) => {
        return error;
      });
    return standardSystem;
  } catch (error) {
    throw error;
  }
};
