import SimpleReactValidator from "simple-react-validator";
import dayjs from "dayjs";

import { createSlice } from "@reduxjs/toolkit";
import {
  UserRoleManagementLocationsPaginationDTO,
  UserRoleManagementLocationsType,
} from "./UserRoleManagementLocationstypes";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumStatus,
} from "../../../../common/enums/common.enums";
import { PageModeType } from "../../../../common/types/common.page.type";
import {
  SearchParameterDTO,
  SystemGroupAzureGroupSiteDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";

const initialState: UserRoleManagementLocationsType = {
  IsDisable: false,
  IsLoading: false,
  GroupName: "",
  Id: 0,
  PageMode: {
    PageMode: EnumPageMode.ViewMode,
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  PagedData: {
    Data: [] as SystemGroupAzureGroupSiteDTO[],
    PageSize: 10,
    SelectedPage: 1,
    TotalRecordsCount: 0,
  } as UserRoleManagementLocationsPaginationDTO,
  UserRoleManagementSearchParameter: {
    IsAdvanceSearch: false,
    SelectedPage: 1,
    Field: "GroupName",
    Sort: "",
    SearchText: "",
    SystemGroupSearchParams: {
      ShowDeactivated: false,
    } as SystemGroupDTO,
  } as SearchParameterDTO,
  SystemGroup: {
    Id: 0,
    GroupName: "",
    Status: EnumStatus.Published,
    ModifiedById: 0,
    ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
  } as SystemGroupDTO,
  SystemGroupModalOpen: false,
  Validator: new SimpleReactValidator(),
  ButtonModeType: EnumButtonModeType.Save,
  ShowErrorMessages: false,
  ExportPressed: false,
};

export const UserRoleManagementLocationsSlice = createSlice({
  name: "userRoleManagement",
  initialState,
  reducers: {
    setLoading: (
      state: UserRoleManagementLocationsType,
      action: { payload: boolean; type: string }
    ) => {
      state.IsLoading = action.payload;
    },
    setPagination: (
      state: UserRoleManagementLocationsType,
      action: { payload: UserRoleManagementLocationsType; type: string }
    ) => {
      state.PagedData = action.payload.PagedData;
    },
    setUserRoleManagementLocationList: (
      state: UserRoleManagementLocationsType,
      action: { payload: UserRoleManagementLocationsType; type: string }
    ) => {
      state.PagedData = action.payload.PagedData;
    },
    setPageMode: (
      state: UserRoleManagementLocationsType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (action.payload === EnumPageMode.AddMode) {
        state.PageMode.PageMode = EnumPageMode.AddMode;
        state.PageMode.IsShowAddBtn = true;
        state.PageMode.IsShowEditBtn = false;
        state.PageMode.IsShowDeleteBtn = false;
        state.PageMode.IsShowCancelBtn = false;
        state.PageMode.IsShowSaveBtn = false;
        state.PageMode.IsShowUpdateBtn = false;
        state.PageMode.IsShowCloseBtn = false;
        state.PageMode.IsDisable = true;
        state.SystemGroup.Id = 0;
        state.SystemGroup.GroupName = "";
        state.SystemGroup.Status = EnumStatus.Published;
        state.SystemGroup.ModifiedDate = dayjs(new Date()).format("YYYY-MM-DD");
      } else if (action.payload === EnumPageMode.EditMode) {
        state.PageMode.PageMode = EnumPageMode.EditMode;
        state.PageMode.IsShowAddBtn = false;
        state.PageMode.IsShowEditBtn = false;
        state.PageMode.IsShowDeleteBtn = true;
        state.PageMode.IsShowCancelBtn = true;
        state.PageMode.IsShowSaveBtn = false;
        state.PageMode.IsShowUpdateBtn = true;
        state.PageMode.IsShowCloseBtn = false;
        state.PageMode.IsDisable = true;
      } else if (action.payload === EnumPageMode.ViewMode) {
        state.PageMode.PageMode = EnumPageMode.ViewMode;
        state.PageMode.IsShowAddBtn = true;
        state.PageMode.IsShowEditBtn = false;
        state.PageMode.IsShowDeleteBtn = false;
        state.PageMode.IsShowCancelBtn = false;
        state.PageMode.IsShowSaveBtn = false;
        state.PageMode.IsShowUpdateBtn = false;
        state.PageMode.IsShowCloseBtn = false;
        state.PageMode.IsDisable = true;
      }
    },
    setSystemGroup: (
      state: UserRoleManagementLocationsType,
      action: { payload: SystemGroupDTO; type: string }
    ) => {
      state.SystemGroup = action.payload;
    },
    setGroupName: (
      state: UserRoleManagementLocationsType,
      action: { payload: string; type: string }
    ) => {
      state.SystemGroup.GroupName = action.payload;
    },
  },
});

export const {
  setLoading,
  setPagination,
  setUserRoleManagementLocationList,
  setPageMode,
  setSystemGroup,
  setGroupName,
} = UserRoleManagementLocationsSlice.actions;
export const userRoleManagementLocationsReducer =
  UserRoleManagementLocationsSlice.reducer;
