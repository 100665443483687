// Redux imports
import { createSlice } from "@reduxjs/toolkit";
// Project imports
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import {
  EmployeeDTO,
  StatusSummaryCountDTO,
  StandardDTO,
  StandardSmeDTO,
  StatusDTO,
  DropDownDTO,
} from "../../../common/types/common.dto.types";
import { StandardPageType } from "./StandardPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";

// ==============================|| VIEWS - PAGES - STANDARD PAGE - REDUCER ||============================== //

// Set initial state values for StandardPageType
const initialState = {
  PageMode: {
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    IsShowDeactivateBtn: false,
    PageMode: EnumPageMode.Default,
  } as PageModeType,
  Standard: {
    Name: "",
    PrimarySME: {
      Sme: { Id: 0, DisplayName: "", Uuid: "" } as EmployeeDTO,
      Smeid: 0,
    } as StandardSmeDTO,
    SecondarySME: [] as Array<StandardSmeDTO>,
    StatusNavigation: { Status1: "Draft" } as StatusDTO,
    QuestionsSummary: {
      All: 0,
      Archive: 0,
      Deactivated: 0,
      Draft: 0,
      Published: 0,
    } as StatusSummaryCountDTO,
    Version: 0.1,
    StandardSmes: [] as Array<StandardSmeDTO>,
    Status: EnumStatus.Draft,
    StandardSmeDropDownList: [] as Array<DropDownDTO>,
  } as StandardDTO,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
} as StandardPageType;

// Create standard slice for reducer
export const standardSlice = createSlice({
  name: "standard",
  initialState: initialState,
  reducers: {
    setStandard: (
      state: StandardPageType,
      action: { payload: StandardDTO; type: string }
    ) => {
      return {
        ...state,
        IsDisable: true,
        Standard: action.payload,
      };
    },
    reSetStandard: (state: StandardPageType) => {
      return {
        ...state,
        IsDisable: true,
        Standard: initialState.Standard,
      };
    },
    setReadOnlyMode: (
      state: StandardPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsDisable: action.payload };
    },
    changePageMode: (
      state: StandardPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowDeactivateBtn: true,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDeactivateBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDeactivateBtn: false,
          },
          Standard: initialState.Standard,
        };
      }
    },
    setStandardSmes: (
      state: StandardPageType,
      action: { payload: StandardDTO; type: string }
    ) => {
      return {
        ...state,
        IsDisable: true,
        Standard: {
          ...state.Standard,
          Name: action.payload.Name,
          PrimarySME: action.payload.PrimarySME,
          SecondarySME: action.payload.SecondarySME,
        },
      };
    },
    setStandardSmeDropDownList: (
      state: StandardPageType,
      action: { payload: Array<DropDownDTO>; type: string }
    ) => {
      return {
        ...state,
        Standard: {
          ...state.Standard,
          StandardSmeDropDownList: action.payload ?? ([] as Array<DropDownDTO>),
        },
      };
    },
  },
});

export const {
  setStandard,
  setReadOnlyMode,
  changePageMode,
  reSetStandard,
  setStandardSmes,
  setStandardSmeDropDownList,
} = standardSlice.actions;

export const standardReducer = standardSlice.reducer;
