import SimpleReactValidator from "simple-react-validator";
import {
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";

/* ==============================|| VIEWS - PAGES - INSPECTION SET - TYPES ||==============================  */
export interface InspectionSetPageParametersType {
  InspectionSetId: number;
}

export interface InspectionSetPageType {
  IsDisable: boolean;
  IsLoading: boolean;
  PageMode: PageModeType;
  ConfirmationPopupOpen: boolean;
  ErrorPopupOpen: boolean;
  HeaderText: string;
  Validator: SimpleReactValidator;
  ShowErrorMsg: boolean;
  InspectionSet: InspectionSetListTypeDTO;
  SearchInspectionSetListAvailable: InspectionSetSelectDTO;
  SearchInspectionSetListSelected: InspectionSetSelectDTO;
  CheckAllAvailable: boolean;
  CheckAllSelected: boolean;
  ClickInspectionSetItem: InspectionSetSelectDTO;
  OriginalInspectionSet: InspectionSetListTypeDTO;
}

export interface ShowListModeType {
  StandardShow: boolean;
  QuestionShow: boolean;
  ReferenceShow: boolean;
  SystemShow: boolean;
  SubSystemShow: boolean;
  SubjectShow: boolean;
  SectionShow: boolean;
}

export enum EnumListType {
  Available = 1,
  Selected = 2,
}
