import { SearchParameterDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - INSPECTIONSET - ACTION ||============================== //

export const GETINSPECTIONSETLIST = "GETINSPECTIONSETLIST";

export const getInspectionSetList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETINSPECTIONSETLIST,
    payload: searchParameter,
  };
};
