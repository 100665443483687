import { GridToolbarContainer } from "@mui/x-data-grid";
import HPRDataGridColumnToolbar from "./HPRDataGridColumnToolbar";
import { HPRDataGridToolbarProps } from "./HPRDataGrid.types";
import HPRDataGridReportToolbar from "./HPRDataGridReportToolbar";
import { Grid } from "@mui/material";
import HPRDataGridPaginationToolbar from "./HPRDataGridPaginationToolbar";
// ==============================|| HPR - DATAGRID Toolbar ||============================== //

const HPRDataGridToolbar = (props: HPRDataGridToolbarProps) => {
  const setNoRecordsFoundMsg = (TotalRecordsCount: number): string => {
    let msg = "";
    if (TotalRecordsCount === 0) {
      msg = "No records found";
    } else if (TotalRecordsCount === 1) {
      msg = "1 record found";
    } else if (TotalRecordsCount > 1) {
      msg = `${TotalRecordsCount} records found`;
    }

    return msg;
  };

  return (
    // <GridToolbarContainer>
    <>
      <Grid container spacing={0} sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={7}
          style={{
            visibility: props.ShowTotalRecordsCount ? "visible" : "hidden",
          }}
        >
          <div className="records-found" style={{ marginTop: 7 }}>
            {setNoRecordsFoundMsg(
              props.TotalRecordsCount ? props.TotalRecordsCount : 0
            )}
          </div>
        </Grid>

        <Grid
          item
          container
          xs={5}
          spacing={0}
          sx={{ display: "inline-flex" }}
          className="fx-right"
        >
          {/* {props?.HPRDataGridToolbarReportProps?.ShowReport && ( */}
          <HPRDataGridReportToolbar
            ShowReport
            ReportViewer={props.HPRDataGridToolbarReportProps?.ReportViewer}
          ></HPRDataGridReportToolbar>
          {/* )} */}

          {props?.HPRDataGridToolbarColumnProps?.ShowColumnOption && (
            <HPRDataGridColumnToolbar
              Columns={props?.HPRDataGridToolbarColumnProps?.Columns}
            />
          )}

          {props?.GridPagination && !props?.GridPagination?.HidePerPage && (
            <HPRDataGridPaginationToolbar
              defaultValue={props?.GridPagination?.defaultValue}
              onChangePerPage={props?.GridPagination?.onChangePerPage}
              PerPageItemList={props?.GridPagination?.PerPageItemList}
            />
          )}
        </Grid>
      </Grid>
      <div></div>
    </>
    // </GridToolbarContainer>
  );
};
export default HPRDataGridToolbar;
