// project imports
import {
  SearchParameterDTO,
  StandardReferenceDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

export const getStandardReferenceList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardReference/GetStandardReferenceList`;
    let result = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        result = response;
      })
      .catch((error) => {
        // console.log(error);
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveStandardReference = async (
  Params: StandardReferenceDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardReference/SaveStandardReference`;
    let standardReference = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardReference = response;
      })
      .catch((error) => {
        standardReference = {} as ActionResponseType;
        return error;
      });
    return standardReference;
  } catch (error) {
    throw error;
  }
};

export const updateStandardReference = async (
  Params: StandardReferenceDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardReference/UpdateStandardReference`;
    let standardReference = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardReference = response;
      })
      .catch((error) => {
        standardReference = {} as ActionResponseType;
        return error;
      });
    return standardReference;
  } catch (error) {
    throw error;
  }
};

export const deleteStandardReference = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardReference/DeleteStandardReferenceById/${id}`;
    let standardReference = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response) => {
        standardReference = response;
      })
      .catch((error) => {
        return error;
      });
    return standardReference;
  } catch (error) {
    throw error;
  }
};
