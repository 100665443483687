import { createSlice } from "@reduxjs/toolkit";
import {
  EnumStandardSortType,
  EnumListShowRowCountList,
  EnumStatus,
  EnumSortTypes,
  EnumGridDataShowRowCount,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";

// project imports
import { StandardsPageInitialState } from "./StandardsPage.types";

// ==============================|| VIEWS - PAGES - STANDARD - REDUCER ||============================== //

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  StandardSearchParameter: {
    StandardSearchParams: {
      Name: "",
      Status: EnumStatus.Published,
    } as StandardDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    //GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
    SortByNavigation: {
      Label: "Name",
      Value: EnumStandardSortType.Name.toString(),
      SortType: EnumSortTypes.Ascending,
    } as SortDropDownDTO,
    BasicSearchValue: "",
  } as SearchParameterDTO,
} as StandardsPageInitialState;

export const standardSlice = createSlice({
  name: "standardList",
  initialState: initialState,
  reducers: {
    setStandardList: (
      state: StandardsPageInitialState,
      action: { payload: StandardsPageInitialState; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.Data,
      };
    },
    setLoading: (state: StandardsPageInitialState, action) => {
      return { ...state, Loading: action.payload };
    },
    setPagination: (
      state: StandardsPageInitialState,
      action: { payload: HPRListPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setStandardSearchParameter: (
      state: StandardsPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardSearchParameter: {
          ...state.StandardSearchParameter,
          StandardSearchParams: action.payload.StandardSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          ListPageSize: action.payload.ListPageSize,
          SortByNavigation: action.payload.SortByNavigation,
          BasicSearchValue: action.payload.BasicSearchValue,
          FromDate: action.payload.FromDate,
          ToDate: action.payload.ToDate,
        } as SearchParameterDTO,
      };
    },
  },
});

export const {
  setStandardList,
  setLoading,
  setPagination,
  setStandardSearchParameter,
} = standardSlice.actions;
export const standardsReducer = standardSlice.reducer;
