import {
  InspectionDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION - ACTION ||============================== //

export const GETINSPECTIONSETLISTFORINSPECTIONALLOCATION =
  "GETINSPECTIONSETLISTFORINSPECTIONALLOCATION";

export const GETSITEINSPECTIONALLOCATIONLIST =
  "GETSITEINSPECTIONALLOCATIONLIST";

export const SAVEINSPECTIONALLOCATION = "SAVEINSPECTIONALLOCATION";

export const UPDATEINSPECTIONALLOCATION = "UPDATEINSPECTIONALLOCATION";

export const DELETEINSPECTIONALLOCATION = "DELETEINSPECTIONALLOCATION";

export const getInspectionSetListForInspectionAllocation = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETINSPECTIONSETLISTFORINSPECTIONALLOCATION,
    payload: searchParameter,
  };
};

export const getSiteInspectionAllocationList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETSITEINSPECTIONALLOCATIONLIST,
    payload: searchParameter,
  };
};

export const saveInspectionAllocation = (Inspection: InspectionDTO) => {
  return {
    type: SAVEINSPECTIONALLOCATION,
    payload: Inspection,
  };
};

export const updateInspectionAllocation = (Inspection: InspectionDTO) => {
  return {
    type: UPDATEINSPECTIONALLOCATION,
    payload: Inspection,
  };
};

export const deleteInspectionAllocation = (inspection: InspectionDTO) => {
  return {
    type: DELETEINSPECTIONALLOCATION,
    payload: inspection,
  };
};
