import { InspectionQuestionDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION QUESTION - ACTION ||============================== //

export const GETINSPECTIONQUESTIONDETAILS = "GETINSPECTIONQUESTIONDETAILS";

export const getViewInspectionQuestionDetails = (
  inspectionQuestion: InspectionQuestionDTO
) => {
  return {
    type: GETINSPECTIONQUESTIONDETAILS,
    payload: inspectionQuestion,
  };
};
