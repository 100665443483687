// saga $ redux
import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";

// project imports
import { EnumReportType } from "../../common/enums/common.enums";
import { ActionResponseType } from "../../common/types/common.types";
import { EnumPopupType } from "../Popups/Popup.enum";
import { openPopup } from "../Popups/Popup.reducer";
import { PopupProps } from "../Popups/PopupProps.types";
import { GETREPORT } from "./ReportViewer.action";
import { getReport } from "./ReportViewer.api";
import {
  setIsReportReady,
  setReportData,
  setReportStatus,
} from "./ReportViewer.reducer";
import {
  ReportViewerDownloadStatusType,
  ReportViewerType,
} from "./ReportViewer.types";
import dayjs from "dayjs";
import store from "../../core/store/index";

// ==============================|| VIEWS - PAGES - REPORT - SAGA ||============================== //

const pdfWorkerReportViewer: Worker = new Worker(
  new URL("./PdfWorkerReportViewer.ts", import.meta.url)
);

pdfWorkerReportViewer.onmessage = (event) => {
  const reportData = event.data;
  const extension = getFileExtensionFromMimeType(reportData.blob.type);
  const reportType = event.data.reportType;
  const url = window.URL.createObjectURL(reportData.blob);

  // Create a download link for the Excel file and trigger the download

  if (reportType == EnumReportType.SummeryReport) {
    let summaryReport = document.createElement("a");
    let downloadFileName = `InspectionSummaryReport - ${reportData.inspectionName}`;
    if (extension != null && extension != "") {
      if (!downloadFileName.endsWith(extension)) {
        downloadFileName += extension;
      }
    }
    summaryReport.href = url;
    summaryReport.download = downloadFileName;
    summaryReport.click();
  } else if (reportType == EnumReportType.StandardReport) {
    let standardReport = document.createElement("a");
    let downloadFileName = `StandardSummaryReport - ${
      reportData.inspectionName
    }${" - " + reportData.standardName}${
      " - " + dayjs(new Date()).format("DD-MMM-YYYY HH_mm")
    }`;
    if (extension != null && extension != "") {
      if (!downloadFileName.endsWith(extension)) {
        downloadFileName += extension;
      }
    }
    standardReport.href = url;
    standardReport.download = downloadFileName;
    standardReport.click();
  } else if (reportType == EnumReportType.RemediationTrackerTasksReport) {
    let remediationTrackerReport = document.createElement("a");
    let downloadFileName = `RemediationTrackerTasksReport - ${dayjs(
      new Date()
    ).format("DD-MMM-YYYY HH_mm")}`;
    if (extension != null && extension != "") {
      if (!downloadFileName.endsWith(extension)) {
        downloadFileName += extension;
      }
    }
    remediationTrackerReport.href = url;
    remediationTrackerReport.download = downloadFileName;
    remediationTrackerReport.click();
  }

  window.URL.revokeObjectURL(url);
  store.dispatch(
    setReportStatus({
      ReportType: reportType,
      InspectionId: reportData.inspectionId,
      StandardId: reportData.standardId,
      StandardName: reportData.standardName,
      IsDownloading: false,
    } as ReportViewerDownloadStatusType)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );
  return () => {
    pdfWorkerReportViewer.terminate();
  };
};

const getReportWatcher = function* (): any {
  yield takeEvery(GETREPORT, function* (action: AnyAction): any {
    // get data from backend
    const Report: ReportViewerType = action.payload;
    const inspectionId = Report?.Inspection?.Id;
    const standardId = Report?.Standard?.Id;
    const standardName = Report?.Standard?.Name;

    try {
      //yield put(setLoadingReport(true));

      const result: ActionResponseType = yield call(() => getReport(Report));

      // Check result status
      if (result.IsSuccess) {
        if (Report.ReportType == EnumReportType.SummeryReport) {
          const data = {
            reportData: result.Data,
            reportType: Report.ReportType,
          };
          pdfWorkerReportViewer.postMessage(data);
          //yield put(setSummaryReport(result.Data));
        } else if (Report.ReportType == EnumReportType.StandardReport) {
          const data = {
            reportData: result.Data,
            reportType: Report.ReportType,
          };
          pdfWorkerReportViewer.postMessage(data);
          //yield put(setStandardReport(result.Data));
        } else if (
          Report.ReportType == EnumReportType.RemediationTrackerTasksReport
        ) {
          const data = {
            reportData: result.Data,
            reportType: Report.ReportType,
          };
          pdfWorkerReportViewer.postMessage(data);
          //yield put(setReportData(result.Data));
        }
        //yield put(setIsReportReady(true));
      } else {
        //yield put(setLoading(false));
        yield put(
          setReportStatus({
            ReportType: Report.ReportType,
            InspectionId: inspectionId,
            StandardId: standardId,
            StandardName: standardName,
            IsDownloading: false,
          } as ReportViewerDownloadStatusType)
        );
        yield put(
          openPopup({
            Open: true,
            BodyText: "Report data not received!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
        pdfWorkerReportViewer.terminate();
        //yield put(setLoadingReport(false));
      }
    } catch (error) {
      //yield put(setLoading(false));
      yield put(
        setReportStatus({
          ReportType: Report.ReportType,
          InspectionId: inspectionId,
          StandardId: standardId,
          StandardName: standardName,
          IsDownloading: false,
        } as ReportViewerDownloadStatusType)
      );
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred while getting Report data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
      pdfWorkerReportViewer.terminate();
      //yield put(setLoadingReport(false));
    }
  });
};

const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};

const reportSaga = function* () {
  yield all([getReportWatcher()]);
};

export default reportSaga;
