import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { ArrowLeft2, DocumentDownload } from "iconsax-react";
import "./QuestionnaireSummary-page.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QuestionnaireSummaryPageInitialState,
  QuestionnaireSummaryPageParametersType,
} from "./QuestionnaireSummaryPage.types";
import {
  InspectionDTO,
  QuestionnaireDTO,
  StandardDTO,
} from "../../../common/types/common.dto.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { useEffect } from "react";
import questionnaireSummaryPageSagaActions from "./QuestionnaireSummaryPage.action";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { questionnaireSummaryPageReducerActions } from "./QuestionnaireSummaryPage.reducer";
import { SiteInspectionStartPageParametersType } from "../SiteInspectionStartPage/SiteInspectionStartPage.types";
import {
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import ReportViewerNew from "../../../components/ReportViewer/ReportViewerNew";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";

const QuestionnaireSummaryPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const reportState = useAppSelector((state) => state.reportReducer);

  const location = useLocation();
  const navigate = useNavigate();
  const questionnaireSummaryState = useAppSelector(
    (s) => s.questionnaireSummaryPageReducer
  );
  const authState = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  const parameter: QuestionnaireSummaryPageParametersType = location.state;

  const initialState = {
    Loading: false,
    Questions: 0,
    AnsweredWithoutGaps: 0,
    AnsweredWithGaps: 0,
    Skipped: 0,
    TotalGaps: 0,
    HasError: false,
    Error: "",
    Reassessed: null,
    SiteInspectionId: 0,
    StandardId: 0,
    InspectionStandardId: 0,
    AnsweredAsNA: 0,
  } as QuestionnaireSummaryPageInitialState;

  useEffect(() => {
    dispatch(questionnaireSummaryPageReducerActions.setSummary(initialState));
    dispatch(
      questionnaireSummaryPageSagaActions.getQuestionnaireSummary(parameter)
    );
  }, []);

  useEffect(() => {
    if (parameter?.ReAssess) {
      dispatch(questionnaireSummaryPageSagaActions.reassess(parameter));
    }
  }, [parameter?.ReAssess]);

  useEffect(() => {
    if (
      questionnaireSummaryState.Reassessed &&
      questionnaireSummaryState.Reassessed.IsSuccess
    ) {
      navigate("/questionnaire", {
        state: {
          SiteInspectionID: parameter.SiteInspectionId,
          StandardID: parameter.StandardId,
          InspectionStandardId: parameter.InspectionStandardId,
        } as QuestionnaireDTO,
      });
      dispatch(questionnaireSummaryPageReducerActions.clearReassessed());
    } else if (
      questionnaireSummaryState.Reassessed &&
      questionnaireSummaryState.Reassessed.IsSuccess === false
    ) {
      dispatch(
        openPopup({
          Open: true,
          HeaderText: "Sorry!",
          BodyText: questionnaireSummaryState.Reassessed.Error,
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }
  }, [questionnaireSummaryState.Reassessed]);

  const backOnClick = () => {
    navigate("/questionnaire", {
      state: {
        StandardID: parameter.StandardId,
        SiteInspectionID: parameter.SiteInspectionId,
        InspectionStandardId: parameter.InspectionStandardId,
        IsSiteInspectionViewButtonClick: true,
      } as QuestionnaireDTO,
    });
  };

  const reassessOnClick = () => {
    dispatch(questionnaireSummaryPageSagaActions.reassess(parameter));
  };

  const backToSiteInspectionStart = () => {
    navigate("/siteInspectionStart", {
      state: {
        InspectionId: parameter.SiteInspectionId,
      } as SiteInspectionStartPageParametersType,
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={questionnaireSummaryState.Loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container>
        <Grid item xs={12}>
          <a
            style={{ display: "inline-flex" }}
            className="cursor-pointer audit-back v-align"
            aria-label="back"
            onClick={backOnClick}
            title={"Back questionnaire page"}
          >
            {/* <ArrowLeft2 size={40} /> */}
            <ArrowBackIosNewOutlinedIcon className="" />
            <span
              className="body-bold secondary-color flex__item--vcenter"
              onClick={backOnClick}
            >
              Back
            </span>
          </a>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={2} lg={1}></Grid>
        <Grid item xl={8} lg={10} md={12} sm={12} xs={12}>
          <div className="outer-wrapper">
            <div className="item-wrapper questionaire-wrap">
              <div className="item-content">
                <h1 className="summary-heading-h1">
                  {questionnaireSummaryState.Heading || "\u00a0\u00a0"}
                </h1>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    className="align-center"
                  >
                    {/* <TickCircle size="72" color="#68C321" /> */}
                    <h1 className="summary-heading-h1 mt-15 green-text">
                      {questionnaireSummaryState.Loading
                        ? "\u00a0\u00a0"
                        : "Completed"}
                    </h1>
                  </Grid>

                  {questionnaireSummaryState.HasError ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                        className="align-center"
                      >
                        <p className="red-text">
                          Sorry, summary is not available at the moment!
                        </p>
                      </Grid>
                    </>
                  ) : (
                    QuestionnaireSummary(
                      questionnaireSummaryState,
                      reassessOnClick,
                      backToSiteInspectionStart,
                      checkSystemFunctionRight
                    )
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const QuestionnaireSummary = (
  questionnaireSummaryState: QuestionnaireSummaryPageInitialState,
  reassessOnClick: () => void,
  backToSiteInspectionStart: () => void,
  checkSystemFunctionRight: (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ) => boolean
) => {
  const dispatch = useAppDispatch();
  // const handleOpenInNewTab = () => {
  //   const searchParams = new URLSearchParams({
  //     ReportType: EnumReportType.StandardReport.toString(),
  //     InspectionID: questionnaireSummaryState.SiteInspectionId.toString(),
  //     StandardID: questionnaireSummaryState.StandardId.toString(),
  //   });
  //   window.open(`/report?${searchParams}`, "_blank");
  // };
  const OnGenaratingReport = (reportProps: any): void => {
    if (reportProps._INTERNAL__LAYOUT__DATA_.children.length > 2) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h1 className="summary-heading-h1 mt-15">Summary</h1>
        </Grid>
        <Grid item xs={12} alignItems="center" className="align-center">
          <span className="info-label-wrappers">
            <span className="info-labels">
              <span className="light-blue-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.Questions}
              </span>
              Questions
            </span>
            <span className="info-labels">
              <span className="green-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.AnsweredWithoutGaps}
              </span>
              Answered without Gaps
            </span>
            <span className="info-labels">
              <span className="red-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.AnsweredWithGaps}
              </span>
              Answered with Gaps
            </span>

            <span className="info-labels">
              <span className="na-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.AnsweredAsNA}
              </span>
              Answered as N/A
            </span>

            <span className="info-labels">
              <span className="orange-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.Skipped}
              </span>
              Skipped
            </span>
          </span>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} alignItems="center" className="align-center">
          <span className="info-label-wrappers">
            <span className="info-labels">
              <span className="red-text">
                {questionnaireSummaryState.Loading
                  ? "\u00a0\u00a0"
                  : questionnaireSummaryState.TotalGaps}
              </span>
              Total Gaps
            </span>
          </span>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Button
            className="light-blue small-btn align-left"
            variant="contained"
            disableElevation
            onClick={backToSiteInspectionStart}
          >
            Back to Site Inspection
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          alignItems="center"
          className="align-center-all"
          style={{
            visibility: checkSystemFunctionRight(
              EnumSystemFunctions.SiteInspectionPage_Download_Standard_Summary,
              EnumSystemFunctionTypes.ButtonAccess
            )
              ? "visible"
              : "hidden",
          }}
        >
          {questionnaireSummaryState.SiteInspectionId > 0 &&
          questionnaireSummaryState.StandardId > 0 ? (
            // <ReportViewer
            //   ReportType={EnumReportType.StandardReport}
            //   Inspection={
            //     {
            //       Id: questionnaireSummaryState.SiteInspectionId,
            //     } as InspectionDTO
            //   }
            //   Standard={
            //     { Id: questionnaireSummaryState.StandardId } as StandardDTO
            //   }
            //   InspectionStandardId={
            //     questionnaireSummaryState.InspectionStandardId
            //   }
            //   ReportLinkName="Download Summary"
            //   ReportDownloadName="SummaryReport"
            //   OnRendering={OnGenaratingReport}
            // ></ReportViewer>
            <ReportViewerNew
              ReportType={EnumReportType.StandardReport}
              Inspection={
                {
                  Id: questionnaireSummaryState.SiteInspectionId,
                } as InspectionDTO
              }
              Standard={
                {
                  Id: questionnaireSummaryState.StandardId,
                  Name: questionnaireSummaryState.Heading,
                } as StandardDTO
              }
              InspectionStandardId={
                questionnaireSummaryState.InspectionStandardId
              }
              ReportLinkName="Download Summary"
              ReportDownloadName="SummaryReport"
              OnRendering={OnGenaratingReport}
            >
              <a
                className="align-center-all download-text"
                href=""
                title={"PDF"}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <DocumentDownload
                  size="42"
                  color="#FF8A65"
                  className="download-ico p-r-10"
                />
                {"Download Summary"}
              </a>
            </ReportViewerNew>
          ) : (
            <></>
          )}

          {/* <a
            className="link-button-type1"
            style={{ float: "none" }}
            onClick={handleOpenInNewTab}
          >
            <DocumentDownload
              size="32"
              color="#FF8A65"
              className="download-ico"
            />
            Download Summary
          </a> */}
        </Grid>
        <Grid item xs={4}>
          {/* <Button
            className="light-blue small-btn align-right"
            variant="contained"
            disableElevation
            onClick={reassessOnClick}
          >
            Re-open
          </Button> */}
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionnaireSummaryPage;
