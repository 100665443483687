import { HPRDataGridToolbarReportProps } from "./HPRDataGrid.types";
import RemediationTrackerTasksExcelDownload from "../../../views/Reports/RemediationTrackerTasksReport/RemediationTrackerTasksReport.Excel";
import { EnumReportType } from "../../../common/enums/common.enums";
// ==============================|| HPR - DATAGRID Column Toolbar ||============================== //

const HPRDataGridReportToolbar = (props: HPRDataGridToolbarReportProps) => {
  return (
    <div className="fx-left">
      {props.ShowReport &&
        props.ReportViewer?.ReportType ===
          EnumReportType.RemediationTrackerTasksReport && (
          <RemediationTrackerTasksExcelDownload
            RemediationTrackerTasksExcelReportFilter={
              props.ReportViewer?.ReportSearchParameter
            }
          />
        )}

      {/* <Button className="colmn-opt mb-10">
        <Stickynote className="m-r-10" style={{ fontSize: 20 }} />
        <span> Report</span>
      </Button> */}
    </div>
  );
};

export default HPRDataGridReportToolbar;
