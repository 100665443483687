import { QuestionnaireDTO } from "../../../common/types/common.dto.types";

export const GETINSPECTIONQUESTION = "GETINSPECTIONQUESTION";
export const RELOADINSPECTIONQUESTION = "RELOADINSPECTIONQUESTION";
export const SAVEINSPECTIONQUESTIONANSWERS = "SAVEINSPECTIONQUESTIONANSWERS";
export const SAVEINSPECTIONSTANDARDSTATUS = "SAVEINSPECTIONSTANDARDSTATUS";
export const INSPECTIONFILEUPLOAD = "INSPECTIONFILEUPLOAD";
export const QUESTIONNAIREPAGESTOPFILEUPLOAD =
  "QUESTIONNAIREPAGESTOPFILEUPLOAD";
export const getSiteInspectionQuestiontData = (
  questionnaireData: QuestionnaireDTO
) => {
  return {
    type: GETINSPECTIONQUESTION,
    payload: questionnaireData,
  };
};

export const saveInspectionQuestionAnswer = (
  questionAnswerData: QuestionnaireDTO
) => {
  return {
    type: SAVEINSPECTIONQUESTIONANSWERS,
    payload: questionAnswerData,
  };
};

export const saveInspectionStandardStatus = (
  questionnaireData: QuestionnaireDTO
) => {
  return {
    type: SAVEINSPECTIONSTANDARDSTATUS,
    payload: questionnaireData,
  };
};

export const inspectionFileUpload = () => {
  return {
    type: INSPECTIONFILEUPLOAD,
  };
};

export const stopFileUpload = () => {
  return {
    type: QUESTIONNAIREPAGESTOPFILEUPLOAD,
  };
};
