import { createSlice } from "@reduxjs/toolkit";
import {
  EnumListShowRowCountList,
  EnumSearchBy,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  DropDownDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";

// project imports
import {
  StandardQuestionsType,
  QuestionDataType,
} from "./StandardsQuestionsPage.types";

// ==============================|| VIEWS - PAGES - STANDARD QUESTION PAGE - REDUCER ||============================== //

interface InitialState {
  StandardQuestions: StandardQuestionsType;
  ListPagination: HPRListPaginationType;
  SearchMenu: Array<DropDownDTO>;
  FilteredStatus: EnumStatus;
  isAddQuestionDisable: boolean;
  StandardId: number;
  SearchStatus: EnumStatus;
  StandardQuestionSearchParameter: SearchParameterDTO;
}

// var StandardTypes = {
//   "1": "By Question",
//   "2": "By Reference",
//   "3": "By System",
//   "4": "By SubSystem",
//   "5": "By Subject",
//   "6": "By Section",
// };
let searchDropdownOptions = Object.entries(EnumSearchBy)
  .map(
    ([label, value]) => ({ Label: `By ${label}`, Value: value } as DropDownDTO)
  )
  .slice((-1 * Object.keys(EnumSearchBy).length) / 2);

const initialState = {
  StandardQuestions: {
    QuestionListData: [] as Array<QuestionDataType>,
  } as StandardQuestionsType,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  SearchMenu: searchDropdownOptions,
  FilteredStatus: EnumStatus.Published,
  isAddQuestionDisable: false,
  StandardId: 0,
  SearchStatus: EnumStatus.Published,
} as InitialState;

export const standardQuestionSlice = createSlice({
  name: "standard",
  initialState: initialState,
  reducers: {
    setStandardDetailsWithQuestionList: (
      state: InitialState,
      action: { payload: StandardQuestionsType; type: string }
    ) => {
      return {
        ...state,
        StandardQuestions: action.payload,
        isAddQuestionDisable:
          action.payload.StandardData.StatusId === EnumStatus.Deactivated
            ? true
            : false,
      };
    },
    setSearchQuestion: (
      state: InitialState,
      action: { payload: StandardQuestionsType; type: string }
    ) => {
      return {
        ...state,
        StandardQuestions: {
          ...state.StandardQuestions,
          QuestionListData: action.payload.QuestionListData,
          QuestionListCount: action.payload.QuestionListCount,
          AllQuestionListCount: action.payload.AllQuestionListCount,
        },
      };
    },
    setSearchStatus: (
      state: InitialState,
      action: { payload: EnumStatus; type: string }
    ) => {
      return {
        ...state,
        SearchStatus: action.payload,
      };
    },
    setStandardDetails: (
      state: InitialState,
      action: { payload: number; type: string }
    ) => {
      return {
        ...state,
        StandardId: action.payload,
      };
    },
    setPagination: (
      state: InitialState,
      action: { payload: HPRListPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setFilteredStatus: (
      state: InitialState,
      action: { payload: EnumStatus; type: string }
    ) => {
      return {
        ...state,
        FilteredStatus: action.payload,
      };
    },
    setByStatusQuestion: (
      state: InitialState,
      action: { payload: StandardQuestionsType; type: string }
    ) => {
      return {
        ...state,
        StandardQuestions: {
          ...state.StandardQuestions,
          QuestionListData: action.payload.QuestionListData,
          QuestionListCount: action.payload.QuestionListCount,
          AllQuestionListCount: action.payload.AllQuestionListCount,
        },
      };
    },
  },
});

export const {
  setStandardDetailsWithQuestionList,
  setSearchQuestion,
  setPagination,
  setByStatusQuestion,
  setFilteredStatus,
  setStandardDetails,
  setSearchStatus,
} = standardQuestionSlice.actions;
export const standardQuestionReducer = standardQuestionSlice.reducer;
