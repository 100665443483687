import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
} from "@redux-saga/core/effects";
import { channel } from "redux-saga";
import { AnyAction } from "redux";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { RootState } from "../../../core/store";
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import {
  CHECKBEFOREDEACTIVATEASSESSMENTTYPEWIZARD,
  GETASSESSMENTTYPEWIZARD,
  saveAssessmentTypeWizard,
  SAVEASSESSMENTTYPEWIZARD,
} from "./AssessmentTypeWizardPage.action";
import { assessmentTypeWizardReducerActions } from "./AssessmentTypeWizardPage.reducer";
import { AssessmentTypeWizardPageType } from "./AssessmentTypeWizardPage.types";
import {
  checkBeforeDeactivateAssessmentTypeWizard,
  getAssessmentTypeWizardByIdApi,
  saveAssessmentTypeWizardApi,
} from "./AssessmentTypeWizardPage.api";

// Get message for popup when saving assessment types
const getStatusMessage = (
  statusChange: boolean,
  status: EnumStatus,
  versionChange: boolean
): string => {
  if (!statusChange) {
    if (versionChange) {
      return "Assessment type updated successfully";
    }
    return "Assessment type saved successfully";
  } else if (status === EnumStatus.Draft) {
    return "Assessment type successfully saved as draft";
  } else if (status === EnumStatus.Published) {
    return "Assessment type successfully published";
  } else if (status === EnumStatus.Deactivated) {
    return "Assessment type successfully deactivated";
  }
  return "Assessment type saved successfully";
};

// Channel for call deactivted AssessmentType from callback function
const deactivateAssessmentTypeChannel = channel();
export function* deactivateAssessmentTypeChannelWatcher() {
  while (true) {
    const action = yield take(deactivateAssessmentTypeChannel);
    yield put(action);
  }
}

// Channel for navigation
const navigationChannel = channel();
export function* navigationChannelWatcher() {
  while (true) {
    const action = yield take(navigationChannel);
    yield put(action);
  }
}

const getAssessmentTypeWizardWatcher = function* (): any {
  yield takeEvery(GETASSESSMENTTYPEWIZARD, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let assessmentTypeId: number = action.payload;

    try {
      let result: ActionResponseType = yield call(() =>
        getAssessmentTypeWizardByIdApi(assessmentTypeId)
      );

      if (result.IsSuccess) {
        const assessmentType: AssessmentTypeDTO = result.Data;

        yield put(
          assessmentTypeWizardReducerActions.setAssessmentTypeWizard(
            assessmentType
          )
        );
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occurred when getting data";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }
    yield put(
      assessmentTypeWizardReducerActions.changePageMode(EnumPageMode.ViewMode)
    );
    yield put(setLoading(false));
  });
};

const saveAssessmentTypeWizardWatcher = function* (): any {
  yield takeEvery(SAVEASSESSMENTTYPEWIZARD, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let assessmentType: AssessmentTypeDTO = action.payload;

    const state: AssessmentTypeWizardPageType = yield select(
      (s: RootState) => s.assessmentTypeWizardReducer
    );

    let statusChange = true;
    let versionChnage: boolean = false;
    let prevStatus = state.AssessmentType.Status;
    let prevVersion = state.AssessmentType.Version;
    if (prevStatus === assessmentType.Status) {
      statusChange = false;
    }

    try {
      let result: ActionResponseType = yield call(() =>
        saveAssessmentTypeWizardApi(assessmentType)
      );

      if (result.IsSuccess) {
        assessmentType = result.Data;

        if (prevVersion !== assessmentType.Version) {
          versionChnage = true;
        }

        yield put(
          openPopup({
            Open: true,
            BodyText: getStatusMessage(
              statusChange,
              assessmentType.Status,
              versionChnage
            ),
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        yield put(
          assessmentTypeWizardReducerActions.setAssessmentTypeWizard(
            assessmentType
          )
        );
        yield put(
          assessmentTypeWizardReducerActions.changePageMode(
            EnumPageMode.ViewMode
          )
        );
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occured when saving the assessment type";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occured when saving the assessment type",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    yield put(setLoading(false));
  });
};

const checkBeforeDeactivateAssessmentTypeWizardWatcher = function* (): any {
  yield takeEvery(CHECKBEFOREDEACTIVATEASSESSMENTTYPEWIZARD, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let assessmentType: AssessmentTypeDTO = action.payload;

    try {
      let result: ActionResponseType = yield call(() =>
        checkBeforeDeactivateAssessmentTypeWizard(assessmentType.Id)
      );

      if (result.IsSuccess) {
        if(result.Data){
          yield put(
            openPopup({
              Open: true,
              BodyText: "Please note that there are active Inspection Set Template(s) associated with the current Assessment Type. Are you sure you want to proceed with deactivation?",
              HeaderText: "Warning",
              PopupType: EnumPopupType.YesNoConfirmation,
              YesBtnClick() {
                deactivateAssessmentTypeChannel.put(saveAssessmentTypeWizard(assessmentType));
              },
            } as PopupProps)
          );
        } else {
          deactivateAssessmentTypeChannel.put(saveAssessmentTypeWizard(assessmentType));
        }
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occured when deactivating the assessment type";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occured when deactivating the assessment type",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    yield put(setLoading(false));
  });
};

const assessmentTypeWizardSaga = function* () {
  yield all([
    getAssessmentTypeWizardWatcher(),
    saveAssessmentTypeWizardWatcher(),
    checkBeforeDeactivateAssessmentTypeWizardWatcher(),
    deactivateAssessmentTypeChannelWatcher(),
    navigationChannelWatcher(),
  ]);
};

export default assessmentTypeWizardSaga;
