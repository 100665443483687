// project imports
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { DataGridRecordsType, HPRDataGridParams } from "./HPRDataGrid.types";

// client object for axios calls to backend
const client = new ApiClient();

const initDataGridRecords = {
  Data: [],
  TotalRows: 0,
  SelectedPage: 1,
} as DataGridRecordsType;

export const getGridDataFromBackend = async (
  url: string,
  gridParams: HPRDataGridParams
): Promise<DataGridRecordsType> => {
  let items = initDataGridRecords;
  try {
    await client
      .post<ActionResponseType>(url, gridParams)
      .then((response: ActionResponseType) => {
        items.Data = response.Data?.Data;
        items.TotalRows = response.Data?.TotalRecordsCount;
        items.SelectedPage = response.Data?.SelectedPage;
        items.PageSize = response?.Data.PageSize;
      })
      .catch((error) => {
        return error;
      });
    return items;
  } catch (error) {
    return items;
  }
};
