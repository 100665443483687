import {
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { DropDownDTO } from "../../../common/types/common.dto.types";
import { getDataFromBackendForMultipleSelectDropDownWithParameter } from "./HPRDropDownMultipleSelect.api";
import { HPRDropDownMultipleSelectProps } from "./HPRDropDownMultipleSelect.types";
import { EnumAuthenticationModes } from "../../../common/enums/common.enums";
import { GetAppOptions } from "../../../common/functions/common.functions";

// ==============================|| COMPONENTS - COMMON - HPRDropDown ||============================== //
interface InitialState {
  SelectedValues: Array<string>;
  SelectOptions: Array<DropDownDTO>;
  SearchText: string;
}

const HPRDropDownMsGraphMultipleSelect = (
  props: HPRDropDownMultipleSelectProps
) => {
  const initialState = {
    SelectedValues: [] as Array<string>,
    SelectOptions: [] as Array<DropDownDTO>,
    SearchText: "",
  } as InitialState;

  const [multipleSelect, setMultipleSelect] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  // const searchBtnClickRef = useRef(false);
  let SelectedDropDownList: Array<string> = props.Select;

  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // If menuitem list received
    if (props.Items && props.Items.length > 0) {
      setMultipleSelect((values) => {
        return {
          ...values,
          SelectOptions: props.Items ? props.Items : ([] as Array<DropDownDTO>),
        };
      });
    }
    // If menuitem list not received
    else {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      if (props.Url && props.Url !== "") {
        getDataFromBackendForMultipleSelectDropDownWithParameter(
          props.Url ? props.Url : ""
        ).then((response) => {
          setMultipleSelect((values) => {
            return { ...values, SelectOptions: response };
          });
        });
      }
    }
  }, [props.Items]);

  const setMultipleSelectSearchText = (newValue: string) => {
    setMultipleSelect((values: any) => {
      return {
        ...values,
        SearchText: newValue,
      };
    });
  };
  // selected option change
  useEffect(() => {
    setLoading(false);
    //setMultipleSelectSearchText("");
  }, [props.Select]);

  const onSelectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: DropDownDTO[]
  ) => {
    // Clear searchText
    setMultipleSelectSearchText("");

    if (props.onMultipleSelectDropDownChange)
      props.onMultipleSelectDropDownChange(
        multipleSelect.SelectOptions,
        value ? value?.map((val) => val?.Value) : ([] as Array<string>)
      );
  };

  const searchMsGraphOptions = (value: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    setLoading(true);
    searchTimeout.current = setTimeout(async () => {
      const result: DropDownDTO[] =
        await getDataFromBackendForMultipleSelectDropDownWithParameter(
          props?.Url,
          value
        );

      const mergeSelectOptions = [
        ...multipleSelect.SelectOptions,
        ...result?.filter(
          (filter) =>
            !multipleSelect.SelectOptions?.some(
              (some) => some.Value?.toString() === filter.Value?.toString()
            )
        ),
      ];

      setMultipleSelect((values: any) => {
        return {
          ...values,
          SelectOptions: mergeSelectOptions,
        };
      });

      setLoading(false);
      searchTimeout.current = null;
    }, 1000);
  };

  const SearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      GetAppOptions().AuthMode ===
      EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication
    ) {
      searchMsGraphOptions(event.target.value);
    }

    //searchBtnClickRef.current = false;
    //setLoading(false);
  };

  // const onSearchBtnClick = () => {
  //   searchBtnClickRef.current = true;
  //   setLoading(true);
  //   searchMsGraphOptions(multipleSelect.SearchText);
  // };

  // const onSearchByEnterBtnClick = (event: any) => {
  //   if (event.keyCode === 13) {
  //     event.preventDefault();
  //     onSearchBtnClick();
  //   }
  // };

  const isOptionEqualToValue = (option: DropDownDTO, value: DropDownDTO) => {
    return option?.Value?.toString() === value?.Value?.toString();
  };

  // On focusout event
  const onBlurChange = (e: React.FocusEvent) => {
    setMultipleSelectSearchText("");
  };

  const selectedValue = useMemo(() => {
    return SelectedDropDownList?.map((value) => {
      const selectedValue = multipleSelect.SelectOptions?.find(
        (item) => item?.Value?.toString() === value?.toString()
      );
      return selectedValue;
    });
  }, [props.Select, multipleSelect.SelectOptions]);

  return (
    <>
      <FormControl
        className="hpr-multiselect-dropdown"
        sx={{ m: 2 }}
        fullWidth
        disabled={props.Disabled}
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
          required={props.Required}
          disabled={props.Disabled}
        >
          {props.Label}
        </InputLabel>

        <Autocomplete
          id={props.Id && props.Id !== "" ? props.Id : props.Name}
          multiple
          disabled={props.Disabled}
          options={
            multipleSelect.SelectOptions &&
            multipleSelect.SelectOptions.length > 0
              ? multipleSelect.SelectOptions
              : ([] as Array<DropDownDTO>)
          }
          getOptionLabel={(option: DropDownDTO) => {
            if (typeof option === "string") {
              const selectedValue = multipleSelect?.SelectOptions?.find(
                (item) => item?.Value?.toString() === option
              );
              return selectedValue?.Label || "";
            } else {
              const selectedValue = multipleSelect.SelectOptions?.find(
                (item) => item?.Value?.toString() === option?.Value?.toString()
              );
              if (multipleSelect?.SearchText?.includes("@")) {
                return option?.EmailAddress;
              } else {
                return option?.Label
                  ? option?.Label
                  : selectedValue?.Label
                  ? selectedValue?.Label
                  : "";
              }
            }
          }}
          renderOption={(props, option: DropDownDTO) => {
            return (
              <li
                {...props}
                key={`${option.Value}_${option.Label}`}
                className={`person-mail-wrapper ${
                  SelectedDropDownList?.some(
                    (some) => some?.toString() === option.Value?.toString()
                  )
                    ? "disabled-mail"
                    : ""
                }`}
              >
                <div>{option?.Label}</div>
                <div className="person-mail"> {option?.EmailAddress || ""}</div>
              </li>
            );
          }}
          inputValue={multipleSelect?.SearchText ?? ""}
          isOptionEqualToValue={isOptionEqualToValue}
          value={selectedValue}
          defaultValue={[]}
          filterSelectedOptions={!multipleSelect.SearchText}
          getOptionDisabled={(option) =>
            SelectedDropDownList?.some(
              (some) => some?.toString() === option.Value?.toString()
            )
          }
          onChange={onSelectionChange}
          onBlur={onBlurChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className={`hpr-multiselect-dropdown ${
                props.Disabled ? "Mui-disabled" : ""
              }`}
              onChange={SearchTextChange}
              //onKeyDown={onSearchByEnterBtnClick}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                readOnly: !props.IsSearchable,
              }}
            />
          )}
          noOptionsText={
            loading ? <div className="loader"></div> : "No record(s) found"
            // GetAppOptions().AuthMode ===
            //   EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication &&
            // !searchBtnClickRef.current ? (
            //   <Grid
            //     className="setcolor fx cursor-pointer"
            //     aria-label="search"
            //     onClick={onSearchBtnClick}
            //   >
            //     <SearchNormal1
            //       variant="Outline"
            //       size="18"
            //       style={{ marginRight: "10px", marginTop: "2px" }}
            //       className="ico-secondary"
            //     />
            //     Search
            //   </Grid>
            // ) : (
            //   <>
            //     {loading ? (
            //       <div className="loader"></div>
            //     ) : (
            //       "No record(s) found"
            //     )}
            //   </>
            // )
          }
          disableClearable={props.DisableClearable}
          onInputChange={(_, value, reason) => {
            if (reason === "clear") {
              setMultipleSelectSearchText("");
            } else if (reason === "input") {
              setMultipleSelectSearchText(value);
            }
          }}
        />
        {props.HelperText && (
          <FormHelperText>{props.HelperText}</FormHelperText>
        )}
        {props.IsEnableValidator ? (
          props.Validator.message(
            props.Name,
            props.Select ?? "",
            props.Rules ?? ""
          )
        ) : (
          <></>
        )}
      </FormControl>
    </>
  );
};

export default HPRDropDownMsGraphMultipleSelect;
