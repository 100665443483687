import { EnumRemediationTrackerTaskStatus } from "../../../common/enums/common.enums";
import {
  RemediationTrackerTaskDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";

export const GETREMEDIATIONTRACKERTASK = "GETREMEDIATIONTRACKERTASK";
export const GETQUESTIONSFORREMEDIATIONTRACKERTASKS =
  "GETQUESTIONSFORREMEDIATIONTRACKERTASKS";
export const SAVEREMEDIATIONTRACKERTASK = "SAVEREMEDIATIONTRACKERTASK";
// export const SAVEREMEDIATIONTRACKERTASKBYFILEUPLOAD =
//   "SAVEREMEDIATIONTRACKERTASKBYFILEUPLOAD";

export const DELETEFILESONCANCEL = "DELETEFILESONCANCEL";
export const REMEDIATIONTRACKERTASKFILEUPLOAD =
  "REMEDIATIONTRACKERTASKFILEUPLOAD";

export const REMEDIATIONTRACKERTASKNAVIGATE = "REMEDIATIONTRACKERTASKNAVIGATE";

// export const REMOVEREMEDIATIONTRACKERTASK = "REMOVEREMEDIATIONTRACKERTASK";

export const getRemediationTrackerTaskById = (
  remediationTrackerTaskId: number
) => {
  return {
    type: GETREMEDIATIONTRACKERTASK,
    payload: remediationTrackerTaskId,
  };
};

export const getQuestionsForRemediationTrackerTasks = (
  inspectionQuestionIdList: Array<number>
) => {
  return {
    type: GETQUESTIONSFORREMEDIATIONTRACKERTASKS,
    payload: inspectionQuestionIdList,
  };
};

export const saveRemediationTrackerTask = (
  remediationTrackerTask: RemediationTrackerTaskDTO,
  statusChange: boolean
) => {
  return {
    type: SAVEREMEDIATIONTRACKERTASK,
    payload: {
      remediationTrackerTask: remediationTrackerTask,
      statusChange: statusChange,
    },
  };
};

export const deleteFilesOnCancel = (
  remediationTrackerTask: RemediationTrackerTaskDTO
) => {
  return {
    type: DELETEFILESONCANCEL,
    payload: remediationTrackerTask,
  };
};
// export const saveRemediationTrackerTaskByFileUpload = (
//   remediationTrackerTask: RemediationTrackerTaskDTO,
//   filesToBeUploaded: Array<UploadFileDTO>,
//   filesToBeRemoved: Array<number>
// ) => {
//   return {
//     type: SAVEREMEDIATIONTRACKERTASKBYFILEUPLOAD,
//     payload: {
//       remediationTrackerTask: remediationTrackerTask,
//       filesToBeUploaded: filesToBeUploaded,
//       filesToBeRemoved: filesToBeRemoved,
//     },
//   };
// };

// export const removeRemediationTrackerTask = (
//   remediationTrackerTaskId: number
// ) => {
//   return {
//     type: REMOVEREMEDIATIONTRACKERTASK,
//     payload: remediationTrackerTaskId,
//   };
// };

export const remediationTrackerTaskFileUpload = () => {
  return {
    type: REMEDIATIONTRACKERTASKFILEUPLOAD,
  };
};

export const remediationTrackerTaskNavigate = () => {
  return {
    type: REMEDIATIONTRACKERTASKNAVIGATE,
  };
};
