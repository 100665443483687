export const GET_EXCEL_REPORT_DATA = "GET_EXCEL_REPORT_DATA";

const standardReportSagaActions = {
  getQuestionnaireSummary: (parameters: {}) => {
    return {
      type: GET_EXCEL_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default standardReportSagaActions;
