import { AnyAction } from "redux";
import { all, takeEvery, put, call, take } from "@redux-saga/core/effects";
import { ActionResponseType } from "../../../common/types/common.types";
import { getAppConfiguration, GetPrivacyNoticeDetailsById } from "./MainLayout.api";

import { GET_APP_CONFIGURATION, GET_TERMS_OF_USE_DETAILS } from "./MainLayout.action";
import { AppConfigurationType } from "./MainLayout.types";
import { setAppConfiguration, setDate, setPopupOpen } from "./MainLayout.reducer";
import { TermsOfUseEmployeeDTO } from "../../../common/types/common.dto.types";

const appConfigurationWatcher = function* (): any {
  yield takeEvery(GET_APP_CONFIGURATION, function* (action: AnyAction): any {
    const result: ActionResponseType = yield call(() => getAppConfiguration());

    if (result?.Data?.AppConfig) {
      const appConfig = (result?.Data?.AppConfig || {}) as AppConfigurationType;
      yield put(setAppConfiguration(appConfig));
    }
  });
};

const termsOfUseDetailsWatcher = function* (): any {
  yield takeEvery(GET_TERMS_OF_USE_DETAILS, function* (action: AnyAction): any {
    const result: ActionResponseType = yield call(() => GetPrivacyNoticeDetailsById());

    if (result?.Data) {
      const termsOfUseDetails = (result?.Data || {}) as TermsOfUseEmployeeDTO;
      yield put(setDate(termsOfUseDetails.ModifiedDate));
    }
    yield put(setPopupOpen(true));
  });
};

const appConfigurationSaga = function* () {
  yield all([appConfigurationWatcher(), termsOfUseDetailsWatcher()]);
};

export default appConfigurationSaga;
