import {RefObject, useEffect, useRef} from "react";

type Callback = () => void;

export const useOutsideClick = (callback: Callback): RefObject<HTMLDivElement> => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent): void => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [callback, ref]);

    return ref;
};
export  default useOutsideClick;
