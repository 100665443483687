import { EnumButtonModeType } from "../../../common/enums/common.enums";
import {
  SearchParameterDTO,
  StandardReferenceDTO,
} from "../../../common/types/common.dto.types";
import { ReferencePageType } from "./ReferencePage.types";

export const GETSTANDRDREFERENCELIST = "GETSTANDRDREFERENCELIST";
export const SAVESTANDARDREREFERENCE = "SAVESTANDARDREREFERENCE";
export const UPDATESTANDARDREFERENCE = "UPDATESTANDARDREFERENCE";
export const DELETESTANDARDREFERENCE = "DELETESTANDARDREFERENCE";

export const getStandardReferenceList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETSTANDRDREFERENCELIST,
    payload: searchParameter,
  };
};

export const saveStandardReference = (referencePageType: ReferencePageType) => {
  return {
    type: SAVESTANDARDREREFERENCE,
    payload: referencePageType,
  };
};

export const updateStandardReference = (
  referencePageType: ReferencePageType
) => {
  return {
    type: UPDATESTANDARDREFERENCE,
    payload: referencePageType,
  };
};

export const deleteStandardReference = (
  referencePageType: ReferencePageType
) => {
  return {
    type: DELETESTANDARDREFERENCE,
    payload: referencePageType,
  };
};
