import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_STANDARD_QUESTIONS_EXCEL_REPORT_DATA =
  "GET_STANDARD_QUESTIONS_EXCEL_REPORT_DATA";

const standardQuestionsReportSagaActions = {
  getStandardQuestionsSummary: (parameters: ReportViewerType) => {
    return {
      type: GET_STANDARD_QUESTIONS_EXCEL_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default standardQuestionsReportSagaActions;
