import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Popover,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import {
  HPRDataGridToolbarColumnProps,
  HPRGridColDef,
} from "./HPRDataGrid.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { GridColDef } from "@mui/x-data-grid";
import { HPRDataGridActions } from "./HRPDataGrid.reducer";
// import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { Setting3 } from "iconsax-react";
// ==============================|| HPR - DATAGRID Column Toolbar ||============================== //

const HPRDataGridColumnToolbar = (props: HPRDataGridToolbarColumnProps) => {
  // Initial state
  const initialState = {
    Columns: [] as Array<GridColDef>,
    ShowColumnOption: false,
  } as HPRDataGridToolbarColumnProps;

  // Initiate state
  const [columnOptionState, setColumnOptionState] =
    useState<HPRDataGridToolbarColumnProps>(initialState);

  // Reducer manage fucntions
  const hprDataGridState = useAppSelector((state) => state.hprDataGridReducer);
  const dispatch = useAppDispatch();

  // Open toolbar settings
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // When HPRDataGridState changed set the values to page state
  useEffect(() => {
    setColumnOptionState((values) => {
      return {
        ...values,
        Columns: hprDataGridState?.Columns,
        ShowColumnOption:
          hprDataGridState?.HPRDataGridToolbarProps
            ?.HPRDataGridToolbarColumnProps.ShowColumnOption,
      };
    });
  }, [hprDataGridState]);

  // Open toolbar Popover method
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close toolbar Popover method
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hide all columns method
  const handleHideAll = () => {
    let filteredColumns: Array<HPRGridColDef> = [] as Array<HPRGridColDef>;
    hprDataGridState.Columns.forEach((column) => {
      if (column.hideable) {
        filteredColumns.push({ ...column, hideColumn: true });
      } else {
        if (column.hideColumn) {
          filteredColumns.push({ ...column, hideColumn: true });
        } else {
          filteredColumns.push({ ...column, hideColumn: false });
        }
      }
    });
    dispatch(HPRDataGridActions.setColumns(filteredColumns));
    // setAnchorEl(null);
  };

  // Show all columns method
  const handleShowAll = () => {
    let filteredColumns: Array<HPRGridColDef> = [] as Array<HPRGridColDef>;
    hprDataGridState.Columns.forEach((column) => {
      if (column?.field !== "Id") {
        filteredColumns.push({ ...column, hideColumn: false });
      }
    });
    dispatch(HPRDataGridActions.setColumns(filteredColumns));
    // setAnchorEl(null);
  };

  // Switch onchange method
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let filteredColumns: Array<HPRGridColDef> = [] as Array<HPRGridColDef>;
    hprDataGridState.Columns.forEach((column) => {
      if (column.field == event.target.name) {
        filteredColumns.push({ ...column, hideColumn: !checked });
      } else {
        filteredColumns.push(column);
      }
    });
    dispatch(HPRDataGridActions.setColumns(filteredColumns));
  };

  return (
    <div className="fx-left">
      <Button onClick={handleClick} className="colmn-opt mb-10">
        <Setting3 className="m-r-10" style={{ fontSize: 20 }} />
        <span>Column options</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "30ch", height: "46ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Stack direction="row" className="flex--container space-between">
              <Button onClick={handleHideAll}>Hide all</Button>
              <Button onClick={handleShowAll}>Show all</Button>
            </Stack>
            <FormGroup>
              {/* <TextField
                id="standard-basic"
                label="Find column"
                variant="standard"
              /> */}
              {columnOptionState?.Columns?.filter(
                (column) =>
                  column?.headerName &&
                  column?.headerName != "" &&
                  column?.hideable
              ).map((column: HPRGridColDef, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Switch
                        name={column?.field}
                        checked={!column?.hideColumn}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={handleChange}
                      />
                    }
                    label={column?.headerName}
                  />
                );
              })}
            </FormGroup>
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default HPRDataGridColumnToolbar;
