import { AnyAction } from "redux";
import { all, call, put, takeEvery, select } from "@redux-saga/core/effects";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";

import {
  getUserRoleManagementList,
  activateSystemGroup,
  deactivateSystemGroup,
} from "./UserRoleManagement.api";

import {
  setPagination,
  setUserRoleManagementList,
} from "./UserRoleManagement.reducer";
import {
  UserRoleManagementPaginationDTO,
  UserRoleManagementType,
} from "./UserRoleManagement.types";
import {
  SearchParameterDTO,
  SystemGroupDTO,
} from "../../../common/types/common.dto.types";
import {
  openPopup,
  updatePopupProps,
} from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import {
  GETUSERMANAGEMENTLIST,
  ACTIVATESYSTEMGROUPSTATUS,
  DEACTIVATESYSTEMGROUPSTATUS,
} from "./UserRoleManagement.actions";
import { ActionResponseType } from "../../../common/types/common.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import dayjs from "dayjs";

const getUserRoleManagementListWatcher = function* (): any {
  yield takeEvery(GETUSERMANAGEMENTLIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // get data from backend
    try {
      const systemGroupsSearchParams: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getUserRoleManagementList(systemGroupsSearchParams)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No System Group data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const paginationtype: UserRoleManagementType = {
            PagedData: result.Data as UserRoleManagementPaginationDTO,
          } as UserRoleManagementType;

          yield put(
            setPagination({
              ItemList: result.Data?.Data?.map((group: SystemGroupDTO) => {
                return {
                  // Icon: icons.StandardIco,
                  // ShowCommonListIcon: true,
                  Id: group.Id,
                  ItemHeading: group.GroupName ?? "-",
                  Status: group.StatusNavigation?.Status1 ?? "-",
                  StatusID: group.Status,
                  Version: group.Version?.toFixed(1),
                  ModifiedBy: group.ModifiedBy ?? "-",
                  ModifiedDate:
                    group.ModifiedDate != null
                      ? dayjs(group.ModifiedDate).format("DD-MMM-YYYY  HH:mm")
                      : "-",
                  CreatedBy: group.CreatedByName ?? "-",
                  CreatedById: group.CreatedById,
                  CreatedDate:
                    group.CreatedDate != null
                      ? dayjs(group.CreatedDate).format("DD-MMM-YYYY  HH:mm")
                      : "-",
                  Scope: group.Scope,
                  ScopeId: group.ScopeId,
                  ScopeName: group.ScopeName ?? "-",
                  AzureGroupName: group.AzureGroupName,
                  GroupFunctions: group.GroupFunctions,
                  GroupScreens: group.GroupScreens,
                  GroupName: group.GroupName,
                } as PaginationListItemType;
              }),
              ItemShowCount: result.Data?.PageSize,
              TotalRecordsCount: result.Data?.TotalRecordsCount,
              StatusSummaryCount: result.Data?.StatusSummaryCount,
              SelectedPage: result.Data?.SelectedPage,
            } as HPRListPaginationType)
          );

          yield put(setUserRoleManagementList(paginationtype));
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const activateSystemGroupWatcher = function* (): any {
  yield put(updatePopupProps({ Open: false }));
  yield takeEvery(
    ACTIVATESYSTEMGROUPSTATUS,
    function* (action: AnyAction): any {
      yield put(setLoading(true));
      const systemGroupId: number = action.payload.id;
      const state: UserRoleManagementType = yield select(
        (s: RootState) => s.userRoleManagementReducer
      );

      try {
        const result: ActionResponseType = yield call(() =>
          activateSystemGroup(systemGroupId)
        );

        if (result.IsSuccess == true) {
          const result: ActionResponseType = yield call(() =>
            getUserRoleManagementList(
              action.payload.userRoleManagementSearchParameter
            )
          );
          if (!result.IsSuccess) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "An error occurred when getting data",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            if (result === null) {
              yield put(
                openPopup({
                  Open: true,
                  BodyText: "No System Group data to show",
                  HeaderText: "Success",
                  PopupType: EnumPopupType.SuccessPopup,
                } as PopupProps)
              );
            } else {
              const paginationtype: UserRoleManagementType = {
                PagedData: result.Data as UserRoleManagementPaginationDTO,
              } as UserRoleManagementType;

              yield put(
                openPopup({
                  Open: true,
                  BodyText: "User group activated successfully",
                  HeaderText: "Success",
                  PopupType: EnumPopupType.SuccessPopup,
                } as PopupProps)
              );
              yield put(
                setPagination({
                  ItemList: result.Data?.Data?.map((group: SystemGroupDTO) => {
                    return {
                      // Icon: icons.StandardIco,
                      // ShowCommonListIcon: true,
                      Id: group.Id,
                      ItemHeading: group.GroupName ?? "-",
                      Status: group.StatusNavigation?.Status1 ?? "-",
                      StatusID: group.Status,
                      Version: group.Version?.toFixed(1),
                      ModifiedBy: group.ModifiedBy ?? "-",
                      ModifiedDate:
                        group.ModifiedDate != null
                          ? dayjs(group.ModifiedDate).format(
                              "DD-MMM-YYYY  HH:mm"
                            )
                          : "-",
                      CreatedBy: group.CreatedByName ?? "-",
                      CreatedById: group.CreatedById,
                      CreatedDate:
                        group.CreatedDate != null
                          ? dayjs(group.CreatedDate).format(
                              "DD-MMM-YYYY  HH:mm"
                            )
                          : "-",
                      Scope: group.Scope,
                      ScopeId: group.ScopeId,
                      ScopeName: group.ScopeName ?? "-",
                      AzureGroupName: group.AzureGroupName,
                      GroupFunctions: group.GroupFunctions,
                      GroupScreens: group.GroupScreens,
                      GroupName: group.GroupName,
                    } as PaginationListItemType;
                  }),
                  ItemShowCount: result.Data?.PageSize,
                  TotalRecordsCount: result.Data?.TotalRecordsCount,
                  StatusSummaryCount: result.Data?.StatusSummaryCount,
                  SelectedPage: result.Data?.SelectedPage,
                } as HPRListPaginationType)
              );

              yield put(setUserRoleManagementList(paginationtype));
            }
          }
        } else {
          if (result.IsSuccess == false && result.ErrorMessage) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            yield put(
              openPopup({
                Open: true,
                BodyText: "An error occurred when activating System Group",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when activating System Group",
            HeaderText: "Warning",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
      yield put(setLoading(false));
    }
  );
};

const deActivateLocationWatcher = function* (): any {
  //const { t } = useTranslation();
  yield put(updatePopupProps({ Open: false }));
  yield takeEvery(
    DEACTIVATESYSTEMGROUPSTATUS,
    function* (action: AnyAction): any {
      yield put(setLoading(true));
      const systemGroupId: number = action.payload.id;
      const state: UserRoleManagementType = yield select(
        (s: RootState) => s.userRoleManagementReducer
      );

      try {
        const result: ActionResponseType = yield call(() =>
          deactivateSystemGroup(systemGroupId)
        );

        if (result.IsSuccess == true) {
          const result: ActionResponseType = yield call(() =>
            getUserRoleManagementList(
              action.payload.userRoleManagementSearchParameter
            )
          );
          if (!result.IsSuccess) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "An error occurred when getting data",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            if (result === null) {
              yield put(
                openPopup({
                  Open: true,
                  BodyText: "No System Group data to show",
                  HeaderText: "Success",
                  PopupType: EnumPopupType.SuccessPopup,
                } as PopupProps)
              );
            } else {
              const paginationtype: UserRoleManagementType = {
                PagedData: result.Data as UserRoleManagementPaginationDTO,
              } as UserRoleManagementType;
              yield put(
                openPopup({
                  Open: true,
                  BodyText: "User Group deactivated successfully",
                  HeaderText: "Success",
                  PopupType: EnumPopupType.SuccessPopup,
                } as PopupProps)
              );
              yield put(
                setPagination({
                  ItemList: result.Data?.Data?.map((group: SystemGroupDTO) => {
                    return {
                      // Icon: icons.StandardIco,
                      // ShowCommonListIcon: true,
                      Id: group.Id,
                      ItemHeading: group.GroupName ?? "-",
                      Status: group.StatusNavigation?.Status1 ?? "-",
                      StatusID: group.Status,
                      Version: group.Version?.toFixed(1),
                      ModifiedBy: group.ModifiedBy ?? "-",
                      ModifiedDate:
                        group.ModifiedDate != null
                          ? dayjs(group.ModifiedDate).format(
                              "DD-MMM-YYYY  HH:mm"
                            )
                          : "-",
                      CreatedBy: group.CreatedByName ?? "-",
                      CreatedById: group.CreatedById,
                      CreatedDate:
                        group.CreatedDate != null
                          ? dayjs(group.CreatedDate).format(
                              "DD-MMM-YYYY  HH:mm"
                            )
                          : "-",
                      Scope: group.Scope,
                      ScopeId: group.ScopeId,
                      ScopeName: group.ScopeName ?? "-",
                      AzureGroupName: group.AzureGroupName,
                      GroupFunctions: group.GroupFunctions,
                      GroupScreens: group.GroupScreens,
                      GroupName: group.GroupName,
                    } as PaginationListItemType;
                  }),
                  ItemShowCount: result.Data?.PageSize,
                  TotalRecordsCount: result.Data?.TotalRecordsCount,
                  StatusSummaryCount: result.Data?.StatusSummaryCount,
                  SelectedPage: result.Data?.SelectedPage,
                } as HPRListPaginationType)
              );

              yield put(setUserRoleManagementList(paginationtype));
            }
          }
        } else {
          if (result.IsSuccess == false && result.ErrorMessage) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            yield put(
              openPopup({
                Open: true,
                BodyText: "An error occurred when deactivating System Group",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when deactivating System Group",
            HeaderText: "Warning",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
      yield put(setLoading(false));
    }
  );
};

const userRoleManagementSaga = function* (): any {
  yield all([
    getUserRoleManagementListWatcher(),
    activateSystemGroupWatcher(),
    deActivateLocationWatcher(),
  ]);
};

export default userRoleManagementSaga;
