import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Popover,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { HPRGridPagination } from "./HPRDataGrid.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { GridColDef } from "@mui/x-data-grid";
import { HPRDataGridActions } from "./HRPDataGrid.reducer";
// import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { ArrowDown2, Setting3 } from "iconsax-react";
import useOutsideClick from "../../../core/hooks/useOutsideClick";
import { DropDownDTO } from "../../../common/types/common.dto.types";
// ==============================|| HPR - DATAGRID Column Toolbar ||============================== //

const HPRDataGridPaginationToolbar = (props: HPRGridPagination) => {
  const [listDisplayState, setListDisplayState] = useState<"none" | "block">(
    "none"
  );

  const [listPageDisplayState, setPageListDisplayState] = useState<
    "none" | "block"
  >("none");

  const [selectedPerPageState, setSelectedPerPageState] = useState<any>(
    props.defaultValue ? props.defaultValue : props.PerPageItemList[0].Label
  );

  const ref = useOutsideClick(() => {
    setListDisplayState("none");
  });

  const perPageRef = useOutsideClick(() => {
    setPageListDisplayState("none");
  });

  const toggleDisplayStyle = (type: string) => {
    if (type == "SortBy") {
      setListDisplayState((prevDisplayStyle) =>
        prevDisplayStyle === "none" ? "block" : "none"
      );
    } else {
      setPageListDisplayState((prevDisplayStyle) =>
        prevDisplayStyle === "none" ? "block" : "none"
      );
    }
  };

  const onChangePerPage = (event: DropDownDTO) => {
    if (props.onChangePerPage) {
      if (selectedPerPageState !== event.Label) {
        // console.log("onChangePerPage called");
        // console.log("setSelectedPerPageState", event.Label);
        setSelectedPerPageState(event.Label);
        props.onChangePerPage(props.Name, event.Value as string);
      }
    }
  };

  return (
    <div className="standarded-tags">
      <div className="sortOpt text-center m-r-10 p-r-10">
        <label className="sort-label">Per page</label>

        <div>
          <div
            className="sort-label-inner bold"
            ref={perPageRef}
            onClick={() => toggleDisplayStyle("PerPageData")}
            style={{ position: "relative" }}
          >
            {selectedPerPageState}
            {/* <KeyboardArrowDown style={{ position: "relative", top: 0 }} /> */}
            <ArrowDown2
              size={14}
              style={{ position: "relative", top: 3 }}
              className="p-l-5"
            />

            <div
              className="drop-items"
              style={{
                display: listPageDisplayState,
                width: 50,
              }}
            >
              <ul>
                {props.PerPageItemList?.map((item, index) => (
                  <li key={index} onClick={() => onChangePerPage(item)}>
                    <a>{item.Label}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HPRDataGridPaginationToolbar;
