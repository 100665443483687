
import { FormControlLabel, Radio } from "@mui/material";
import { ChangeEvent } from "react";
import { HPRRadioButtonProps } from "./HPRRadioButton.types";

// ==============================|| COMPONENTS - COMMON - HPRRADIOBUTTON ||============================== //

const HPRRadioButton = (props: HPRRadioButtonProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onRadioButtonChange)
      props.onRadioButtonChange(event.target.name, event.target.checked);
  };

  // styles
  const styles = {
    color: "#219ebc",
  };

  return (
    <>
      <FormControlLabel
        value={props.Value}
        label={props.Label}
        control={
          <Radio
            style={styles}
            id={props.Id}
            name={props.Name}
            required={props.Required ? props.Required : false}
            disabled={props.Disabled ? props.Disabled : false}
            onChange={onChange}
            className={props.ClassName}
          ></Radio>
        }
        className={props.ClassName}
      />
    </>
  );
};

export default HPRRadioButton;
