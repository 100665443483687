// project imports
import { DropDownDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

export const getDataFromBackend = async (
  url: string
): Promise<Array<DropDownDTO>> => {
  let items = [] as Array<DropDownDTO>;
  try {
    await client
      .get<ActionResponseType>(url)
      .then((response: ActionResponseType) => {
        items = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return items;
  } catch (error) {
    return items;
  }
};

export const getDataFromBackendWithParameter = async (
  url: string,
  search?: any
): Promise<Array<DropDownDTO>> => {
  let items = [] as Array<DropDownDTO>;

  try {
    await client
      .get<ActionResponseType>(`${url}/${search ?? ""}`)
      .then((response: ActionResponseType) => {
        items = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return items;
  } catch (error) {
    return items;
  }
};
