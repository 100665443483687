import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSystemDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  DELETESTANDARDSYSTEM,
  GETSTANDRDSYSTEMLIST,
  SAVESTANDARDRESYSTEM,
  UPDATESTANDARDSYSTEM,
} from "./SystemPage.action";
import {
  deleteStandardSystem,
  getStandardSystemList,
  saveStandardSystem,
  updateStandardSystem,
} from "./SystemPage.api";
import {
  setPageMode,
  setStandardSystem,
  setStandardSystemList,
  setSystemModalOpen,
} from "./SystemPage.reducer";
import { SystemPageType } from "./SystemPage.types";

// ==============================|| VIEWS - PAGES - STANDARDSYSTEM - SAGA ||============================== //

const getStandardSystemListWatcher = function* (): any {
  yield takeEvery(GETSTANDRDSYSTEMLIST, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    try {
      // get data from backend
      const systemSearchParam: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getStandardSystemList(systemSearchParam)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No system data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const systempagetype: SystemPageType = {
            Data: result.Data,
          } as SystemPageType;

          yield put(
            setStandardSystemList({
              ...systempagetype,
              Data: {
                Data: systempagetype.Data.Data,
                TotalRecordsCount: systempagetype.Data.TotalRecordsCount,
                SelectedPage: systempagetype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading on
    yield put(setLoading(false));
  });
};

const saveStandardSystemWatcher = function* (): any {
  yield takeEvery(SAVESTANDARDRESYSTEM, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // save System data from backend
    const systemPageType: SystemPageType = action.payload;

    const result: ActionResponseType = yield call(() =>
      saveStandardSystem(systemPageType.StandaradSystem)
    );

    if (!result.IsSuccess) {
      yield put(
        openPopup({
          Open: true,
          BodyText: result.ErrorMessage
            ? result.ErrorMessage
            : "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    } else {
      //set Page Mode with Data
      if (systemPageType.ButtonModeType === EnumButtonModeType.Save) {
        yield put(setStandardSystem(result.Data));
        yield put(setPageMode(EnumPageMode.ViewMode));
      } else if (
        systemPageType.ButtonModeType === EnumButtonModeType.SaveAndContinue
      ) {
        yield put(
          setStandardSystem({
            Id: 0,
            Name: "",
            StandardId: systemPageType.StandaradSystem.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSystemDTO)
        );
        yield put(setPageMode(EnumPageMode.AddMode));
      } else if (
        systemPageType.ButtonModeType === EnumButtonModeType.SaveAndClose
      ) {
        yield put(
          setStandardSystem({
            Id: 0,
            Name: "",
            StandardId: systemPageType.StandaradSystem.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSystemDTO)
        );

        yield put(setPageMode(EnumPageMode.Default));
        yield put(setSystemModalOpen(false));
      }

      //get System List
      const standardSystemState: SystemPageType = yield select(
        (s: RootState) => s.standardSystemReducer
      );
      const listResult: ActionResponseType = yield call(() =>
        getStandardSystemList({
          ...systemPageType.StandardSystemSearchParameter,
          SelectedPage: 1,
        })
      );
      if (!listResult.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (listResult === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No system data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          // if (
          //   systemPageType.ButtonModeType === EnumButtonModeType.SaveAndContinue
          // ) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully saved",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
          // }

          const systempagetype: SystemPageType = {
            Data: listResult.Data,
          } as SystemPageType;
          yield put(
            setStandardSystemList({
              ...systempagetype,
              Data: {
                Data: systempagetype.Data.Data,
                TotalRecordsCount: systempagetype.Data.TotalRecordsCount,
                SelectedPage: 1,
              } as PaginationDTO,
            })
          );
        }
      }
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const updateStandardSystemWatcher = function* (): any {
  //
  yield takeEvery(UPDATESTANDARDSYSTEM, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // save reference data from backend

    const systemPageType: SystemPageType = action.payload;

    const result: ActionResponseType = yield call(() =>
      updateStandardSystem(systemPageType.StandaradSystem)
    );
    if (!result.IsSuccess) {
      yield put(
        openPopup({
          Open: true,
          BodyText: result.ErrorMessage
            ? result.ErrorMessage
            : "An error occurred when updating data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    } else {
      //set Page Mode with Data
      if (systemPageType.ButtonModeType === EnumButtonModeType.Update) {
        yield put(setStandardSystem(result.Data));
        yield put(setPageMode(EnumPageMode.ViewMode));
      } else if (
        systemPageType.ButtonModeType === EnumButtonModeType.UpdateAndClose
      ) {
        yield put(
          setStandardSystem({
            Id: 0,
            Name: "",
            StandardId: systemPageType.StandaradSystem.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSystemDTO)
        );
        yield put(setPageMode(EnumPageMode.Default));
        yield put(setSystemModalOpen(false));
      }

      //get System List
      const standardSystemState: SystemPageType = yield select(
        (s: RootState) => s.standardSystemReducer
      );
      const listResult: ActionResponseType = yield call(() =>
        getStandardSystemList(systemPageType.StandardSystemSearchParameter)
      );
      if (!listResult.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (listResult === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No reference data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const systempagetype: SystemPageType = {
            Data: listResult.Data,
          } as SystemPageType;
          yield put(
            setStandardSystemList({
              ...systempagetype,
              Data: {
                Data: systempagetype.Data.Data,
                TotalRecordsCount: systempagetype.Data.TotalRecordsCount,
                SelectedPage: systempagetype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const deleteStandardSystemWatcher = function* (): any {
  //
  yield takeEvery(DELETESTANDARDSYSTEM, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // save System data from backend
    const systemPageType: SystemPageType = action.payload;

    const result: ActionResponseType = yield call(() =>
      deleteStandardSystem(systemPageType.StandaradSystem.Id)
    );
    if (!result.IsSuccess) {
      yield put(
        openPopup({
          Open: true,
          BodyText: result.ErrorMessage
            ? result.ErrorMessage
            : "An error occurred when deactivating data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    } else {
      const standardSystemState: SystemPageType = yield select(
        (s: RootState) => s.standardSystemReducer
      );

      //set Page Mode with Data
      yield put(
        setStandardSystem({
          Id: 0,
          Name: "",
          StandardId: standardSystemState.StandaradSystem.StandardId,
          Version: 1,
          Status: EnumStatus.Published,
        } as StandardSystemDTO)
      );
      yield put(setPageMode(EnumPageMode.Default));
      yield put(setSystemModalOpen(false));

      //get System List
      const listResult: ActionResponseType = yield call(() =>
        getStandardSystemList({
          ...systemPageType.StandardSystemSearchParameter,
          SelectedPage: 1,
          GridPageSize:
            standardSystemState.StandardSystemSearchParameter.GridPageSize,
        } as SearchParameterDTO)
      );
      if (!listResult.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when deactivating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No reference data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const systempagetype: SystemPageType = {
            Data: listResult.Data,
          } as SystemPageType;
          yield put(
            setStandardSystemList({
              ...systempagetype,
              Data: {
                Data: systempagetype.Data.Data,
                TotalRecordsCount: systempagetype.Data.TotalRecordsCount,
                SelectedPage: systempagetype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const standardSystemSaga = function* () {
  yield all([
    getStandardSystemListWatcher(),
    saveStandardSystemWatcher(),
    updateStandardSystemWatcher(),
    deleteStandardSystemWatcher(),
  ]);
};

export default standardSystemSaga;
