import { FC } from "react";
import { HPRMaterialReactTableToolbarComponentProps } from "./HPRMaterialReactTable.type";
import { Grid } from "@mui/material";

// ==============================|| HPR - MATERIAL_REACT_TABLE_TOOLBAR_COMPONENT ||============================== //

const HPRMaterialReactTableToolbarComponent: FC<
  HPRMaterialReactTableToolbarComponentProps
> = (props) => {
  return (
    <div
      className="fx width100-p v-align wizard-stat-header"
      ref={props.ToolBarRef}
    >
      <Grid container className="pl-3 pt-5">
        {props?.ToolBarProps?.StatusSummaryProps?.StatusSummary?.map((item) => {
          return (
            <div
              className="standards-status"
              key={`Status_${item.Value}`}
              onClick={() => {
                props?.ToolBarProps?.StatusSummaryProps?.onClickStatusSummaryCallBack(
                  item.Value
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                className={`status-color ${item.ClassName} ${
                  item.Value ===
                  props?.ToolBarProps?.StatusSummaryProps?.SelectedStatus
                    ? "active"
                    : ""
                }`}
              >
                {item.Label}
                {item.StatusSummaryCount || item.StatusSummaryCount === 0
                  ? ` (${item.StatusSummaryCount})`
                  : ""}
              </span>
            </div>
          );
        })}
      </Grid>
      {/* <Grid className="d-flex rem-trc-expo-btn-wrap">
        <div className="btn-sec">
          <Button
            className="rem-lined-btn"
            disableElevation
            size="small"
            variant="outlined"
            startIcon={<Setting3 variant="Outline" size={20} />}
            // onClick={handleClick}
          >
            Column Options
          </Button>
        </div>
      </Grid> */}
    </div>
  );
};

export default HPRMaterialReactTableToolbarComponent;
