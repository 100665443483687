/** @format */

import { Button, Grid } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
// import "./UnauthorizedPage.css";
import useAuth from "../../../core/hooks/useAuth";
import Box from "@mui/material/Box";
import unauthimg from "../../../images/unauthorized.svg";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ArrowLeft2 } from "iconsax-react";

// ==============================|| VIEWS - Pages - ErrorHandlingPages - UnauthorizedPage ||============================== //

const UnauthorizedPage = () => {
  // use for page navigation
  const navigate = useNavigate();
  const [auth] = useAuth();

  // user not authenticated then
  if (!auth) {
    return <Navigate to="/login" />;
  }

  const navigateToHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <div className="content-section-card">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="65vh"
        >
          <img src={unauthimg} width="100%" height="300" />
        </Box>

        <Box sx={{ mt: -10 }}>
          <div className="item-wrapper text-center">
            <Typography style={{ color: "#fb8500", padding: "10px" }}>
              <h1 className="unauth-fs">403</h1>
            </Typography>
          </div>
        </Box>

        <div className="item-wrapper text-center">
          <Typography style={{ color: "#bbb6b6" }}>
            {" "}
            <h1>You Don't have permission to access this page.</h1>
          </Typography>
        </div>
        <div className="item-wrapper text-center">
          <Button
            sx={{ mt: 2 }}
            className="secondary-btn"
            variant="outlined"
            onClick={navigateToHomePage}
          >
            <ArrowLeft2 size="20" variant="Outline" />
            Back to Home
          </Button>
        </div>

        {/* <div className="text-wrapper">
        <div className="uptitle" data-content="404">
          403 - ACCESS DENIED
        </div>

        <div className="subtitle">
          Oops, You don't have permission to access this page.
        </div>
        <div className="isi">
          A web server may return a 403 Forbidden HTTP status code in response
          to a request from a client for a web page or resource to indicate that
          the server can be reached and understood the request, but refuses to
          take any further action. Status code 403 responses are the result of
          the web server being configured to deny access, for some reason, to
          the requested resource by the client.
        </div>

        <div className="buttons">
          <Button onClick={navigateToHomePage}>Go to Home Page</Button>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default UnauthorizedPage;
