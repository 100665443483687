// project imports
import {
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  ActionResponseType,
  AppOptions,
  LoginDTO,
} from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { LoginDataType } from "./LoginPage.types";
import { GetAppOptions } from "../../../common/functions/common.functions";
import { EnumAzureAuthenticationWindowMode } from "../../../common/enums/common.enums";

// client object for axios calls to backend
const client = new ApiClient();

export const login = async (
  loginData: LoginDataType
): Promise<ActionResponseType> => {
  try {
    const URL: string = "Auth/Login";
    let data = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, loginData)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {
          Data: {
            Errors: {
              General:
                "We have trouble signing you in, please try again later!",
            },
            ErrorMessage:
              "We have trouble signing you in, please try again later!",
          },
        } as ActionResponseType;
      });
    return data;
  } catch {
    return {
      Data: {
        Errors: {
          General: "We have trouble signing you in, please try again later!",
        },
        ErrorMessage: "We have trouble signing you in, please try again later!",
      },
    } as ActionResponseType;
  }
};

export const loginWithMicrosoftAzureActiveDirectory = async (
  msalInstance: PublicClientApplication
): Promise<AuthenticationResult> => {
  try {
    let authenticationResult: AuthenticationResult;
    const appOptions: AppOptions = GetAppOptions();

    if (
      appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions.WindowMode ==
      EnumAzureAuthenticationWindowMode.Popup
    ) {
      // This part design for separate popup
      //await msalInstance.clearCache();
      await msalInstance.initialize();
      // await msalInstance.logoutPopup();

      await msalInstance
        .loginPopup({
          scopes:
            appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions.Scopes,
        })
        .then((response: AuthenticationResult) => {
          authenticationResult = response;
        })
        .catch((error: any) => {});
    } else if (
      appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions.WindowMode ==
      EnumAzureAuthenticationWindowMode.InWindow
    ) {
      // This part design for in windows
      // const loginRequest = {
      //   scopes:
      //     appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions.Scopes, // Specify the scopes you need
      // };
      // await msalInstance.loginRedirect(loginRequest);
      // await msalInstance
      //   .handleRedirectPromise()
      //   .then((authResponse) => {
      //   })
      //   .catch((error) => {
      //   });
    }

    return authenticationResult;
  } catch (error) {}
};

export const newLogin = async (
  loginDTO: LoginDTO
): Promise<ActionResponseType> => {
  let data: ActionResponseType = {} as ActionResponseType;

  try {
    const URL: string = "Auth/NewLogin";
    await client
      .post<ActionResponseType>(URL, loginDTO)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        data = {
          IsSuccess: false,
          Data: {
            ErrorMessage:
              "Sorry, an unexpected error occured. Please try again later.",
          },
        } as ActionResponseType;
      });
    return data;
  } catch (error) {
  } finally {
  }
};
