import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { SubjectPageType } from "./SubjectPage.types";

export const GETSTANDRDSUBJECTLIST = "GETSTANDRDSUBJECTLIST";
export const SAVESTANDARDRESUBJECT = "SAVESTANDARDRESUBJECT";
export const UPDATESTANDARDSUBJECT = "UPDATESTANDARDSUBJECT";
export const DELETESTANDARDSUBJECT = "DELETESTANDARDSUBJECT";

export const getStandardSubjectList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETSTANDRDSUBJECTLIST,
    payload: searchParameter,
  };
};

export const saveStandardSubject = (subjectPageType: SubjectPageType) => {
  return {
    type: SAVESTANDARDRESUBJECT,
    payload: subjectPageType,
  };
};

export const updateStandardSubject = (subjectPageType: SubjectPageType) => {
  return {
    type: UPDATESTANDARDSUBJECT,
    payload: subjectPageType,
  };
};

export const deleteStandardSubject = (subjectPageType: SubjectPageType) => {
  return {
    type: DELETESTANDARDSUBJECT,
    payload: subjectPageType,
  };
};
