import { ReportViewerType } from "./ReportViewer.types";

export const GETREPORT = "GETREPORT";

export const getReport = (report: ReportViewerType) => {
  return {
    type: GETREPORT,
    payload: report,
  };
};
