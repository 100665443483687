import { createSlice } from "@reduxjs/toolkit";
import { LoginSwitchPopupProps } from "./LoginSwitchPopup.types";

const initialState = {
  Open: false,
  SelectedRole: 0,
} as LoginSwitchPopupProps;
export const loginSwitchPopupSlice = createSlice({
  name: "loginSwitchPopup",
  initialState: initialState,
  reducers: {
    openPopup: (
      state: LoginSwitchPopupProps,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        Open: action.payload,
      };
    },
    setRole: (
      state: LoginSwitchPopupProps,
      action: { payload: number; type: string }
    ) => {
      return {
        ...state,
        SelectedRole: action.payload,
      };
    },
  },
});

export const { openPopup, setRole } = loginSwitchPopupSlice.actions;

export const loginSwitchPopupReducer = loginSwitchPopupSlice.reducer;
