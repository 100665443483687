import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { HPRSplitButtonProps } from "./HPRSplitButton.types";

// ==============================|| COMPONENTS - COMMON - HPRBUTTON ||============================== //

const HPRSplitButton = (props: HPRSplitButtonProps) => {
  ///const options = ["Save & Exit", "Save"];

  const [selectedButton, setSelectedButton] = useState(
    props.DefaultSelectedButton ? props.DefaultSelectedButton : "0"
  );
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (event: any, value: string) => {
    setSelectedButton(value);
    setOpen(false);
    props.ButtonOptions[value]?.onButtonClickCallBack();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        aria-label="split button"
        className="hpr-split-btn"
      >
        <Button
          className="primary-btn-small"
          size="small"
          variant="outlined"
          disabled={props.IsDisabled}
          hidden={props.IsHide}
          onClick={() => {
            props.ButtonOptions[selectedButton]?.onButtonClickCallBack();
          }}
        >
          {!props.ButtonOptions[selectedButton].HideButton &&
          !props.ButtonOptions[selectedButton].DisableButton
            ? props.ButtonOptions[selectedButton]?.ButtonName
            : ""}
        </Button>
        <Button
          className="primary-btn-small"
          size="small"
          variant="outlined"
          disabled={props.IsDisabled}
          hidden={props.IsHide}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.ButtonOptions?.map((option, index) => {
                    if (!option.HideButton) {
                      return (
                        <MenuItem
                          key={option.ButtonId}
                          disabled={option.DisableButton}
                          selected={option.ButtonId === selectedButton}
                          onClick={(event) =>
                            handleMenuItemClick(event, option.ButtonId)
                          }
                          className={option.ClassName}
                        >
                          {option.ButtonName}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HPRSplitButton;
