import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_INSPECTION_SET_REPORT_EXCEL =
  "GET_INSPECTION_SET_REPORT_EXCEL";

const inspectionSetReportSagaActions = {
  getInspectionSetReport: (parameters: ReportViewerType) => {
    return {
      type: GET_INSPECTION_SET_REPORT_EXCEL,
      payload: parameters,
    };
  },
};

export default inspectionSetReportSagaActions;
