import { takeEvery, put, call, select, fork } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { GETINSPECTIONQUESTIONDETAILS } from "./ViewInspectionQuestion.action";
import { ViewInspectionQuestionType } from "./ViewInspectionQuestion.types";
import {
  InspectionQuestionDTO,
  QuestionnaireDTO,
} from "../../../common/types/common.dto.types";
import { viewInspectionQuestionSliceReducerActions } from "./ViewInspectionQuestion.reducer";
import { getViewInspectionQuestionDetails } from "./ViewInspectionQuestion.api";
import { id } from "date-fns/locale";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION QUESTION - SAGA ||============================== //

const getViewInspectionQuestionWatcher = function* (): any {
  yield takeEvery(
    GETINSPECTIONQUESTIONDETAILS,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      const state: ViewInspectionQuestionType = yield select(
        (s: RootState) => s.viewInspectionQuestionReducer
      );

      // get data from backend
      try {
        const inspectionQuestion: InspectionQuestionDTO = action.payload;
        const result: ActionResponseType = yield call(() =>
          getViewInspectionQuestionDetails(inspectionQuestion)
        );
        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question details  to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
              } as PopupProps)
            );
          } else {
            const inspectionQuestionDTO: InspectionQuestionDTO = result.Data;
            yield put(
              viewInspectionQuestionSliceReducerActions.setViewInspectionQuestion(
                {
                  ...state,
                  InspectionQuestion: inspectionQuestionDTO,
                }
              )
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading on
      yield put(setLoading(false));
    }
  );
};

const viewInspectionQuestionSaga = function* () {
  yield fork(getViewInspectionQuestionWatcher);
};

export default viewInspectionQuestionSaga;
