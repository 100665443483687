import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { SectionPageType } from "./SectionPage.types";

export const GETSTANDRDSECTIONLIST = "GETSTANDRDSECTIONLIST";
export const SAVESTANDARDRESECTION = "SAVESTANDARDRESECTION";
export const UPDATESTANDARDSECTION = "UPDATESTANDARDSECTION";
export const DELETESTANDARDSECTION = "DELETESTANDARDSECTION";

export const getStandardSectionList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETSTANDRDSECTIONLIST,
    payload: searchParameter,
  };
};

export const saveStandardSection = (sectionPageType: SectionPageType) => {
  return {
    type: SAVESTANDARDRESECTION,
    payload: sectionPageType,
  };
};

export const updateStandardSection = (sectionPageType: SectionPageType) => {
  return {
    type: UPDATESTANDARDSECTION,
    payload: sectionPageType,
  };
};

export const deleteStandardSection = (sectionPageType: SectionPageType) => {
  return {
    type: DELETESTANDARDSECTION,
    payload: sectionPageType,
  };
};
