// ==============================|| COMPONENTS - COMMON - HPRTEXTBOXUSERROLE ||============================== //

import { TextField, IconButton, InputAdornment } from "@mui/material";
import React, { useCallback } from "react";
import { ChangeEvent, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { HPRTextBoxUserRoleProps } from "./HPRTextBox.types";
import { EnumTextBoxType } from "./HPRTextBox.enum";

const HPRTextBoxUserRole = (props: HPRTextBoxUserRoleProps) => {
  // State for keep text
  const [textValue, setName] = React.useState("");
  const [isMouseOver, setIsMouseOver] = useState(false);

  // On text change event
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);

    if (props.onTextBoxChange) {
      props.onTextBoxChange(
        event.target.name,
        event.target.value,
        props.SiteId,
        props.AzureGroupSiteId
      );
    }
  };

  // On focusout event
  const onBlur = () => {
    if (props.onBlurChange) {
      props.onBlurChange(props.Name, textValue);
    }
  };

  // On click event
  const onClick = () => {
    if (props.onTextBoxClick) {
      props.onTextBoxClick(props.Name, textValue);
    }
  };

  // const CssTextField = styled(TextField)({
  //   "label + &": {
  //     color: "#023047",
  //   },
  //   "& label.Mui-focused": {
  //     color: "#purple",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "#8ECAE6",
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     "& fieldset": {
  //       borderColor: "#8ECAE6",
  //     },
  //     "&:hover fieldset": {
  //       borderColor: "#219EBC",
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#219EBC",
  //     },
  //   },
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       outlined: {
  //         "&.MuiInputLabel-shrink": {
  //           fontSize: "18px",
  //         },
  //       },
  //     },
  //   },
  // });

  //Auto Focus Text Field
  //The React useCallback Hook returns a memoized callback function.
  //Think of memoization as caching a value so that it does not need to be recalculated.

  const callbackRefAutoFocus = useCallback(
    (inputElement: any) => {
      if (inputElement && props.AutoFocus) {
        inputElement.focus();
      }
    },
    [props.AutoFocus]
  );

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const clearFeild = () => {
    setName("");
    if (props.onTextBoxChange) {
      props.onTextBoxChange("", "", 0, 0);
    }
  };

  return (
    <>
      <TextField
        role={"presentation"}
        onClick={onClick}
        id={props.Id}
        name={props.Name}
        value={props.Value}
        label={props.Label}
        placeholder={props.PlaceHolder}
        multiline={props.MultiLine}
        type={
          props.MultiLine
            ? "text"
            : props.Type === EnumTextBoxType.Number
            ? "number"
            : props.Type === EnumTextBoxType.Password
            ? "password"
            : props.Type === EnumTextBoxType.Search
            ? "search"
            : "text"
        }
        disabled={props.Disabled}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={props.InputProps}
        autoComplete={props.AutoComplete ?? "off"}
        className={props.MultiLine ? "hpr-textarea" : "hpr-textbox"}
        fullWidth
        InputLabelProps={{ shrink: true }}
        inputRef={callbackRefAutoFocus}
        required={props.Required}
        onInput={props.onInputValidator}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isMouseOver &&
                props.Value &&
                props.Value.length > 0 &&
                props.CloseIcon && (
                  <IconButton onClick={clearFeild}>
                    <CloseIcon style={{ color: "#219ebc", fontSize: "20px" }} />
                  </IconButton>
                )}
            </InputAdornment>
          ),
        }}
      ></TextField>
      <div className="flex--container">
        <div className="validate-msg flex__item" style={{}}>
          {props.IsEnableValidator ? (
            props.Validator?.message(
              props.Name,
              props.Value ? props.Value : "",
              props.Rules ? props.Rules : ""
            )
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            props.Disabled && props.TextLength != 0
              ? "validate-hint-dis"
              : "validate-hint flex__item--inherit"
          }
        >
          {props.ShowTextCounter
            ? `${props.Value?.replaceAll(/\r/g, "")?.length}/${props.TextLength}
           characters` //characters
            : ""}
        </div>
      </div>
    </>
  );
};

export default HPRTextBoxUserRole;
