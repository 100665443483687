import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { Box, Button, Modal, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import HPRDataGrid from "../../../components/common/HPRDataGrid/HRPDataGrid";
import { HPRGridColDef } from "../../../components/common/HPRDataGrid/HPRDataGrid.types";
import { ViewInspectionQuestionHistoryType } from "./ViewInspectionQuestionHistory.types";
import { viewInspectionQuestionHistorySliceReducerActions } from "./ViewInspectionQuestionHistory.reducer";
import { getInspectionQuestionHistoryDetails } from "./ViewInspectionQuestionHistory.action";

// ==============================|| VIEWS - COMPONENTS -VIEW REMEDIATION TRACKER TASKS HISTORY ||============================== //

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const initialState = {
  InspectionQuestionId: 0,
  Open: false,
  Ref: null,
} as ViewInspectionQuestionHistoryType;

const ViewInspectionQuestionHistory: React.FC = () => {
  const dataFetchedRef = useRef(false);

  const dispatch = useAppDispatch();
  const viewInspectionQuestionHistoryState = useAppSelector(
    (state) => state.viewInspectionQuestionHistoryReducer
  );

  //Page onload
  useEffect(() => {
    if (dataFetchedRef.current) return;

    if (
      viewInspectionQuestionHistoryState?.InspectionQuestionId !== undefined &&
      viewInspectionQuestionHistoryState?.InspectionQuestionId !== 0 &&
      viewInspectionQuestionHistoryState?.InspectionQuestionId !== null
    ) {
      dataFetchedRef.current = true;
      dispatch(
        getInspectionQuestionHistoryDetails(
          viewInspectionQuestionHistoryState?.InspectionQuestionId
        )
      );
    }
  }, [viewInspectionQuestionHistoryState?.InspectionQuestionId]);

  const onCloseViewInspectionQuestionHistory = () => {
    dataFetchedRef.current = false;
    dispatch(
      viewInspectionQuestionHistorySliceReducerActions.resetInspectionQuestionHistory()
    );
  };

  const getDateToString = (_date: any): string => {
    const date = dayjs(_date).format("DD-MMM-YYYY");
    return date !== "Invalid Date" ? date : "-";
  };

  const renderGridCellWithToolTips = (value: any) => {
    return (
      <Tooltip title={value ? value : ""}>
        <div className="MuiDataGrid-cellContent">
          {value && value !== "" ? value : "-"}
        </div>
      </Tooltip>
    );
  };

  const columns: HPRGridColDef[] = [
    {
      field: "Id",
      headerName: "Id",
      flex: 3,
      sortable: false,
      hideColumn: true,
      hideable: true,
      // width: 20,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "QuestionVersion",
      headerName: "Inspection Question Version",
      flex: 3,
      sortable: false,
      // width: 20,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return renderGridCellWithToolTips(params?.row?.Version);
      },
    },
    {
      field: "Message",
      headerName: "Message",
      flex: 1,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return renderGridCellWithToolTips(params?.row?.Message ?? "");
      },
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return renderGridCellWithToolTips(params?.row?.ModifiedBy);
      },
    },
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return renderGridCellWithToolTips(
          getDateToString(params?.row?.ModifiedDate)
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        className="modal-outter"
        open={viewInspectionQuestionHistoryState.Open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="question-summary lg" sx={style}>
          <div className="modal-header">
            <h1>{`Inspection Question History`}</h1>
          </div>
          <div className="modal-content read-only">
            <div className="pb-10 width100-p">
              <HPRDataGrid
                Columns={columns}
                Data={
                  viewInspectionQuestionHistoryState?.InspectionQuestionHistory
                }
                AutoHeight={false}
                PageSize={
                  viewInspectionQuestionHistoryState?.InspectionQuestionHistory
                    ?.length
                }
                ShowCustomePagination
              ></HPRDataGrid>
            </div>
          </div>
          <div className="modal-button-bar">
            <Button
              className="secondary-btn-small"
              variant="outlined"
              disableElevation
              onClick={(e) => {
                e.stopPropagation();
                onCloseViewInspectionQuestionHistory();
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>

    // <Popover
    //   open={viewRemediationTrackerTaskHistoryState.Open}
    //   anchorEl={viewRemediationTrackerTaskHistoryState.Ref}
    //   onClose={onCloseViewRemediationTrackerTaskHistory}
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left",
    //   }}
    // >
    //   <Box
    //     component="form"
    //     sx={{
    //       "& > :not(style)": { m: 1, width: "35ch", maxHeight: "46ch" },
    //     }}
    //     noValidate
    //     autoComplete="off"
    //   >
    //     <div>
    //       <div className="bold">Approver(s) Details</div>
    //       <br />
    //       {viewRemediationTrackerTaskHistoryState.RemediationTrackerTaskHistory
    //         ?.length > 0 ? (
    //         viewRemediationTrackerTaskHistoryState.RemediationTrackerTaskHistory?.map(
    //           (approve, index) => {
    //             return (
    //               <div
    //                 className="flex--container justify-space"
    //                 key={`approve_${index}`}
    //               >
    //                 <div className="flex__item">
    //                   <span className="body4">{approve.ApproverName}</span>
    //                   <span>-</span>
    //                   <span className="body3">
    //                     {getDateToString(approve.ApprovedDate)}
    //                   </span>
    //                 </div>
    //                 <div className="body4 flex__item--inherit green-text">
    //                   {approve.IsApproved ? "Approved" : "Pending"}
    //                 </div>
    //               </div>
    //             );
    //           }
    //         )
    //       ) : (
    //         <span>No records found</span>
    //       )}
    //     </div>
    //   </Box>
    // </Popover>
  );
};

export default ViewInspectionQuestionHistory;
