import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { SystemPageType } from "./SystemPage.types";

export const GETSTANDRDSYSTEMLIST = "GETSTANDRDSYSTEMLIST";
export const SAVESTANDARDRESYSTEM = "SAVESTANDARDRESYSTEM";
export const UPDATESTANDARDSYSTEM = "UPDATESTANDARDSYSTEM";
export const DELETESTANDARDSYSTEM = "DELETESTANDARDSYSTEM";

export const getStandardSystemList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETSTANDRDSYSTEMLIST,
    payload: searchParameter,
  };
};

export const saveStandardSystem = (systemPageType: SystemPageType) => {
  return {
    type: SAVESTANDARDRESYSTEM,
    payload: systemPageType,
  };
};

export const updateStandardSystem = (systemPageType: SystemPageType) => {
  return {
    type: UPDATESTANDARDSYSTEM,
    payload: systemPageType,
  };
};

export const deleteStandardSystem = (systemPageType: SystemPageType) => {
  return {
    type: DELETESTANDARDSYSTEM,
    payload: systemPageType,
  };
};
