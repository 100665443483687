import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";

const client = new ApiClient();

export const getSiteInspectionsList = async (
  Params: SearchParameterDTO
): Promise<SiteInspectionsPageInitialState> => {
  try {
    const URL: string = `SiteInspection/GetSiteInspectionsList`;
    let data = {} as SiteInspectionsPageInitialState;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as SiteInspectionsPageInitialState;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const getSiteInspectionsCurrentStatus = async (
  Params: Array<number>
): Promise<SiteInspectionsPageInitialState> => {
  try {
    const URL: string = `SiteInspection/CheckInspectionStatus`;
    let data = {} as SiteInspectionsPageInitialState;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as SiteInspectionsPageInitialState;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};
