import {SearchParameterDTO} from "../../../common/types/common.dto.types";

export const GETLOCATIONSLIST = "GETLOCATIONSLIST";

export const getLocationsList = (searchParameter: SearchParameterDTO) => {
    return {
        type: GETLOCATIONSLIST,
        payload: searchParameter,
    };
};
