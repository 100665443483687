import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";

export const GETASSESSMENTTYPEWIZARD = "GETASSESSMENTTYPE";
export const SAVEASSESSMENTTYPEWIZARD = "SAVEASSESSMENTTYPE";
export const CHECKBEFOREDEACTIVATEASSESSMENTTYPEWIZARD = "CHECKBEFOREDEACTIVATEASSESSMENTTYPEWIZARD"

export const getAssessmentTypeWizardById = (assessmentTypeId: number) => {
  return {
    type: GETASSESSMENTTYPEWIZARD,
    payload: assessmentTypeId,
  };
};

export const saveAssessmentTypeWizard = (assessmentType: AssessmentTypeDTO) => {
  return {
    type: SAVEASSESSMENTTYPEWIZARD,
    payload: assessmentType,
  };
};

export const checkBeforeDeactivateAssessmentTypeWizard = (assessmentType: AssessmentTypeDTO) => {
  return {
    type: CHECKBEFOREDEACTIVATEASSESSMENTTYPEWIZARD,
    payload: assessmentType,
  };
};
