import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionMemberDTO,
  QuestionnaireDTO,
} from "../../../common/types/common.dto.types";
import { RedirectObject } from "../../../common/types/common.page.type";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  QuestionnaireSummaryPageParametersType,
  QuestionnaireSummaryParameterDTO,
} from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import {
  COMPLETEINSPECTION,
  COMPLETEINSPECTIONSTANDARD,
  COPYINSPECTIONSTANDARD,
  GETSITEINSPECTIONQUESTIONLIST,
  REOPENCOMPLETEINSPECTION,
  REOPENINSPECTIONSTANDARD,
  RESUMEINSPECTIONSTANDARD,
  SAVEINSPECTIONSITETEAMMEMBER,
} from "./SiteInspectionStartPage.action";
import {
  completeInspection,
  completeInspectionStandard,
  copyInspectionStandard,
  getSiteInspectionQuestionList,
  reOpenCompleteInspection,
  reOpenInspectionStandard,
  resumeInspectionStandard,
  saveInspectionSiteTeamMember,
} from "./SiteInspectionStartPage.api";
import {
  clearPageState,
  setPageMode,
  setPageModeRedirectMode,
  setSiteInspectionStartList,
  setSiteTeamMember,
  updateInspectionStandardStatus,
} from "./SiteInspectionStartPage.reducer";
import {
  CopyInspectionStandardDTO,
  ReopenInspectionStandardData,
  SiteInspectionStartDataType,
} from "./SiteInspectionStartPage.types";

const getSiteInspectionQuestionListWatcher = function* (): any {
  yield takeEvery(
    GETSITEINSPECTIONQUESTIONLIST,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));
      yield put(clearPageState());
      // get data from backend

      try {
        const InspectionId: number = action.payload;
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionId)
        );
        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }

      // set page loading on
      yield put(setLoading(false));
    }
  );
};

const saveSiteTeamMemberWatcher = function* (): any {
  yield takeEvery(
    SAVEINSPECTIONSITETEAMMEMBER,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      try {
        // save Subject data from backend
        const InspectionMemberParam: Array<InspectionMemberDTO> =
          action.payload;
        const result: ActionResponseType = yield call(() =>
          saveInspectionSiteTeamMember(InspectionMemberParam)
        );

        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          const siteTeamMember: Array<InspectionMemberDTO> = result.Data;
          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully saved",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
          yield put(setPageMode(EnumPageMode.ViewMode));
          yield put(setSiteTeamMember(siteTeamMember));
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const copyInspectionStandardWatcher = function* (): any {
  yield takeEvery(COPYINSPECTIONSTANDARD, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save Subject data from backend
      const inspectionStandardParams: CopyInspectionStandardDTO =
        action.payload;
      const result: ActionResponseType = yield call(() =>
        copyInspectionStandard(inspectionStandardParams)
      );

      if (!result.Data.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.Data.ErrorMessage
              ? result.Data.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const state: SiteInspectionStartDataType = yield select(
          (s: RootState) => s.siteInspectionStartReducer
        );

        const InspectionId: number = state?.Inspection?.Id;
        const inspectionresult: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionId)
        );

        if (inspectionresult.IsSuccess) {
          const inspection: InspectionDTO = inspectionresult?.Data;
          const siteInspectionStartDataType: SiteInspectionStartDataType = {
            Inspection: inspection,
            InspectionName: inspection.Name,
            InspectonDescription: inspection.Description,
            QuestionMainData: createMainDataObject(inspection),
            InspectionMembers: inspection.InspectionMembers,
            SiteTeamMemberName:
              inspection.InspectionMembers.length > 0
                ? inspection.InspectionMembers[0].MemberName
                : "",
          } as SiteInspectionStartDataType;
          yield put(setSiteInspectionStartList(siteInspectionStartDataType));
          yield put(setPageMode(EnumPageMode.ViewMode));

          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully copied",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick: () => window.location.reload(),
            } as PopupProps)
          );
        }

        // yield put(setSiteTeamMember(inspectionStandard));
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};
const resumeInspectionStandardWatcher = function* (): any {
  yield takeEvery(RESUMEINSPECTIONSTANDARD, function* (action: AnyAction): any {
    const inspectionStandardId = action.payload;
    yield put(setLoading(true));
    const result: ActionResponseType = yield call(() =>
      resumeInspectionStandard(inspectionStandardId)
    );
    yield put(setLoading(false));
  });
};
const completeSiteInspectionStandardWatcher = function* (): any {
  yield takeEvery(
    COMPLETEINSPECTIONSTANDARD,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));
      // use for page navigation
      try {
        // save Subject data from backend
        const InspectionStandardStatusParam: QuestionnaireDTO = action.payload;
        const result: ActionResponseType = yield call(() =>
          completeInspectionStandard(InspectionStandardStatusParam)
        );

        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          yield put(setLoading(false));

          if (
            InspectionStandardStatusParam.InspectionStandardStatus ===
            EnumInspectionStandardStatus.Paused
          ) {
            // TODO document why this block is empty
          } else if (
            InspectionStandardStatusParam.InspectionStandardStatus ===
            EnumInspectionStandardStatus.Completed
          ) {
            let redirectObject = {
              NavigateURl: "/questionnaireSummary",
              NavigateObject: {
                state: {
                  StandardId: InspectionStandardStatusParam.StandardID,
                  SiteInspectionId:
                    InspectionStandardStatusParam.SiteInspectionID,
                  InspectionStandardId:
                    InspectionStandardStatusParam.InspectionStandardId,
                  ReAssess: false,
                } as QuestionnaireSummaryPageParametersType,
              },
            } as RedirectObject;

            yield put(setPageModeRedirectMode(redirectObject));
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const completeSiteInspectionWatcher = function* (): any {
  yield takeEvery(COMPLETEINSPECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionParam: InspectionDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        completeInspection(InspectionParam)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionParam.Id)
        );
        if (!result.IsSuccess) {
          yield put(setLoading(false));
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(setLoading(false));
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
            yield put(
              setPageModeRedirectMode({
                NavigateURl: "/remediationTrackerTasks",
              } as RedirectObject)
            );
          }
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const reopenCompleteSiteInspectionWatcher = function* (): any {
  yield takeEvery(REOPENCOMPLETEINSPECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionParam: QuestionnaireDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        reOpenCompleteInspection(InspectionParam.SiteInspectionID)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionParam.SiteInspectionID)
        );
        if (!result.IsSuccess) {
          yield put(setLoading(false));
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(setLoading(false));
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
          }
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const reopenSiteInspectionStandardWatcher = function* (): any {
  yield takeEvery(REOPENINSPECTIONSTANDARD, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionStandardParam: QuestionnaireSummaryParameterDTO =
        action.payload;
      const result: ActionResponseType = yield call(() =>
        reOpenInspectionStandard(InspectionStandardParam)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        let data = result.Data;
        // let redirectObject = {
        //   NavigateURl: "/questionnaireStart",
        //   NavigateObject: {
        //     state: {
        //       InspectionStandardId:
        //         InspectionStandardParam.InspectionStandardId,
        //       StandardId: InspectionStandardParam.StandardId,
        //       InspectionId: InspectionStandardParam.SiteInspectionId,
        //       Title: InspectionStandardParam.InspectionStandardName,
        //       IsContinueButtonVisible: true,
        //     } as QuestionnaireStartPageParametersTypes,
        //   },
        // } as RedirectObject;
        // yield put(setPageModeRedirectMode(redirectObject));
        yield put(
          updateInspectionStandardStatus({
            allowResumption: true,
            idToUpdate: InspectionStandardParam.InspectionStandardId,
            reopenedData: {
              InspectionStandardId: data?.InspectionStandardId,
              ReopenedBy: data?.ReOpenedBy,
              ReopenedDate: data?.ReOpenedDate,
              StatusName: data?.StatusName,
            } as ReopenInspectionStandardData,
          })
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const createMainDataObject = (inspection: InspectionDTO) => {
  const QuestionMainData = [];
  for (let k = 0; k < inspection.InspectionStandards.length; k++) {
    for (const element of inspection.InspectionQuestions) {
      if (
        inspection.InspectionStandards[k].StandardId === element.StandardId &&
        inspection.InspectionStandards[k].Id === element.InspectionStandardId
      ) {
        let answerCount: number = 0;
        let pendingCount: number = 0;
        let gapCount: number = 0;
        let skipCount: number = 0;
        let progressValue: number = 0;
        let answeredAsNA: number = 0;

        if (
          element.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Answered
        ) {
          if (element.NotApplicableAnswer) {
            answeredAsNA++;
          } else {
            answerCount++;
          }
        } else if (
          element.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Pending
        ) {
          pendingCount++;
        } else if (
          element.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Gap
        ) {
          gapCount++;
        } else if (
          element.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Skipped
        ) {
          skipCount++;
        }

        progressValue = Math.round(
          ((answerCount + gapCount + answeredAsNA) / 1) * 100
        );

        if (QuestionMainData.length === 0) {
          let MainDataObject = {
            AllQuestionCount: 1,
            AnsweredCount: answerCount,
            PendingCount: pendingCount,
            GapCount: gapCount,
            SkippedCount: skipCount,
            InspectionStandardId: inspection.InspectionStandards[k].Id,
            SelectedBuildingId: inspection.InspectionStandards[k].BuildingId,
            Suffix: inspection.InspectionStandards[k].Suffix,
            Location: inspection.InspectionStandards[k].Location,
            RelatedEntity: inspection.InspectionStandards[k].RelatedEntity,
            InspectionStandardName:
              inspection.InspectionStandards[k].Standard?.Name,
            InspectionStandardStartedDate:
              inspection?.InspectionStandards[k]?.StartedDate,
            InspectionStandardStartedBy:
              inspection?.InspectionStandards[k]?.StartedBy,
            InspectionStandardCompletedDate:
              inspection?.InspectionStandards[k]?.CompletedDate,
            InspectionStandardCompletedBy:
              inspection?.InspectionStandards[k]?.ModifiedBy,
            InspectionStandardReOpenedBy:
              inspection?.InspectionStandards[k]?.ReOpenedBy,
            InspectionStandardReOpenedDate:
              inspection?.InspectionStandards[k]?.ReOpenedDate,
            InspectionStandardStatus:
              inspection.InspectionStandards[k]?.InspectionStandardStatusId,
            InspectionStandardStatusName:
              inspection.InspectionStandards[k]?.InspectionStandardStatus
                ?.StatusName,
            GlobalPrimarySME:
              inspection.InspectionStandards[k]?.Standard?.PrimarySME?.Sme
                ?.DisplayName,
            SitePrimarySME:
              inspection.InspectionStandards[k].InspectionStandardSmes?.length >
              0
                ? inspection.InspectionStandards[k].InspectionStandardSmes?.map(
                    (sme) => sme.Sme.DisplayName
                  ).join("; ")
                : inspection.InspectionStandards[k]?.PrimarySme?.DisplayName,
            IsDownloadFile: true,
            InspectionQuestionStatus: element.InspectionQuestionStatusId,
            StandardId: element.StandardId,
            StandardName: `${
              inspection.InspectionStandards[k]?.Standard?.Name
            }${
              inspection.InspectionStandards[k]?.Suffix != null
                ? " - " + inspection.InspectionStandards[k]?.Suffix
                : ""
            }`,
            InspectionId: element.SiteInspectionId,
            ProgressBarValue: progressValue,
            Buildings: inspection.Site.Buildings,
            AnsweredAsNA: answeredAsNA,
          };

          QuestionMainData.push(MainDataObject);
        } else {
          let DataObject = {};
          let questionMainCount = QuestionMainData.length;
          for (let p = 0; p < QuestionMainData.length; p++) {
            if (questionMainCount === p + 1) {
              if (
                QuestionMainData[p].StandardId === element.StandardId &&
                QuestionMainData[p].InspectionStandardId ===
                  element.InspectionStandardId
              ) {
                let questionCount = QuestionMainData[p].AllQuestionCount + 1;
                QuestionMainData[p].AllQuestionCount = questionCount;

                if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Answered
                ) {
                  if (element.NotApplicableAnswer) {
                    QuestionMainData[p].AnsweredAsNA =
                      QuestionMainData[p].AnsweredAsNA + 1;
                  } else {
                    QuestionMainData[p].AnsweredCount =
                      QuestionMainData[p].AnsweredCount + 1;
                  }

                  let progressval: number;
                  progressval = Math.round(
                    ((QuestionMainData[p].AnsweredCount +
                      QuestionMainData[p].GapCount +
                      QuestionMainData[p].AnsweredAsNA) /
                      QuestionMainData[p].AllQuestionCount) *
                      100
                  );

                  QuestionMainData[p].ProgressBarValue = progressval;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Pending
                ) {
                  QuestionMainData[p].PendingCount =
                    QuestionMainData[p].PendingCount + 1;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Gap
                ) {
                  QuestionMainData[p].GapCount =
                    QuestionMainData[p].GapCount + 1;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Skipped
                ) {
                  QuestionMainData[p].SkippedCount =
                    QuestionMainData[p].SkippedCount + 1;
                }
              } else {
                let answerCount: number = 0;
                let pendingCount: number = 0;
                let gapCount: number = 0;
                let skipCount: number = 0;
                let progressValue: number = 0;

                if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Answered
                ) {
                  if (!element.NotApplicableAnswer) {
                    answerCount++;
                  }
                  //answerCount++;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Pending
                ) {
                  pendingCount++;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Gap
                ) {
                  gapCount++;
                } else if (
                  element.InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Skipped
                ) {
                  skipCount++;
                }

                progressValue = Math.round(
                  ((answerCount + gapCount + answeredAsNA) / 1) * 100
                );

                DataObject = {
                  AllQuestionCount: 1,
                  AnsweredCount: answerCount,
                  PendingCount: pendingCount,
                  GapCount: gapCount,
                  SkippedCount: skipCount,
                  InspectionStandardId: inspection.InspectionStandards[k].Id,
                  SelectedBuildingId:
                    inspection.InspectionStandards[k].BuildingId,
                  Suffix: inspection.InspectionStandards[k].Suffix,
                  Location: inspection.InspectionStandards[k].Location,
                  RelatedEntity:
                    inspection.InspectionStandards[k].RelatedEntity,
                  InspectionStandardName:
                    inspection.InspectionStandards[k].Standard?.Name,
                  InspectionStandardStatus:
                    inspection.InspectionStandards[k]
                      ?.InspectionStandardStatusId,
                  InspectionStandardStatusName:
                    inspection.InspectionStandards[k]?.InspectionStandardStatus
                      ?.StatusName,
                  InspectionStandardStartedDate:
                    inspection?.InspectionStandards[k]?.StartedDate,
                  InspectionStandardStartedBy:
                    inspection?.InspectionStandards[k]?.StartedBy,
                  InspectionStandardCompletedDate:
                    inspection?.InspectionStandards[k]?.CompletedDate,
                  InspectionStandardCompletedBy:
                    inspection?.InspectionStandards[k]?.ModifiedBy,
                  InspectionStandardReOpenedBy:
                    inspection?.InspectionStandards[k]?.ReOpenedBy,
                  InspectionStandardReOpenedDate:
                    inspection?.InspectionStandards[k]?.ReOpenedDate,
                  GlobalPrimarySME:
                    inspection.InspectionStandards[k]?.Standard?.PrimarySME?.Sme
                      ?.DisplayName,
                  SitePrimarySME:
                    inspection.InspectionStandards[k].InspectionStandardSmes
                      ?.length > 0
                      ? inspection.InspectionStandards[
                          k
                        ].InspectionStandardSmes?.map(
                          (sme) => sme.Sme.DisplayName
                        ).join("; ")
                      : inspection.InspectionStandards[k]?.PrimarySme
                          ?.DisplayName,
                  IsDownloadFile: true,
                  InspectionQuestionStatus: element.InspectionQuestionStatusId,
                  StandardId: element.StandardId,
                  StandardName: `${
                    inspection.InspectionStandards[k]?.Standard?.Name
                  }${
                    inspection.InspectionStandards[k]?.Suffix != null
                      ? " - " + inspection.InspectionStandards[k]?.Suffix
                      : ""
                  }`,
                  InspectionId: element.SiteInspectionId,
                  ProgressBarValue: progressValue,
                  Buildings: inspection.Site.Buildings,
                  AnsweredAsNA: answeredAsNA,
                };

                QuestionMainData.push(DataObject);
              }
            }
          }
        }
      }
    }
  }
  return QuestionMainData;
};

const stieInspectionStartPageSaga = function* () {
  yield all([
    getSiteInspectionQuestionListWatcher(),
    saveSiteTeamMemberWatcher(),
    completeSiteInspectionStandardWatcher(),
    copyInspectionStandardWatcher(),
    reopenSiteInspectionStandardWatcher(),
    resumeInspectionStandardWatcher(),
    completeSiteInspectionWatcher(),
    reopenCompleteSiteInspectionWatcher(),
  ]);
};

export default stieInspectionStartPageSaga;
