// project imports
import {
  InspectionDTO,
  InspectionMemberDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { QuestionnaireSummaryParameterDTO } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import { CopyInspectionStandardDTO } from "./SiteInspectionStartPage.types";

// client object for axios calls to backend
const client = new ApiClient();

export const getSiteInspectionQuestionList = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionQuestion/GetInspectionQuestionByInspectionId/${id}`;
    let siteInspectionQuestion = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        siteInspectionQuestion = response;
      })
      .catch((error) => {
        siteInspectionQuestion = {} as ActionResponseType;
      });
    return siteInspectionQuestion;
  } catch (error) {
    throw error;
  }
};

export const resumeInspectionStandard = async (
  inspectionStandardId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Questionnaire/ResumeInspectionStandard/${inspectionStandardId}`;
    let siteInspectionQuestion = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        siteInspectionQuestion = response;
      })
      .catch((error) => {
        siteInspectionQuestion = {} as ActionResponseType;
      });
    return siteInspectionQuestion;
  } catch (error) {
    throw error;
  }
};

export const saveInspectionSiteTeamMember = async (
  Params: Array<InspectionMemberDTO>
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionQuestion/SaveInspectionSiteTeamMember`;
    let inspectionSiteTeamMember = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        inspectionSiteTeamMember = response;
      })
      .catch((error) => {
        inspectionSiteTeamMember = {} as ActionResponseType;
        return error;
      });
    return inspectionSiteTeamMember;
  } catch (error) {
    throw error;
  }
};

export const copyInspectionStandard = async (
  Params: CopyInspectionStandardDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Questionnaire/CopyInspectionStandard`;
    let inspectionStandard = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        inspectionStandard = response;
      })
      .catch((error) => {
        inspectionStandard = {} as ActionResponseType;
        return error;
      });
    return inspectionStandard;
  } catch (e) {
    throw e;
  }
};

export const completeInspectionStandard = async (
  siteInspectionStandardParam: QuestionnaireDTO
): Promise<ActionResponseType> => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const URL: string = `Questionnaire/SaveInspectionStandardStatus`;
    let status = {} as ActionResponseType;
    const formData = new FormData();

    for (const key in siteInspectionStandardParam) {
      if (key !== "UploadedFileList") {
        formData.append(key, siteInspectionStandardParam[key]);
      } else {
      }
    }
    if (siteInspectionStandardParam.UploadedFileList.length > 0) {
      siteInspectionStandardParam.UploadedFileList.forEach(
        (file: UploadFileDTO, index: number) => {
          formData.append(`ImageFiles`, file.File);
        }
      );
    }

    await client
      .post<ActionResponseType>(URL, siteInspectionStandardParam)
      .then((response) => {
        status = response;
      })
      .catch((error) => {
        status = {} as ActionResponseType;
        return error;
      });
    return status;
  } catch (error) {
    throw error;
  }
};

export const completeInspection = async (
  siteInspectionParam: InspectionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionQuestion/CompleteInspection`;
    let inspection = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, siteInspectionParam)
      .then((response) => {
        inspection = response;
      })
      .catch((error) => {
        inspection = {} as ActionResponseType;
        return error;
      });
    return inspection;
  } catch (error) {
    throw error;
  }
};

export const reOpenCompleteInspection = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionQuestion/ReOpenCompleteInspection/${id}`;
    let siteInspectionQuestion = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        siteInspectionQuestion = response;
      })
      .catch((error) => {
        siteInspectionQuestion = {} as ActionResponseType;
      });
    return siteInspectionQuestion;
  } catch (error) {
    throw error;
  }
};

export const reOpenInspectionStandard = async (
  Params: QuestionnaireSummaryParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `QuestionnaireSummary/Reassess`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};
