import inspectionReportSagaActions from "./StandardsSummaryReport.actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import { InspectionDTO } from "../../../common/types/common.dto.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { useRef, useEffect, useState } from "react";
import { inspectionReportReducerActions } from "./StandardsSummaryReport.reducer";
import { CircularProgress } from "@mui/material";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import { Document } from "iconsax-react";
import { SummaryReportDownloadStatusType } from "./StandardsSummaryReport.types";

const InspectionExcelDownload = (props: { InspectionId: number }) => {
  const state = useAppSelector((state) => state.inspectionReportReducer);
  const [isReportDownloading, setIsReportDownloading] = useState(false);

  const dispatch = useAppDispatch();
  const isInProgress = useRef(false);

  useEffect(() => {
    const reportStatus = state.excelReportStatus.some(
      (summary) =>
        summary.InspectionId === props.InspectionId &&
        summary.IsDownloading === true
    );
    setIsReportDownloading(reportStatus);
  }, [state]);

  const downloadInspections = () => {
    isInProgress.current = true;

    dispatch(
      inspectionReportReducerActions.setExcelReportStatus({
        InspectionId: props.InspectionId,
        IsDownloading: true,
      } as SummaryReportDownloadStatusType)
    );

    dispatch(
      inspectionReportSagaActions.getInspectionSummary({
        ReportType: EnumReportType.InspectionReport,
        Inspection: {
          Id: props.InspectionId,
          Name: "",
        } as InspectionDTO,
      } as ReportViewerType)
    );
  };
  const authState = useAppSelector((state) => state.authReducer);
  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  return (
    <>
      {/* <Button
        className="primary small-btn"
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          downloadInspections();
        }}
        style={{
          visibility: checkSystemFunctionRight(
            EnumSystemFunctions.SiteInspectionPage_New_Summary,
            EnumSystemFunctionTypes.ButtonAccess
          )
            ? "visible"
            : "hidden",
        }}
      >
        Standards Summary Report
      </Button> */}
      {isReportDownloading ? (
        <div>
          <CircularProgress size={20} />
        </div>
      ) : null}

      <div
        className={`colmn-opt excel`}
        onClick={(e) => {
          e.preventDefault();
          downloadInspections();
        }}
        style={
          isReportDownloading
            ? {
                cursor: "none",
                opacity: "0.7",
              }
            : null
        }
      >
        <span className="">
          <Document
            className="m-r-10"
            color="#66bb6a"
            style={{ fontSize: 20 }}
          />
        </span>
        <span>UpToDate Inspection Summary</span>
      </div>
    </>
  );
};

export default InspectionExcelDownload;
