/* ==============================|| VIEWS - PAGES - QUESTION - SCSS ||==============================  */

import {EnumSortTypes, EnumStandardQuestionSortType, EnumStatus} from "../../../common/enums/common.enums";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";

export const STANDARDDETAILSWITHQUESTIONLIST =
  "STANDARDDETAILSWITHQUESTIONLIST";

export const SEARCHQUESTION = "SEARCHQUESTION";

export const SEARCHBYSTATUS = "SEARCHBYSTATUS";

export const getStandardDetailsWithQuestionList = (
  StandardId: number,
  pageNo: number,
  SearchStatus: EnumStatus,
  SortBy:EnumStandardQuestionSortType,
  SortType: EnumSortTypes
) => {
  return {
    type: STANDARDDETAILSWITHQUESTIONLIST,
    payload: { StandardId, pageNo, SearchStatus, SortBy, SortType },
  };
};

export const getSearchQuestion = (
  questionSearchParameter: SearchParameterDTO
) => {
  return {
    type: SEARCHQUESTION,
    payload: questionSearchParameter,
  };
};

export const getQuestionsByStatus = (status: number, standardID: number) => {
  return {
    type: SEARCHBYSTATUS,
    payload: { status, standardID },
  };
};
