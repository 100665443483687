import { EnumStatus } from "../../../common/enums/common.enums";
import { SiteDTO } from "../../../common/types/common.dto.types";

export const GETSITE = "GETSITE";
export const SAVESITE = "SAVESITE";
export const GETDROPDOWNS = "GETDROPDOWNS";

export const getDropdowns = (SiteId: number) => {
  return {
    type: GETDROPDOWNS,
    payload: SiteId,
  };
};

export const getSiteById = (id: number) => {
  return {
    type: GETSITE,
    payload: id,
  };
};

export const saveSite = (site: SiteDTO, action: EnumStatus) => {
  return {
    type: SAVESITE,
    payload: { site, action },
  };
};
