import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { confirmable } from "react-confirm";
import {
  CheckCircleOutlineOutlined,
  HelpOutline,
  InfoOutlined,
  WarningAmber,
} from "@mui/icons-material";

interface ConfirmationBoxProps {
  show: boolean;
  proceed: (decision: boolean) => boolean;
  Confirmation: string;
  YesBtnClick?: () => void;
  NoBtnClick?: () => void;
}
const ConfirmationBox = (props: ConfirmationBoxProps) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const YesButtonClick = () => {
    if (props.YesBtnClick) {
      props.YesBtnClick();
    }
    props.proceed(true);
  };
  const NoButtonClick = () => {
    if (props.NoBtnClick) {
      props.NoBtnClick();
    }
    props.proceed(false);
  };
  return (
    <Modal
      className="modal-outter modal-md"
      open={props.show}
      //onClose={NoButtonClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal-header">
          <h1>{"Confirmation"}</h1>
        </div>
        <div className="modal-content info-popup confirmation">
          <WarningAmber className="error-svg" />
          <InfoOutlined className="info-svg" />
          <CheckCircleOutlineOutlined className="success-svg" />
          <HelpOutline className="confirmation-svg" />
          <p>
            <span
              dangerouslySetInnerHTML={{ __html: props.Confirmation }}
            ></span>
          </p>
        </div>
        <div className="modal-button-bar">
          <Button
            className="secondary-btn-small"
            variant="contained"
            disableElevation
            onClick={NoButtonClick}
          >
            No
          </Button>
          <Button
            className="primary"
            variant="contained"
            disableElevation
            onClick={YesButtonClick}
          >
            Yes
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
export default confirmable(ConfirmationBox);
