import { TermsOfUseEmployeeDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";


const client = new ApiClient();

export const savePrivacyNoticeEmployee = async (
  Params: TermsOfUseEmployeeDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `TermsOfUse/SaveTermsOfUse`;
    let result = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        result = {} as ActionResponseType;
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
