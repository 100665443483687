import {
  EnumRemediationTrackerTaskStatus,
  EnumSortTypes,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { HPRStatusProps } from "./HPRStatus.types";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  DropDownDTO,
  SortDropDownDTO,
  StatusSummaryDTO,
} from "../../../common/types/common.dto.types";
import { useState } from "react";
import useOutsideClick from "../../../core/hooks/useOutsideClick";
import {
  ArrowDown,
  ArrowDown2,
  ArrowDown3,
  ArrowUp,
  ArrowUp3,
  Sort,
} from "iconsax-react";
import { IconButton, Grid } from "@mui/material";
import StandardQuestionsExcelDownload from "../../../views/Reports/StandardQuestionsReport/StandardQuestionsReport.Excel";

// ==============================|| COMPONENTS - COMMON - HPRSTATUS ||============================== //

const HPRStatus = (props: HPRStatusProps) => {
  const [selectedSortState, setSelectedSortState] = useState<SortDropDownDTO>(
    props.InitialSortByState
  );
  const onChangeSortBy = (event: SortDropDownDTO) => {
    if (props.onChangeSortBy) {
      if (
        selectedSortState.Label !== event.Label ||
        selectedSortState.SortType !== event.SortType
      ) {
        setSelectedSortState(event);
        props.onChangeSortBy(event);
      }
    }
  };

  // const [selectedPerPageState, setSelectedPerPageState] = useState<string>(
  //   props.PerPageItemList ? props.PerPageItemList[0].Label : ""
  // );

  const selectedPerPage = props.HidePerPage
    ? ""
    : props.defaultValue
    ? props.defaultValue
    : props.PerPageItemList[1].Label;

  const [selectedPerPageState, setSelectedPerPageState] =
    useState<string>(selectedPerPage);

  const onChangePerPage = (event: DropDownDTO) => {
    if (props.onChangePerPage) {
      if (selectedPerPageState !== event.Label) {
        setSelectedPerPageState(event.Label);
        props.onChangePerPage(props.Name, event.Value as string);
      }
    }
  };

  const [listDisplayState, setListDisplayState] = useState<"none" | "block">(
    "none"
  );
  const [listPageDisplayState, setPageListDisplayState] = useState<
    "none" | "block"
  >("none");

  const ref = useOutsideClick(() => {
    setListDisplayState("none");
  });

  const perPageRef = useOutsideClick(() => {
    setPageListDisplayState("none");
  });

  const toggleDisplayStyle = (type: string) => {
    if (type == "SortBy") {
      setListDisplayState((prevDisplayStyle) =>
        prevDisplayStyle === "none" ? "block" : "none"
      );
    } else {
      setPageListDisplayState((prevDisplayStyle) =>
        prevDisplayStyle === "none" ? "block" : "none"
      );
    }
  };

  function toggleSortType() {
    props.onChangeSortBy({
      ...selectedSortState,
      SortType:
        selectedSortState.SortType === EnumSortTypes.Ascending
          ? EnumSortTypes.Descending
          : EnumSortTypes.Ascending,
    });
    setSelectedSortState((prevState) => ({
      ...prevState,
      SortType:
        prevState.SortType === EnumSortTypes.Ascending
          ? EnumSortTypes.Descending
          : EnumSortTypes.Ascending,
    }));
  }

  return (
    <>
      <div className="standarded-tags">
        <Grid container columns={12}>
          <Grid item lg={6} md={6} sm={6} xs={5} className="">
            {props.StatusSummaryItemList &&
              props.StatusSummaryItemList?.map((item) => {
                return (
                  <span
                    key={`Status_${item.Value}_${item.Value}`}
                    className={`${
                      props.SelectedStatus === item.Value ? "tags-active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.onClickStatus(item.Value);
                    }}
                  >
                    {item.Label}
                    {item.StatusSummaryCount || item.StatusSummaryCount === 0
                      ? `(${item.StatusSummaryCount})`
                      : ""}
                  </span>
                );
              })}
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={7} className="">
            {!props.HideSortBy ? (
              <>
                <div className="sortOpt p-r-10">
                  <label className="sort-label" style={{ padding: "0 15px" }}>
                    Sort by
                  </label>
                  <div className="p-r-5 flex--container height-32">
                    {/* <ArrowUp variant="Outline" className="ico-primary" size={15} /> */}

                    <div
                      ref={ref}
                      className="valign-middle p-r-5"
                      onClick={() => toggleDisplayStyle("SortBy")}
                      style={{ position: "relative" }}
                    >
                      {selectedSortState.Label}
                      <ArrowDown2
                        size={14}
                        style={{ position: "relative", top: 1 }}
                        className="p-l-5"
                      />
                      <div
                        className="drop-items"
                        style={{
                          display: listDisplayState,
                          right: -10,
                          top: 31,
                          minWidth: 115,
                          width: "max-content",
                          maxWidth: 250,
                        }}
                      >
                        <ul>
                          {props.SortByItemList?.sort((a, b) =>
                            a.Label.localeCompare(b.Label)
                          ).map((item, index) => (
                            <li
                              key={index}
                              onClick={() => onChangeSortBy(item)}
                            >
                              <a className="flex--container">
                                <label className="flex__item text-left vcenter">
                                  {item.Label}
                                </label>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {/*Toggle sort button start*/}
                    <div
                      className="p-l-10"
                      // title={
                      //   selectedSortState.SortType === EnumSortTypes.Descending
                      //     ? "Descending"
                      //     : "Ascending"
                      // }
                      onClick={() => toggleSortType()}
                    >
                      <div title="Ascending">
                        <ArrowUp3
                          size={14}
                          className={`order${
                            selectedSortState.SortType ===
                            EnumSortTypes.Ascending
                              ? " ascending"
                              : ""
                          }`}
                          style={{ position: "relative", top: 2 }}
                        />
                      </div>
                      <div title="Descending">
                        <ArrowDown3
                          size={14}
                          className={`order${
                            selectedSortState.SortType ===
                            EnumSortTypes.Descending
                              ? " descending"
                              : ""
                          }`}
                          style={{ position: "relative", top: -10 }}
                        />
                      </div>
                      {/* )} */}
                    </div>
                    {/*Toggle sort button end*/}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {!props.HidePerPage ? (
              <>
                <div className="sortOpt text-center m-r-10 p-r-10">
                  <label className="sort-label">Per page</label>

                  <div>
                    <div
                      ref={perPageRef}
                      onClick={() => toggleDisplayStyle("PerPageData")}
                      style={{ position: "relative" }}
                    >
                      {selectedPerPageState}
                      {/* <KeyboardArrowDown style={{ position: "relative", top: 0 }} /> */}
                      <ArrowDown2
                        size={14}
                        style={{ position: "relative", top: 3 }}
                        className="p-l-5"
                      />

                      <div
                        className="drop-items"
                        style={{
                          display: listPageDisplayState,
                          width: 50,
                        }}
                      >
                        <ul>
                          {props.PerPageItemList?.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => onChangePerPage(item)}
                            >
                              <a>{item.Label}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* {props.ShowReportDownload ? (
              <>
                <div className="sortOpt m-r-10 p-r-10">
                  <StandardQuestionsExcelDownload
                    ReportViewer={props.StandardQuestionReportViewer}
                    StandardQuestionsExcelReportFilter={
                      props.StandardQuestionReportViewer?.ReportSearchParameter
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default HPRStatus;
