import { createSlice } from "@reduxjs/toolkit";
import {
  EnumListShowRowCountList,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  AssessmentTypeDTO,
  EmployeeDTO,
  PaginationDTO,
  StatusDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";

// project imports
import { AssessmentTypeWizardsPageType } from "./AssessmentTypeWizardsPage.types";

// ==============================|| VIEWS - PAGES - ASSESSMENT TYPE WIZARDS - REDUCER ||============================== //

const initialState = {
  Data: {
    Data: [] as Array<AssessmentTypeDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  AssessmentTypeWizardSearchParameter: {
    AssessmentTypeName: "",
    Description: "",
    Code: "",
    StatusNavigation: {
      Status1: "",
    } as StatusDTO,
    CreatedBy: { DisplayName: "" } as EmployeeDTO,
    CreatedDateFrom: null,
    CreatedDateTo: null,
    ModifiedByNavigation: { DisplayName: "" } as EmployeeDTO,
    ModifiedDateFrom: null,
    ModifiedDateTo: null,
    Status: EnumStatus.Published,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    BasicSearchValue: "",
  } as AssessmentTypeDTO,
  DataLoading: false,
} as AssessmentTypeWizardsPageType;

export const assessmentTypeWizardSlice = createSlice({
  name: "AssessmentTypeWizardList",
  initialState: initialState,
  reducers: {
    setAssessmentTypeWizardList: (
      state: AssessmentTypeWizardsPageType,
      action: { payload: AssessmentTypeWizardsPageType; type: string }
    ) => {
      return {
        ...state,
        Data: {
          ...state.Data,
          Data: action.payload.Data?.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
          PageSize: action.payload.Data?.PageSize,
        } as PaginationDTO,
        AssessmentTypeStatusSummary:
          action.payload?.AssessmentTypeStatusSummary,
      };
    },
    setAssessmentTypeWizardSearchParameter: (
      state: AssessmentTypeWizardsPageType,
      action: { payload: AssessmentTypeDTO; type: string }
    ) => {
      return {
        ...state,
        AssessmentTypeWizardSearchParameter: action.payload,
      };
    },
    resetAssessmentTypeWizardList: () => {
      return initialState;
    },
    setColumnFilterDataLoading: (
      state: AssessmentTypeWizardsPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        DataLoading: action.payload,
      };
    },
  },
});

export const assessmentTypeWizardsReducerActions =
  assessmentTypeWizardSlice.actions;
export const assessmentTypeWizardListReducer =
  assessmentTypeWizardSlice.reducer;
