import { createSlice } from "@reduxjs/toolkit";
import {
  HPRDataGridProps,
  HPRDataGridToolbarColumnProps,
  HPRDataGridToolbarProps,
  HPRGridColDef,
} from "./HPRDataGrid.types";
import { EnumGridDataShowRowCount } from "../../../common/enums/common.enums";
// ==============================|| HPR - DATAGRID REDUCER ||============================== //

const initialState = {
  Id: "",
  Name: "",
  Columns: [] as Array<HPRGridColDef>,
  Data: [] as Array<object>,
  Url: "",
  TotalRecordsCount: 0,
  Height: 0,
  Width: 0,
  AutoHeight: true,
  SelectedPage: 1,
  PageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  CustomFooter: null,
  HPRDataGridToolbarProps: {
    HPRDataGridToolbarColumnProps: {
      Columns: [] as Array<HPRGridColDef>,
      ShowColumnOption: false,
    } as HPRDataGridToolbarColumnProps,
  } as HPRDataGridToolbarProps,
} as HPRDataGridProps;

export const HPRDataGridSlice = createSlice({
  name: "hPRDataGrid",
  initialState,
  reducers: {
    changeColumnOptions: (
      state: HPRDataGridProps,
      action: { payload: HPRDataGridToolbarColumnProps; type: string }
    ) => {
      return {
        ...state,
        HPRDataGridToolbarProps: {
          ...state.HPRDataGridToolbarProps,
          HPRDataGridToolbarColumnProps: {
            ...state.HPRDataGridToolbarProps.HPRDataGridToolbarColumnProps,
            Columns: action.payload.Columns,
            ShowColumnOption: action.payload.ShowColumnOption,
          },
        },
      };
    },
    setColumns: (
      state: HPRDataGridProps,
      action: { payload: Array<HPRGridColDef>; type: string }
    ) => {
      return {
        ...state,
        Columns: action.payload,
      };
    },
  },
});

export const HPRDataGridActions = HPRDataGridSlice.actions;
export const hprDataGridReducer = HPRDataGridSlice.reducer;
