import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { Button, Divider, Grid } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import "../../layouts/main-layout/audit-view-main.scss";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import {
  DropDownDTO,
  InspectionMemberDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  QuestionnaireStartDTO,
  StandardReferenceDTO,
  StandardSmeDTO,
} from "../../../common/types/common.dto.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import {
  EnumInspectionStandardStatus,
  EnumPageMode,
  EnumPageType,
} from "../../../common/enums/common.enums";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import {
  QuestionnaireStartPageParametersTypes,
  QuestionnaireStartPageType,
} from "./QuestionnaireStartPage.types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getQuestionnaireStartDropdowns,
  startQuestionnaire,
} from "./QuestionnaireStartPage.action";
import {
  changePageMode,
  pausePage,
  setInitState,
} from "./QuestionnaireStartPage.reducer";
import HPRLinedTextArea from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea";
import { EnumLinedTextAreaType } from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea.enum";
import { ArrowLeft2 } from "iconsax-react";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";

const QuestionnaireStartPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  const initialState = {
    QuestionnaireStart: {
      StandardId: 0,
      InspectionId: 0,
      SelectedBuildingId: 0,
      Location: "",
      RelatedEntity: "",
      TeamMembers: "",
      GlobalPrimarySme: {} as StandardSmeDTO,
      GlobalSecondarySmes: [] as StandardSmeDTO[],
      References: [] as StandardReferenceDTO[],
      InspectionMember: {} as InspectionMemberDTO,
      InspectionStandard: {} as InspectionStandardDTO,
      BuildingList: [] as Array<DropDownDTO>,
      QuestionnaireStarted: false,
      ReferenceID: 0,
    } as QuestionnaireStartDTO,
    IsLoading: false,
    PageMode: {
      IsDisable: false,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      PageMode: EnumPageMode.Default,
    } as PageModeType,
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ShowErrorMsg: false,
    IsEditableFieldVisible: true,
  } as QuestionnaireStartPageType;

  const [questionnaireStartPageState, setQuestionnaireStartPageState] =
    useState(initialState);

  const authState = useAppSelector((state) => state.authReducer);
  // use for page navigation
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
  } as UsePromptTypes;
  usePrompt(prompt);
  // pass parameters from outside to page
  const location = useLocation();
  const parameter: QuestionnaireStartPageParametersTypes = location.state;

  // reducer functions
  const questionnaireStartState = useAppSelector(
    (state) => state.questionnaireStartReducer
  );
  const dispatch = useAppDispatch();

  // check when one time call to get location data from backend
  const dataFetchedRef = useRef(false);

  //On back button click
  function BackOnClickFunction() {
    navigate("/locations");
  }

  const onLocationChange = (name: string, value: any) => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          Location: value,
        },
      };
    });
  };

  const IsPageValid = (): boolean => {
    //check form valid
    let isValid = questionnaireStartPageState.Validator.allValid();
    if (!isValid) {
      setQuestionnaireStartPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !questionnaireStartPageState.ShowErrorMsg,
        };
      });

      //show validation message
      questionnaireStartPageState.Validator.showMessages();
    } else {
      //hide validation message
      questionnaireStartPageState.Validator.hideMessages();
    }

    return isValid;
  };

  const onRelatedEntityChange = (name: string, value: any) => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          RelatedEntity: value,
        },
      };
    });
  };
  const onTeamMembersChange = (name: string, value: string) => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          TeamMembers: value,
        },
      };
    });
  };
  const onCopySiteMembers = () => {
    onTeamMembersChange(
      "TeamMembers",
      questionnaireStartState.QuestionnaireStart.InspectionMember.MemberName
    );
  };

  const [startClicked, setStartClicked] = useState(false);
  const onClickStart = () => {
    /*
                // console.log(IsPageValid())
        */
    if (IsPageValid()) {
      setStartClicked(true);
      setIsDirty(false);
      dispatch(
        startQuestionnaire(questionnaireStartPageState.QuestionnaireStart)
      );
      // console.log(questionnaireStartPageState.QuestionnaireStart);
    } else {
      // console.log("invalid page");
    }
  };

  const onDropDownChange = (
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ) => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          SelectedBuildingId: parseInt(selectedValue),
          BuildingList: selectOption,
        },
      };
    });
  };

  useEffect(() => {
    questionnairePagevalidation();
  }, [questionnaireStartPageState]);

  useEffect(() => {
    dispatch(pausePage());
    if (parameter?.StandardId < 0 || parameter?.StandardId === undefined) {
      //Clear previous state
      dispatch(setInitState());
      //// console.log(parameter);
    } else {
      //// console.log(parameter);
      //Clear previous state
      dispatch(setInitState());

      dispatch(
        getQuestionnaireStartDropdowns({
          ...initialState.QuestionnaireStart,
          StandardId: parameter.StandardId,
          InspectionId: parameter.InspectionId,
          InspectionStandardId: parameter.InspectionStandardId,
        })
      );
      dispatch(changePageMode(EnumPageMode.EditMode));
    }
  }, []);

  useEffect(() => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          StandardId: questionnaireStartState.QuestionnaireStart.StandardId,
          InspectionId: questionnaireStartState.QuestionnaireStart.InspectionId,
          InspectionStandardId:
            questionnaireStartState.QuestionnaireStart.InspectionStandardId,
          SelectedBuildingId:
            questionnaireStartState.QuestionnaireStart?.SelectedBuildingId,
          Location: questionnaireStartState.QuestionnaireStart?.Location,
          RelatedEntity:
            questionnaireStartState.QuestionnaireStart?.RelatedEntity,
          TeamMembers: questionnaireStartState.QuestionnaireStart?.TeamMembers,
          InspectionMember:
            questionnaireStartState.QuestionnaireStart.InspectionMember,
          InspectionStandard:
            questionnaireStartState.QuestionnaireStart.InspectionStandard,
          GlobalPrimarySme:
            questionnaireStartState.QuestionnaireStart.GlobalPrimarySme,
          BuildingList: questionnaireStartState.QuestionnaireStart.BuildingList,
          StatusId: questionnaireStartState.QuestionnaireStart.StatusId,
        },
        IsEditableFieldVisible: questionnaireStartState.IsEditableFieldVisible,
      };
    });
  }, [questionnaireStartState]);

  useEffect(() => {
    if (
      questionnaireStartState.QuestionnaireStart.QuestionnaireStarted &&
      startClicked
    ) {
      navigate("/questionnaire", {
        state: {
          SiteInspectionID:
            questionnaireStartPageState.QuestionnaireStart.InspectionId,
          StandardID: questionnaireStartPageState.QuestionnaireStart.StandardId,
          InspectionStandardId:
            questionnaireStartPageState.QuestionnaireStart.InspectionStandardId,
          QuestionnaireStarted: true,
          ReferenceID:
            questionnaireStartPageState.QuestionnaireStart.ReferenceID,
          InspectionStandardName: parameter.Title,
        } as QuestionnaireDTO,
      });
    }
  }, [
    questionnaireStartState.QuestionnaireStart.QuestionnaireStarted,
    startClicked,
  ]);

  useEffect(() => {
    if (
      startClicked &&
      questionnaireStartState.QuestionnaireStart.QuestionnaireAlreadyStarted
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "This questionnaire has been started by another user",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          OkBtnClick() {
            navigate("/questionnaire", {
              state: {
                SiteInspectionID:
                  questionnaireStartPageState.QuestionnaireStart.InspectionId,
                StandardID:
                  questionnaireStartPageState.QuestionnaireStart.StandardId,
                InspectionStandardId:
                  questionnaireStartPageState.QuestionnaireStart
                    .InspectionStandardId,
                QuestionnaireStarted: true,
                ReferenceID:
                  questionnaireStartPageState.QuestionnaireStart.ReferenceID,
                InspectionStandardName: parameter.Title,
              } as QuestionnaireDTO,
            });
          },
        } as PopupProps)
      );
    }
  }, [
    questionnaireStartState.QuestionnaireStart.QuestionnaireAlreadyStarted,
    startClicked,
  ]);

  const questionnairePagevalidation = () => {
    if (
      questionnaireStartState.QuestionnaireStart?.TeamMembers !==
        questionnaireStartPageState.QuestionnaireStart?.TeamMembers ||
      questionnaireStartState.QuestionnaireStart?.Location !==
        questionnaireStartPageState.QuestionnaireStart?.Location ||
      questionnaireStartState.QuestionnaireStart?.BuildingList !==
        questionnaireStartPageState.QuestionnaireStart?.BuildingList ||
      questionnaireStartState.QuestionnaireStart?.RelatedEntity !==
        questionnaireStartPageState.QuestionnaireStart?.RelatedEntity
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  const BacktnOnclick = () => {
    if (
      questionnaireStartState.QuestionnaireStart?.TeamMembers !==
        questionnaireStartPageState.QuestionnaireStart?.TeamMembers ||
      questionnaireStartState.QuestionnaireStart?.Location !==
        questionnaireStartPageState.QuestionnaireStart?.Location ||
      questionnaireStartState.QuestionnaireStart?.BuildingList !==
        questionnaireStartPageState.QuestionnaireStart?.BuildingList ||
      questionnaireStartState.QuestionnaireStart?.RelatedEntity !==
        questionnaireStartPageState.QuestionnaireStart?.RelatedEntity
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }

    if (isDirty) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard the changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setIsDirty(false);
            navigate("/siteInspectionStart", {
              state: {
                InspectionId: parameter.InspectionId,
              },
            });
          },
        } as PopupProps)
      );
    } else {
      navigate("/siteInspectionStart", {
        state: {
          InspectionId: parameter.InspectionId,
        },
      });
    }
  };

  const onChangeReference = (Id: number) => {
    if (
      questionnaireStartPageState.QuestionnaireStart.StatusId !==
      EnumInspectionStandardStatus.Intial
    ) {
      if (IsPageValid()) {
        if (isDirty) {
          dispatch(
            openPopup({
              Open: true,
              BodyText: "Do you want to discard the changes?",
              HeaderText: "Confirmation",
              PopupType: EnumPopupType.YesNoConfirmation,
              YesBtnClick() {
                setIsDirty(false);
                setStartClicked(true);
                setQuestionnaireStartPageState((values) => {
                  return {
                    ...values,
                    QuestionnaireStart: {
                      ...values.QuestionnaireStart,
                      ReferenceID: Id,
                    },
                  };
                });

                dispatch(
                  startQuestionnaire({
                    ...questionnaireStartPageState.QuestionnaireStart,
                    ReferenceID: Id,
                  })
                );

                // console.log(questionnaireStartPageState.QuestionnaireStart);
              },
            } as PopupProps)
          );
        } else {
          setStartClicked(true);
          setQuestionnaireStartPageState((values) => {
            return {
              ...values,
              QuestionnaireStart: {
                ...values.QuestionnaireStart,
                ReferenceID: Id,
              },
            };
          });

          dispatch(
            startQuestionnaire({
              ...questionnaireStartPageState.QuestionnaireStart,
              ReferenceID: Id,
            })
          );

          // console.log(questionnaireStartPageState.QuestionnaireStart);
        }
      } else {
        // console.log("invalid page");
      }
    }
  };

  const onClickEditBtn = () => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        IsEditableFieldVisible: false,
      };
    });
    setIsDirty(true);
  };

  const onClickCancelBtn = () => {
    setQuestionnaireStartPageState((values) => {
      return {
        ...values,
        QuestionnaireStart: {
          ...values.QuestionnaireStart,
          StandardId: questionnaireStartState.QuestionnaireStart.StandardId,
          InspectionId: questionnaireStartState.QuestionnaireStart.InspectionId,
          InspectionStandardId:
            questionnaireStartState.QuestionnaireStart.InspectionStandardId,
          SelectedBuildingId:
            questionnaireStartState.QuestionnaireStart?.SelectedBuildingId,
          Location: questionnaireStartState.QuestionnaireStart?.Location,
          RelatedEntity:
            questionnaireStartState.QuestionnaireStart?.RelatedEntity,
          TeamMembers: questionnaireStartState.QuestionnaireStart?.TeamMembers,
          InspectionMember:
            questionnaireStartState.QuestionnaireStart.InspectionMember,
          InspectionStandard:
            questionnaireStartState.QuestionnaireStart.InspectionStandard,
          GlobalPrimarySme:
            questionnaireStartState.QuestionnaireStart.GlobalPrimarySme,
          BuildingList: questionnaireStartState.QuestionnaireStart.BuildingList,
          StatusId: questionnaireStartState.QuestionnaireStart.StatusId,
        },
        IsEditableFieldVisible: true,
      };
    });
    setIsDirty(false);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  return (
    <>
      <div className="content-area-wrapper">
        <Grid item xs={12}>
          <a
            style={{ display: "inline-flex" }}
            className="cursor-pointer audit-back v-align"
            aria-label="back"
            title="Back to inspection page"
          >
            {/* <ArrowLeft2 size={40} onClick={BacktnOnclick} /> */}
            <ArrowBackIosNewOutlinedIcon className="" />
            <span
              className="body-bold secondary-color flex__item--vcenter"
              onClick={BacktnOnclick}
            >
              Back
            </span>
          </a>
        </Grid>

        <Grid container>
          <Grid item xl={2} lg={1} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={12} sm={12} xs={12}>
            <div className="item-wrapper">
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <h1>{parameter.Title ? parameter.Title : "Title"}</h1>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 4 }}></Divider>
              <Grid container spacing={3}>
                <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
                  <Grid container direction={"column"} spacing={3}>
                    <Grid item>
                      <div className="item-content gray-background">
                        <div className="unstyled-list-wrapper">
                          <div className="item-header">
                            <h2>Global primary SME(s)</h2>
                          </div>
                          {questionnaireStartPageState.QuestionnaireStart
                            .GlobalPrimarySme ? (
                            <ul>
                              {questionnaireStartPageState.QuestionnaireStart
                                .GlobalPrimarySme.Sme ? (
                                <li>
                                  {
                                    questionnaireStartPageState
                                      .QuestionnaireStart.GlobalPrimarySme.Sme
                                      .DisplayName
                                  }
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                          ) : (
                            <ul>
                              <li></li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid item>
                      <div className="item-content gray-background">
                        <div className="unstyled-list-wrapper">
                          <div className="item-header">
                            <h2>Global secondary SME(s)</h2>
                          </div>
                          {questionnaireStartState.QuestionnaireStart
                            .GlobalSecondarySmes ? (
                            <ul className="sme-height">
                              {questionnaireStartState.QuestionnaireStart.GlobalSecondarySmes.map(
                                (sme, index) => (
                                  <li key={index + 1}>{sme.Sme.DisplayName}</li>
                                )
                              )}
                            </ul>
                          ) : (
                            <ul>
                              <li></li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="item-content gray-background">
                        <div className="unstyled-list-wrapper">
                          <div className="item-header">
                            <h2>Site primary SME(s)</h2>
                          </div>
                          {questionnaireStartState.QuestionnaireStart
                            .InspectionStandard?.InspectionStandardSmes
                            ?.length > 0 ? (
                            <ul className="sme-height">
                              {questionnaireStartState.QuestionnaireStart.InspectionStandard?.InspectionStandardSmes?.map(
                                (item, index) => (
                                  <li key={index + 1}>
                                    {item.Sme?.DisplayName}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <ul></ul>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xl={9} lg={9} md={7} sm={12} xs={12}>
                  <div className="form-group">
                    <HPRDropDownSingleSelect
                      Label={"Building"}
                      Name={"Building"}
                      Items={Object.assign(
                        [] as Array<DropDownDTO>,
                        questionnaireStartPageState.QuestionnaireStart
                          .BuildingList
                      )}
                      onDropDownChange={onDropDownChange}
                      Select={
                        questionnaireStartPageState?.QuestionnaireStart
                          ?.SelectedBuildingId
                      }
                      // Validator={questionnaireStartPageState?.Validator}
                      // IsEnableValidator={true}
                      IsSearchableWithAdd={true}
                      Disabled={
                        questionnaireStartPageState.IsEditableFieldVisible &&
                        parameter.IsContinueButtonVisible
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="form-group">
                    <HPRTextBox
                      Id=""
                      Name="Location"
                      Label={"Location (floor, column, room, space ID, other)"}
                      Value={
                        questionnaireStartPageState.QuestionnaireStart.Location
                      }
                      onTextBoxChange={onLocationChange}
                      Type={EnumTextBoxType.Text}
                      // Validator={questionnaireStartPageState.Validator}
                      // IsEnableValidator={true}
                      Disabled={
                        questionnaireStartPageState.IsEditableFieldVisible &&
                        parameter.IsContinueButtonVisible
                          ? true
                          : false
                      }
                    ></HPRTextBox>
                  </div>
                  <div className="form-group">
                    <HPRTextBox
                      Id=""
                      Name="RelatedEntity"
                      Label={"Equipment, Application,  System or other"}
                      Value={
                        questionnaireStartPageState.QuestionnaireStart
                          .RelatedEntity
                      }
                      onTextBoxChange={onRelatedEntityChange}
                      Type={EnumTextBoxType.Text}
                      // Validator={questionnaireStartPageState.Validator}
                      // IsEnableValidator={true}
                      Disabled={
                        questionnaireStartPageState.IsEditableFieldVisible &&
                        parameter.IsContinueButtonVisible
                          ? true
                          : false
                      }
                    ></HPRTextBox>
                  </div>
                  <div className="flex--container">
                    <div className="flex__item">
                      <div className="form-group">
                        {/*<HPRTextArea
                          Id=""
                          Name="TeamMembers"
                          Label={"Team members"}
                          Value={questionnaireStartPageState.QuestionnaireStart?.TeamMembers}
                          onTextAreaChange={onTeamMembersChange}
                          Type={EnumTextAreaType.Text}
                          ClassName={"hpr-textarea"}
                        ></HPRTextArea>*/}
                        <HPRLinedTextArea
                          Id=""
                          Name="TeamMembers"
                          Label={"Team members"}
                          Value={
                            questionnaireStartPageState.QuestionnaireStart
                              ?.TeamMembers
                              ? questionnaireStartPageState.QuestionnaireStart
                                  ?.TeamMembers
                              : ""
                          }
                          onTextAreaChange={onTeamMembersChange}
                          Type={EnumLinedTextAreaType.Text}
                          ClassName={"hpr-textarea"}
                          // Validator={questionnaireStartPageState.Validator}
                          Disabled={
                            questionnaireStartPageState.IsEditableFieldVisible &&
                            parameter.IsContinueButtonVisible
                              ? true
                              : false
                          }
                        ></HPRLinedTextArea>
                      </div>
                    </div>
                    <div className="flex__item--inherit ml-10">
                      <Button
                        className={
                          questionnaireStartPageState.IsEditableFieldVisible &&
                          parameter.IsContinueButtonVisible
                            ? "disabled primary large-btn align-right"
                            : "primary"
                        }
                        variant="contained"
                        disableElevation
                        onClick={onCopySiteMembers}
                      >
                        Copy from site team members
                      </Button>
                    </div>
                  </div>

                  {/* <div className="item-wrapper"> */}
                  <div className="item-header">
                    {questionnaireStartState.QuestionnaireStart.References
                      .length > 0 ? (
                      <h2>Reference</h2>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="item-content"> */}
                  <div className="unstyled-list-wrapper">
                    {questionnaireStartState.QuestionnaireStart.References ? (
                      <ul
                        className="remove-list-style inline-item-list"
                        style={{
                          maxHeight: "150px",
                          overflowX: "hidden",
                          // overflowY: "scroll",
                          overflow: "auto",
                          // overscrollBehavior: "contain",
                        }}
                      >
                        {questionnaireStartState.QuestionnaireStart?.References.map(
                          (reference, index) =>
                            questionnaireStartPageState.QuestionnaireStart
                              .StatusId !==
                            EnumInspectionStandardStatus.Intial ? (
                              <li
                                key={index}
                                onClick={() => onChangeReference(reference.Id)}
                              >
                                <a className="cursor-pointer">
                                  <u>{reference.Name}</u>
                                </a>
                              </li>
                            ) : (
                              <li key={index}>
                                <a className="none">{reference.Name}</a>
                              </li>
                            )
                        )}
                      </ul>
                    ) : (
                      <ul></ul>
                    )}
                  </div>
                  {/* </div> */}
                  {/* </div> */}

                  <div style={{ marginTop: 10 }} className="align-right">
                    <Button
                      className="primary small-btn"
                      variant="contained"
                      disableElevation
                      onClick={onClickStart}
                    >
                      {parameter.IsContinueButtonVisible
                        ? questionnaireStartPageState.IsEditableFieldVisible
                          ? "Continue"
                          : "Save and Continue"
                        : "Start"}
                    </Button>
                  </div>
                  <div style={{ marginTop: 10 }} className="align-right">
                    {parameter.IsContinueButtonVisible &&
                    questionnaireStartPageState.IsEditableFieldVisible ? (
                      <>
                        <Button
                          className="primary small-btn"
                          variant="contained"
                          disableElevation
                          style={{
                            display: "flex",
                            alignSelf: "end",
                            visibility: checkSystemFunctionRight(
                              EnumSystemFunctions.QuestionnairePage_Edit_Intermediate,
                              EnumSystemFunctionTypes.ButtonAccess
                            )
                              ? "visible"
                              : "hidden",
                          }}
                          onClick={onClickEditBtn}
                        >
                          Edit
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ marginTop: 10 }} className="align-right">
                    {parameter.IsContinueButtonVisible &&
                    !questionnaireStartPageState.IsEditableFieldVisible ? (
                      <>
                        <Button
                          className="primary small-btn"
                          variant="contained"
                          disableElevation
                          style={{ display: "flex", alignSelf: "end" }}
                          onClick={onClickCancelBtn}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default QuestionnaireStartPage;
