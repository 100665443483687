import { createSlice } from "@reduxjs/toolkit";

// project imports
import {
  AppConfigurationType,
  MainLayoutType,
  PageSearchParamterType,
  UploadFileSetting,
} from "./MainLayout.types";
import {
  SearchParameterDTO,
  TileCategoryDTO,
} from "../../../common/types/common.dto.types";
// ==============================|| VIEWS - LAYOUTS - MAIN-LAYOUT - REDUCER ||============================== //

const initialState = {
  IsLoading: false,
  PageName: "",
  HeaderSize: "",
  TermsOfUseDate: "",

  AppConfiguration: {
    UploaderSupportedFileSize: "10485760",
    UploaderSupportedFileTypes: {
      Doc: ".doc,.docx,.pdf",
      Pdf: ".pdf",
      Image: ".jpg,.jpeg,.png,image/*",
      All: ".doc,.docx,.pdf,image/*",
    },
    UploadFileSettings: [] as Array<UploadFileSetting>,
    CurrencyFormat: "",
  },

  PageSearchParamter: {
    RemediationTrackerTasksPageSearchParamter: {} as SearchParameterDTO,
  } as PageSearchParamterType,
  openPrivacyNoticePopup: false,
  SystemTileCategories: [] as Array<TileCategoryDTO>,
} as MainLayoutType;

export const mainLayoutSlice = createSlice({
  name: "mainLayout",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: MainLayoutType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setPageName: (
      state: MainLayoutType,
      action: { payload: string; type: string }
    ) => {
      return { ...state, PageName: action.payload };
    },
    setPageSizes: (
      state: MainLayoutType,
      action: { payload: string; type: string }
    ) => {
      return { ...state, HeaderSizes: action.payload };
    },
    setAppConfiguration: (state, action: { payload: AppConfigurationType }) => {
      return {
        ...state,
        AppConfiguration: { ...action.payload, Fetched: true },
      };
    },
    setPageSearchParameters: (
      state,
      action: { payload: PageSearchParamterType }
    ) => {
      return {
        ...state,
        PageSearchParamter: {
          ...state.PageSearchParamter,
          RemediationTrackerTasksPageSearchParamter:
            action.payload.RemediationTrackerTasksPageSearchParamter,
        },
      };
    },
    setDate: (
      state: MainLayoutType,
      action: { payload: string; type: string }
    ) => {
      return { ...state, TermsOfUseDate: action.payload };
    },
    setPopupOpen: (
      state: MainLayoutType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, openPrivacyNoticePopup: action.payload };
    },
  },
});

export const {
  setPageName,
  setLoading,
  setPageSizes,
  setAppConfiguration,
  setPageSearchParameters,
  setDate,
  setPopupOpen,
} = mainLayoutSlice.actions;
export const mainLayoutReducer = mainLayoutSlice.reducer;
