import {
  InspectionDTO,
  InspectionStandardDTO,
} from "../../../common/types/common.dto.types";

export interface ViewInspectionSummaryProps {
  SerachInspectionStandard: InspectionStandardDTO;
  InspectionSummaryType: EnumInspectionSummaryType;
  onCompleteBtnClick?: (inspectionSummary: InspectionDTO) => void;
  onCloseBtnClick?: () => void;
}

export interface ViewInspectionSummaryType {
  SerachInspectionStandardSummary: InspectionStandardDTO;
  InspectionSummaryType: EnumInspectionSummaryType;
  InspectionSummary: InspectionDTO;
  Open: boolean;
  onCompleteBtnClick?: (inspectionSummary: InspectionDTO) => void;
  onCloseBtnClick?: () => void;
}

export enum EnumInspectionSummaryType {
  Inspection = 1,
  InspectionStandard = 2,
  InspectionStatus = 3,
}
