import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AppOptions,
  MicrosoftAzureActiveDirectoryOptions,
} from "../../../common/types/common.types";
import { GetAppOptions } from "../../../common/functions/common.functions";

const createMSALConfig = (): Configuration => {
  // Get app option(s) from env file
  const appOptions: AppOptions = GetAppOptions();

  // Get MicrosoftAzureActiveDirectoryOptions
  const microsoftAzureActiveDirectoryOptions: MicrosoftAzureActiveDirectoryOptions =
    appOptions.AuthDetails.MicrosoftAzureActiveDirectoryOptions;

  // Initialize MASL Config
  const msalConfig = {
    auth: {
      clientId: microsoftAzureActiveDirectoryOptions.ClientId,
      authority: `https://login.microsoftonline.com/${microsoftAzureActiveDirectoryOptions.TenantId}`,
      redirectUri: microsoftAzureActiveDirectoryOptions.RedirectUri,
    },
    // cache: {
    //   cacheLocation: "sessionStorage", // You can choose your preferred cache location
    // },
    // system: {
    //   loggerOptions: {
    //     loggerCallback: (level, message, containsPii) => {
    //       if (containsPii) {
    //         return;
    //       }
    //       switch (level) {
    //         case LogLevel.Error:
    //           console.error(message);
    //           break;
    //         case LogLevel.Warning:
    //           console.warn(message);
    //           break;
    //         case LogLevel.Info:
    //           console.info(message);
    //           break;
    //         default:
    //           console.debug(message);
    //       }
    //     },
    //     logLevel: LogLevel.Verbose, // Set the desired log level
    //   },
    // },
  } as Configuration;
  return msalConfig;
};

export const createMSALPublicClientApplicationObject =
  (): PublicClientApplication => {
    try {
      // Create new object using MASL config
      const msalInstance: PublicClientApplication = new PublicClientApplication(
        createMSALConfig()
      );

      return msalInstance;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

export const initializeMSALInstance = async (
  msalInstance: PublicClientApplication
) => {
  return await msalInstance.initialize();
};
