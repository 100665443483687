import {
  SearchParameterDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../../common/types/common.types";
import { ApiClient } from "../../../../core/api/apiClient.service";
import { SaveAzureGroupSiteListDTO } from "./UserRoleManagementLocationstypes";
const client = new ApiClient();

export const getAzureGroupSiteList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetSiteList`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveAzureGroupSiteList = async (
  Params: SaveAzureGroupSiteListDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/SaveAzureGroupSiteList`;
    let building = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        building = response;
      })
      .catch((error) => {
        building = {} as ActionResponseType;
        return error;
      });
    return building;
  } catch (error) {
    throw error;
  }
};

export const saveUserRoleManagementAdgroup = async (
  param: SystemGroupDTO
): Promise<ActionResponseType> => {
  try {
    const URL = `UserRoleManagement/SaveUserRoleManagementAdgroup`;

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, param)
      .then((res) => {
        result = res;
      })
      .catch((error) => {
        return error;
      });
    //console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getSystemGroupById = async (
  groupId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetSystemGroupById?groupId=${groupId}`;
    let data = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, groupId)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        data = {
          Data: {},
          ErrorMessage:
            "Sorry, an unexpected error occured. Please try again later.",
        } as ActionResponseType;
      });
    return data;
  } catch {
    return {
      Data: {},
      ErrorMessage:
        "Sorry, an unexpected error occured. Please try again later.",
    } as ActionResponseType;
  }
};
