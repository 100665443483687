import SimpleReactValidator from "simple-react-validator";
import dayjs from "dayjs";
import {
  UserRoleManagementPaginationDTO,
  UserRoleManagementType,
} from "./UserRoleManagement.types";

import { createSlice } from "@reduxjs/toolkit";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  SystemGroupDTO,
} from "../../../common/types/common.dto.types";
import {
  EnumButtonModeType,
  EnumListShowRowCountList,
  EnumPageMode,
  EnumSortTypes,
  EnumStatus,
  EnumUserRoleManagementSortType,
} from "../../../common/enums/common.enums";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";

const initialState: UserRoleManagementType = {
  IsDisable: false,
  IsLoading: false,
  GroupName: "",
  Id: 0,
  PageMode: {
    PageMode: EnumPageMode.ViewMode,
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  PagedData: {
    Data: [] as SystemGroupDTO[],
    PageSize: 10,
    SelectedPage: 1,
    TotalRecordsCount: 0,
  } as UserRoleManagementPaginationDTO,

  UserRoleManagementSearchParameter: {
    SystemGroupSearchParams: {
      GroupName: "",
      ShowDeactivated: false,
    } as SystemGroupDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    SortByNavigation: {
      Label: "Group Name",
      Value: EnumUserRoleManagementSortType.Groupname.toString(),
      SortType: EnumSortTypes.Ascending,
    } as SortDropDownDTO,
    BasicSearchValue: "",
  } as SearchParameterDTO,
  SystemGroup: {
    Id: 0,
    GroupName: "",
    Status: EnumStatus.Published,
    ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
  } as SystemGroupDTO,
  SystemGroupModalOpen: false,
  Validator: new SimpleReactValidator(),
  ButtonModeType: EnumButtonModeType.Save,
  ShowErrorMessages: false,
  ExportPressed: false,
  Data: {} as PaginationDTO,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
};

export const UserRoleManagementSlice = createSlice({
  name: "userRoleManagement",
  initialState,
  reducers: {
    setLoading: (
      state: UserRoleManagementType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    // setPagination: (
    //   state: UserRoleManagementType,
    //   action: { payload: UserRoleManagementType; type: string }
    // ) => {
    //   state.PagedData = action.payload.PagedData;
    // },
    setPagination: (
      state: UserRoleManagementType,
      action: { payload: HPRListPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setUserRoleManagementList: (
      state: UserRoleManagementType,
      action: { payload: UserRoleManagementType; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.PagedData,
      };
    },
    setPageMode: (
      state: UserRoleManagementType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (action.payload === EnumPageMode.AddMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsDisable: true,
          },
          SystemGroup: {
            ...state.SystemGroup,
            Id: 0,
            GroupName: "",
            Status: EnumStatus.Published,
            ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
          },
        };
      } else if (action.payload === EnumPageMode.EditMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
            IsDisable: true,
          },
        };
      } else if (action.payload === EnumPageMode.ViewMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsDisable: true,
          },
        };
      }
    },
    setSystemGroup: (
      state: UserRoleManagementType,
      action: { payload: SystemGroupDTO; type: string }
    ) => {
      return {
        ...state,
        SystemGroup: action.payload,
      };
    },
    setGroupName: (
      state: UserRoleManagementType,
      action: { payload: string; type: string }
    ) => {
      return {
        ...state,
        SystemGroup: {
          ...state.SystemGroup,
          GroupName: action.payload,
        },
      };
    },

    setUserRoleManagementSearchParameter: (
      state: UserRoleManagementType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        UserRoleManagementSearchParameter: {
          ...state.UserRoleManagementSearchParameter,
          SystemGroupSearchParams: action.payload.SystemGroupSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          ListPageSize: action.payload.ListPageSize,
          SortByNavigation: action.payload.SortByNavigation,
          BasicSearchValue: action.payload.BasicSearchValue,
        } as SearchParameterDTO,
      };
    },
  },
});

export const {
  setLoading,
  setPagination,
  setUserRoleManagementList,
  setPageMode,
  setSystemGroup,
  setGroupName,
  setUserRoleManagementSearchParameter,
} = UserRoleManagementSlice.actions;
export const userRoleManagementReducer = UserRoleManagementSlice.reducer;
