import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { DropDownDTO } from "../../../common/types/common.dto.types";
import { getDataFromBackendWithParameter } from "./HPRDropDownSingleSelect.api";
import { HPRDropDownProps } from "./HPRDropDownSingleSelect.types";
import { SearchNormal1 } from "iconsax-react";
import { GetAppOptions } from "../../../common/functions/common.functions";
import { EnumAuthenticationModes } from "../../../common/enums/common.enums";

// ==============================|| COMPONENTS - COMMON - HPRMSGRAPHDropDown ||============================== //

interface InitialState {
  SelectedValue: string;
  SelectOptions: Array<DropDownDTO>;
  SearchText: string;
}

const HPRDropDownMsGraphSingleSelect = (props: HPRDropDownProps) => {
  const initialState = {
    SelectedValue: "",
    SelectOptions: [] as Array<DropDownDTO>,
    SearchText: "",
  } as InitialState;

  const [singleSelect, setSingleSelect] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  //const searchBtnClickRef = useRef(false);

  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // If menuitem list received
    if (props.Items && props.Items.length > 0) {
      let selectedSME =
        props.Items.find((sme) => sme.Value?.toString() === props.SelectedValue)
          ?.Label ?? "";
      setSingleSelect((values) => {
        return {
          ...values,
          SelectOptions: props.Items ? props.Items : ([] as Array<DropDownDTO>),
          SearchText: selectedSME,
        };
      });
    }
    // If menuitem list not received
    else {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      if (props.Url && props.Url !== "") {
        getDataFromBackendWithParameter(props.Url ? props.Url : "").then(
          (response) => {
            setSingleSelect((values) => {
              return {
                ...values,
                SelectOptions: response,
                SearchText:
                  response?.find(
                    (find) => find.Value?.toString() === props.SelectedValue
                  )?.Label ?? "",
              };
            });
          }
        );
      }
    }
  }, [props.Items]);

  // selected option change
  useEffect(() => {
    setLoading(false);
    setSingleSelect((values: any) => {
      return {
        ...values,
        SearchText:
          values.SelectOptions?.find(
            (find: DropDownDTO) =>
              find.Value?.toString() === props.SelectedValue
          )?.Label ?? "",
      };
    });
  }, [props.SelectedValue, props.Disabled]);

  const setSingleSelectSearchText = (newValue: string) => {
    setSingleSelect((values: any) => {
      return {
        ...values,
        SearchText: newValue,
      };
    });
  };

  const onSelectionChange = (event: any, value) => {
    if (!value?.Value) {
      setSingleSelectSearchText("");
    }

    if (props.onDropDownChange)
      props.onDropDownChange(
        singleSelect.SelectOptions,
        value ? value.Value?.toString() : "",
        props.Name
      );
  };

  // On focusout event
  const onBlurChange = (e: React.FocusEvent) => {
    setSingleSelect((values: any) => {
      return {
        ...values,
        SearchText:
          values.SelectOptions?.find(
            (find: DropDownDTO) =>
              find.Value?.toString() === props.SelectedValue
          )?.Label ?? "",
      };
    });

    if (props.onBlurChange)
      props.onBlurChange(
        singleSelect.SelectOptions,
        e.target["value"] ? e.target["value"] : "",
        props.Name
      );
  };

  const SearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    //set search value

    //setSingleSelectSearchText(event.target.value ?? "");

    //searchBtnClickRef.current = false;
    //setLoading(false);

    //search with delay
    if (
      GetAppOptions().AuthMode ===
      EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication
    ) {
      searchMsGraphOptions(event.target.value);
    }
  };

  // const onSearchBtnClick = () => {
  //   searchBtnClickRef.current = true;
  //   setLoading(true);
  //   searchMsGraphOptions(singleSelect.SearchText);
  // };

  const searchMsGraphOptions = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout.current);
    }
    setLoading(true);
    searchTimeout.current = setTimeout(async () => {
      const result: DropDownDTO[] = await getDataFromBackendWithParameter(
        props?.Url,
        value
      );

      const mergeSelectOptions = [
        ...singleSelect.SelectOptions,
        ...result?.filter(
          (filter) =>
            !singleSelect.SelectOptions?.some(
              (some) => some.Value?.toString() === filter.Value?.toString()
            )
        ),
      ];

      setSingleSelect((values: any) => {
        return {
          ...values,
          SelectOptions: mergeSelectOptions,
        };
      });
      setLoading(false);
      searchTimeout.current = null;
    }, 1000);
  };

  const isOptionEqualToValue = (option, value) => {
    return option?.Value?.toString() === value[0]?.toString();
  };

  const selectedValue = useMemo(
    () => [props.SelectedValue ?? "0"],
    [props.SelectedValue, singleSelect.SelectOptions]
  );

  // const onSearchByEnterBtnClick = (event: any) => {
  //   if (event.keyCode === 13) {
  //     event.preventDefault();
  //     onSearchBtnClick();
  //   }
  // };

  return (
    <>
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        className="hpr-singleselect-dropdown"
      >
        <InputLabel
          id="demo-simple-select-helper-label"
          className="input-label"
          shrink
          required={props.Required}
          disabled={props.Disabled}
        >
          {props.Label}
        </InputLabel>
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          id={props.Id && props.Id !== "" ? props.Id : props.Name}
          disabled={props.Disabled}
          options={
            singleSelect.SelectOptions && singleSelect.SelectOptions.length > 0
              ? singleSelect.SelectOptions
              : ([] as Array<DropDownDTO>)
          }
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              const selectedValue = singleSelect.SelectOptions?.find(
                (item) => item?.Value?.toString() === option
              );
              return selectedValue?.Label || "";
            } else {
              const selectedValue = singleSelect.SelectOptions?.find(
                (item) => item?.Value?.toString() === option?.Value?.toString()
              );
              if (singleSelect?.SearchText?.includes("@")) {
                return option?.EmailAddress;
              } else {
                return option?.Label
                  ? option?.Label
                  : selectedValue?.Label
                  ? selectedValue?.Label
                  : "";
              }
            }
          }}
          renderOption={(props, option: DropDownDTO) => {
            return (
              <li {...props} key={option.Value} className="person-mail-wrapper">
                <div>{option?.Label}</div>
                <div className="person-mail">{option?.EmailAddress || ""}</div>
              </li>
            );
          }}
          inputValue={singleSelect?.SearchText ?? ""}
          //freeSolo
          forcePopupIcon
          autoComplete
          value={selectedValue}
          filterSelectedOptions
          onChange={onSelectionChange}
          onBlur={onBlurChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className={` ${props.Disabled ? "Mui-disabled" : ""}`}
              InputLabelProps={{ shrink: true }}
              onChange={SearchTextChange}
              //onKeyDown={onSearchByEnterBtnClick}
              inputProps={{
                ...params.inputProps,
                readOnly: !props.IsSearchable && !props.IsSearchableWithAdd,
              }}
            />
          )}
          noOptionsText={
            loading ? <div className="loader"></div> : "No record(s) found"
            // GetAppOptions().AuthMode ===
            //   EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication &&
            // !searchBtnClickRef.current ? (
            //   <Grid
            //     className="setcolor fx cursor-pointer"
            //     aria-label="search"
            //     onClick={onSearchBtnClick}
            //   >
            //     <SearchNormal1
            //       variant="Outline"
            //       size="18"
            //       style={{ marginRight: "10px", marginTop: "2px" }}
            //       className="ico-secondary"
            //     />
            //     Search
            //   </Grid>
            // ) : (
            //   <>
            //     {loading ? (
            //       <div className="loader"></div>
            //     ) : (
            //       "No record(s) found"
            //     )}
            //   </>
            // )
          }
          disableClearable={props.DisableClearable}
          onInputChange={(_, value, reason) => {
            if (reason === "clear") {
              setSingleSelectSearchText("");
            } else if (reason === "input") {
              setSingleSelectSearchText(value);
            }
          }}
        />

        {props.HelperText && (
          <FormHelperText>{props.HelperText}</FormHelperText>
        )}
        {props.IsEnableValidator ? (
          props.Validator?.message(
            props.Name,
            props.SelectedValue ?? "",
            props.Rules ?? ""
          )
        ) : (
          <></>
        )}
      </FormControl>
    </>
  );
};

export default HPRDropDownMsGraphSingleSelect;
