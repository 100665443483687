import {
  StandardQuestionsDownloadStatusType,
  StandardQuestionsFilterType,
} from "./StandardQuestionsReport.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { DirectInbox } from "iconsax-react";
import { EnumReportType } from "../../../common/enums/common.enums";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { useRef, useState, useEffect } from "react";
import { Button, CircularProgress, Grid, Menu, MenuItem } from "@mui/material";
import standardQuestionsReportSagaActions from "./StandardQuestionsReport.actions";
import { standardQuestionsReportReducerActions } from "./StandardQuestionsReport.reducer";

const StandardQuestionsExcelDownload: React.FC<StandardQuestionsFilterType> = (
  props
) => {
  const initialState = [
    {
      StandardId: 0,
      ExportFullReport: false,
      IsDownloading: false,
    },
  ] as Array<StandardQuestionsDownloadStatusType>;
  const state = useAppSelector((state) => state.standardQuestionsReportReducer);

  const dispatch = useAppDispatch();
  const isInProgress = useRef(false);

  const [taskReportanchorEl, setTaskReportanchorEl] =
    useState<null | HTMLElement>(null);
  const taskReportOpen = Boolean(taskReportanchorEl);

  const [isReportDownloading, setIsReportDownloading] = useState(initialState);

  useEffect(() => {
    setIsReportDownloading(state.StandardQuestionsExcelReportStatus);
  }, [state]);

  //open and close download popup Menu Item
  const onClickReportDownload = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setTaskReportanchorEl(event.currentTarget);
  };

  const handleReportDownloadClose = () => {
    setTaskReportanchorEl(null);
  };

  const downloadStandardQuestions = (
    exportFullReport: boolean,
    searchParameter?: SearchParameterDTO
  ) => {
    isInProgress.current = true;
    dispatch(
      standardQuestionsReportReducerActions.setExcelReportStatus({
        StandardId:
          props.StandardQuestionsExcelReportFilter.StandardQuestionSearchParams
            ?.StandardId,
        ExportFullReport: exportFullReport,
        IsDownloading: true,
      } as StandardQuestionsDownloadStatusType)
    );
    dispatch(
      standardQuestionsReportSagaActions.getStandardQuestionsSummary({
        ReportType: EnumReportType.StandardQuestionsReport,
        ReportSearchParameter: {
          ...searchParameter,
          StandardQuestionSearchParams: {
            ...searchParameter?.StandardQuestionSearchParams,
            ExportFullReport: exportFullReport,
          },
        },
      } as ReportViewerType)
    );
    handleReportDownloadClose();
  };

  return (
    <>
      {/* <div
        className="flex--container exceldownload"
        onClick={onClickReportDownload}
      >
        <a className="excel flex__item--vcenter">
          <Document className="ml-5 mr-5" style={{ fontSize: 20 }} />
        </a>
        <div> Export</div>
      </div> */}
      <Button
        className="primary-btn-small shadow-remove"
        size="small"
        variant="outlined"
        onClick={onClickReportDownload}
        startIcon={<DirectInbox variant="Outline" />}
      >
        Export Report(s)
      </Button>
      <Menu
        className="sort-dialog-content"
        open={taskReportOpen}
        anchorEl={taskReportanchorEl}
        onClose={handleReportDownloadClose}
      >
        <Grid display={"flex"} alignItems={"center"}>
          {isReportDownloading.map(
            (item, index) =>
              item.StandardId ===
                props.StandardQuestionsExcelReportFilter
                  .StandardQuestionSearchParams?.StandardId &&
              !item.ExportFullReport &&
              item.IsDownloading && <CircularProgress key={index} size={20} />
          )}
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              downloadStandardQuestions(
                false,
                props.StandardQuestionsExcelReportFilter
              );
            }}
            disabled={
              !props.ReportViewer?.IsReportDataFound ||
              isReportDownloading.some(
                (item) =>
                  item.StandardId ===
                    props.StandardQuestionsExcelReportFilter
                      .StandardQuestionSearchParams?.StandardId &&
                  !item.ExportFullReport &&
                  item.IsDownloading
              )
            }
          >
            Filtered record(s) only
          </MenuItem>
        </Grid>
        <Grid container display={"flex"} alignItems={"center"}>
          {isReportDownloading.map(
            (item, index) =>
              item.StandardId ===
                props.StandardQuestionsExcelReportFilter
                  .StandardQuestionSearchParams?.StandardId &&
              item.ExportFullReport &&
              item.IsDownloading && <CircularProgress key={index} size={20} />
          )}
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              downloadStandardQuestions(
                true,
                props.StandardQuestionsExcelReportFilter
              );
            }}
            disabled={
              !props.ReportViewer?.IsAllDataAvailable ||
              isReportDownloading.some(
                (item) =>
                  item.StandardId ===
                    props.StandardQuestionsExcelReportFilter
                      .StandardQuestionSearchParams?.StandardId &&
                  item.ExportFullReport &&
                  item.IsDownloading
              )
            }
          >
            All record(s)
          </MenuItem>
        </Grid>
      </Menu>
    </>
  );
};

export default StandardQuestionsExcelDownload;
