import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "./HPRListPagination.types";
import { useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import { EnumPageType, EnumStatus } from "../../../common/enums/common.enums";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { StandardSmeType } from "../../../views/pages/StandardsPage/StandardsPage.types";
import HPRFilterNode from "../HPRFilterNode/HPRFilterNode";
import { StandardQuestionParameters } from "../../../views/pages/StandardQuestionsPage/StandardsQuestionsPage.types";
import { MessageQuestion } from "iconsax-react";
import dayjs from "dayjs";
import { StandardDTO } from "../../../common/types/common.dto.types";
import noData from "../../../images/no_data.svg";
const HPRListPagination = (props: HPRListPaginationType) => {
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.PaginationOnClickFunction(value);
  };

  return (
    <div className="data-wrapper">
      {/* inspection set data start */}
      {/* <div className="width-p-100 d-flex mb-15 main-card">
        <Grid container className="standrads-cards-main-wrap">
          <div className="standrads-cards-contain-wrap d-flex">
            <div className="standrads-icon d-flex">009</div>
            <div className="standrads-icon-details-wrap">
              <Grid container>
                <Grid item lg={10} className="">
                  <h4 className="standrads-icon-details-header form-group">
                    Inspection Set 1{" "}
                  </h4>
                  <div className="form-group truncate">
                    <div className="standards-list d-flex">
                      <div className="">
                        <span className="gray-text body2 title">Standards</span>
                      </div>
                      <div className="d-flex">
                        <div className="standard-label body2 flex--container">
                          <span className="standard-name flex__item--vcenter">
                            Equipments
                          </span>
                          <span className="no-question flex__item--vcenter">
                            Q24
                          </span>
                        </div>
                        <div className="standard-label body2 flex--container">
                          <span className="standard-name flex__item--vcenter">
                            Information Systems
                          </span>
                          <span className="no-question flex__item--vcenter">
                            Q5
                          </span>
                        </div>
                        <div className="standard-label body2 flex--container">
                          <span className="standard-name flex__item--vcenter">
                            Maintenance
                          </span>
                          <span className="no-question flex__item--vcenter">
                            Q18
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={1}></Grid>
                <Grid item lg={1}>
                  <div className="view-more flex--container">
                    <div className="view-more-text">More</div>
                    <More size="18" color="#fb8500" />
                    <span className="arrowdown">
                      <ArrowDown2 size="14" color="#fb8500" />
                    </span>
                    <span className="arrowup">
                      <ArrowUp2 size="14" color="#fb8500" />
                    </span>
                  </div>
                </Grid>
              </Grid>
              <div className="modified-by flex--container">
                <div className="body3 title pr-10">Last modified by: </div>
                <div className="name body3 default-text-color">
                  Claudia Enriquez
                </div>
                <div className="date body3 gray-text">11-Jan-2023 13:15</div>
              </div>
  
            </div>
            <div
              className="flex--container col"
              style={{ width: "15%", paddingTop: "10px" }}
            >
              <Grid item lg={3}>
                <div className="standards-total-status-wrap flex--container">

                  <div className="standards-status flex--container">
                    <span>Status:</span>
                    <span className="status-color green">Publish </span>
                  </div>
                </div>
                <div className="standarded-que-version flex--container line-h">
                  <span>Version:</span>
                  <span className="d-flex flex-valign-middle">4.0</span>
                </div>
              </Grid>
            </div>
          </div>
          <div className="standards-icon-forward d-flex flex-valign-middle cursor-pointer">
            <KeyboardArrowRightIcon />
          </div>
          <div className="standards-card-end"></div>
        </Grid>
      </div>
      <div className="fx-middle">
        <Stack spacing={3}>
          <Pagination showFirstButton showLastButton onChange={handleChange} />
        </Stack>
      </div> */}
      {/* inspection set data end */}
      {props.ItemList && props.ItemList.length > 0 ? (
        <>
          <div
            className="main-card-outer"
            style={{ height: props.ListHeight ?? 0 }}
          >
            {props.ItemList?.map((item: PaginationListItemType, index) => {
              return (
                <div className="width-p-100 d-flex main-card" key={index}>
                  <Grid container className="standrads-cards-main-wrap">
                    <div className="standrads-cards-contain-wrap d-flex">
                      {props.PageType === EnumPageType.Standards ? (
                        <>
                          {" "}
                          <div className="standrads-icon d-flex">
                            {item.ShowCommonListIcon ? (
                              <>
                                <img src={item.Icon} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="standrads-icon-details-wrap">
                            <Grid container>
                              {/*  Standard question */}
                              {item.Text ? (
                                <>
                                  <Grid item lg={11}>
                                    <div className="standrads-que-header">
                                      {item.Text}
                                    </div>

                                    {/* {item.QuestionParameters?.length > 0 ? (
                                    <div className="filter-tag-wrapper">
                                      <div className="filter-tag-inner-row">
                                        <Grid container>
                                          <Grid
                                            item
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={12}
                                          >
                                            <label className="hpr-filternote-lbl body3">
                                              Reference
                                            </label>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={12}
                                          >
                                            <label className="hpr-filternote-lbl body3">
                                              System
                                            </label>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={12}
                                          >
                                            <label className="hpr-filternote-lbl body3">
                                              Sub-system
                                            </label>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={12}
                                          >
                                            <label className="hpr-filternote-lbl body3">
                                              Subject
                                            </label>
                                          </Grid>
                                          <Grid
                                            item
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={12}
                                          >
                                            <label className="hpr-filternote-lbl body3">
                                              Section
                                            </label>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )} */}

                                    {/* {item.QuestionParameters?.map(
                                    (
                                      questionPatameter: StandardQuestionParameters,
                                      questionPatameterIndex
                                    ) => {                                  
                                      return (
                                        <div
                                          className="filter-tag-wrapper"
                                          key={questionPatameterIndex}
                                        >
                                          <div className="filter-tag-inner-row">
                                            <Grid container>
                                              <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={12}
                                              >
                                                <HPRFilterNode
                                                  ClassName="hpr-filternote ref"
                                                  Label={
                                                    questionPatameter.reference
                                                  }
                                                  Name="Reference"
                                                  Value="22"
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                lg={4}
                                                md={4}
                                                sm={4}
                                                xs={12}
                                              >
                                                <HPRFilterNode
                                                  ClassName="hpr-filternote sys"
                                                  Label={
                                                    questionPatameter.system
                                                  }
                                                  Name="System"
                                                  Value="22"
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                lg={3}
                                                md={3}
                                                sm={2}
                                                xs={12}
                                              >
                                                <HPRFilterNode
                                                  ClassName="hpr-filternote sub-sys"
                                                  Label={
                                                    questionPatameter.subsystem
                                                  }
                                                  Name="Sub-system"
                                                  Value="22"
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={12}
                                              >
                                                <HPRFilterNode
                                                  ClassName="hpr-filternote subject"
                                                  Label={
                                                    questionPatameter.subject
                                                  }
                                                  Name="Subject"
                                                  Value="22"
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={12}
                                              >
                                                <HPRFilterNode
                                                  ClassName="hpr-filternote section"
                                                  Label={
                                                    questionPatameter.section
                                                  }
                                                  Name="Section"
                                                  Value="22"
                                                />
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )} */}
                                  </Grid>
                                  {/* <Grid item lg={1}></Grid> */}
                                  <Grid item lg={1}>
                                    <div className="view-more flex--container">
                                      {/* <div className="view-more-text">More</div> */}
                                      {/* <More size="18" color="#fb8500" /> */}
                                      {/* <span className="arrowdown">
                              <ArrowDown2 size="14" color="#fb8500" />
                            </span> */}
                                      {/* <span className="arrowup">
                              <ArrowUp2 size="14" color="#fb8500" />
                            </span> */}
                                    </div>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  {/*  Standard */}
                                  {item.ItemHeading ? (
                                    <h4 className="standrads-icon-details-header">
                                      {item.ItemHeading}
                                    </h4>
                                  ) : (
                                    ""
                                  )}

                                  {/*  Standard */}
                                  {item.PublishedQuestions >= 0 ? (
                                    <div className="standards-total-status-wrap d-flex">
                                      <div className="standards-total-ques flex-valign-middle">
                                        <span>Published Question(s):</span>
                                        <span className="d-flex flex-valign-middle">
                                          {item.PublishedQuestions}
                                        </span>
                                      </div>

                                      {/* <div className="standards-status">
                              <span>Status:</span>
                              <span
                                className={`status-color ${
                                  item.Status == EnumStatus.Published
                                    ? "green"
                                    : "yellow"
                                } `}
                              >
                                {item.StatusName}
                              </span>
                            </div> */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                {/* <div style={{ width: "30%" }}>
                          
                        </div> */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <div className="flex--container">
                                    {item.SecondarySME ? (
                                      <span style={{}}>
                                        Global Primary SME:&nbsp;
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {/* {item.PrimarySME?.map((sme: SmeType, smeIndex) => {
                          return (
                            <div className="gray-text" key={smeIndex}>
                              <span style={{}}>{sme.DisplayName}</span>
                            </div>
                          );
                        })} */}

                                    <div className="gray-text">
                                      <span style={{}}>
                                        {item.PrimarySME?.Sme?.DisplayName}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex--container" style={{}}>
                                    {item.SecondarySME ? (
                                      <span
                                        className="no-wrap"
                                        // style={{
                                        //   marginLeft: "20px",
                                        //   marginRight: "20px",
                                        // }}
                                      >
                                        Global Secondary SME(s):&nbsp;
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <div className="">
                                      <div className="wrapper">
                                        <div
                                          className="gray-text limit-1 sme-list"
                                          title={item.SecondarySMEToolTips}
                                        >
                                          {item.SecondarySME?.filter(
                                            (
                                              sme: StandardSmeType,
                                              smeIndex
                                            ) => {
                                              return smeIndex < 2;
                                            }
                                          )
                                            ?.map(
                                              (
                                                sme: StandardSmeType,
                                                smeIndex
                                              ) => {
                                                return sme.Sme.DisplayName;
                                              }
                                            )
                                            ?.join("; ")}
                                          {item.SecondarySME?.length >= 3
                                            ? "..."
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              {/* <div style={{ width: "70%" }}>
                          
                        </div> */}
                            </Grid>
                            <div className="modified-by flex--container">
                              {item.ModifiedBy ? (
                                <>
                                  <div className="body3 title pr-10">
                                    Last modified by:{" "}
                                  </div>
                                  {/* <div className="gray-text body3 default-text-color"> */}
                                  <div className="name body3 default-text-color">
                                    {item.ModifiedBy}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item.ModifiedDate ? (
                                <>
                                  <div className="date body3 gray-text">
                                    {dayjs(item.ModifiedDate).format(
                                      "DD-MMM-YYYY HH:mm"
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* </div> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {props.PageType === EnumPageType.StandardQuestions ? (
                        <>
                          {" "}
                          <div className="standrads-icon d-flex">
                            {item.SequenceNumber ? (
                              <div className="icon-question flex--container">
                                <div className="question secondary-color flex__item--vcenter">
                                  Q
                                </div>
                                <h5 className="primary-color flex__item--vcenter question-no">
                                  {item.SequenceNumber
                                    ? item.SequenceNumber
                                    : 0}
                                </h5>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="standrads-icon-details-wrap">
                            <Grid container>
                              {/*  Standard question */}
                              {item.Text ? (
                                <>
                                  <Grid item lg={11}>
                                    <div
                                      className="standrads-que-header"
                                      style={{ whiteSpace: "pre-line" }}
                                    >
                                      {item.Text}
                                    </div>

                                    {item.QuestionParameters?.length > 0 ? (
                                      <div className="filter-tag-wrapper">
                                        <div className="filter-tag-inner-row">
                                          <Grid container>
                                            <Grid
                                              item
                                              lg={2}
                                              md={2}
                                              sm={2}
                                              xs={12}
                                            >
                                              <label className="hpr-filternote-lbl body3">
                                                Reference
                                              </label>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              md={4}
                                              sm={4}
                                              xs={12}
                                            >
                                              <label className="hpr-filternote-lbl body3">
                                                System
                                              </label>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={3}
                                              md={3}
                                              sm={3}
                                              xs={12}
                                            >
                                              <label className="hpr-filternote-lbl body3">
                                                Sub-system
                                              </label>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={2}
                                              md={2}
                                              sm={2}
                                              xs={12}
                                            >
                                              <label className="hpr-filternote-lbl body3">
                                                Subject
                                              </label>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={1}
                                              md={1}
                                              sm={1}
                                              xs={12}
                                            >
                                              <label className="hpr-filternote-lbl body3">
                                                Section
                                              </label>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {item.QuestionParameters?.map(
                                      (
                                        questionPatameter: StandardQuestionParameters,
                                        questionPatameterIndex
                                      ) => {
                                        return (
                                          <div
                                            className="filter-tag-wrapper"
                                            key={questionPatameterIndex}
                                          >
                                            <div className="filter-tag-inner-row">
                                              <Grid container>
                                                <Grid
                                                  item
                                                  lg={2}
                                                  md={2}
                                                  sm={2}
                                                  xs={12}
                                                >
                                                  <HPRFilterNode
                                                    ClassName="hpr-filternote ref limit-1"
                                                    Label={
                                                      questionPatameter.reference !=
                                                        "" &&
                                                      questionPatameter.reference !=
                                                        null
                                                        ? questionPatameter.reference
                                                        : "N/A"
                                                    }
                                                    Name="Reference"
                                                    Value="22"
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={4}
                                                  md={4}
                                                  sm={4}
                                                  xs={12}
                                                >
                                                  <HPRFilterNode
                                                    ClassName="hpr-filternote sys limit-1"
                                                    Label={
                                                      questionPatameter.system !=
                                                        "" &&
                                                      questionPatameter.system !=
                                                        null
                                                        ? questionPatameter.system
                                                        : "N/A"
                                                    }
                                                    Name="System"
                                                    Value="22"
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={3}
                                                  md={3}
                                                  sm={3}
                                                  xs={12}
                                                >
                                                  <HPRFilterNode
                                                    ClassName="hpr-filternote sub-sys limit-1"
                                                    Label={
                                                      questionPatameter.subsystem !=
                                                        "" &&
                                                      questionPatameter.subsystem !=
                                                        null
                                                        ? questionPatameter.subsystem
                                                        : "N/A"
                                                    }
                                                    Name="Sub-system"
                                                    Value="22"
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={2}
                                                  md={2}
                                                  sm={2}
                                                  xs={12}
                                                >
                                                  <HPRFilterNode
                                                    ClassName="hpr-filternote subject limit-1"
                                                    Label={
                                                      questionPatameter.subject !=
                                                        "" &&
                                                      questionPatameter.subject !=
                                                        null
                                                        ? questionPatameter.subject
                                                        : "N/A"
                                                    }
                                                    Name="Subject"
                                                    Value="22"
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  lg={1}
                                                  md={1}
                                                  sm={1}
                                                  xs={12}
                                                >
                                                  <HPRFilterNode
                                                    ClassName="hpr-filternote section limit-1"
                                                    Label={
                                                      questionPatameter.section !=
                                                        "" &&
                                                      questionPatameter.section !=
                                                        null
                                                        ? questionPatameter.section
                                                        : "N/A"
                                                    }
                                                    Name="Section"
                                                    Value="22"
                                                  />
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </Grid>
                                  {/* <Grid item lg={1}></Grid> */}
                                  <Grid item lg={1}>
                                    <div className="view-more flex--container">
                                      {/* <div className="view-more-text">More</div> */}
                                      {/* <More size="18" color="#fb8500" /> */}
                                      {/* <span className="arrowdown">
                              <ArrowDown2 size="14" color="#fb8500" />
                            </span> */}
                                      {/* <span className="arrowup">
                              <ArrowUp2 size="14" color="#fb8500" />
                            </span> */}
                                    </div>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <div className="modified-by flex--container">
                              {item.ModifiedBy ? (
                                <>
                                  <div className="body3 title pr-10">
                                    Last modified by:{" "}
                                  </div>
                                  {/* <div className="gray-text body3 default-text-color"> */}
                                  <div className="name body3 default-text-color">
                                    {item.ModifiedBy}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item.ModifiedDate ? (
                                <>
                                  <div className="date body3 gray-text">
                                    {dayjs(item.ModifiedDate).format(
                                      "DD-MMM-YYYY HH:mm"
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* </div> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {props.PageType === EnumPageType.InspectionSets ? (
                        <>
                          {/* inspection set data start */}
                          <div className="standrads-icon d-flex">
                            {" "}
                            <img src={item.Icon} />
                          </div>
                          <div className="standrads-icon-details-wrap">
                            <Grid container>
                              <Grid item lg={2} className="">
                                <h4 className="standrads-icon-details-header form-group">
                                  {item.ItemHeading}
                                </h4>
                              </Grid>
                              <Grid item lg={8}>
                                <div className="form-group">
                                  <div className="standards-list d-flex limit-2">
                                    {/* <div className="">
                                <span className="gray-text body2 title">
                                  Standards
                                </span>
                              </div> */}

                                    <Grid
                                      container
                                      spacing={1}
                                      className="d-flex"
                                    >
                                      {item.StandardList?.map(
                                        (standard: StandardDTO, index) => {
                                          return (
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={6}
                                              xs={12}
                                              key={index}
                                            >
                                              <div
                                                className="standard-label body2 flex--container space-between"
                                                title={standard.Name}
                                              >
                                                <span className="standard-name flex__item--vcenter truncate">
                                                  {standard.Name}
                                                </span>
                                                <span className="no-question flex__item--vcenter">
                                                  <MessageQuestion />
                                                  {standard.TotalQuestion}
                                                </span>
                                              </div>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item lg={1}></Grid>
                              <Grid item lg={1}>
                                {/* <div className="view-more flex--container">
                            <div className="view-more-text">More</div>
                            <More size="18" color="#fb8500" />
                            <span className="arrowdown">
                              <ArrowDown2 size="14" color="#fb8500" />
                            </span>
                            <span className="arrowup">
                              <ArrowUp2 size="14" color="#fb8500" />
                            </span>
                          </div> */}
                              </Grid>
                            </Grid>
                            <div className="modified-by flex--container">
                              <div className="body3 title pr-10">
                                Last modified by:{" "}
                              </div>
                              <div className="name body3 default-text-color">
                                {item.ModifiedBy}
                              </div>
                              <div className="date body3 gray-text">
                                {dayjs(item.ModifiedDate).format(
                                  "DD-MMM-YYYY HH:mm"
                                )}
                              </div>
                              {/* {commented for later use} */}
                              {/* <div className="date body3 gray-text">
                                {item.Code ?? ""}
                              </div> */}
                            </div>
                          </div>
                          {/* inspection set data end */}
                        </>
                      ) : (
                        <></>
                      )}
                      {/*Locations Table*/}
                      {props.PageType === EnumPageType.Locations ? (
                        <>
                          {" "}
                          <div className="standrads-icon d-flex">
                            {item.ShowCommonListIcon ? (
                              <>
                                <img src={item.Icon} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="standrads-icon-details-wrap">
                            <Grid container>
                              {/*  Standard question */}
                              {item.Text ? (
                                <>
                                  <Grid item lg={10}>
                                    <div className="standrads-que-header">
                                      {item.Text}
                                    </div>
                                  </Grid>
                                  <Grid item lg={1}></Grid>
                                  <Grid item lg={1}>
                                    <div className="view-more flex--container">
                                      {/* <div className="view-more-text">More</div> */}
                                      {/* <More size="18" color="#fb8500" /> */}
                                      {/* <span className="arrowdown">
                              <ArrowDown2 size="14" color="#fb8500" />
                            </span> */}
                                      {/* <span className="arrowup">
                              <ArrowUp2 size="14" color="#fb8500" />
                            </span> */}
                                    </div>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}

                              <div className="location-name-wrap">
                                {/*  Standard */}
                                {item.ItemHeading ? (
                                  <Tooltip
                                    title={item.ItemHeading}
                                    placement="top-start"
                                  >
                                    <h4 className="standrads-icon-details-header truncate-loc">
                                      {item.ItemHeading}
                                    </h4>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {/*  Standard */}
                              </div>
                              <div className="location-country-wrap">
                                <div className="flex--container">
                                  {item.Region ? (
                                    <span style={{}}>Region:&nbsp;</span>
                                  ) : (
                                    ""
                                  )}
                                  {/* {item.PrimarySME?.map((sme: SmeType, smeIndex) => {
                          return (
                            <div className="gray-text" key={smeIndex}>
                              <span style={{}}>{sme.DisplayName}</span>
                            </div>
                          );
                        })} */}
                                  <div className="gray-text">
                                    <span style={{}}>
                                      {item.Region.RegionName}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex--container">
                                  {item.Region ? (
                                    <span style={{}}>Country:&nbsp;</span>
                                  ) : (
                                    ""
                                  )}
                                  {/* {item.PrimarySME?.map((sme: SmeType, smeIndex) => {
                          return (
                            <div className="gray-text" key={smeIndex}>
                              <span style={{}}>{sme.DisplayName}</span>
                            </div>
                          );
                        })} */}
                                  <div className="gray-text">
                                    <span style={{}}>
                                      {item.Country.CountryName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex--container col location-status-wrap">
                                {item.Status ? (
                                  <>
                                    <Grid item lg={3}>
                                      <div className="standards-total-status-wrap flex--container p-0">
                                        <div className="standards-status flex--container">
                                          <span>Status:</span>
                                          <span
                                            className={
                                              item.StatusID ==
                                              EnumStatus.Published
                                                ? "status-color green"
                                                : item.StatusID ==
                                                  EnumStatus.Deactivated
                                                ? "status-color gray"
                                                : "status-color yellow"
                                            }
                                          >
                                            {item.Status}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="standarded-que-version flex--container p-0">
                                        <span>Version:</span>
                                        <span>
                                          {item.Version.toString() +
                                            (item.StatusID ===
                                              EnumStatus.Published ||
                                            item.StatusID ===
                                              EnumStatus.Draft ||
                                            item.StatusID ===
                                              EnumStatus.Deactivated ||
                                            item.StatusID === EnumStatus.All
                                              ? ""
                                              : "")}
                                        </span>
                                        {/* <span className="d-flex flex-valign-middle">54</span> */}
                                      </div>
                                    </Grid>
                                    {/* <div className="modified-by">
                        <div className="gray-text body2">
                          Last modified by:{" "}
                        </div>
                        <div className="gray-text body3 default-text-color">
                          <span className="name default-text-color">
                            Sasanika Wijerathna
                          </span>
                          <span className="date">20-Dec-2022</span>
                        </div>
                      </div> */}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                            <div className="modified-by flex--container">
                              {item.ModifiedBy ? (
                                <>
                                  <div className="body3 title pr-10">
                                    Last modified by:{" "}
                                  </div>
                                  {/* <div className="gray-text body3 default-text-color"> */}
                                  <div className="name body3 default-text-color">
                                    {item.ModifiedBy}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {item.ModifiedDate ? (
                                <>
                                  <div className="date body3 gray-text">
                                    {dayjs(item.ModifiedDate).format(
                                      "DD-MMM-YYYY HH:mm"
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* </div> */}
                            </div>
                          </div>
                          <a
                            className="standards-icon-forward d-flex flex-valign-middle cursor-pointer p-0"
                            onClick={() => props.ItemOnClickFunction(item.Id)}
                          >
                            <KeyboardArrowRightIcon />
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      {props.PageType != EnumPageType.Locations && (
                        <div
                          className="flex--container col"
                          style={{ width: "15%", paddingTop: "0" }}
                        >
                          {item.Status ? (
                            <>
                              <Grid item lg={3}>
                                <div className="standards-total-status-wrap flex--container">
                                  <div className="standards-total-ques flex--container">
                                    {/* <span className="version">
                        Version: {item.Version}
                      </span> */}
                                    {/* <span className="d-flex flex-valign-middle">54</span> */}
                                  </div>

                                  <div className="standards-status flex--container">
                                    <span>Status:</span>
                                    <span
                                      className={
                                        item.StatusID == EnumStatus.Published
                                          ? "status-color green"
                                          : item.StatusID ==
                                            EnumStatus.Deactivated
                                          ? "status-color gray"
                                          : "status-color yellow"
                                      }
                                    >
                                      {item.Status}
                                    </span>
                                  </div>
                                </div>
                                <div className="standarded-que-version flex--container">
                                  <span>Version:</span>
                                  <span>
                                    {item.Version.toString() +
                                      (item.StatusID === EnumStatus.Published ||
                                      item.StatusID === EnumStatus.Draft ||
                                      item.StatusID ===
                                        EnumStatus.Deactivated ||
                                      item.StatusID === EnumStatus.All
                                        ? ""
                                        : "")}
                                  </span>
                                  {/* <span className="d-flex flex-valign-middle">54</span> */}
                                </div>
                              </Grid>
                              {/* <div className="modified-by">
                        <div className="gray-text body2">
                          Last modified by:{" "}
                        </div>
                        <div className="gray-text body3 default-text-color">
                          <span className="name default-text-color">
                            Sasanika Wijerathna
                          </span>
                          <span className="date">20-Dec-2022</span>
                        </div>
                      </div> */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>

                    {props.PageType != EnumPageType.Locations && (
                      <a
                        className="standards-icon-forward d-flex flex-valign-middle cursor-pointer"
                        onClick={() => props.ItemOnClickFunction(item.Id)}
                      >
                        <KeyboardArrowRightIcon />
                      </a>
                    )}
                    <div className="standards-card-end"></div>
                  </Grid>
                </div>
              );
            })}
          </div>
          {props.TotalRecordsCount > props.ItemShowCount ? (
            <div className="fx-middle  pagination-wrapper">
              <Stack spacing={3}>
                {props.ItemList && props.ItemList?.length > 0 ? (
                  <Pagination
                    count={
                      parseInt(
                        `${props.TotalRecordsCount / props.ItemShowCount}`
                      ) +
                      (props.TotalRecordsCount % props.ItemShowCount > 0
                        ? 1
                        : 0)
                    }
                    showFirstButton
                    showLastButton
                    onChange={handleChange}
                    page={props.SelectedPage}
                  />
                ) : (
                  <></>
                )}
              </Stack>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {/* no data start */}
          <div className="no-data width100-p flex--container">
            <img className="" src={noData} style={{}}></img>
            <div className="gray-text body2 flex__item--vcenter nodata-text">
              No records found
            </div>
          </div>
          {/* no data end */}
        </>
      )}
    </div>
  );
};
export default HPRListPagination;
