import { SearchParameterDTO } from "../../../common/types/common.dto.types";

export const GETUSERMANAGEMENTLIST = "GETUSERMANAGEMENTLIST";
export const ACTIVATESYSTEMGROUPSTATUS = "ACTIVATESYSTEMGROUPSTATUS";
export const DEACTIVATESYSTEMGROUPSTATUS = "DEACTIVATESYSTEMGROUPSTATUS";

export const getUserRoleManagementList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETUSERMANAGEMENTLIST,
    payload: searchParameter,
  };
};

export const activateSystemGroup = (systemGroupState: any) => {
  return {
    type: ACTIVATESYSTEMGROUPSTATUS,
    payload: systemGroupState,
  };
};

export const deActivateSystemGroup = (systemGroupState: any) => {
  return {
    type: DEACTIVATESYSTEMGROUPSTATUS,
    payload: systemGroupState,
  };
};
