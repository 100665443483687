import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import {
  GETQUESTIONNAIRESUMMARY,
  REASSESSINSPECTION,
} from "./QuestionnaireSummaryPage.action";
import {
  getQuestionnaireSummary,
  reassess,
} from "./QuestionnaireSummaryPage.api";
import { questionnaireSummaryPageReducerActions } from "./QuestionnaireSummaryPage.reducer";
import {
  QuestionnaireSummaryPageInitialState,
  QuestionnaireSummaryPageParametersType,
  QuestionnaireSummaryParameterDTO,
} from "./QuestionnaireSummaryPage.types";

const getQuestionnaireSummaryWatcher = function* () {
  yield takeEvery(GETQUESTIONNAIRESUMMARY, function* (action: AnyAction): any {
    yield put(questionnaireSummaryPageReducerActions.setLoading(true));
    yield put(questionnaireSummaryPageReducerActions.clearError());
    yield put(questionnaireSummaryPageReducerActions.clearPageState());

    const parameters: QuestionnaireSummaryPageParametersType = action.payload;

    const result: ActionResponseType = yield call(() =>
      getQuestionnaireSummary({
        StandardId: parameters.StandardId,
        SiteInspectionId: parameters.SiteInspectionId,
        InspectionStandardId: parameters.InspectionStandardId,
      } as QuestionnaireSummaryParameterDTO)
    );

    if (result.IsSuccess) {
      let questionSummery: QuestionnaireSummaryPageInitialState = result.Data;
      questionSummery.StandardId = parameters.StandardId;
      questionSummery.SiteInspectionId = parameters.SiteInspectionId;
      questionSummery.InspectionStandardId = parameters.InspectionStandardId;
      yield put(
        questionnaireSummaryPageReducerActions.setSummary(questionSummery)
      );
    } else {
      yield put(
        questionnaireSummaryPageReducerActions.setError(result.ErrorMessage)
      );
    }

    yield put(questionnaireSummaryPageReducerActions.setLoading(false));
  });
};

const reassessWatcher = function* () {
  yield takeEvery(REASSESSINSPECTION, function* (action: AnyAction): any {
    yield put(questionnaireSummaryPageReducerActions.setLoading(true));
    yield put(questionnaireSummaryPageReducerActions.clearReassessed());

    const parameters: QuestionnaireSummaryPageParametersType = action.payload;

    const result: ActionResponseType = yield call(() =>
      reassess({
        StandardId: parameters.StandardId,
        SiteInspectionId: parameters.SiteInspectionId,
        InspectionStandardId: parameters.InspectionStandardId,
      } as QuestionnaireSummaryParameterDTO)
    );

    if (result.IsSuccess) {
      yield put(
        questionnaireSummaryPageReducerActions.setReassessed({
          IsSuccess: true,
          Error: "",
        })
      );
    } else {
      yield put(
        questionnaireSummaryPageReducerActions.setReassessed({
          IsSuccess: false,
          Error:
            result.ErrorMessage || "This cannot be reassessed at this moment!",
        })
      );
    }

    yield put(questionnaireSummaryPageReducerActions.setLoading(false));
  });
};

const questionnaireSummaryPageSaga = function* () {
  yield all([getQuestionnaireSummaryWatcher(), reassessWatcher()]);
};

export default questionnaireSummaryPageSaga;
