import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { StandardSubSystemPageType } from "./StandardSubSystemPage.types";

export const GETSTANDRDSUBSYSTEMLIST = "GETSTANDRDSUBSYSTEMLIST";
export const SAVESTANDARDRESUBSYSTEM = "SAVESTANDARDRESUBSYSTEM";
export const UPDATESTANDARDSUBSYSTEM = "UPDATESTANDARDSUBSYSTEM";
export const DELETESTANDARDSUBSYSTEM = "DELETESTANDARDSUBSYSTEM";

export const getStandardSubSystemList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETSTANDRDSUBSYSTEMLIST,
    payload: searchParameter,
  };
};

export const saveStandardSubSystem = (
  standardSubSystemPageType: StandardSubSystemPageType
) => {
  return {
    type: SAVESTANDARDRESUBSYSTEM,
    payload: standardSubSystemPageType,
  };
};

export const updateStandardSubSystem = (
  standardSubSystemPageType: StandardSubSystemPageType
) => {
  return {
    type: UPDATESTANDARDSUBSYSTEM,
    payload: standardSubSystemPageType,
  };
};

export const deleteStandardSubSystem = (
  standardSubSystemPageType: StandardSubSystemPageType
) => {
  return {
    type: DELETESTANDARDSUBSYSTEM,
    payload: standardSubSystemPageType,
  };
};
