import React from "react";
import HPRDataGridToolbar from "./HPRDataGridToolbar";

const HPRDataGridCustomToolBar = (props) => {
  const {
    Columns,
    HPRDataGridToolbarProps,
    TotalRecordsCount,
    GridRowData,
    GridPagination,
  } = props;

  return (
    <HPRDataGridToolbar
      HPRDataGridToolbarColumnProps={{
        Columns: Columns,
        ShowColumnOption:
          HPRDataGridToolbarProps?.HPRDataGridToolbarColumnProps
            ?.ShowColumnOption,
      }}
      HPRDataGridToolbarReportProps={
        HPRDataGridToolbarProps?.HPRDataGridToolbarReportProps
      }
      ShowTotalRecordsCount={HPRDataGridToolbarProps?.ShowTotalRecordsCount}
      TotalRecordsCount={
        TotalRecordsCount ? TotalRecordsCount : GridRowData?.TotalRecordsCount
      }
      GridPagination={GridPagination}
    />
  );
};

export default HPRDataGridCustomToolBar;
