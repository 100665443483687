import { Button, Grid, IconButton, InputBase, Paper, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";

import HPRDataGrid from "../../../components/common/HPRDataGrid/HRPDataGrid";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  StandardSectionDTO,
} from "../../../common/types/common.dto.types";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";

import {
  AddCircle,
  ArrowLeft2,
  ArrowRight2,
  CardSlash,
  CloseCircle,
  Edit,
  SearchNormal1,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate } from "react-router-dom";
import {
  SectionPageParametersType,
  SectionPageType,
} from "./SectionPage.types";
import {
  setPageMode,
  setSectionModalOpen,
  setStandardSection,
  setStandardSectionInitState,
  setStandardSectionSearchParameter,
} from "./SectionPage.reducer";
import {
  deleteStandardSection,
  getStandardSectionList,
  saveStandardSection,
  updateStandardSection,
} from "./SectionPage.action";
import { StandardPageParametersType } from "../StandardPage/StandardPage.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import HPRSplitButton from "../../../components/common/HPRSplitButton/HPRSplitButton";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import SimpleReactValidator from "simple-react-validator";
import dayjs from "dayjs";
import {
  HPRDataGridToolbarProps,
  HPRGridColDef,
} from "../../../components/common/HPRDataGrid/HPRDataGrid.types";

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandardSection: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Draft,
  } as StandardSectionDTO,
  StandardSectionSearchParameter: {
    StandardSectionSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardSectionDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  SectionModalOpen: false,
  Validator: new SimpleReactValidator({
    messages: {
      default: "This field is required",
    },
  }),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as SectionPageType;

const SectionPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for StandardPageType with initialState values
  const [standardSectionPageState, setstandardSectionPageState] =
    useState(initialState);

  // pass parameters to page

  const location = useLocation();
  const parameter: SectionPageParametersType = location.state;

  // use for page navigation
  const navigate = useNavigate();

  const dataFetchedRef = useRef(false);

  const dispatch = useAppDispatch();
  const standardSectionState = useAppSelector(
    (state) => state.standardSectionReducer
  );
  const standardState = useAppSelector((state) => state.standardReducer);

  //open and close Modal
  const handleOpen = () => {
    dispatch(setSectionModalOpen(true));
  };
  const handleClose = () => {
    dispatch(setSectionModalOpen(false));
  };
  //=========================================================
  const columns: HPRGridColDef[] = [
    {
      field: "Id",
      headerName: "Id",
      hideColumn: true,
      hideable: false,
      filterable: false,
      flex: 0.5,
    },
    { field: "Name", headerName: "Section", flex: 14, value: "" },
    {
      field: "ModifiedBy",
      headerName: "Last modified by",
      flex: 6,
      value: "",
    },
    {
      field: "ModifiedDate",
      headerName: "Last modified date",
      flex: 6,
      renderCell: (params) => {
        return dayjs(params.row?.ModifiedDate).format("DD-MMM-YYYY HH:mm");
      },
      value: "",
    },
    {
      field: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      hide: parameter?.StatusId === EnumStatus.Deactivated,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          dispatch(
            setStandardSection({
              Id: params.row?.Id,
              Name: params.row?.Name,
              StandardId: parameter?.StandardId,
            } as StandardSectionDTO)
          );

          setstandardSectionPageState((values) => {
            return {
              ...values,
              StandardSection: {
                ...values.StandardSection,
                Name: params.row?.Name,
                Id: params.row?.Id,
              },
            };
          });
          onClickViewBtn();
        };

        return (
          <>
            <Button
              className="standards-icon-forward d-flex flex-valign-middle cursor-pointer grid-icon-btn"
              onClick={onClick}
            >
              <ArrowRight2 size="32" variant="Outline" />
            </Button>
          </>
        );
      },
    },
  ];

  //Page onload
  useEffect(() => {
    if (parameter?.StandardId > 0) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      dispatch(
        setStandardSectionInitState({
          ...standardSectionState,
          StandardSection: {
            ...standardSectionState.StandardSection,
            StandardId: parameter.StandardId,
          },
          StandardSectionSearchParameter: {
            ...standardSectionState.StandardSectionSearchParameter,
            StandardSectionSearchParams: {
              ...standardSectionState.StandardSectionSearchParameter
                .StandardSectionSearchParams,
              StandardId: parameter.StandardId,
              Name: standardSectionPageState.StandardSectionSearchParameter
                .StandardSectionSearchParams.Name,
            },
          },
          Data: {} as PaginationDTO,
        })
      );

      dispatch(
        getStandardSectionList({
          ...standardSectionPageState.StandardSectionSearchParameter,
          StandardSectionSearchParams: {
            ...standardSectionPageState.StandardSectionSearchParameter
              .StandardSectionSearchParams,
            StandardId: parameter.StandardId,
          },
        })
      );

      setstandardSectionPageState((values) => {
        return {
          ...values,
          StandardSection: {
            ...values.StandardSection,
            StandardId: parameter.StandardId,
          },
          StandardSectionSearchParameter: {
            ...values.StandardSectionSearchParameter,
            StandardSectionSearchParams: {
              ...values.StandardSectionSearchParameter
                .StandardSectionSearchParams,
              StandardId: parameter.StandardId,
            },
          },
        };
      });
    }
  }, []);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setstandardSectionPageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: standardSectionState.Data.Data,
          TotalRecordsCount: standardSectionState.Data.TotalRecordsCount,
          SelectedPage: standardSectionState.Data.SelectedPage,
        },
        StandardSection: {
          Id: standardSectionState.StandardSection.Id,
          Name: standardSectionState.StandardSection.Name,
          StandardId: parameter.StandardId,
          Version: standardSectionState.StandardSection.Version,
          Status: standardSectionState.StandardSection.Status,
        } as StandardSectionDTO,
        StandardSectionSearchParameter: {
          ...values.StandardSectionSearchParameter,
          SelectedPage: standardSectionState.Data.SelectedPage,
          SortByNavigation:
            standardSectionState.StandardSectionSearchParameter
              .SortByNavigation,
          GridPageSize:
            standardSectionState.StandardSectionSearchParameter.GridPageSize,
        },
        SectionModalOpen: standardSectionState.SectionModalOpen,
        PageMode: standardSectionState.PageMode,
      };
    });
  }, [standardSectionState]);

  //================================ Search ============================================================

  const onChangeSearchName = (event: ChangeEvent<HTMLInputElement>) => {
    setstandardSectionPageState((values) => {
      return {
        ...values,
        StandardSectionSearchParameter: {
          ...values.StandardSectionSearchParameter,
          StandardSectionSearchParams: {
            ...values.StandardSectionSearchParameter
              .StandardSectionSearchParams,
            Name: event.target.value,
          },
        },
      };
    });

    if (!event.target.value) {
      dispatch(
        getStandardSectionList({
          ...standardSectionPageState.StandardSectionSearchParameter,
          StandardSectionSearchParams: {
            ...standardSectionPageState.StandardSectionSearchParameter
              .StandardSectionSearchParams,
            Name: "",
          },
        })
      );
    }
  };

  const onChangeStandardSectionSearchParameter = (
    isAdvanceSearch: boolean,
    selectedPage: number
  ) => {
    dispatch(
      setStandardSectionSearchParameter({
        ...standardSectionPageState.StandardSectionSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
      })
    );

    dispatch(
      getStandardSectionList({
        ...standardSectionPageState.StandardSectionSearchParameter,
        IsAdvanceSearch: isAdvanceSearch,
        SelectedPage: selectedPage,
      })
    );
  };

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();

      if (
        standardSectionPageState.StandardSectionSearchParameter
          .StandardSectionSearchParams.Name !==
        standardSectionState.StandardSectionSearchParameter
          .StandardSectionSearchParams.Name
      ) {
        onChangeStandardSectionSearchParameter(
          standardSectionPageState?.StandardSectionSearchParameter
            ?.IsAdvanceSearch,
          1
        );
      }
    }
  };

  const serachBtnClick = (IsAdvanceSearch: boolean) => {
    onChangeStandardSectionSearchParameter(IsAdvanceSearch, 1);
  };

  const onPaginationCallBack = (newPage: number) => {
    if (
      standardSectionPageState.StandardSectionSearchParameter.SelectedPage !==
      newPage
    ) {
      onChangeStandardSectionSearchParameter(
        standardSectionPageState?.StandardSectionSearchParameter
          ?.IsAdvanceSearch,
        newPage
      );
    }
  };

  const onSortByNavigationCallBack = (
    selectedSortByNavigation: SortDropDownDTO
  ) => {
    dispatch(
      setStandardSectionSearchParameter({
        ...standardSectionPageState.StandardSectionSearchParameter,
        StandardSectionSearchParams: {
          ...standardSectionPageState.StandardSectionSearchParameter
            .StandardSectionSearchParams,
        },
        SortByNavigation: selectedSortByNavigation,
        SelectedPage: 1,
      })
    );

    dispatch(
      getStandardSectionList({
        ...standardSectionPageState.StandardSectionSearchParameter,
        StandardSectionSearchParams: {
          ...standardSectionPageState.StandardSectionSearchParameter
            .StandardSectionSearchParams,
        },
        SortByNavigation: selectedSortByNavigation,
        SelectedPage: 1,
      })
    );
  };

  //================================ End Search ============================================================

  const OnSectionTextChange = (name: string, value: any) => {
    setstandardSectionPageState((values) => {
      return {
        ...values,
        StandardSection: {
          ...values.StandardSection,
          Name: value,
        },
      };
    });
  };

  //================ Button functions ===================//
  const onClickCancelBtn = () => {
    //hide validation message
    standardSectionPageState.Validator.hideMessages();
    if (standardSectionPageState.PageMode.IsShowEditBtn) {
      dispatch(
        setStandardSection({
          Id: 0,
          Name: "",
          StandardId: parameter?.StandardId,
          Version: 1,
          Status: EnumStatus.Published,
        } as StandardSectionDTO)
      );

      dispatch(setPageMode(EnumPageMode.Default));
      handleClose();
    } else if (standardSectionPageState.PageMode.IsShowSaveBtn) {
      if (
        standardSectionPageState.StandardSection.Name === null ||
        standardSectionPageState.StandardSection.Name.match(/^ *$/) !== null
      ) {
        dispatch(
          setStandardSection({
            Id: 0,
            Name: "",
            StandardId: parameter?.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSectionDTO)
        );
        dispatch(setPageMode(EnumPageMode.Default));
        handleClose();
      } else {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                setStandardSection({
                  Id: 0,
                  Name: "",
                  StandardId: parameter?.StandardId,
                  Version: 1,
                  Status: EnumStatus.Published,
                } as StandardSectionDTO)
              );
              dispatch(setPageMode(EnumPageMode.Default));
              handleClose();
            },
          } as PopupProps)
        );
      }
    } else {
      if (
        standardSectionPageState.StandardSection.Name !==
        standardSectionState.StandardSection.Name
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardSectionPageState((values) => {
                return {
                  ...values,
                  StandardSection: {
                    Id: standardSectionState.StandardSection.Id,
                    Name: standardSectionState.StandardSection.Name,
                    StandardId: parameter?.StandardId,
                    Version: 1,
                    Status: standardSectionState.StandardSection.Status,
                  } as StandardSectionDTO,
                };
              });

              dispatch(setPageMode(EnumPageMode.ViewMode));
            },
          } as PopupProps)
        );
      } else {
        dispatch(setPageMode(EnumPageMode.ViewMode));
      }
    }
  };

  const CloseButtonClick = () => {
    dispatch(
      setStandardSection({
        Id: 0,
        Name: "",
        StandardId: parameter?.StandardId,
        Version: 1,
        Status: EnumStatus.Published,
      } as StandardSectionDTO)
    );

    dispatch(setPageMode(EnumPageMode.Default));
    handleClose();
  };

  const onClickDeleteBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          if (standardSectionPageState.StandardSection?.Id > 0) {
            dispatch(deleteStandardSection(standardSectionPageState));
            //hide validation message
            standardSectionPageState.Validator.hideMessages();
          }
        },
      } as PopupProps)
    );
  };

  const onClickAddBtn = () => {
    dispatch(setPageMode(EnumPageMode.AddMode));
    handleOpen();
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
    handleOpen();
  };

  const onClickViewBtn = () => {
    dispatch(setPageMode(EnumPageMode.ViewMode));
    handleOpen();
  };

  const onClickSaveBtn = (buttonModeType: EnumButtonModeType) => {
    if (IsPageValid()) {
      dispatch(
        saveStandardSection({
          ...standardSectionPageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  const onClickUpdateBtn = (buttonModeType: EnumButtonModeType) => {
    if (IsPageValid()) {
      dispatch(
        updateStandardSection({
          ...standardSectionPageState,
          ButtonModeType: buttonModeType,
        })
      );
    }
  };

  const IsPageValid = (): boolean => {
    let isValid = standardSectionPageState.Validator.allValid();
    if (!isValid) {
      setstandardSectionPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !standardSectionPageState.ShowErrorMsg,
        };
      });

      //show validation message
      standardSectionPageState.Validator.showMessages();

      return isValid;
    } else {
      //hide validation message
      standardSectionPageState.Validator.hideMessages();
    }

    return isValid;
  };

  //================ End Button functions ===================//
  // modal popup
  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //------------------------------------
  return (
    <>
      {/* Page loading backdrop */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={
          standardSectionState != undefined
            ? standardSectionState?.IsLoading
            : false
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* Page loading backdrop */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={
          standardSectionState != undefined
            ? standardSectionState?.IsLoading
            : false
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/* <Grid container columns={12} className="global-header-wrap"> */}
      {/* <Grid item xs={12} className="">
          <h1>{props.PageTitle + "s"}</h1>
        </Grid> */}
      {/* </Grid> */}
      <div className="content-section-card">
        <Grid container>
          {/* Button bar */}
          <Grid container>
            <div className="flex--container width100-p">
              <div className="flex__item--inherit pr-10">
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  onClick={() => {
                    navigate("/standard", {
                      state: {
                        StandardId: parameter?.StandardId,
                      } as StandardPageParametersType,
                    });
                  }}
                  title="Back to home page"
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>{" "}
              </div>
              <div className="global-header-wrap">
                <h1 className="page_title">{props.PageTitle + "s"}</h1>
              </div>
              <div className="flex__item search-bar--small ml-10 mr-10">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    value={
                      standardSectionPageState.StandardSectionSearchParameter
                        .StandardReferenceSearchParams?.Name
                    }
                    onChange={onChangeSearchName}
                    onKeyPress={onKeyPressSearchName}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by Section"
                    inputProps={{ "aria-label": "Section" }}
                    type="search"
                  />
                  <IconButton
                    onClick={() => {
                      // serachBtnClick(false);
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    className="m-0"
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      // serachBtnClick(true);
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Filter variant="Outline" className="ico-secondary" />
                  </IconButton> */}
                  {/* <IconButton
                    onClick={() => {
                      serachBtnClick(false);
                    }}
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      serachBtnClick(true);
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <FilterAltOutlinedIcon />
                  </IconButton> */}
                </Paper>
              </div>
              <Button
                className="primary-btn-small"
                size="small"
                variant="outlined"
                disableElevation
                startIcon={<AddCircle variant="Outline" />}
                onClick={onClickAddBtn}
                style={{
                  visibility:
                    standardSectionPageState?.PageMode?.IsShowAddBtn &&
                    parameter?.StatusId !== EnumStatus.Deactivated
                      ? "visible"
                      : "hidden",
                }}
              >
                Add
              </Button>
            </div>
          </Grid>
          <Grid container>
            <Modal
              className="modal-outter sub-form-section"
              open={standardSectionPageState.SectionModalOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="modal-header">
                  <h1>
                    <span>Section</span>
                    <span>
                      {standardSectionPageState.PageMode.IsShowSaveBtn ? (
                        <>
                          <HPRSplitButton
                            Name="SectionSave"
                            ButtonOptions={[
                              {
                                ButtonId: "0",
                                ButtonName: "Save",
                                onButtonClickCallBack: () => {
                                  onClickSaveBtn(EnumButtonModeType.Save);
                                },
                              },
                              {
                                ButtonId: "1",
                                ButtonName: "Save & Close",
                                onButtonClickCallBack: () => {
                                  onClickSaveBtn(
                                    EnumButtonModeType.SaveAndClose
                                  );
                                },
                              },
                              {
                                ButtonId: "2",
                                ButtonName: "Save & Continue",
                                onButtonClickCallBack: () => {
                                  onClickSaveBtn(
                                    EnumButtonModeType.SaveAndContinue
                                  );
                                },
                              },
                            ]}
                          ></HPRSplitButton>
                        </>
                      ) : (
                        <></>
                      )}
                      {standardSectionPageState.PageMode.IsShowUpdateBtn ? (
                        <>
                          <HPRSplitButton
                            Name="SectionUpdateBtn"
                            ButtonOptions={[
                              {
                                ButtonId: "0",
                                ButtonName: "Update & Close",
                                onButtonClickCallBack: () => {
                                  onClickUpdateBtn(
                                    EnumButtonModeType.UpdateAndClose
                                  );
                                },
                              },
                              {
                                ButtonId: "1",
                                ButtonName: "Update",
                                onButtonClickCallBack: () => {
                                  onClickUpdateBtn(EnumButtonModeType.Update);
                                },
                              },
                            ]}
                          ></HPRSplitButton>
                        </>
                      ) : (
                        <></>
                      )}
                      {standardSectionPageState.PageMode.IsShowEditBtn ? (
                        <>
                          <Button
                            className="primary-btn-small"
                            size="small"
                            variant="outlined"
                            disableElevation
                            onClick={onClickEditBtn}
                            startIcon={<Edit variant="Outline" />}
                          >
                            Edit
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {standardSectionPageState.PageMode.IsShowDeleteBtn ? (
                        <>
                          <Button
                            className="primary-btn-small"
                            size="small"
                            variant="outlined"
                            disableElevation
                            startIcon={<CardSlash variant="Outline" />}
                            onClick={onClickDeleteBtn}
                          >
                            Deactivate
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {standardSectionPageState.PageMode.IsShowCancelBtn ? (
                        <>
                          <Button
                            className="secondary-btn-small"
                            size="small"
                            variant="outlined"
                            disableElevation
                            startIcon={<CloseCircle variant="Outline" />}
                            onClick={onClickCancelBtn}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {standardSectionPageState.PageMode.IsShowCloseBtn && (
                        <Button
                          className="secondary-btn-small"
                          size="small"
                          variant="outlined"
                          disableElevation
                          onClick={CloseButtonClick}
                          startIcon={<CloseCircle variant="Outline" />}
                        >
                          Close
                        </Button>
                      )}
                    </span>
                  </h1>
                </div>
                <div className="modal-content">
                  <Grid container>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={8}
                      sm={12}
                      xs={12}
                      className="m-auto"
                    >
                      <div className="form-group">
                        <HPRTextBox
                          Id=""
                          Label="Section"
                          Name="section"
                          Value={standardSectionPageState?.StandardSection.Name}
                          Type={EnumTextBoxType.Text}
                          Disabled={standardSectionPageState.PageMode.IsDisable}
                          onTextBoxChange={OnSectionTextChange}
                          Validator={standardSectionPageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          AutoFocus={
                            !standardSectionPageState.PageMode.IsDisable
                          }
                          Required={true}
                        ></HPRTextBox>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Modal>

            <div className="HPRDataGrid-wrapper">
              <HPRDataGrid
                Columns={columns}
                Data={standardSectionPageState?.Data?.Data}
                TotalRecordsCount={
                  standardSectionPageState?.Data?.TotalRecordsCount
                }
                //onRowClickedCallBack={onSectionGridRowClickedCallBack}
                AutoHeight={true}
                onPaginationChangeCallBack={onPaginationCallBack}
                SelectedPage={standardSectionPageState.Data?.SelectedPage}
                HPRDataGridToolbarProps={
                  {
                    ShowTotalRecordsCount: true,
                  } as HPRDataGridToolbarProps
                }
                PageSize={
                  standardSectionPageState?.StandardSectionSearchParameter
                    ?.GridPageSize ??
                  EnumGridDataShowRowCount.GridDataShowRowCount
                }
                // MenuFunctions={{
                //   onChangeSortByNavigation: onSortByNavigationCallBack,
                // }}
                ShowCustomePagination
              ></HPRDataGrid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SectionPage;
