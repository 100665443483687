import { ApiClient } from "../../../core/api/apiClient.service";
import {
  SearchParameterDTO,
  StandardSubSystemDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
const client = new ApiClient();

export const getStandardSubSystemList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubSystem/GetStandardSubSystemList`;
    let result = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        // console.log(error);
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveStandardSubSystem = async (
  Params: StandardSubSystemDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubSystem/SaveStandardSubSystem`;
    let standardSubSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSubSystem = response;
      })
      .catch((error) => {
        standardSubSystem = {} as ActionResponseType;
        return error;
      });
    return standardSubSystem;
  } catch (error) {
    throw error;
  }
};

export const updateStandardSubSystem = async (
  Params: StandardSubSystemDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubSystem/UpdateStandardSubSystem`;
    let standardSubSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSubSystem = response;
      })
      .catch((error) => {
        standardSubSystem = {} as ActionResponseType;
        return error;
      });
    return standardSubSystem;
  } catch (error) {
    throw error;
  }
};

export const deleteStandardSubSystem = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubSystem/DeleteStandardSubSystemById/${id}`;
    let standardSubSystem = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response) => {
        standardSubSystem = response;
      })
      .catch((error) => {
        return error;
      });
    return standardSubSystem;
  } catch (error) {
    throw error;
  }
};
