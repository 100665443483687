import { Document } from "iconsax-react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import {
  EnumReportFormatType,
  HPRReportMenuProps,
} from "./HPRReportMenu.types";
import { Download } from "@mui/icons-material";

// ==============================|| HPR - REPORTMENU ||============================== //

const HPRReportMenu: React.FC<HPRReportMenuProps> = (props) => {
  const [reportanchorEl, setReportanchorEl] = useState<null | HTMLElement>(
    null
  );
  const taskReportOpen = Boolean(reportanchorEl);

  //open and close download popup Menu Item
  const onClickReportDownload = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setReportanchorEl(event.currentTarget);
  };

  const handleReportDownloadClose = () => {
    setReportanchorEl(null);
  };

  const setReportTypeIcon = (reportType: EnumReportFormatType): string => {
    let reportIcon = "";

    if (reportType === EnumReportFormatType.PDF) {
      reportIcon = "pdf";
    } else if (reportType === EnumReportFormatType.EXCEL) {
      reportIcon = "excel";
    } else if (reportType === EnumReportFormatType.WORD) {
      reportIcon = "word";
    }

    return reportIcon;
  };

  return (
    <>
      {props?.ReportList?.filter((filter) => !filter?.HideReport)?.length >
        0 && (
        <>
          <Button
            className="primary small-btn shadow-remove"
            variant="contained"
            onClick={onClickReportDownload}
          >
            {/* <Download className="m-r-10" style={{ fontSize: 20 }} /> */}
            <span> Export Report(s)</span>
          </Button>

          <Menu
            className="sort-dialog-content"
            open={taskReportOpen}
            anchorEl={reportanchorEl}
            onClose={handleReportDownloadClose}
          >
            {props?.ReportList?.filter((filter) => !filter?.HideReport)?.map(
              (report, index) => {
                return (
                  <MenuItem
                    disabled={report?.DisabledReport}
                    key={`report_${index}`}
                  >
                    {/* can add word/excel/pdf classes */}
                    {/* <div
                  className={`colmn-opt ${setReportTypeIcon(
                    report?.ReportFormatType
                  )}`}
                >
                  <Document className="m-r-10" style={{ fontSize: 20 }} />
                </div>
                {report?.ReportName} */}
                    {report?.ReportComponent}
                  </MenuItem>
                );
              }
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default HPRReportMenu;
