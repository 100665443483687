// saga $ redux
import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumInspectionSetListType,
  EnumPageMode,
} from "../../../common/enums/common.enums";

// project imports
import {
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  DEACTIVATEINSPECTIONSET,
  GETAVAILABLEDATA,
  SAVESELECTEDDATA,
} from "./InspectionSetPage.action";
import {
  deactivateInspectionSetApi,
  getAvailableData,
  saveSelectedData,
} from "./InspectionSetPage.api";
import {
  changePageMode,
  setAvailableDataFromBackEnd,
  setInitialAvailableDataFromBackEnd,
  setInspectionSet,
} from "./InspectionSetPage.reducer";
import { InspectionSetPageType } from "./InspectionSetPage.types";

// ==============================|| VIEWS - PAGES - INSPECTIONSET PAGE - SAGA ||============================== //

const getAvailableDataWatcher = function* (): any {
  yield takeEvery(GETAVAILABLEDATA, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    const state: InspectionSetPageType = yield select(
      (s: RootState) => s.inspectionSetReducer
    );
    const pageState: InspectionSetPageType = action.payload;

    // yield put(
    //   pageState?.SearchInspectionSetListAvailable?.InspectionSetListType ===
    //     EnumInspectionSetListType.Initial
    //     ? setInitialAvailableDataFromBackEnd(pageState)
    //     : setAvailableDataFromBackEnd(pageState)
    // );
    // get data from backend
    try {
      const result: ActionResponseType = yield call(() =>
        getAvailableData(pageState.SearchInspectionSetListAvailable)
      );
      // Check result status
      if (result.IsSuccess) {
        //
        // validate result
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No inspection set data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const data: InspectionSetListTypeDTO = result.Data;

          const dataObject = {
            ...state,
            InspectionSet: data,
            SearchInspectionSetListAvailable:
              pageState.SearchInspectionSetListAvailable,
            SearchInspectionSetListSelected: {
              ...pageState.SearchInspectionSetListAvailable,
              Name: pageState.SearchInspectionSetListSelected?.Name,
              IsSelected: pageState.SearchInspectionSetListSelected?.IsSelected,
            },
            ClickInspectionSetItem: pageState.ClickInspectionSetItem,
          } as InspectionSetPageType;

          yield put(
            pageState?.SearchInspectionSetListAvailable
              ?.InspectionSetListType === EnumInspectionSetListType.Initial
              ? setInitialAvailableDataFromBackEnd(dataObject)
              : setAvailableDataFromBackEnd(dataObject)
          );

          yield put(setLoading(false));
        }
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }
  });
};

const saveSelectedDataWatcher = function* (): any {
  yield takeEvery(SAVESELECTEDDATA, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    //yield put(setStatus(status));

    const state: InspectionSetPageType = yield select(
      (s: RootState) => s.inspectionSetReducer
    );

    const InspectionSetList: InspectionSetListTypeDTO = action.payload;

    // get data from backend
    try {
      const result: ActionResponseType = yield call(() =>
        saveSelectedData(InspectionSetList)
      );

      // Check result status
      if (result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText:
              result.InfoMessage || "Inspection set changes saved successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        const saveResult: InspectionSetListTypeDTO = result.Data;
        yield put(
          setInspectionSet({
            ...saveResult,
            InspectionSetListAvailable:
              state.InspectionSet?.InspectionSetListAvailable,
            InspectionSetListSelected:
              state.InspectionSet?.InspectionSetListSelected,
          })
        );

        yield put(changePageMode(EnumPageMode.ViewMode));

        yield put(setLoading(false));
      } else {
        yield put(setLoading(false));

        yield put(
          openPopup({
            Open: true,
            BodyText: "Inspection set is not saved successfully",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(setLoading(false));

      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred while saving inspection set",
          HeaderText: "Warning",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }
  });
};

// watcher for DEACTIVATEINSPECTIONSET(deactivate inspection set) action
const deactivateStandardWatcher = function* (): any {
  yield takeEvery(DEACTIVATEINSPECTIONSET, function* (action: AnyAction): any {
    const state: InspectionSetPageType = yield select(
      (s: RootState) => s.inspectionSetReducer
    );

    // set page loading on
    yield put(setLoading(true));

    const inspectionSetList: InspectionSetListTypeDTO = action.payload;
    // get data from backend
    try {
      let result: ActionResponseType = yield call(() =>
        deactivateInspectionSetApi(inspectionSetList)
      );
      if (result.IsSuccess) {
        if (result.ErrorMessage != "" && result.ErrorMessage != null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage,
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              YesBtnClick() {},
            } as PopupProps)
          );
        } else {
          const inspectionSetListResult: InspectionSetListTypeDTO = result.Data;

          yield put(
            openPopup({
              Open: true,
              BodyText: "Inspection set successfully deactivated",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );

          const dataObject = {
            ...state,
            InspectionSet: inspectionSetListResult,
            SearchInspectionSetListAvailable: {
              InspectionId: inspectionSetListResult?.InspectionId,
              Name: "",
              InspectionSetListType: EnumInspectionSetListType.Initial,
              StandardId: 0,
              StandardSystemId: null,
              StandardSubSystemId: null,
              StandardSubectId: null,
              StandardReferenceId: null,
              StandardSectionId: null,
              IsSelected: true,
            } as InspectionSetSelectDTO,
            SearchInspectionSetListSelected: {
              InspectionId: 0,
              Name: "",
              InspectionSetListType: EnumInspectionSetListType.Initial,
              StandardId: 0,
              StandardSystemId: null,
              StandardSubSystemId: null,
              StandardSubectId: null,
              StandardReferenceId: null,
              StandardSectionId: null,
              IsSelected: false,
            } as InspectionSetSelectDTO,
          } as InspectionSetPageType;

          yield put(setInitialAvailableDataFromBackEnd(dataObject));
          // change page mode (change disable and button show hide)
          yield put(changePageMode(EnumPageMode.ViewMode));
        }
      } else {
        if (result.ErrorMessage != "" && result.ErrorMessage != null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage,
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving standard",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

const inspectionSetSaga = function* () {
  yield all([
    getAvailableDataWatcher(),
    saveSelectedDataWatcher(),
    deactivateStandardWatcher(),
  ]);
};

export default inspectionSetSaga;
