export interface HPRReportMenuProps {
  ReportList: Array<HPRReportMenuType>;
}

export interface HPRReportMenuType {
  ReportName: any;
  ReportComponent?: any; // pass componet
  DisabledReport?: boolean;
  HideReport?: boolean;
  ReportFormatType: EnumReportFormatType;
  //OnClickReport?: () => void;
}

export enum EnumReportFormatType {
  PDF = 1,
  EXCEL = 2,
  WORD = 3,
}
