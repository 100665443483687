import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

const client = new ApiClient();

export const getUserRoleManagementList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetUserRoleManagementList`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const getAzureGroupSiteList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetSiteList`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const activateSystemGroup = async (
  systemGroupId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/ActivateUserRoleManagement?userRoleManagementId=${systemGroupId}`;
    let response = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, {})
      .then((r) => {
        response = r;
      })
      .catch((error) => {
        response = {
          Data: {},
          ErrorMessage:
            "Sorry, an unexpected error occured. Please try again later.",
        } as ActionResponseType;
      });
    return response;
  } catch (error) {
    return {
      Data: {},
      ErrorMessage:
        "Sorry, an unexpected error occured. Please try again later.",
    } as ActionResponseType;
  }
};

export const deactivateSystemGroup = async (
  systemGroupId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/DeActivateUserRoleManagement?userRoleManagementId=${systemGroupId}`;
    let response = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, {})
      .then((r) => {
        response = r;
      })
      .catch((error) => {
        response = {
          Data: {},
          ErrorMessage:
            "Sorry, an unexpected error occured. Please try again later.",
        } as ActionResponseType;
      });
    return response;
  } catch (error) {
    return {
      Data: {},
      ErrorMessage:
        "Sorry, an unexpected error occured. Please try again later.",
    } as ActionResponseType;
  }
};
