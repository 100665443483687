import { createSlice } from "@reduxjs/toolkit";

// project imports
import {
  EnumLoginFormErrorType,
  LoginRequestErrorType,
  LoginRequestType,
} from "./LoginPage.types";
// ==============================|| VIEWS - PAGES - LOGIN PAGE - REDUCER ||============================== //

const initialState = {
  Password: "",
  UserName: "",
  Errors: [],
  LoginError: "",
  Language: 1,
  Loading: false,
  ErrorMessage: "",
  ProgressMessage: "",
} as LoginRequestType;

export const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setUsername: (state: LoginRequestType, action: { payload: string }) => {
      return { ...state, UserName: action.payload };
    },
    setPassword: (state: LoginRequestType, action: { payload: string }) => {
      return { ...state, Password: action.payload };
    },
    setLanguage: (state: LoginRequestType, action: { payload: number }) => {
      return { ...state, Language: action.payload };
    },
    clearForm: (state) => {
      return { ...state, UserName: "", Password: "" };
    },
    addError: (
      state: LoginRequestType,
      action: { payload: LoginRequestErrorType }
    ) => {
      return { ...state, Errors: [...state.Errors, action.payload] };
    },
    setErrors: (
      state: LoginRequestType,
      action: { payload: Array<LoginRequestErrorType> }
    ) => {
      return { ...state, Errors: action.payload };
    },
    setError: (state, action: { payload: string }) => {
      return { ...state, ErrorMessage: action.payload };
    },
    clearError: (state) => {
      return { ...state, ErrorMessage: "" };
    },
    clearErrors: (state: LoginRequestType) => {
      return { ...state, Errors: [] };
    },
    clearUserNameError: (state) => {
      return {
        ...state,
        Errors: state.Errors.filter(
          (e) => e.Name != EnumLoginFormErrorType.UserName
        ),
      };
    },
    clearPasswordError: (state) => {
      return {
        ...state,
        Errors: state.Errors.filter(
          (e) => e.Name != EnumLoginFormErrorType.Password
        ),
      };
    },
    clearLanguageError: (state) => {
      return {
        ...state,
        Errors: state.Errors.filter(
          (e) => e.Name != EnumLoginFormErrorType.Language
        ),
      };
    },
    setLoading: (state: LoginRequestType, action) => {
      return { ...state, Loading: action.payload };
    },
    setProgress: (state, action: { payload: string }) => {
      return { ...state, ProgressMessage: action.payload };
    },
    clearProgress: (state) => {
      return { ...state, ProgressMessage: "" };
    },
  },
});

// export const {
//   setUsername,
//   setPassword,
//   setLoginError,
//   clearErrors,
//   clearLogin,
//   setLoading,
// } = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
export const loginReducerActions = loginSlice.actions;
