import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { UploadFileDTO } from "../../../common/types/common.dto.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { ActionResponseType } from "../../../common/types/common.types";

// client object for axios calls to backend
const client = new ApiClient();

export const GetFileFromBackend = async (
  uploadFileDTO: UploadFileDTO,
  cb?: () => void
) => {
  try {
    const config = {
      responseType: "blob",
    } as AxiosRequestConfig;
    const URL: string = `File/DownloadFile/${uploadFileDTO.Id}`;

    let uploadFile = { ...uploadFileDTO };
    uploadFile.FilePreview = null;
    const response1 = await client
      .get(URL, config)
      .then((response) => {
        const urlObj = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = urlObj;
        link.setAttribute("download", uploadFileDTO.FileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (cb) {
          cb();
        }
      })
      .catch((error) => {});
  } catch (error) {}
};

export const fileRename = async (id: number, name: string) => {
  try {
    const URL: string = `File/RenameFile`;
    let data: ActionResponseType;

    return client
      .post<ActionResponseType>(URL, { Id: id, Name: name })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return {} as ActionResponseType;
      });
  } catch (error) {
    throw error;
  }
};
