import {
  PaginationDTO,
  SearchParameterDTO,
  SiteDTO,
  SortDropDownDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import {
  EnumListShowRowCountList,
  EnumLocationSortType,
  EnumSortTypes,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { LocationsPageInitialState } from "./LocationsPage.types";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: 2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  LocationsSearchParameter: {
    LocationsSearchParams: {
      SiteName: "",
      Status: EnumStatus.Published,
      SortBy: {
        Label: "Name",
        Value: EnumLocationSortType.Name.toString(),
        SortType: EnumSortTypes.Ascending,
      } as SortDropDownDTO,
    } as SiteDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as LocationsPageInitialState;

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocationsList: (
      state: LocationsPageInitialState,
      action: { payload: LocationsPageInitialState; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.Data,
      };
    },
    setLoading: (state: LocationsPageInitialState, action) => {
      return { ...state, Loading: action.payload };
    },
    setPagination: (
      state: LocationsPageInitialState,
      action: { payload: HPRListPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setLocationsSearchParameter: (
      state: LocationsPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        LocationsSearchParameter: {
          ...state.LocationsSearchParameter,
          LocationsSearchParams: action.payload.LocationsSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          ListPageSize: action.payload.ListPageSize,
        } as SearchParameterDTO,
      };
    },
  },
});

export const {
  setLocationsList,
  setLoading,
  setPagination,
  setLocationsSearchParameter,
} = locationsSlice.actions;
export const locationsReducer = locationsSlice.reducer;
