import { QuestionnaireSummaryPageParametersType } from "./QuestionnaireSummaryPage.types";

export const GETQUESTIONNAIRESUMMARY = "GETQUESTIONNAIRESUMMARY";
export const REASSESSINSPECTION = "REASSESSINSPECTION";

const questionnaireSummaryPageSagaActions = {
  getQuestionnaireSummary: (parameters: QuestionnaireSummaryPageParametersType) => {
    return {
      type: GETQUESTIONNAIRESUMMARY,
      payload: parameters,
    };
  },
  reassess: (parameters: QuestionnaireSummaryPageParametersType) => {
    return {
      type: REASSESSINSPECTION,
      payload: parameters,
    };
  }
};

export default questionnaireSummaryPageSagaActions;