import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TablePaginationProps,
  Pagination,
  TextField,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {
  DataGrid,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridFilterModel,
  GridLogicOperator,
  GridPagination,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  EnumGridDataShowRowCount,
  EnumSortTypes,
} from "../../../common/enums/common.enums";
import { getGridDataFromBackend } from "./HPRDataGrid.api";
import {
  CustomColumnMenuProps,
  HPRDataGridProps,
  HPRDataGridToolbarColumnProps,
} from "./HPRDataGrid.types";
import noData from "../../../images/no_data.svg";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { HPRDataGridActions } from "./HRPDataGrid.reducer";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HPRDataGridCustomToolBar from "./HPRDataGridCustomToolBar";

// ==============================|| HPR - DATAGRID ||============================== //

// column menu sort Item in ascending order
const CustomAscItem = (props: GridColumnMenuItemProps) => {
  const { colDef, icon, onClick, onChangeSortBy } = props;
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onChangeSortBy({
        Value: colDef.field.split("-")[1],
        Label: colDef.field.split("-")[0],
        SortType: EnumSortTypes.Ascending,
      });
      onClick(event);
    },
    [colDef.field]
  );
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>Sort by ASC</ListItemText>
    </MenuItem>
  );
};

// column menu sort Item in descending order
const CustomDescItem = (props: GridColumnMenuItemProps) => {
  const { colDef, icon, onClick, onChangeSortBy } = props;
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onChangeSortBy({
        Value: colDef.field.split("-")[1],
        Label: colDef.field.split("-")[0],
        SortType: EnumSortTypes.Descending,
      });
      onClick(event);
    },
    [colDef.field]
  );
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>Sort by DSC</ListItemText>
    </MenuItem>
  );
};

const getValue = (col: any) => {
  return col.value ?? "";
};

const columnMenuFilterItem = (props: GridColumnMenuItemProps) => {
  const { colDef, onChangeColumnFilter } = props;

  const handleFilterChange = (event, newValue) => {
    onChangeColumnFilter({ ...colDef, value: newValue });
  };

  return (
    <div>
      {/* <Grid container spacing={2} className="fx-align-center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}
      <div className="flex--container p-10">
        <div className="flex__item">
          <TextField
            name={`${colDef.headerName}`}
            value={getValue(props.colDef)}
            onChange={(event) => {
              handleFilterChange(null, event.target.value);
            }}
            label={`Search by ${colDef.headerName}`}
            type={"search"}
            InputLabelProps={{ shrink: true }}
            className={"hpr-textbox"}
            fullWidth
            autoComplete="off"
          ></TextField>
        </div>
      </div>
      {/* </Grid>
      </Grid> */}
    </div>
  );
};

// custom column menu
const CustomColumnMenu = (props: CustomColumnMenuProps) => {
  const {
    checkSortingArr,
    onChangeSortBy,
    onChangeColumnFilter,
    ...restProps
  } = props;

  return (
    <GridColumnMenu
      {...restProps}
      slots={{
        // columnMenuUserItem: CustomUserItem,
        columnMenuSortItem: null,
        columnMenuColumnsItem: null,
        columnMenuFilterItem: columnMenuFilterItem,
        columnCustomAscItem: checkSortingArr.includes(props.colDef.field)
          ? CustomAscItem
          : null,
        columnCustomDescItem: checkSortingArr.includes(props.colDef.field)
          ? CustomDescItem
          : null,
      }}
      slotProps={{
        columnCustomAscItem: {
          displayOrder: 15,
          icon: <ArrowUpwardIcon style={{ fontSize: "25px" }} />,
          onChangeSortBy: onChangeSortBy,
        },
        columnCustomDescItem: {
          displayOrder: 15,
          icon: <ArrowDownwardIcon style={{ fontSize: "25px" }} />,
          onChangeSortBy: onChangeSortBy,
        },
        columnMenuFilterItem: { onChangeColumnFilter: onChangeColumnFilter },
      }}
    />
  );
};

// No record found
const NoRowsOverlay = () => {
  return (
    <>
      {/* no data start */}
      <div className="no-data width100-p flex--container">
        <img className="" src={noData} style={{}}></img>
        <div className="gray-text body2 flex__item--vcenter nodata-text">
          No records found
        </div>
      </div>
    </>
  );
};

// Pagination
const PaginationCus = (props: TablePaginationProps) => {
  return (
    <MuiPagination
      color="primary"
      className={props.className}
      count={Math.ceil(props?.count / props?.rowsPerPage)}
      page={props?.page + 1}
      onChange={props.onPageChange}
    />
  );
};

const CustomPagination = (props: any) => {
  return (
    <GridPagination
      labelRowsPerPage={"Per page"}
      ActionsComponent={PaginationCus}
      {...props}
    />
  );
};

const HPRDataGrid = (props: HPRDataGridProps) => {
  // Initial state
  interface InitialState {
    Rows: Array<object>;
    TotalRecordsCount: number;
    SelectedPage: number;
    PageSize: number;
  }

  const initialState = {
    Rows: [] as Array<object>,
    TotalRecordsCount: 0,
    SelectedPage: 1,
    PageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as InitialState;

  const [gridRowData, setGridRowData] = useState<InitialState>(initialState); // create dataGrid state
  const [dynamicObject, setDynamicObject] = useState({}); // Initial empty object state

  const [columns, setColumns] = useState([]); // Initial state of Columns

  const [filterMode, setFilterMode] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  } as GridFilterModel); // filterMode

  const [sortMode, setSortMode] = useState<GridSortModel>([
    { field: "", sort: "asc" },
  ]); // filterMode

  const [checkSortingArr, setCheckSortingArr] = useState([]); // Initial state of check sorting array

  const pageSize = props.PageSize
    ? props.PageSize
    : EnumGridDataShowRowCount.GridDataShowRowCount;
  const totalRecordsCount = gridRowData.TotalRecordsCount;
  const totalPages = Math.ceil(totalRecordsCount / pageSize);

  // Reducer manage methods
  const hprDataGridState = useAppSelector((state) => state.hprDataGridReducer);
  const dispatch = useAppDispatch();

  const dataFetchedRef = useRef(false);

  // DataGrid initial load time
  useEffect(() => {
    dispatch(HPRDataGridActions.setColumns(props?.Columns));
    dispatch(
      HPRDataGridActions.changeColumnOptions({
        Columns: props?.Columns,
      } as HPRDataGridToolbarColumnProps)
    );
  }, []);

  // Datagrid colums intial loading
  useEffect(() => {
    props.Columns.forEach((col) => {
      setColumns((oldArray) => [...oldArray, col]);
      if (col.MenuTypes?.checkSorting) {
        setCheckSortingArr((oldArray) => [...oldArray, col.field]);
      }
    });
  }, []);

  useEffect(() => {
    // If data list not received
    if (props.Url && props.Url != "") {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getGridDataFromBackend(props.Url, {
        SelectedPage: gridRowData.SelectedPage,
        PageSize: props.PageSize
          ? props.PageSize
          : EnumGridDataShowRowCount.GridDataShowRowCount,
      }).then((response) => {
        setGridRowData((values) => {
          return {
            ...values,
            Rows: response.Data,
            TotalRecordsCount: response.TotalRows,
            SelectedPage: response.SelectedPage,
            PageSize: response.PageSize,
          };
        });
      });
    } else {
      // If data list received
      setGridRowData((values) => {
        return {
          ...values,
          Rows: props.Data ? props.Data : ([] as Array<object>),
          TotalRecordsCount: props.TotalRecordsCount
            ? props.TotalRecordsCount
            : 0,
          SelectedPage: props.SelectedPage ?? props.SelectedPage,
          PageSize:
            props.PageSize ?? EnumGridDataShowRowCount.GridDataShowRowCount,
        };
      });
    }
  }, [props]);

  useEffect(() => {
    hprDataGridState.Columns.forEach((column) => {
      setDynamicObject((prevObject) => ({
        ...prevObject,
        [column.field.toString()]: !column.hideColumn,
      }));
    });
  }, [hprDataGridState.Columns]);

  //Data Grid Row Clicked call back
  const onDataGridRowClicked = (event: GridRowParams<any>) => {
    if (props.onRowClickedCallBack) {
      props.onRowClickedCallBack(event.row);
    }
  };

  //Data Grid Pagination change call back
  const handleDataGridPaginationChange = (page: number, pageSize: number) => {
    if (props.Url && props.Url != "") {
      getGridDataFromBackend(props.Url ? `${props.Url}` : "", {
        SelectedPage: page,
        PageSize: pageSize,
      }).then((response) => {
        setGridRowData((values) => {
          return {
            ...values,
            Rows: response.Data,
            TotalRecordsCount: response.TotalRows,
            SelectedPage: page,
          };
        });
      });
    } else {
      setGridRowData((values) => {
        return {
          ...values,
          SelectedPage: page,
          PageSize: pageSize,
        };
      });
    }

    if (props.onPaginationChangeCallBack) {
      props.onPaginationChangeCallBack(page, pageSize);
    }
  };

  // create sortby function
  const onChangeSortBy = (newSortModel: GridSortModel) => {
    // if (props.MenuFunctions?.onChangeSortBy && gridRowData.Rows?.length > 0) {
    //   const model = {
    //     SortType:
    //       sortModel[0]?.sort === "desc"
    //         ? EnumSortTypes.Descending
    //         : EnumSortTypes.Ascending,
    //     Value: sortModel[0]?.field?.split("-")[1],
    //     Label: sortModel[0]?.field?.split("-")[0],
    //   } as SortDropDownDTO;

    //   props.MenuFunctions.onChangeSortBy(model);
    // }
    setSortMode(newSortModel);
  };

  const onChangeColumnFilter = (column: GridColDef & { value }) => {
    const updatedColumns = columns?.map((item) => {
      if (item.field === column.field) {
        setFilterMode((values) => {
          return {
            ...values,
            items: column.value
              ? [
                  {
                    id: column.field,
                    field: column.field,
                    operator: "contains",
                    value: column.value,
                  },
                ]
              : [],
            logicOperator: GridLogicOperator.And,
          };
        });

        return {
          ...item,
          value: column.value,
        };
      } else {
        return {
          ...item,
          value: "",
        };
      }
    });

    setColumns(updatedColumns);
  };

  return (
    <>
      <div
        style={{
          height: `${props.Height ? props.Height : 90}%`,
          width: `${props.Width ? props.Width : 100}%`,
        }}
      >
        <DataGrid
          className={`${
            !props.ShowCustomePagination ? "style-change-apply" : ""
          }`}
          autoHeight={props.AutoHeight}
          columns={columns}
          rows={gridRowData.Rows ? gridRowData.Rows : []}
          getRowId={(row: any) => row.Id}
          hideFooter={props.CustomFooter ? false : true}
          hideFooterSelectedRowCount
          rowCount={gridRowData.TotalRecordsCount}
          rowHeight={props.RowHeight ? props.RowHeight : 40}
          disableColumnMenu={!props?.ShowMenu}
          columnVisibilityModel={dynamicObject}
          initialState={{
            sorting: {
              sortModel: [{ field: "", sort: "asc" }],
            },
            pagination: {
              paginationModel: {
                pageSize:
                  gridRowData.PageSize ??
                  EnumGridDataShowRowCount.GridDataShowRowCount,
              },
            },
          }}
          filterModel={filterMode ?? undefined}
          sortModel={sortMode ?? []}
          sortingOrder={["asc", "desc"]}
          paginationMode="server"
          pageSizeOptions={[10, 15, 25, 50, 100]}
          slots={{
            columnMenu: CustomColumnMenu,
            noRowsOverlay: NoRowsOverlay,
            toolbar: HPRDataGridCustomToolBar,
            pagination: !props.ShowCustomePagination ? CustomPagination : null,
            footer: props.CustomFooter,
          }}
          slotProps={{
            toolbar: {
              Columns: columns,
              HPRDataGridToolbarProps: props.HPRDataGridToolbarProps,
              TotalRecordsCount: props.TotalRecordsCount,
              GridRowData: gridRowData,
            },
            columnMenu: {
              checkSortingArr: checkSortingArr,
              onChangeSortBy: onChangeSortBy,
              onChangeColumnFilter: onChangeColumnFilter,
            } as CustomColumnMenuProps,
            pagination: {
              page:
                gridRowData.SelectedPage <= 0
                  ? 0
                  : gridRowData.SelectedPage - 1,
              count: gridRowData.TotalRecordsCount,
              onPageChange(event, page) {
                handleDataGridPaginationChange(page, gridRowData.PageSize);
              },
            } as TablePaginationProps,
          }}
          onRowClick={onDataGridRowClicked}
          onSortModelChange={(model) => {
            onChangeSortBy(model);
          }}
          onPaginationModelChange={(model) => {
            handleDataGridPaginationChange(model.page, model.pageSize);
          }}
        />

        {props.onPaginationChangeCallBack &&
          props.ShowCustomePagination &&
          gridRowData.TotalRecordsCount >
            (props.PageSize
              ? props.PageSize
              : EnumGridDataShowRowCount.GridDataShowRowCount) && (
            <div className="fx-middle pagination-wrapper">
              <Stack spacing={3}>
                {gridRowData.Rows && gridRowData.Rows?.length > 0 ? (
                  <Pagination
                    count={totalPages}
                    showFirstButton
                    showLastButton
                    onChange={(event, newPage: number) => {
                      handleDataGridPaginationChange(newPage, props.PageSize);
                    }}
                    page={gridRowData.SelectedPage}
                  />
                ) : (
                  <></>
                )}
              </Stack>
            </div>
          )}
      </div>
    </>
  );
};

export default HPRDataGrid;
