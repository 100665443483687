import { QuestionnaireStartDTO } from "../../../common/types/common.dto.types";

export const GETSMES = "GETSMES";
export const STARTQUESTIONNAIRE = "STARTQUESTIONNAIRE";
export const GETQUESTIONNAIRSTARTDROPDOWNS = "GETQUESTIONNAIRSTARTDROPDOWNS";

export const startQuestionnaire = (
  questionnaireStart: QuestionnaireStartDTO
) => {
  return {
    type: STARTQUESTIONNAIRE,
    payload: questionnaireStart,
  };
};
export const getQuestionnaireStartDropdowns = (
  questionnaireStartDTO: QuestionnaireStartDTO
) => {
  return {
    type: GETQUESTIONNAIRSTARTDROPDOWNS,
    payload: questionnaireStartDTO,
  };
};

export const getSmes = (standardId: number) => {
  return {
    type: GETSMES,
    payload: standardId,
  };
};
