import { createSlice } from "@reduxjs/toolkit";

import {
  InspectionDTO,
  InspectionStandardDTO,
} from "../../../common/types/common.dto.types";
import {
  ViewInspectionSummaryProps,
  ViewInspectionSummaryType,
} from "./ViewInspectionSummary.types";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION SUMMARY - REDUCER ||============================== //
const initialState = {
  SerachInspectionStandardSummary: {
    Id: 0,
    InspectionId: 0,
    Inspection: {} as InspectionDTO,
  } as InspectionStandardDTO,
  InspectionSummary: {} as InspectionDTO,
  Open: false,
} as ViewInspectionSummaryType;

export const viewInspectionSummarySlice = createSlice({
  name: "viewInspectionSummary",
  initialState: initialState,
  reducers: {
    setSummaryOfInspection: (
      state: ViewInspectionSummaryType,
      action: { payload: ViewInspectionSummaryType; type: string }
    ) => {
      return {
        ...state,
        InspectionSummary: action.payload.InspectionSummary,
        Open: action.payload.Open,
      };
    },
    viewInspectionSummaryDetails: (
      state: ViewInspectionSummaryType,
      action: { payload: ViewInspectionSummaryProps; type: string }
    ) => {
      return {
        ...state,
        SerachInspectionStandardSummary: {
          ...state.SerachInspectionStandardSummary,
          Id: action.payload.SerachInspectionStandard?.Id,
          InspectionId: action.payload.SerachInspectionStandard?.InspectionId,
          Inspection: action.payload.SerachInspectionStandard?.Inspection,
        },
        InspectionSummaryType: action.payload?.InspectionSummaryType,
        onCloseBtnClick: action.payload?.onCloseBtnClick,
        onCompleteBtnClick: action.payload?.onCompleteBtnClick,
      };
    },
    resetViewInspectionSummary: () => {
      return initialState;
    },
  },
});

export const viewInspectionSummarySliceReducerActions =
  viewInspectionSummarySlice.actions;
export const viewInspectionSummaryReducer = viewInspectionSummarySlice.reducer;
