import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

export const GET_REMEDIATION_TRACKER_EXCEL_REPORT_DATA =
  "GET_REMEDIATION_TRACKER_EXCEL_REPORT_DATA";

const remediationTrackerTasksReportSagaActions = {
  getRemediationTrackerTasksSummary: (parameters: ReportViewerType) => {
    return {
      type: GET_REMEDIATION_TRACKER_EXCEL_REPORT_DATA,
      payload: parameters,
    };
  },
};

export default remediationTrackerTasksReportSagaActions;
