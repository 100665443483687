import { QuestionnaireStartDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { AxiosError } from "axios";
const client = new ApiClient();

export const getQuestionnaireStartDetails = async (
  questionnaireStartDTO: QuestionnaireStartDTO
): Promise<QuestionnaireStartDTO> => {
  try {
    const URL: string = `QuestionnaireStart/GetQuestionnaireStartDetails`;
    let result = {} as QuestionnaireStartDTO;
    await client
      .post<ActionResponseType>(URL, questionnaireStartDTO)
      .then((response: ActionResponseType) => {
        result = response.Data;
      })
      .catch((error) => {
        // console.log(error);
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const startQuestionnaire = async (
  questionnaireStart: QuestionnaireStartDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "QuestionnaireStart/StartQuestionnaire";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, questionnaireStart)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
