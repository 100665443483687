// project imports

import {
  InspectionDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { InspectionAllocationPageInitialState } from "./InspectionAllocationPage.types";

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getInspectionSetListForInspectionAllocation = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionAllocation/GetInspectionSetListForInspectionAllocation`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const getSiteInspectionAllocationList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionAllocation/GetSiteInspectionAllocationList`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const saveInspectionAllocation = async (
  Params: InspectionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionAllocation/SaveInspectionAllocation`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const updateInspectionAllocation = async (
  Params: InspectionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionAllocation/UpdateInspectionAllocation`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const deleteInspectionAllocation = async (
  inspectionId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionAllocation/DeleteInspectionAllocationmById/${inspectionId}`;
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, inspectionId)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as ActionResponseType;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};
