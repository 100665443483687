export interface FunctionRights {
  SystemFunctions: Array<SystemFunctionDTO>;
}

export interface SystemFunctionRights {
  FunctionRights: Array<FunctionRightDTO>;
}
export interface SystemFunctionDTO {
  Id: number;
  FunctionName: string;
  SystemScreen: SystemScreenDTO;
  Type: SystemFunctionTypeDTO;
  Description: string;
}

export interface FunctionRightDTO {
  FunctionId: number;
  FunctionName: string;
  ScreenId: number;
  ScreenName: string;
  Status: number;
  FunctionTypeId: number;
  FunctionTypeName: string;
}

export interface SystemFunctionTypeDTO {
  Id: number;
  TypeName: string;
}

export interface SystemScreenDTO {
  Id: number;
  ScreenName: string;
  Status: number;
}

export enum EnumSystemFunctions {
  None = 0,
  //RemTrackerTaskPage_Access = 1,
  RemTrackerTaskPage_View_Question = 1,
  RemTrackerTaskPage_EditTask = 2,
  RemTrackerTaskPage_SaveTask = 3,
  RemTrackerTaskPage_ChangeStatus = 4,
  RemTrackerTaskPage_DeleteLinked_Questions = 5,
  RemTrackerTaskPage_Submit_Review = 6,
  RemTrackerTaskPage_Submit_Closure = 7,
  RemTrackerTaskPage_Approval = 8,
  RemTrackerTaskPage_Reject = 9,
  RemTrackerTaskPage_Approval_For_Closure = 10,
  RemTrackerTaskPage_Reject_For_Closure = 11,
  RemTrackerTaskPage_Remove_Attachments = 12,
  //RemTrackerTasksPage_Access = 14,
  //RemTrackerTasksPage_View = 15,
  RemTrackerTasksPage_Create_ChildTask = 13,
  RemTrackerTasksPage_AddOrMergedTask = 14,
  RemTrackerTasksPage_Delete_childTask = 15,
  RemTrackerTasksPage_Export = 16,
  RemTrackerTasksPage_ColumnOptions = 17,
  RemTrackerTasksPage_ViewStatistics = 18,
  RemTrackerTasksPage_SaveUser_Preferences = 19,
  //SiteInspectionPage_Access = 23,
  //SiteInspectionPage_View = 24,
  SiteInspectionPage_Edit_TeamMembers = 20,
  SiteInspectionPage_CreateCopy = 21,
  SiteInspectionPage_Download_InspectionSummary = 22,
  SiteInspectionPage_StartStandard = 23,
  SiteInspectionPage_ResumeStandard = 24,
  SiteInspectionPage_CompleteStandard = 25,
  SiteInspectionPage_ReopenStandard = 26,
  //SiteInspectionPage_ViewStandard = 32,
  SiteInspectionPage_CloseInspection = 27,
  SiteInspectionPage_ReopenInspection = 28,
  SiteInspectionPage_Download_Standard_Summary = 29,
  SiteInspectionPage_New_Summary = 30,
  //QuestionnairePage_Access = 37,
  //QuestionnairePage_View = 38,
  QuestionnairePage_Edit_Intermediate = 31,
  QuestionnairePage_CompleteStandard = 32,
  QuestionnairePage_ClearAnswer = 33,
  QuestionnairePage_CreateCopy = 34,
  RemTrackerTaskPage_Edit_In_Pending_Review = 35,
  RemTrackerTaskPage_Edit_In_Pending_Closure_Approval = 36,
  //LocationPage_Access = 45,
  //StandardsPage_Access = 46,
  //InspectionSetTemplatesPage_Access = 47,
  //InspectionAssignmentsPage_Access = 48,
  SiteInspectionPage_ViewStatistics = 37,
  RemTrackerTaskPage_Approval_For_SME_Review = 38,
  RemTrackerTaskPage_Approval_For_Site_Review = 39,
  RemTrackerTaskPage_Reject_For_SME_Review = 40,
  RemTrackerTaskPage_Reject_For_Site_Review = 41,
  UserRoleManagementPage_View_User_Role = 42,
  UserRoleManagementPage_Edit_User_Role = 43,
  UserRoleManagementPage_View_User_Group = 44,
  UserRoleManagementPage_Add_User_Group = 45,
  UserRoleManagementPage_Edit_User_Group = 46,
  UserRoleManagementPage_Deactivate_User_Group = 47,
  RemTrackerTaskPage_CancelTask = 48,
  RemTrackerTasksPage_Export_TasksPendingApprovers = 49,
  RemTrackerTasksPage_Export_BasicStatistic = 50,
  UserProfilePage_View_Terms_Of_Use = 51,
  AssessmentTypeWizardPage_Add_Assessment_Type = 52,
  AssessmentTypeWizardPage_Edit_Assessment_Type = 53,
  AssessmentTypeWizardPage_View_Assessment_Type = 54,
  AssessmentTypeWizardPage_Deactivate_Assessment_Type = 55,
  AssessmentTypeWizardPage_Reactivate_Assessment_Type = 56,
  RemTrackerTaskPage_CloseTask = 57,
}
export enum EnumSystemFunctionTypes {
  None = 0,
  AccessPage = 1,
  ViewPage = 2,
  ButtonAccess = 3,
}
