import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
} from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { channel } from "redux-saga";
import { EnumPageMode } from "../../../common/enums/common.enums";
import { QuestionDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  CHECKQUESTIONBEFOREDEACTIVATE,
  deactivateQuestion,
  GETLANGUAGELIST,
  GETNAVIGATIONQUESTION,
  GETQUESTIONBYID,
  QUESTIONPAGEDEACTIVATEQUESTION,
  QUESTIONPAGEDECHECKAVAILABLEINSPECTIONSETSBEFOREUPDATEQUESTION,
  QUESTIONPAGEGETQUESTIONINITIALDATA,
  saveQuestion,
  SAVEQUESTION,
} from "./QuestionPage.actions";
import {
  CheckBeforeUpdateOrDeactivateQuestion,
  deactivateQuestionApi,
  getLanguageList,
  getNavigateQuestion,
  getQuestionById,
  getQuestionPageInitialData,
  SaveQuestion,
} from "./QuestionPage.api";
import {
  changePageMode,
  setInitialDataLoad,
  setLanguageId,
  setLanguageList,
  setQuestion,
  setQuestionPageInitialData,
  setQuestionStatus,
} from "./QuestionPage.reducer";
import { QuestionPageType } from "./QuestionsPage.types";
import { PageModeType } from "../../../common/types/common.page.type";

// ==============================|| VIEWS - PAGES - QUESTION - SAGA ||============================== //

// Watcher for Deactivated channel
const deactivateQuestionChannel = channel();
export function* deactivateQuestionWithInspectionsChannelWatcher() {
  while (true) {
    const action = yield take(deactivateQuestionChannel);
    yield put(action);
  }
}

const getLanguageListWatcher = function* (): any {
  yield takeEvery(GETLANGUAGELIST, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    try {
      const result: ActionResponseType = yield call(() => getLanguageList());
      if (result.IsSuccess) {
        if (result.Data != null) {
          yield put(setLanguageList(result.Data));
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Something went worng",
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      } else {
      }
    } catch (error) {
      // set page loading off
      yield put(setLoading(false));
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

const getQuestionByIdWatcher = function* (): any {
  yield takeEvery(GETQUESTIONBYID, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));
    yield put(changePageMode(EnumPageMode.ViewMode));
    const state: QuestionPageType = yield select(
      (s: RootState) => s.questionReducer
    );

    // passed parameter
    const questionId: number = action.payload;
    try {
      const result: ActionResponseType = yield call(() =>
        getQuestionById(questionId)
      );
      if (result.IsSuccess) {
        if (result.Data != null) {
          const question: QuestionDTO = result.Data;

          // question.Sources = question.QuestionParameters.map(
          //   (parameter: QuestionParameterDTO, index: number) => {
          //     return {
          //       Id: index,
          //       Reference: parameter.StandardReference.Name,
          //       System: parameter.StandardSystem.Name,
          //       SubSystem: parameter.StandardSubSystem.Name,
          //       Subject: parameter.StandardSubect.Name,
          //       Section: parameter.StandardSection.Name,
          //     } as SourcesDTO;
          //   }
          // );

          // question.LanguageList = state.Question.LanguageList;
          // question.QuestionTypeList = state.Question.QuestionTypeList;
          // question.ReferanceList = state.Question.ReferanceList;
          // question.SystemList = state.Question.SystemList;
          // question.SubSystemList = state.Question.SubSystemList;
          // question.SubjectList = state.Question.SubjectList;
          // question.SectionList = state.Question.SectionList;
          question.Standard = state.Question.Standard;
          yield put(setQuestion(question));
          yield put(setLanguageId(1));
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Something went worng",
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      } else {
      }
    } catch (error) {
      // set page loading off
      yield put(setLoading(false));
    }

    // change page mode (change disable and button show hide)
    yield put(changePageMode(EnumPageMode.ViewMode));
    // set page loading off
    yield put(setLoading(false));
  });
};

const saveQuestionWatcher = function* (): any {
  yield takeEvery(SAVEQUESTION, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    const state: QuestionPageType = yield select(
      (s: RootState) => s.questionReducer
    );

    let question: QuestionDTO = action.payload;
    try {
      const result: ActionResponseType = yield call(() =>
        SaveQuestion(Object.assign({}, question))
      );

      if (result.IsSuccess) {
        question = result.Data;

        yield put(
          openPopup({
            Open: true,
            BodyText:
              result.InfoMessage != null
                ? result.InfoMessage
                : "Question saved successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        // set again saved data with new id's
        question.LanguageList = state.Question.LanguageList;
        question.QuestionTypeList = state.Question.QuestionTypeList;
        question.ReferanceList = state.Question.ReferanceList;
        question.SystemList = state.Question.SystemList;
        question.SubSystemList = state.Question.SubSystemList;
        question.SubjectList = state.Question.SubjectList;
        question.SectionList = state.Question.SectionList;
        question.Standard = state.Question.Standard;
        yield put(setQuestion(question));

        // change page mode (change disable and button show hide)
        yield put(changePageMode(EnumPageMode.ViewMode));
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText:
              result.ErrorMessage !== ""
                ? result.ErrorMessage
                : "Question not saved successfully",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving question",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

// const getStandardWatcher = function* (): any {
//   yield takeEvery(QUESTIONPAGEGETSTANDARD, function* (action: AnyAction): any {
//     const standardId: number = action.payload;

//     // get data from backend
//     try {
//       const result: ActionResponseType = yield call(() =>
//         getStandardById(standardId)
//       );

//       // Check result status
//       if (result.IsSuccess) {
//         // validate result
//         if (result === null) {
//         } else {
//           let standard: StandardDTO = result.Data;

//           yield put(setStandard(standard));
//         }
//       } else {
//       }
//     } catch (error) {}
//   });
// };

const getQuestionPageInitialDataWatcher = function* (): any {
  yield takeEvery(
    QUESTIONPAGEGETQUESTIONINITIALDATA,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      const question: QuestionDTO = action.payload;
      let standardId: number = question.StandardId;

      // get data from backend
      try {
        const result: ActionResponseType = yield call(() =>
          getQuestionPageInitialData(question)
        );

        // Check result status
        if (result.IsSuccess) {
          // validate result
          if (result === null) {
            //
          } else {
            let questionData: QuestionDTO = result.Data;
            questionData.StandardId = standardId;
            questionData.Id = question.Id;
            yield put(
              setQuestionPageInitialData({
                PageMode: { PageMode: EnumPageMode.ViewMode } as PageModeType,
                Question: questionData,
                SystemList: questionData.SystemList,
                SectionList: questionData.SectionList,
                SubjectList: questionData.SubjectList,
                ReferanceList: questionData.ReferanceList,
                SubSystemList: questionData.SubSystemList,
                LanguageList: questionData.LanguageList,
                QuestionTypeList: questionData.QuestionTypeList,
              } as QuestionPageType)
            );
          }
        } else {
          //
        }
      } catch (error) {
        // set page loading on
        yield put(setLoading(false));
      } finally {
        yield put(setInitialDataLoad(true));
      }
      if (question?.Id === 0) {
        // set page loading on
        yield put(setLoading(false));
        yield put(changePageMode(EnumPageMode.AddMode));
      }
    }
  );
};

const deactivateQuestionWatcher = function* (): any {
  yield takeEvery(
    QUESTIONPAGEDEACTIVATEQUESTION,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      let question: QuestionDTO = action.payload;
      try {
        const result: ActionResponseType = yield call(() =>
          deactivateQuestionApi(Object.assign({}, question))
        );

        if (result.IsSuccess) {
          question = result.Data;

          yield put(
            openPopup({
              Open: true,
              BodyText: "Question deactivated successfully",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );

          yield put(setQuestionStatus(question));

          // change page mode (change disable and button show hide)
          yield put(changePageMode(EnumPageMode.ViewMode));
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Question not deactivating successfully",
              HeaderText: "Warning",
              PopupType: result.PopupType,
              YesBtnClick: () => {},
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred deactivating question",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      yield put(setLoading(false));
    }
  );
};
const getNavigateQuestionWatcher = function* (): any {
  yield takeEvery(GETNAVIGATIONQUESTION, function* (action: AnyAction): any {
    yield put(setLoading(true));
    const result: ActionResponseType = yield call(() =>
      getNavigateQuestion(
        action.payload.Id,
        action.payload.StandardId,
        action.payload.IsPrev
      )
    );
    if (result.Data !== null) {
      // console.log(result.Data);
      yield put(setQuestion(result.Data));
      yield put(setLanguageId(1));
    }
    yield put(setLoading(false));
  });
};
const checkAvailableInspectionSetsBeforeUpdateQuestionWatcher =
  function* (): any {
    yield takeEvery(
      QUESTIONPAGEDECHECKAVAILABLEINSPECTIONSETSBEFOREUPDATEQUESTION,
      function* (action: AnyAction): any {
        // set page loading on
        yield put(setLoading(true));

        let question: QuestionDTO = action.payload;

        try {
          const result: ActionResponseType = yield call(() =>
            CheckBeforeUpdateOrDeactivateQuestion(Object.assign({}, question))
          );

          if (result.IsSuccess) {
            question = result.Data;

            if (result.ErrorMessage !== "" && result.ErrorMessage !== null) {
              yield put(
                openPopup({
                  Open: true,
                  BodyText:
                    result.ErrorMessage !== ""
                      ? result.ErrorMessage
                      : "Question not updated successfully",
                  HeaderText: "Warning",
                  PopupType: EnumPopupType.YesNoConfirmation,
                  YesBtnClick: () => {
                    saveQuestionChannel.put(saveQuestion(question));
                  },
                } as PopupProps)
              );
            } else {
              yield put(
                openPopup({
                  Open: true,
                  BodyText: "Question updated successfully",
                  HeaderText: "Success",
                  PopupType: EnumPopupType.SuccessPopup,
                } as PopupProps)
              );

              yield put(setQuestion(question));

              // change page mode (change disable and button show hide)
              yield put(changePageMode(EnumPageMode.ViewMode));
            }
          } else {
            if (result.ErrorMessage !== "" && result.ErrorMessage !== null) {
              yield put(
                openPopup({
                  Open: true,
                  BodyText: result.ErrorMessage,
                  HeaderText: "Warning",
                  PopupType: EnumPopupType.WarningPopup,
                } as PopupProps)
              );
            }
          }
        } catch (error) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred updating question",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
          yield put(setLoading(false));
        }
        // set page loading off
        yield put(setLoading(false));
      }
    );
  };

const checkQuestionBeforeDeactivateWatcher = function* (): any {
  yield takeEvery(
    CHECKQUESTIONBEFOREDEACTIVATE,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      let question: QuestionDTO = action.payload;

      // get data from backend
      try {
        let result: ActionResponseType = yield call(() =>
          CheckBeforeUpdateOrDeactivateQuestion(Object.assign({}, question))
        );
        question = result.Data;
        if (result.IsSuccess) {
          if (result.ErrorMessage !== "" && result.ErrorMessage !== null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.YesNoConfirmation,
                YesBtnClick() {
                  deactivateQuestionChannel.put(deactivateQuestion(question));
                },
              } as PopupProps)
            );
          } else {
            yield put(
              openPopup({
                Open: true,
                BodyText: "Question deactivated successfully",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
              } as PopupProps)
            );

            yield put(setQuestionStatus(question));

            // change page mode (change disable and button show hide)
            yield put(changePageMode(EnumPageMode.ViewMode));
          }
        } else {
          if (result.ErrorMessage !== "" && result.ErrorMessage !== null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred deactivating question",
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      yield put(setLoading(false));
    }
  );
};

// Channel for call Update question from callback function
const saveQuestionChannel = channel();
export function* saveQuestionChannelWatcher() {
  while (true) {
    const action = yield take(saveQuestionChannel);
    yield put(action);
  }
}

const questionSaga = function* () {
  yield all([
    saveQuestionWatcher(),
    getQuestionByIdWatcher(),
    getLanguageListWatcher(),
    // getStandardWatcher(),
    getQuestionPageInitialDataWatcher(),
    deactivateQuestionWatcher(),
    checkAvailableInspectionSetsBeforeUpdateQuestionWatcher(),
    saveQuestionChannelWatcher(),
    getNavigateQuestionWatcher(),
    checkQuestionBeforeDeactivateWatcher(),
    deactivateQuestionWithInspectionsChannelWatcher(),
  ]);
};
export default questionSaga;
