import {
  SearchParameterDTO,
  SystemGroupSaveDTO,
} from "../../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../../common/types/common.types";
import { ApiClient } from "../../../../core/api/apiClient.service";

const client = new ApiClient();
export const getAddUserRoleScreenData = async (
  param: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL = `UserRoleManagement/GetUserRoleManagementScreenData`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, param)
      .then((res) => {
        result = res;
      })
      .catch((error) => {
        return error;
      });
    //console.log(result);
    return result;
  } catch (error) {
    //console.log(error);
    throw error;
  }
};

export const saveUserRoleScreenData = async (
  param: SystemGroupSaveDTO
): Promise<ActionResponseType> => {
  try {
    const URL = `UserRoleManagement/SaveUserRoleManagementScreenData`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, param)
      .then((res) => {
        result = res;
      })
      .catch((error) => {
        return error;
      });
    //console.log(result);
    return result;
  } catch (error) {
    //console.log(error);
    throw error;
  }
};

export const getSystemGroupFunctionsAndScreensById = async (
  groupId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetSystemGroupScreensAndFunctionsById?groupId=${groupId}`;
    let data = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, groupId)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        //console.log(error);
        data = {
          Data: {},
          ErrorMessage:
            "Sorry, an unexpected error occured. Please try again later.",
        } as ActionResponseType;
      });
    return data;
  } catch {
    return {
      Data: {},
      ErrorMessage:
        "Sorry, an unexpected error occured. Please try again later.",
    } as ActionResponseType;
  }
};

export const getSystemGroupById = async (
  groupId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `UserRoleManagement/GetSystemGroupById?groupId=${groupId}`;
    let data = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, groupId)
      .then((response) => {
        data = response;
      })
      .catch((error) => {
        //console.log(error);
        data = {
          Data: {},
          ErrorMessage:
            "Sorry, an unexpected error occured. Please try again later.",
        } as ActionResponseType;
      });
    return data;
  } catch {
    return {
      Data: {},
      ErrorMessage:
        "Sorry, an unexpected error occured. Please try again later.",
    } as ActionResponseType;
  }
};
