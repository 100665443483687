import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { SourcesPageInitialState } from "./SourcesPage.types";

// ==============================|| VIEWS - PAGES - SOURCES - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getSourceList = async (
  Params: SearchParameterDTO
): Promise<SourcesPageInitialState> => {
  try {
    const URL: string = `Source/GetSourceList`;
    let data = {} as SourcesPageInitialState;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as SourcesPageInitialState;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};
