
export const SAVEPRIVACYNOTICEEMPLOYEE = "SAVEPRIVACYNOTICEEMPLOYEE";



export const savePrivacyNoticeEmployeeAction = () => {
  return {
    type: SAVEPRIVACYNOTICEEMPLOYEE,
  };
};
