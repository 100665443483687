import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  TablePaginationProps,
} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {
  AddCircle,
  ArrowLeft2,
  ClipboardClose,
  ClipboardTick,
  Edit,
  Eye,
  People,
  SearchNormal1,
} from "iconsax-react";
import dayjs from "dayjs";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import {
  UserRoleManagementPageParametersType,
  UserRoleManagementPaginationDTO,
  UserRoleManagementType,
} from "./UserRoleManagement.types";
import {
  EnumButtonModeType,
  EnumListShowRowCountList,
  EnumPageMode,
  EnumPageType,
  EnumSortTypes,
  EnumStatus,
  EnumUserRoleManagementSortType,
} from "../../../common/enums/common.enums";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
  SystemGroupDTO,
} from "../../../common/types/common.dto.types";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useState, useEffect, useRef, ChangeEvent, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  DataGrid,
  GridColDef,
  GridPagination,
  GridPaginationModel,
  GridSortModel,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  activateSystemGroup,
  deActivateSystemGroup,
  getUserRoleManagementList,
} from "./UserRoleManagement.actions";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { setPageMode } from "./UserRoleManagementScreen/UserRoleManagementScreens.reducer";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import noData from "../../../images/no_data.svg";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../core/reducers/functionRights.types";

const initialState: UserRoleManagementType = {
  IsDisable: false,
  IsLoading: false,
  GroupName: "",
  Id: 0,
  Data: {} as PaginationDTO,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListPaginationType,
  PageMode: {
    PageMode: EnumPageMode.ViewMode,
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  PagedData: {
    Data: [] as SystemGroupDTO[],
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    SelectedPage: 1,
    TotalRecordsCount: 0,
  } as UserRoleManagementPaginationDTO,
  UserRoleManagementSearchParameter: {
    SystemGroupSearchParams: {
      GroupName: "",
      ShowDeactivated: false,
    } as SystemGroupDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    SortByNavigation: {
      Label: "Group Name",
      Value: EnumUserRoleManagementSortType.Groupname.toString(),
      SortType: EnumSortTypes.Ascending,
    } as SortDropDownDTO,
    BasicSearchValue: "",
    Field: "",
    Sort: "",
  } as SearchParameterDTO,
  SystemGroup: {
    Id: 0,
    GroupName: "",
    Status: EnumStatus.Published,
    ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
  } as SystemGroupDTO,
  SystemGroupModalOpen: false,
  Validator: new SimpleReactValidator(),
  ButtonModeType: EnumButtonModeType.Save,
  ShowErrorMessages: false,
  ExportPressed: false,
};

const UserRoleManagement = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  const [userRoleManagementPageState, setUserRoleManagementPageState] =
    useState(initialState);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [queryOptions, setQueryOptions] = useState({
    field: "",
    sort: "",
  });
  const [iconClick, setIconClick] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authState = useAppSelector((state) => state.authReducer);
  const userRoleManagementState = useAppSelector(
    (state) => state.userRoleManagementReducer
  );

  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  const location = useLocation();
  const parameter: UserRoleManagementPageParametersType = location.state;

  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  //   if (parameter != null) {
  //     dispatch(
  //       setUserRoleManagementSearchParameter({
  //         ...initialState.UserRoleManagementSearchParameter,
  //         SystemGroupSearchParams: {
  //           ...initialState.UserRoleManagementSearchParameter
  //             .SystemGroupSearchParams,
  //         },
  //       })
  //     );
  //     dispatch(
  //       getUserRoleManagementList({
  //         ...initialState.UserRoleManagementSearchParameter,
  //         SystemGroupSearchParams: {
  //           ...initialState.UserRoleManagementSearchParameter
  //             .SystemGroupSearchParams,
  //         },
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       setUserRoleManagementSearchParameter(
  //         initialState.UserRoleManagementSearchParameter
  //       )
  //     );
  //     dispatch(
  //       getUserRoleManagementList(
  //         initialState.UserRoleManagementSearchParameter
  //       )
  //     );
  //   }
  // }, []);

  useEffect(() => {
    dispatch(
      getUserRoleManagementList(
        userRoleManagementPageState.UserRoleManagementSearchParameter
      )
    );
  }, [
    userRoleManagementPageState.UserRoleManagementSearchParameter
      .SystemGroupSearchParams.GroupName,
    userRoleManagementPageState.UserRoleManagementSearchParameter
      .SystemGroupSearchParams.ShowDeactivated,
    userRoleManagementPageState.UserRoleManagementSearchParameter.SelectedPage,
    userRoleManagementPageState.UserRoleManagementSearchParameter.Sort,
    userRoleManagementPageState.UserRoleManagementSearchParameter.SortParams,
    userRoleManagementPageState.UserRoleManagementSearchParameter.ListPageSize,
    dispatch,
    iconClick,
  ]);

  useEffect(() => {
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        ListPagination: userRoleManagementState.ListPagination,
      };
    });
  }, [userRoleManagementState.ListPagination]);

  useEffect(() => {
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          SelectedPage: paginationModel.page + 1,
          ListPageSize: paginationModel.pageSize,
        },
      };
    });
  }, [iconClick, paginationModel, queryOptions]);

  //setting search term to the state
  useEffect(() => {
    if (searchTerm === "") {
      setIconClick(searchTerm);
      setUserRoleManagementPageState((prevState) => {
        return {
          ...prevState,
          UserRoleManagementSearchParameter: {
            ...prevState.UserRoleManagementSearchParameter,
            SystemGroupSearchParams: {
              ...prevState.UserRoleManagementSearchParameter
                .SystemGroupSearchParams,
              GroupName: searchTerm,
            },
          },
        };
      });
    }
  }, [searchTerm]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      setQueryOptions({
        field: sortModel[0]?.field,
        sort: sortModel[0]?.sort ?? "",
      });
    } else {
      setQueryOptions({
        field: sortModel[0]?.field,
        sort: "",
      });
    }
  }, []);

  //sorting effects
  useEffect(() => {
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          Field: queryOptions.field,
          Sort: queryOptions.sort,
        },
      };
    });
  }, [queryOptions]);

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.UserRoleManagement,
      functionId,
      functionTypeId
    );
  };

  //sorting effects
  // useEffect(() => {
  //   setUserRoleManagementPageState((prevState) => {
  //     return {
  //       ...prevState,
  //       userRoleManagementSearchParameter: {
  //         ...prevState.userRoleManagementSearchParameter,
  //         field: queryOptions.field,
  //         sort: queryOptions.sort,
  //       },
  //     };
  //   });
  // }, [queryOptions]);

  const AddOrEditOrViewSite = (group: SystemGroupDTO) => {
    navigate("/userRoleManagementScreen", {
      state: {
        Id: group.Id,
        GroupName: group.GroupName,
        ScopeId: group.ScopeId,
        Scope: group.Scope,
        AzureGroupName: group.AzureGroupName,
        GroupFunctions: group.GroupFunctions,
        GroupScreens: group.GroupScreens,
      } as SystemGroupDTO,
    });
  };

  const AddOrEditOrViewSystemGroupLocations = (group: any) => {
    if (group.ScopeId == 1) {
      navigate("/userRoleManagementLocations", {
        state: {
          Id: group.Id,
          GroupName: group.GroupName,
          ScopeId: group.ScopeId,
          Scope: group.Scope,
          AzureGroupName: group.AzureGroupName,
          ModifiedBy: group.ModifiedBy,
          ModifiedDate: group.ModifiedDate,
          GroupFunctions: group.GroupFunctions,
          GroupScreens: group.GroupScreens,
        } as any,
      });
    } else {
      navigate("/userRoleManagementLocations", {
        state: {
          Id: group.Id,
          GroupName: group.GroupName,
          ScopeId: group.ScopeId,
          Scope: group.Scope,
          AzureGroupName: group.AzureGroupName,
        } as any,
      });
    }
  };

  const columns: GridColDef[] = [
    // {
    //   field: "Id",
    //   headerName: "Id",
    //   flex: 3,
    //   hideColumn: true,
    //   hideable: false,
    // },
    {
      field: "ItemHeading",
      headerName: "Group Name",
      flex: 3,
      // width: 300,
      hideable: false,
      cellClassName: "table-cell status",
      disableColumnMenu: true,
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "app--header",
      flex: 3,
      // width: 100,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell status",
      renderCell: (params) => {
        if (params.value === "Published") {
          return (
            <div className="stat-color standards-status">
              <span style={{ display: "none" }}></span>
              <span className="status-color green">{params.value}</span>
            </div>
          );
        } else {
          return (
            <div className="stat-color standards-status">
              <span style={{ display: "none" }}></span>
              <span className="status-color gray">{params.value}</span>
            </div>
          );
        }
      },
      //maxWidth: 200
    },
    {
      field: "CreatedBy",
      headerName: "Created By",
      headerClassName: "app--header",
      flex: 3,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "CreatedDate",
      headerName: "Created Date",
      headerClassName: "app--header",
      flex: 3,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "ModifiedBy",
      headerName: "Modified By",
      headerClassName: "app--header",
      flex: 4,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "ModifiedDate",
      headerName: "Modified Date",
      headerClassName: "app--header",
      flex: 4,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      //maxWidth: 200
    },
    {
      field: "ScopeName",
      headerName: "Scope",
      headerClassName: "app--header",
      flex: 2,
      // minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: "table-cell",
      //maxWidth: 200
    },
    {
      field: "action",
      headerName: "Action", //"Action",
      headerClassName: "app--header",
      flex: 4,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="">
            <Tooltip title={"View"}>
              <IconButton
                className="ml-10"
                onClick={() => {
                  dispatch(setPageMode(EnumPageMode.ViewMode));
                  AddOrEditOrViewSite(params.row);
                }}
                style={{
                  visibility: checkSystemFunctionRight(
                    EnumSystemFunctions.UserRoleManagementPage_View_User_Role,
                    EnumSystemFunctionTypes.ButtonAccess
                  )
                    ? "visible"
                    : "hidden",
                }}
              >
                <Eye className="cursor-pointer" size="20" color="#292D32" />
              </IconButton>
            </Tooltip>

            {/*TODO:change the permision*/}
            {params?.row?.AzureGroupName !=
            "APP-EPD-HPR-PROD-Global-SuperAdmin" ? (
              <Tooltip title={"Groups"}>
                <IconButton
                  onClick={() => {
                    AddOrEditOrViewSystemGroupLocations(params.row);
                  }}
                  style={{
                    visibility: checkSystemFunctionRight(
                      EnumSystemFunctions.UserRoleManagementPage_View_User_Group,
                      EnumSystemFunctionTypes.ButtonAccess
                    )
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <People
                    className="cursor-pointer"
                    size="19"
                    color="#292D32"
                  />
                </IconButton>
              </Tooltip>
            ) : null}
            {params?.row?.AzureGroupName !=
            "APP-EPD-HPR-PROD-Global-SuperAdmin" ? (
              <Tooltip title={"Edit"}>
                <IconButton
                  onClick={() => {
                    dispatch(setPageMode(EnumPageMode.EditMode));
                    AddOrEditOrViewSite(params.row as SystemGroupDTO);
                  }}
                  style={{
                    visibility: checkSystemFunctionRight(
                      EnumSystemFunctions.UserRoleManagementPage_Edit_User_Role,
                      EnumSystemFunctionTypes.ButtonAccess
                    )
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <Edit className="cursor-pointer" size="19" color="#292D32" />
                </IconButton>
              </Tooltip>
            ) : null}
            {params?.row?.StatusID === EnumStatus.Deactivated &&
            params.row.AzureGroupName !=
              "APP-EPD-HPR-PROD-Global-SuperAdmin" ? (
              <Tooltip title={"Activate"}>
                <IconButton
                  className=""
                  onClick={() => {
                    dispatch(
                      openPopup({
                        Open: true,
                        BodyText: "Do you want to activate this System Group?",
                        HeaderText: "Confirmation",
                        PopupType: EnumPopupType.YesNoConfirmation,
                        YesBtnClick() {
                          const data = {
                            id: params.id,
                            userRoleManagementSearchParameter:
                              userRoleManagementPageState.UserRoleManagementSearchParameter,
                          };

                          dispatch(activateSystemGroup(data));
                        },
                      } as PopupProps)
                    );
                  }}
                  style={{
                    visibility: checkSystemFunctionRight(
                      EnumSystemFunctions.UserRoleManagementPage_Deactivate_User_Group,
                      EnumSystemFunctionTypes.ButtonAccess
                    )
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <ClipboardTick
                    className="cursor-pointer"
                    size="20"
                    color="#292D32"
                  />
                </IconButton>
              </Tooltip>
            ) : params?.row?.StatusID === EnumStatus.Published &&
              params.row.AzureGroupName !=
                "APP-EPD-HPR-PROD-Global-SuperAdmin" ? (
              <>
                <Tooltip title="Deactivate">
                  <IconButton
                    className="ml-3 cursor-pointer"
                    onClick={() => {
                      // confirm before deactivate

                      dispatch(
                        openPopup({
                          Open: true,
                          BodyText: "Do you wish to deactivate the User Group?",
                          HeaderText: "Confirmation",
                          PopupType: EnumPopupType.YesNoConfirmation,
                          YesBtnClick() {
                            const data = {
                              id: params.id,
                              userRoleManagementSearchParameter:
                                userRoleManagementPageState.UserRoleManagementSearchParameter,
                            };

                            dispatch(deActivateSystemGroup(data));
                          },
                        } as PopupProps)
                      );
                    }}
                    style={{
                      visibility: checkSystemFunctionRight(
                        EnumSystemFunctions.UserRoleManagementPage_Deactivate_User_Group,
                        EnumSystemFunctionTypes.ButtonAccess
                      )
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {" "}
                    <ClipboardClose size="20" color={"#292D32"} />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </div>
        );
      },
      disableColumnMenu: true,
      //maxWidth: 200
    },
  ];

  //-----on change serach TextBox-----
  const onsearchNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    // event.stopPropagation();
    // dispatch(
    //   setUserRoleManagementSearchParameter({
    //     ...userRoleManagementState.UserRoleManagementSearchParameter,
    //     SystemGroupSearchParams: {
    //       ...userRoleManagementState.UserRoleManagementSearchParameter
    //         .SystemGroupSearchParams,
    //       GroupName: event.target.value,
    //     },
    //     BasicSearchValue: event.target.value,
    //   })
    // );

    // if (!event.target.value) {
    //   dispatch(
    //     getUserRoleManagementList({
    //       ...userRoleManagementState.UserRoleManagementSearchParameter,
    //       SystemGroupSearchParams: {
    //         ...userRoleManagementState.UserRoleManagementSearchParameter
    //           .SystemGroupSearchParams,
    //         GroupName: event.target.value,
    //       },
    //       BasicSearchValue: event.target.value,
    //     })
    //   );
    // }

    // setUserRoleManagementPageState((prevState) => {
    //   return {
    //     ...prevState,
    //     UserRoleManagementSearchParameter: {
    //       ...prevState.UserRoleManagementSearchParameter,
    //       SystemGroupSearchParams: {
    //         ...prevState.UserRoleManagementSearchParameter
    //           .SystemGroupSearchParams,
    //         GroupName: event.target.value,
    //       },
    //       BasicSearchValue: event.target.value,
    //     },
    //   };
    // });
  };

  //-----enetr Key Press ===> serach TextBox-----
  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setIconClick(searchTerm);
      setUserRoleManagementPageState((prevState) => {
        return {
          ...prevState,
          UserRoleManagementSearchParameter: {
            ...prevState.UserRoleManagementSearchParameter,
            SystemGroupSearchParams: {
              ...prevState.UserRoleManagementSearchParameter
                .SystemGroupSearchParams,
              GroupName: searchTerm,
            },
          },
        };
      });
      // dispatch(
      //   setUserRoleManagementSearchParameter({
      //     ...userRoleManagementState.UserRoleManagementSearchParameter,
      //     IsAdvanceSearch: false,
      //     SelectedPage: 1,
      //   })
      // );
      // dispatch(
      //   getUserRoleManagementList({
      //     ...userRoleManagementState.UserRoleManagementSearchParameter,
      //     SelectedPage: 1,
      //     IsAdvanceSearch: false,
      //   })
      // );
    }
  };

  //-----Serach Buttion Click-----
  const serachBtnClick = (IsAdvanceSearch: boolean) => {
    // if (
    //   userRoleManagementState.UserRoleManagementSearchParameter
    //     .IsAdvanceSearch === IsAdvanceSearch
    // ) {
    //   dispatch(
    //     getUserRoleManagementList({
    //       ...userRoleManagementState.UserRoleManagementSearchParameter,
    //       SelectedPage: 1,
    //       IsAdvanceSearch: IsAdvanceSearch,
    //     })
    //   );
    // } else {
    //   PageChangeRef.current = true;
    //   dispatch(
    //     setUserRoleManagementSearchParameter({
    //       ...userRoleManagementState.UserRoleManagementSearchParameter,
    //       IsAdvanceSearch: IsAdvanceSearch,
    //       SelectedPage: 1,
    //     })
    //   );
    // }
    setIconClick(searchTerm);
    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          SystemGroupSearchParams: {
            ...prevState.UserRoleManagementSearchParameter
              .SystemGroupSearchParams,
            GroupName: searchTerm,
          },
        },
      };
    });
  };

  // const onPageChange = (page: number) => {
  //   //PageChangeRef.current = true;
  //   dispatch(
  //     setUserRoleManagementSearchParameter({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SystemGroupSearchParams: {
  //         ...userRoleManagementState.UserRoleManagementSearchParameter
  //           .SystemGroupSearchParams,
  //       },
  //       SortByNavigation: selectedSortByNavigation,
  //       SelectedPage: page,
  //       ListPageSize: userRoleManagementState.Data?.PageSize,
  //     })
  //   );

  //   dispatch(
  //     getUserRoleManagementList({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SelectedPage: page,
  //       ListPageSize: userRoleManagementState.Data?.PageSize,
  //     })
  //   );
  // };

  //-----call after selected page change(pagination) or is advanceSearch-----
  // useEffect(() => {
  //   if (!PageChangeRef.current) return;
  //   PageChangeRef.current = false;
  //   dispatch(
  //     getUserRoleManagementList({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SelectedPage: 1,
  //     })
  //   );
  // }, [userRoleManagementState.UserRoleManagementSearchParameter]);

  // const [selectedSortByNavigation, setSelectedSortByNavigation] = useState<SortDropDownDTO>({
  //   Label: "Name",
  //   Value: EnumUserRoleManagementSortType.Groupname.toString(),
  //   SortType: EnumSortTypes.Ascending,
  // } as SortDropDownDTO);

  // const onSortByNavigationCallBack = (selectedSortByNavigation: SortDropDownDTO) => {
  //   dispatch(
  //     setUserRoleManagementSearchParameter({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SystemGroupSearchParams: {
  //         ...userRoleManagementState.UserRoleManagementSearchParameter
  //           .SystemGroupSearchParams,
  //       },
  //       SortByNavigation: selectedSortByNavigation,
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  // function onChangePagination(Name: string, Id: string) {
  //   dispatch(
  //     setUserRoleManagementSearchParameter({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SystemGroupSearchParams: {
  //         ...userRoleManagementState.UserRoleManagementSearchParameter
  //           .SystemGroupSearchParams,
  //         Status:
  //           userRoleManagementState.UserRoleManagementSearchParameter
  //             .SystemGroupSearchParams?.Status,
  //       },
  //       SelectedPage: 1,
  //       ListPageSize: parseInt(Id),
  //     })
  //   );
  //   PageChangeRef.current = true;
  // }

  // const onChangeSortByNavigation = (SelectedSortState: SortDropDownDTO) => {
  //   dispatch(
  //     setUserRoleManagementSearchParameter({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SystemGroupSearchParams: {
  //         ...userRoleManagementState.UserRoleManagementSearchParameter
  //           .SystemGroupSearchParams,
  //       },
  //       SortByNavigation: SelectedSortState,
  //       SelectedPage: 1,
  //     })
  //   );
  //   setSelectedSortByNavigation(SelectedSortState);
  //   PageChangeRef.current = true;
  // };

  // const onChangePageSize = (Name: string, Id: string) => {
  //   dispatch(
  //     setUserRoleManagementSearchParameter({
  //       ...userRoleManagementState.UserRoleManagementSearchParameter,
  //       SystemGroupSearchParams: {
  //         ...userRoleManagementState.UserRoleManagementSearchParameter
  //           .SystemGroupSearchParams,
  //         Status:
  //           userRoleManagementState.UserRoleManagementSearchParameter
  //             .SystemGroupSearchParams?.Status,
  //       },
  //       SelectedPage: 1,
  //       ListPageSize: parseInt(Id),
  //     })
  //   );
  //   PageChangeRef.current = true;
  // };

  const onChangeShowDeactivated = (name: string, value: any) => {
    // dispatch(
    //   setUserRoleManagementSearchParameter({
    //     ...userRoleManagementState.UserRoleManagementSearchParameter,
    //     SystemGroupSearchParams: {
    //       ...userRoleManagementState.UserRoleManagementSearchParameter
    //         .SystemGroupSearchParams,
    //       ShowDeactivated: value,
    //     },
    //   })
    // );
    // PageChangeRef.current = true;

    setUserRoleManagementPageState((prevState) => {
      return {
        ...prevState,
        UserRoleManagementSearchParameter: {
          ...prevState.UserRoleManagementSearchParameter,
          SystemGroupSearchParams: {
            ...prevState.UserRoleManagementSearchParameter
              .SystemGroupSearchParams,
            ShowDeactivated: value,
          },
        },
      };
    });
  };

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={Math.ceil(
          userRoleManagementState?.ListPagination?.TotalRecordsCount /
            userRoleManagementState?.ListPagination?.ItemShowCount
        )}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPagination = (props: any) => {
    return (
      <GridPagination
        labelRowsPerPage="Per page"
        ActionsComponent={Pagination}
        {...props}
      />
    );
  };

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };
  const onAddBtnClick = () => {
    dispatch(setPageMode(EnumPageMode.AddMode));
    navigate("/userRoleManagementScreen");
  };

  const onCloseBtnClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="content-section-card top-main-padding">
        <Grid container>
          {/* Button bar */}
          <Grid container>
            <div className="flex--container width100-p top-main-border pl-10 pr-10">
              <div className="flex__item--inherit pr-10">
                {/* <Tooltip title="Back to home page" arrow> */}
                <Button
                  aria-label="back"
                  className="sub-head-back-btn back"
                  title="Back to home page"
                  onClick={() => navigate("/")}
                >
                  {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                  <ArrowBackIosNewOutlinedIcon className="" />
                  <span className="body-bold secondary-color">Back</span>
                </Button>
                {/* </Tooltip> */}
              </div>
              <div className="global-header-wrap">
                <h1>User Role Management</h1>
              </div>
              <div className="flex__item search-bar--small ml-10 mr-10">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    value={searchTerm}
                    onChange={onsearchNameChange}
                    onKeyPress={onsearchNameKeyPress}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={"Search By Group Name"}
                    inputProps={{ "aria-label": "Locations" }}
                    type="search"
                  />
                  <IconButton
                    type="button"
                    className="m-0"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      serachBtnClick(false);
                    }}
                  >
                    <SearchNormal1
                      variant="Outline"
                      className="ico-secondary"
                      size={18}
                    />
                  </IconButton>
                </Paper>
              </div>
              {/* {checkSystemFunctionRight(
                              EnumSystemFunctions.UserRoleManagementPage_Add_User_Group,
                              EnumSystemFunctionTypes.ButtonAccess
                          ) && ( */}
              {/* <Button
                className="secondary-btn-small"
                size="small"
                variant="outlined"
                startIcon={<CloseCircle variant="Outline" />}
                onClick={onCloseBtnClick}
              >
                Close
              </Button> */}
              {checkSystemFunctionRight(
                EnumSystemFunctions.UserRoleManagementPage_Add_User_Group,
                EnumSystemFunctionTypes.ButtonAccess
              ) && (
                <Button
                  className="primary-btn-small"
                  size="small"
                  variant="outlined"
                  startIcon={<AddCircle variant="Outline" />}
                  onClick={onAddBtnClick}
                >
                  Add
                </Button>
              )}
              {/* )} */}
            </div>
          </Grid>
          <Grid container>
            <Grid container className="mb-15">
              <Grid item xs={12} className="fx">
                <Grid item xs={7} lg={9} md={9} xl={9}>
                  {/* <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <InputBase
                      value={searchTerm}
                      onChange={onsearchNameChange}
                      onKeyPress={onsearchNameKeyPress}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder={"Search By Group name"}
                      inputProps={{ "aria-label": "Locations" }}
                      type="search"
                    />
                    <IconButton
                      type="button"
                      className="m-0"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={() => {
                        serachBtnClick(false);
                      }}
                    >
                      <SearchNormal1
                        variant="Outline"
                        className="ico-secondary"
                        size={18}
                      />
                    </IconButton>
                  </Paper> */}
                </Grid>
                {/* Serarch End*/}
                <Grid item xs={5} lg={3} md={3} xl={3} className="">
                  <h1 style={{ display: "inline-block", width: "100%" }}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="fx-right mb-10"
                    >
                      <FormGroup className="">
                        <HPRCheckBox
                          Id={""}
                          Name={"Show Deactivated"}
                          Label={"Show Deactivated"}
                          ClassName={""}
                          checked={
                            userRoleManagementPageState
                              ?.UserRoleManagementSearchParameter
                              ?.SystemGroupSearchParams.ShowDeactivated
                          }
                          onCheckBoxChange={onChangeShowDeactivated}
                        />
                      </FormGroup>
                    </Grid>
                  </h1>
                </Grid>
              </Grid>
              {/* <HPRDataGrid
                  Columns={columns}
                  Data={userRoleManagementState.ListPagination.ItemList}
                  TotalRecordsCount={
                    userRoleManagementState.ListPagination.TotalRecordsCount
                  }
                  AutoHeight={false}
                  onPaginationChangeCallBack={onPageChange}
                  SelectedPage={
                    userRoleManagementState.ListPagination.SelectedPage
                  }
                  HPRDataGridToolbarProps={
                    {
                      ShowTotalRecordsCount: true,
                    } as HPRDataGridToolbarProps
                  }
                  ShowMenu={true}
                  // MenuFunctions={{
                  //   onChangeSortByNavigation: onChangeSortByNavigation,
                  // }}

                  PageSize={
                    userRoleManagementState.ListPagination.ItemShowCount ??
                    EnumGridDataShowRowCount.GridDataShowRowCount
                  }
                  MenuFunctions={{
                    onChangeSortByNavigation: onSortByNavigationCallBack,
                  }}
                  ShowCustomePagination
                ></HPRDataGrid> */}
              <div
                className="location-page-items-wrap content-section-card-inn pl-10 pr-10"
                style={{
                  width: "100%",
                  overflowX: "hidden",
                  height: "65vh",
                }}
              >
                <DataGrid
                  className="datagrid-f"
                  getRowId={(row: any) => row.Id}
                  style={{
                    // height: "auto",
                    width: "99.6%",
                    overflowY: "hidden",
                    maxHeight: "571px",
                    borderWidth: "0px !important",
                  }}
                  pagination
                  slots={{
                    pagination: CustomPagination,
                    noRowsOverlay: () => (
                      // <Stack
                      //   // height="100%"
                      //   alignItems="center"
                      //   justifyContent="center"
                      // >
                      //   {t("noRecordsFound") /*No record(s) found */}
                      // </Stack>
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          {"No record(s) found"}
                        </div>
                      </div>
                    ),
                    noResultsOverlay: () => (
                      // <Stack
                      //   // height="100%"
                      //   alignItems="center"
                      //   justifyContent="center"
                      // >
                      //   {t("noRecordsFound") /*No record(s) found */}
                      // </Stack>
                      <div className="no-data width100-p flex--container">
                        <img className="" src={noData} style={{}}></img>
                        <div className="gray-text body2 flex__item--vcenter nodata-text">
                          {"No record(s) found"}
                        </div>
                      </div>
                    ),
                  }}
                  autoHeight={false}
                  getRowHeight={() => "auto"}
                  disableColumnFilter
                  sx={{
                    "& .app--header": {
                      color: "#000000",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "#c6e4ee !important;",
                    },
                    "& .MuiDataGrid-cell:hover ": {
                      backgroundColor: "transparent",
                    },
                  }}
                  paginationMode="server"
                  disableColumnSelector={true}
                  sortingOrder={["asc", "desc"]}
                  // sortingMode="server"
                  rows={userRoleManagementPageState.ListPagination.ItemList}
                  columns={columns}
                  rowCount={
                    userRoleManagementPageState?.ListPagination
                      ?.TotalRecordsCount
                  }
                  paginationModel={paginationModel}
                  pageSizeOptions={[10, 15, 25, 50, 100]}
                  onPaginationModelChange={handlePaginationModelChange}
                  hideFooterSelectedRowCount={true}
                  onSortModelChange={handleSortModelChange}
                  // initialState={{
                  //   sorting: {
                  //     sortModel: [{ field: "ItemHeading", sort: "asc" }],
                  //   },
                  // }}
                  // onRowClick={(params, event) => {
                  //   handleConfigPopupOpen();
                  // }}
                ></DataGrid>

                {/* Data Row End */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default UserRoleManagement;
