import SimpleReactValidator from "simple-react-validator";

import { UserRoleManagementType } from "../UserRoleManagement.types";
import {
  SearchParameterDTO,
  SystemGroupAzureGroupSiteDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";
import { PageModeType } from "../../../../common/types/common.page.type";
import { EnumButtonModeType } from "../../../../common/enums/common.enums";

export interface UserRoleManagementLocationsPaginationDTO {
  Data: SystemGroupAzureGroupSiteDTO[] | null;
  PageSize: number;
  SelectedPage: number;
  TotalRecordsCount: number;
}
export interface UserRoleManagementLocationsType {
  IsDisable: boolean;
  IsLoading: boolean;
  GroupName: string;
  Id: number;
  PageMode: PageModeType;
  PagedData: UserRoleManagementLocationsPaginationDTO;
  UserRoleManagementSearchParameter: SearchParameterDTO;
  SystemGroup: SystemGroupDTO;
  SystemGroupModalOpen: boolean;
  Validator: SimpleReactValidator;
  ButtonModeType: EnumButtonModeType;
  ShowErrorMessages: boolean;
  ExportPressed: boolean;
}

export interface UserRoleManagementLocationDataRowType {
  Id: number;
  AzureGroupSiteId: number;
  SiteId: number;
  SystemGroupId: number | undefined;
  SiteName: string | undefined;
  AzureGroupName: string | undefined;
  ModifiedBy: string;
  ModifiedDate: string;
}

export interface UserRoleManagementScopeDataRowType {
  Id: number;
  AzureGroupSiteId: number;
  SystemGroupId: number | undefined;
  RoleName: string | undefined;
  AzureGroupName: string | undefined;
  ModifiedBy: string;
  ModifiedDate: string;
}

export interface SaveAzureGroupSiteListDTO {
  AzureGroupSiteList: SystemGroupAzureGroupSiteDTO[];
  GroupId: number;
  SearchParams: SearchParameterDTO;
}

export enum EnumScopeUserRole {
  Globle = 1,
}
