import { createSlice } from "@reduxjs/toolkit";

import {
  InspectionQuestionChoiceDTO,
  InspectionQuestionDTO,
} from "../../../common/types/common.dto.types";
import {
  ViewInspectionQuestionProps,
  ViewInspectionQuestionType,
} from "./ViewInspectionQuestion.types";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION QUESTION - REDUCER ||============================== //
const initialState = {
  InspectionQuestion: {} as InspectionQuestionDTO,
  YesNoAnswer: "",
  Open: false,
  SerachInspectionQuestion: { Id: 0 } as InspectionQuestionDTO,
  onCloseBtnClick() {},
} as ViewInspectionQuestionType;

export const viewInspectionQuestionSlice = createSlice({
  name: "viewInspectionQuestion",
  initialState: initialState,
  reducers: {
    setDataFetched: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionProps; type: string }
    ) => {
      return {
        ...state,
        InspectionQuestion: {
          ...state.InspectionQuestion,
          Id: action.payload.InspectionQuestionId,
        },
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    setViewInspectionQuestion: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionType; type: string }
    ) => {
      let notApplicableAnswer: boolean = action?.payload?.InspectionQuestion
        ? action?.payload?.InspectionQuestion?.NotApplicableAnswer
        : false;

      let yesnoAnswer =
        action?.payload?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );

      let answerVal = "";
      if (
        yesnoAnswer != undefined &&
        yesnoAnswer != null &&
        yesnoAnswer?.length > 0
      ) {
        if (
          yesnoAnswer[0].ChoiceText != null &&
          yesnoAnswer[0].ChoiceText.DefaultText == "Yes"
        ) {
          answerVal = "1";
        } else {
          answerVal = "0";
        }
      } else if (notApplicableAnswer !== null && notApplicableAnswer) {
        answerVal = "-1";
      }

      return {
        ...state,
        InspectionQuestion: action.payload.InspectionQuestion,
        YesNoAnswer: answerVal,
        Open: true,
      };
    },
    viewInspectionQuestionDetails: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionProps; type: string }
    ) => {
      return {
        ...state,
        SerachInspectionQuestion: {
          ...state.InspectionQuestion,
          Id: action.payload.InspectionQuestionId,
        },
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    resetViewInspectionQuestion: () => {
      return initialState;
    },
  },
});

export const viewInspectionQuestionSliceReducerActions =
  viewInspectionQuestionSlice.actions;
export const viewInspectionQuestionReducer =
  viewInspectionQuestionSlice.reducer;
