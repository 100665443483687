import SimpleReactValidator from "simple-react-validator";
import { EnumPageMode } from "../../../common/enums/common.enums";
import {
  AssessmentTypeDTO,
  AssessmentTypeRuleDTO,
  InspectionSetDTO,
  StatusDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { createSlice } from "@reduxjs/toolkit";
import { AssessmentTypeWizardPageType } from "./AssessmentTypeWizardPage.types";

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    IsShowDeactivateBtn: false,
  } as PageModeType,
  AssessmentType: {
    Id: 0,
    AssessmentTypeName: "",
    Description: "",
    Code: "",
    AssessmentTypeRules: [] as Array<AssessmentTypeRuleDTO>,
    StatusNavigation: { Status1: "Draft" } as StatusDTO,
    Version: 0.1,
    GroupId: "",
  } as AssessmentTypeDTO,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
} as AssessmentTypeWizardPageType;

export const assessmentTypeWizardSlice = createSlice({
  name: "assessmentTypeWizard",
  initialState: initialState,
  reducers: {
    setAssessmentTypeWizard: (
      state: AssessmentTypeWizardPageType,
      action: {
        payload: AssessmentTypeDTO;
        type: string;
      }
    ) => {
      return {
        ...state,
        IsDisable: true,
        AssessmentType: action.payload,
      };
    },
    changePageMode: (
      state: AssessmentTypeWizardPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (action.payload === EnumPageMode.ViewMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowSaveBtn: false,
            IsShowCloseBtn: true,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowEditBtn: true,
            IsShowPublishedBtn: false,
            IsShowDeactivateBtn: false,
          },
        };
      } else if (action.payload === EnumPageMode.AddMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: false,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowEditBtn: false,
            IsShowPublishedBtn: true,
            IsShowDeactivateBtn: false,
          },
          AssessmentType: initialState.AssessmentType,
        };
      } else if (action.payload === EnumPageMode.EditMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowSaveBtn: false,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowEditBtn: false,
            IsShowPublishedBtn: true,
            IsShowDeactivateBtn: true,
          },
        };
      }
    },

    resetAssessmentTypeWizard: (state: AssessmentTypeWizardPageType) => {
      return {
        ...state,
        IsDisable: true,
        AssessmentType: initialState.AssessmentType,
      };
    },
  },
});

export const assessmentTypeWizardReducer = assessmentTypeWizardSlice.reducer;

export const assessmentTypeWizardReducerActions =
  assessmentTypeWizardSlice.actions;
