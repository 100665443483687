import { createSlice } from "@reduxjs/toolkit";
// project imports
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import {
  DropDownDTO,
  EmailEventCategoryDTO,
  EmailRecipientDTO,
  LocationStandardDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";
import { LocationPageType } from "./LocationPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import { act } from "react-dom/test-utils";

// ==============================|| VIEWS - PAGES - LOCATION PAGE - REDUCER ||============================== //

// set initial state values for StandardPageType
const initialState = {
  Site: {
    Id: 0,
    SiteName: "",
    SiteCode: "",
    SelectedRegionId: 0,
    SelectedCountryId: 0,
    SelectedDirectorId: "0",
    SelectedQADirectorId: "0",
    SelectedEHSDirectorId: "0",
    SelectedEngineeringDirectorId: "0",
    SelectedQCManagementId: "0",
    SelectedMainteneceManagerId: "0",
    Status: EnumStatus.Draft,
    StatusName: EnumStatus[EnumStatus.Draft],
    Version: 0.1,
    LocationStandards: [] as Array<LocationStandardDTO>,
    EmailEventCategories: [] as Array<EmailEventCategoryDTO>,
    SiteEmployeeDropDownList: [] as Array<DropDownDTO>,
    SelectedEmployeeRecipientList: [] as Array<string>,
    OriginalEmailEventCategories: [] as Array<EmailEventCategoryDTO>,
    CheckAll: false,
    CheckRemediation: false,
    TabIsDisable: false,
    IsFieldRequired: false,
  } as SiteDTO,
  IsLoading: false,
  PageMode: {
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    PageMode: EnumPageMode.Default,
  } as PageModeType,

  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  EventSearchValue: "",
  UserSearchValue: "",
} as LocationPageType;

export const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    setReadOnlyMode: (
      state: LocationPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsDisable: action.payload };
    },
    setDropdownList: (
      state: LocationPageType,
      action: { payload: SiteDTO; type: string }
    ) => {
      return {
        ...state,
        Site: {
          ...state.Site,
          Id: action.payload.Id,
          SiteName: action.payload.SiteName ? action.payload.SiteName : "",
          SiteCode: action.payload.SiteCode ? action.payload.SiteCode : "",
          SelectedRegionId: action.payload.SelectedRegionId,
          SelectedCountryId: action.payload.SelectedCountryId,
          SelectedDirectorId: action.payload.SelectedDirectorId,
          SelectedQADirectorId: action.payload.SelectedQADirectorId,
          SelectedEHSDirectorId: action.payload.SelectedEHSDirectorId,
          SelectedEngineeringDirectorId:
            action.payload.SelectedEngineeringDirectorId,
          SelectedQCManagementId: action.payload.SelectedQCManagementId,
          SelectedMainteneceManagerId:
            action.payload.SelectedMainteneceManagerId,
          Status: action.payload.Status,
          StatusName: action.payload.StatusName
            ? action.payload.StatusName
            : "",
          Version: action.payload.Version,
          RegionList: action.payload.RegionList,
          CountryList: action.payload.CountryList,
          EmployeeList: action.payload.EmployeeList,
          LocationStandards: action.payload.LocationStandards,
          EmailEventCategories: action.payload.EmailEventCategories,
          OriginalEmailEventCategories: action.payload.EmailEventCategories,
          CheckAll: action.payload.CheckAll,
          CheckRemediation: action.payload.CheckAll,
          TabIsDisable: action.payload.TabIsDisable,
        },
      };
    },
    resetPage: (state: LocationPageType) => {
      return {
        ...state,
        Site: {
          Id: 0,
          SiteName: "",
          SiteCode: "",
          SelectedRegionId: 0,
          SelectedCountryId: 0,
          SelectedDirectorId: "0",
          SelectedQADirectorId: "0",
          SelectedEHSDirectorId: "0",
          SelectedEngineeringDirectorId: "0",
          SelectedQCManagementId: "0",
          SelectedMainteneceManagerId: "0",
        } as SiteDTO,
        IsLoading: false,
        PageMode: {
          ...state.PageMode,
          IsDisable: true,
        },

        Validator: new SimpleReactValidator(),
        ShowErrorMsg: false,
      };
    },
    setSaveLocation: (
      state: LocationPageType,
      action: { payload: SiteDTO; type: string }
    ) => {
      return {
        ...state,
        Site: {
          ...state.Site,
          Id: action.payload.Id,
          Status: action.payload.Status,
          StatusName: action.payload.StatusName,
          Version: action.payload.Version,
          EmployeeList: action.payload.EmployeeList,
          SiteName: action.payload.SiteName,
          SiteCode: action.payload.SiteCode,
          SelectedRegionId: action.payload.SelectedRegionId,
          SelectedCountryId: action.payload.SelectedCountryId,
          SelectedDirectorId: action.payload.SelectedDirectorId,
          SelectedQADirectorId: action.payload.SelectedQADirectorId,
          SelectedEHSDirectorId: action.payload.SelectedEHSDirectorId,
          SelectedEngineeringDirectorId:
            action.payload.SelectedEngineeringDirectorId,
          SelectedQCManagementId: action.payload.SelectedQCManagementId,
          SelectedMainteneceManagerId:
            action.payload.SelectedMainteneceManagerId,
          LocationStandards: action.payload.LocationStandards,
          EmailEventCategories: action.payload.EmailEventCategories,
          OriginalEmailEventCategories: action.payload.EmailEventCategories,
          CheckAll: action.payload.CheckAll,
          CheckRemediation: action.payload.CheckAll,
          TabIsDisable: action.payload.TabIsDisable,
        },
      };
    },
    changePageMode: (
      state: LocationPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowUpdateBtn: true,
            IsShowCancelBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: true,
            IsShowCancelBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
          },
          Site: {
            ...state.Site,
            Version: initialState.Site.Version,
            Status: initialState.Site.Status,
            StatusName: initialState.Site.StatusName,
          },
        };
      }
    },
  },
});

export const {
  setReadOnlyMode,
  changePageMode,
  setDropdownList,
  setSaveLocation,
  resetPage,
} = siteSlice.actions;

export const locationReducer = siteSlice.reducer;
