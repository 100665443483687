import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import InspectionReportApi from "./StandardsSummaryReport.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { GET_INSPECTION_EXCEL_REPORT_DATA } from "./StandardsSummaryReport.actions";
import { inspectionReportReducerActions } from "./StandardsSummaryReport.reducer";
import dayjs from "dayjs";
import store from "../../../core/store/index";
import { SummaryReportDownloadStatusType } from "./StandardsSummaryReport.types";

const excelWorkerStandardsSummary: Worker = new Worker(
  new URL("./ExcelWorkerStandardsSummary.ts", import.meta.url)
);

excelWorkerStandardsSummary.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  let downloadFileName = `${
    event.data.name
  } -  Inspection Summary Report - ${dayjs(new Date()).format(
    "DD-MMM-YYYY HH_mm"
  )}`;
  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension != null && extension != "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    inspectionReportReducerActions.setExcelReportStatus({
      InspectionId: event.data.inspectionId,
      IsDownloading: false,
    } as SummaryReportDownloadStatusType)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerStandardsSummary.terminate();
  };
};

const inspectionReportWatcher = function* () {
  yield takeEvery(
    GET_INSPECTION_EXCEL_REPORT_DATA,
    function* (action: AnyAction): any {
      const data = action.payload as ReportViewerType;

      // yield put(setLoading(true));
      try {
        const result: ActionResponseType = yield call(() =>
          InspectionReportApi.getInspectionReport(data)
        );
        if (result.IsSuccess) {
          const workerObj = {
            data: result.Data.InspectionQuestions,
            inspectionId: result.Data.Id,
            name: result.Data.Name,
          };
          excelWorkerStandardsSummary.postMessage(workerObj);
          // yield put(
          //   inspectionReportReducerActions.setInspectionReportData({
          //     InspectionReport: {
          //       ReportData: result.Data,
          //       IsReady: true,
          //     },
          //   } as InspectionReportType)
          // );
          // yield put(
          //   remediationTrackerTasksReportReducerActions.setIsReportReady(true)
          // );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText:
                "Sorry, An error occured while generating the excel report!",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick() {},
            } as PopupProps)
          );
        }
      } catch {
        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );
      }

      // yield put(setLoading(false));
    }
  );
};

const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const inspectionReportSaga = function* () {
  yield all([inspectionReportWatcher()]);
};

export default inspectionReportSaga;
