import { LoginDataType } from "./LoginPage.types";

export const LOGIN = "LOGIN";
export const LOGIN_WITH_MICROSOFT_AZURE_ACTIVE_DIRECTORY_LOGIN =
  "LOGIN_WITH_MICROSOFT_AZURE_ACTIVE_DIRECTORY_LOGIN";
export const LOGOUT = "LOGOUT";

const loginSagaActions = {
  login: (loginData: LoginDataType) => {
    return {
      type: LOGIN,
      payload: loginData,
    };
  },
  loginWithMicrosoftAzureActiveDirectory: () => {
    return {
      type: LOGIN_WITH_MICROSOFT_AZURE_ACTIVE_DIRECTORY_LOGIN,
    };
  },
  logout: () => ({ type: LOGOUT }),
};

export default loginSagaActions;
