export interface LoginRequestType {
  UserName: string;
  Password: string;
  Errors: Array<LoginRequestErrorType>;
  Loading: boolean;
  Language: number;
  ErrorMessage: string;
  ProgressMessage: string;
}

export interface LoginRequestErrorType {
  Name: EnumLoginFormErrorType;
  Error: string;
}

export interface LoginDataType {
  AuthenticationMode?: string;
  UserName: string;
  Password: string;
  Language: number;
}

export enum EnumLoginFormErrorType {
  UserName,
  Password,
  Language,
  General,
}
