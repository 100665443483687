// project imports
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import {
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";
import { AxiosError } from "axios";
// client object for axios calls to backend
const client = new ApiClient();

/* ==============================|| VIEWS - PAGES - INSPECTION SET - API ||==============================  */

export const getAvailableData = async (
  SearchParameter: InspectionSetSelectDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionSetQuestionAssign/GetAvailableData`;
    let inspectionData = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, SearchParameter)
      .then((response: ActionResponseType) => {
        inspectionData = response;
      })
      .catch((error) => {
        // console.log(error);
        inspectionData = {} as ActionResponseType;
      });
    return inspectionData;
  } catch (error) {
    throw error;
  }
};

export const saveSelectedData = async (
  InspectionSetList: InspectionSetListTypeDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `InspectionSetQuestionAssign/SaveInspectionSetSelectedQuestions`;
    let inspectionSetListTypeDTO = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, InspectionSetList)
      .then((response: ActionResponseType) => {
        inspectionSetListTypeDTO = response;
      })
      .catch((error) => {
        // console.log(error);
        inspectionSetListTypeDTO = {} as ActionResponseType;
      });
    return inspectionSetListTypeDTO;
  } catch (error) {
    throw error;
  }
};

// Deactivate Inspection Set
export const deactivateInspectionSetApi = async (
  inspectionSet: InspectionSetListTypeDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "InspectionSetQuestionAssign/DeactivateInspectionSet";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, inspectionSet)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
