// project imports
import {
  EnumSortTypes,
  EnumStandardQuestionSortType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import {
  StandardQuestionsType,
  GetStandardDetailsType,
} from "./StandardsQuestionsPage.types";

// client object for axios calls to backend
const client = new ApiClient();

export const getStandardDetailsWithQuestionList = async (
  StandardId: number,
  listShowCount: number,
  pageNo: number,
  SearchStatus: EnumStatus,
  SortBy: EnumStandardQuestionSortType,
  SortType: EnumSortTypes
): Promise<StandardQuestionsType> => {
  try {
    const URL: string = `StandardQuestion/GetStandardDetailsWithQuestionList`;
    let data = {} as StandardQuestionsType;
    await client
      .get<ActionResponseType>(URL, {
        params: {
          standardId: StandardId,
          listShowCount: listShowCount,
          pageNo: pageNo,
          SearchStatus: SearchStatus,
          SortBy: SortBy,
          SortType: SortType,
        },
      })
      .then((response: ActionResponseType) => {
        data = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetStandardDetails = async (
  standardId: number
): Promise<GetStandardDetailsType> => {
  try {
    const URL: string = `StandardQuestion/GetStandardDetails/${standardId}`;
    var data = {} as GetStandardDetailsType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        data = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSearchQuestion = async (
  params: SearchParameterDTO
): Promise<StandardQuestionsType> => {
  try {
    const URL: string = `StandardQuestion/SearchQuestion`;
    var data = {} as StandardQuestionsType;
    await client
      .post<ActionResponseType>(URL, params)
      .then((response: ActionResponseType) => {
        data = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getQuestionByStatus = async (
  status: number,
  standardID: number
): Promise<StandardQuestionsType> => {
  try {
    const URL: string = `StandardQuestion/SearchByStatus`;
    var data = {} as StandardQuestionsType;
    await client
      .get<ActionResponseType>(URL, {
        params: { status: status, standardID: standardID },
      })
      .then((response: ActionResponseType) => {
        data = response.Data;
      })
      .catch((error) => {
        return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};
