// project imports
import { SiteDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { useAppDispatch } from "../../../core/hooks";
import { put } from "@redux-saga/core/effects";
import { AxiosError } from "axios";
// client object for axios calls to backend
const client = new ApiClient();

/* ==============================|| VIEWS - PAGES - LOCATION - API ||==============================  */

export const getDropDownList = async (SiteId: number): Promise<SiteDTO> => {
  try {
    const URL: string = `Location/GetLocationDetails/?SiteId=${SiteId}`;
    let result = {} as SiteDTO;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        result = response.Data;
      })
      .catch((error) => {
        // console.log(error);
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveSite = async (site: SiteDTO): Promise<ActionResponseType> => {
  try {
    let URL: string = "Location/SaveLocation";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, site)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
