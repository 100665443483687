//-------common imports-----//

import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { ArrowLeft2, SearchNormal1 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import HPRListAuditPagination from "../../../components/common/HPRListAuditPagination/HPRListAuditPagination";
import {
  EnumInspectionSortType,
  EnumListShowRowCountList,
  EnumPageType,
  EnumSortTypes,
  EnumStatus,
} from "../../../common/enums/common.enums";
import siteInspectionsSagaActions from "./SiteInspectionsPage.action";
import { siteInspectionsPageReducerActions } from "./SiteInspectionsPage.reducer";
import {
  DropDownDTO,
  InspectionDTO,
  PaginationDTO,
  SearchParameterDTO,
  SortDropDownDTO,
} from "../../../common/types/common.dto.types";

import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";
import { SiteInspectionStartPageParametersType } from "../SiteInspectionStartPage/SiteInspectionStartPage.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PaginationListAuditItemType } from "../../../components/common/HPRListAuditPagination/HPRListAuditPagination.types";
import HPRStatus from "../../../components/common/HPRStatus/HPRStatus";

const SiteInspectionPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(0);
  const siteInspectionsState = useAppSelector(
    (state) => state.siteInspectionsPageReducer
  );
  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const PageChangeRef = useRef(false);

  const initialState = {
    Data: {} as PaginationDTO,
    Loading: false,
    ListPagination: {
      ItemList: [] as Array<PaginationListItemType>,
      ItemShowCount: 2,
      TotalRecordsCount: 0,
      SelectedPage: 1,
    } as HPRListPaginationType,
    SiteInspectionSearchParameter: {
      InspectionSearchParams: {
        Name: "",
        Description: "",
        Status: EnumStatus.Published,
        CreatedBy: "",
        CreatedDate: "0001-01-01",
        SortBy: EnumInspectionSortType.Scheduling,
      } as InspectionDTO,
      IsAdvanceSearch: false,
      SelectedPage: 1,
      ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
    } as SearchParameterDTO,
  } as SiteInspectionsPageInitialState;

  // Check Preparing Ref
  const setTimeOutRef = useRef(null);
  const setTimeOutParaRef = useRef<PaginationListAuditItemType[] | null>(null);
  const [selectedSortBy, setSelectedSortBy] = useState<EnumInspectionSortType>(
    EnumInspectionSortType.Scheduling
  );
  useEffect(() => {
    setTimeOutParaRef.current = siteInspectionsState.ListPagination.ItemList;

    if (setTimeOutRef.current === null) {
      setTimeOutRef.current = setInterval(isInspectionPreparing, 15000);
    }
  }, [siteInspectionsState]);

  // end Check Preparing

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter(
        initialState.SiteInspectionSearchParameter
      )
    );
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList(
        initialState.SiteInspectionSearchParameter
      )
    );
  }, []);

  //On list item click
  function ItemOnClickFunction(Id: number): void {
    //Clear Interval
    if (setTimeOutRef.current !== null) {
      clearInterval(setTimeOutRef.current);
      setTimeOutRef.current = null;
    }

    navigate("/siteInspectionStart", {
      state: {
        InspectionId: Id,
      } as SiteInspectionStartPageParametersType,
    });
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onsearchNameChange = (event: any) => {
    event.stopPropagation();
    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        InspectionSearchParams: {
          ...siteInspectionsState.SiteInspectionSearchParameter
            .InspectionSearchParams,
          Name: event.target.value,
        },
      })
    );

    if (!event.target.value) {
      dispatch(
        siteInspectionsSagaActions.getSiteInspectionsList({
          ...siteInspectionsState.SiteInspectionSearchParameter,
          InspectionSearchParams: {
            ...siteInspectionsState.SiteInspectionSearchParameter
              .InspectionSearchParams,
            Name: "",
          },
          SelectedPage: 1,
        })
      );
    }
  };

  const onsearchNameKeyPress = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      dispatch(
        siteInspectionsSagaActions.getSiteInspectionsList({
          ...siteInspectionsState.SiteInspectionSearchParameter,
          SelectedPage: 1,
        })
      );
    }
  };

  const searchBtnClick = (IsAdvanceSearch: boolean) => {
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        SelectedPage: 1,
      })
    );
  };

  //-----selected Page Change (callback function)-----
  const onPageChange = (page: number) => {
    PageChangeRef.current = true;
    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        SelectedPage: page,
        ListPageSize: siteInspectionsState.ListPagination.ItemShowCount,
      })
    );
  };

  //-----call after selected page change(pagination) or is advanceSearch-----
  useEffect(() => {
    if (!PageChangeRef.current) return;
    PageChangeRef.current = false;
    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList(
        siteInspectionsState.SiteInspectionSearchParameter
      )
    );
  }, [
    siteInspectionsState.SiteInspectionSearchParameter.SelectedPage,
    siteInspectionsState.SiteInspectionSearchParameter.IsAdvanceSearch,
    siteInspectionsState.SiteInspectionSearchParameter.InspectionSearchParams
      .Status,
    siteInspectionsState.SiteInspectionSearchParameter.InspectionSearchParams
      .SortBy,
  ]);

  const backOnClick = () => {
    //Clear Interval
    if (setTimeOutRef.current !== null) {
      clearInterval(setTimeOutRef.current);
      setTimeOutRef.current = null;
    }

    navigate("/");
  };

  //Check Inspection is still Preparing
  const isInspectionPreparing = () => {
    if (
      setTimeOutParaRef.current !== null &&
      setTimeOutParaRef.current?.length > 0
    ) {
      const inspectionPreparingList = setTimeOutParaRef.current
        ?.filter((filter) => {
          return filter.StatusID === EnumStatus.Preparing;
        })
        .map((item) => item.Id);

      if (inspectionPreparingList.length > 0) {
        dispatch(
          siteInspectionsSagaActions.checkSiteInspectionsStatus(
            inspectionPreparingList
          )
        );
      } else {
        if (setTimeOutRef.current !== null) {
          clearInterval(setTimeOutRef.current);
          setTimeOutRef.current = null;
        }
      }
    }
  };

  function onChangePagination(Name: string, Id: string) {
    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        InspectionSearchParams: {
          ...siteInspectionsState.SiteInspectionSearchParameter
            .InspectionSearchParams,
        },
        ListPageSize: parseInt(Id),
        SelectedPage: 1,
      })
    );

    dispatch(
      siteInspectionsSagaActions.getSiteInspectionsList({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        SelectedPage: 1,
        ListPageSize: parseInt(Id),
      })
    );
  }

  function onChangeSortBy(SelectedSortState: SortDropDownDTO) {
    setSelectedSortBy(parseInt(SelectedSortState.Value));
    PageChangeRef.current = true;
    dispatch(
      siteInspectionsPageReducerActions.setSiteInspectionListSearchParameter({
        ...siteInspectionsState.SiteInspectionSearchParameter,
        InspectionSearchParams: {
          ...siteInspectionsState.SiteInspectionSearchParameter
            .InspectionSearchParams,
          SortBy: parseInt(SelectedSortState.Value),
        },
      })
    );
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={
          siteInspectionsState !== undefined
            ? siteInspectionsState?.Loading
            : false
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="content-area-wrapper">
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <a
              className="cursor-pointer audit-back v-align"
              style={{ display: "inline-flex" }}
              aria-label="back"
              onClick={backOnClick}
              title={"Back to home page"}
            >
              {/* <ArrowLeft2 size={40} className="flex__item" /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color flex__item--vcenter">
                Back
              </span>
            </a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={2} lg={1} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={8} lg={10} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={2} md={1} sm={12} xs={12}></Grid>
              <Grid item xl={8} lg={8} md={10} sm={12} xs={12}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <h1>{props.PageTitle}</h1>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="item-wrapper item-wrapper-no-background padding-0">
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          borderRadius: "30px",
                        }}
                      >
                        <InputBase
                          style={{ height: "38px" }}
                          value={
                            siteInspectionsState.SiteInspectionSearchParameter
                              .InspectionSearchParams.Name
                          }
                          onChange={onsearchNameChange}
                          onKeyPress={onsearchNameKeyPress}
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search Site Inspections"
                          inputProps={{ "aria-label": "Standard" }}
                          type="search"
                        />
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() => {
                            searchBtnClick(false);
                          }}
                        >
                          {/* <SearchIcon /> */}
                          <SearchNormal1
                            variant="Outline"
                            className="ico-secondary"
                            size={18}
                          />
                        </IconButton>
                      </Paper>
                    </div>
                  </Grid>

                  <Grid item xs={12} className="mb-15">
                    <div
                      className="records-found"
                      style={{ float: "left", marginTop: 0 }}
                    >
                      {siteInspectionsState.ListPagination.TotalRecordsCount ===
                        undefined ||
                      siteInspectionsState.ListPagination.TotalRecordsCount ===
                        0
                        ? "No records found"
                        : siteInspectionsState.ListPagination
                            .TotalRecordsCount === 1
                        ? "1 record found"
                        : siteInspectionsState.ListPagination
                            .TotalRecordsCount + " records found"}
                    </div>
                    <div>
                      <HPRStatus
                        Name={"InspectionSetsStatus"}
                        HideSortBy={true}
                        PerPageItemList={
                          [
                            {
                              Label: "5",
                              Value:
                                EnumListShowRowCountList.ListShowRowCountPerPage_1.toString(),
                            },
                            {
                              Label: "10",
                              Value:
                                EnumListShowRowCountList.ListShowRowCountPerPage_2.toString(),
                            },
                            {
                              Label: "15",
                              Value:
                                EnumListShowRowCountList.ListShowRowCountPerPage_3.toString(),
                            },
                            {
                              Label: "20",
                              Value:
                                EnumListShowRowCountList.ListShowRowCountPerPage_4.toString(),
                            },
                            {
                              Label: "25",
                              Value:
                                EnumListShowRowCountList.ListShowRowCountPerPage_5.toString(),
                            },
                          ] as DropDownDTO[]
                        }
                        onChangePerPage={onChangePagination}
                        onClickStatus={function (status: EnumStatus): void {}}
                        /*SortByItemList={
                          [
                            {
                              Label: "Scheduling",
                              Value: EnumInspectionSortType.Scheduling.toString(),
                              SortType: EnumSortTypes.Ascending
                            },
                            {
                              Label: "Gaps",
                              Value: EnumInspectionSortType.Gaps.toString(),
                              SortType: EnumSortTypes.Ascending
                            },
                            {
                              Label: "Location Name",
                              Value: EnumInspectionSortType.LocationName.toString(),
                              SortType: EnumSortTypes.Ascending
                            },
                            {
                              Label: "Inspection Set Name",
                              Value: EnumInspectionSortType.InspectionSetName.toString(),
                              SortType: EnumSortTypes.Ascending
                            },
                            {
                              Label: "Status",
                              Value: EnumInspectionSortType.Status.toString(),
                              SortType: EnumSortTypes.Ascending
                            },
                          ] as SortDropDownDTO[]
                        }
                        onChangeSortBy={onChangeSortBy}
                        InitialSortByState= {
                            {
                              Label: "Scheduling",
                              Value: EnumInspectionSortType.Scheduling.toString(),
                              SortType: EnumSortTypes.Ascending
                            } as SortDropDownDTO
                        }*/
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <HPRListAuditPagination
                      ItemList={siteInspectionsState.ListPagination.ItemList}
                      ItemShowCount={
                        siteInspectionsState.ListPagination.ItemShowCount
                      }
                      TotalRecordsCount={
                        siteInspectionsState.ListPagination.TotalRecordsCount
                      }
                      PaginationOnClickFunction={onPageChange}
                      ItemOnClickFunction={ItemOnClickFunction}
                      PageType={EnumPageType.SiteInspections}
                      SelectedPage={
                        siteInspectionsState.ListPagination.SelectedPage
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={12} xs={12}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SiteInspectionPage;
