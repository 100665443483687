import { InspectionStandardDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION SUMMARY - ACTION ||============================== //

export const GETSUMMARYOFINSPECTION = "GETSUMMARYOFINSPECTION";

export const getViewInspectionSummary = (
  inspectionStandard: InspectionStandardDTO
) => {
  return {
    type: GETSUMMARYOFINSPECTION,
    payload: inspectionStandard,
  };
};
