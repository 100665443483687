import { createSlice } from "@reduxjs/toolkit";
// project imports
import { EnumPageMode } from "../../../common/enums/common.enums";
import {
  AssignInspectionDTO,
  DropDownDTO,
  InspectionMemberDTO,
  InspectionQuestionDTO,
  InspectionSetDTO,
  InspectionStandardDTO,
  InspectionStandardEmployeeDTO,
  InspectionStatusDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";

import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import { AssignInspectionPageType } from "./AssignInspectionPage.types";

// ==============================|| VIEWS - PAGES - ASSIGN INSPECTION PAGE - REDUCER ||============================== //

// set initial state values for Assign inspection
const initialState = {
  Inspection: {
    Id: 0,
    ModifiedBy: "",
    ModifiedDate: "",
    Version: 0,
    SiteId: 0,
    InspectionSetId: 0,
    Name: "",
    Description: "",
    Status: 0,
    InspectionStatusId: 0,
    CreatedDate: "",
    CreatedBy: "",
    StartedDate: "",
    StartedBy: "",
    CompletedDate: "",
    CompletedBy: "",
    PlannedDate: "",
    AllocatedTime: "",
    InspectionSet: {} as InspectionSetDTO,
    InspectionStatus: {} as InspectionStatusDTO,
    Site: {} as SiteDTO,
    InspectionMembers: [] as Array<InspectionMemberDTO>,
    InspectionQuestions: [] as Array<InspectionQuestionDTO>,
    InspectionStandards: [] as Array<InspectionStandardDTO>,
    SaveAction: 0,
    SiteList: [] as Array<DropDownDTO>,
    InspectionList: [] as Array<DropDownDTO>,
    Recurring: false,
    RecurringPattenId: null,
    OccurrenceCount: 0,
    EndBy: "",
    InspectionSetList: [] as Array<DropDownDTO>,
    EventSuccess: false,
    RecurringDates: [] as Array<string>,
    //InspectionStandardsList: [] as Array<InspectionStandardEmployeeDTO>
  } as AssignInspectionDTO,
  IsLoading: false,
  PageMode: {
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    PageMode: EnumPageMode.Default,
  } as PageModeType,

  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
} as AssignInspectionPageType;

export const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    setReadOnlyMode: (
      state: AssignInspectionPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsDisable: action.payload };
    },

    resetPage: (state: AssignInspectionPageType) => {
      return {
        ...state,
        IsLoading: false,
        PageMode: {
          IsDisable: false,
          IsShowSaveBtn: false,
          IsShowCloseBtn: false,
          IsShowUpdateBtn: false,
          IsShowCancelBtn: false,
          IsShowDraftBtn: false,
          IsShowEditBtn: false,
          IsShowPublishedBtn: false,
          PageMode: EnumPageMode.Default,
        } as PageModeType,

        Validator: new SimpleReactValidator(),
        ShowErrorMsg: false,
      };
    },
    setSaveAssignInspection: (
      state: AssignInspectionPageType,
      action: { payload: AssignInspectionDTO; type: string }
    ) => {
      return {
        ...state,
        Inspection: {
          ...state.Inspection,
          Id: action.payload.Id,
          Status: action.payload.Status,
          Name: action.payload.Name,
        },
      };
    },
    setInitPageData: (state, action) => {
      state.Inspection = action.payload;
    },
    changePageMode: (
      state: AssignInspectionPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowUpdateBtn: true,
            IsShowCancelBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: true,
            IsShowCancelBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
          },
        };
      }
    },
    setEventSuccess: (state: AssignInspectionPageType) => {
      state.Inspection.EventSuccess = true;
    },
  },
});

export const {
  setReadOnlyMode,
  changePageMode,
  setSaveAssignInspection,
  resetPage,
  setInitPageData,
  setEventSuccess,
} = siteSlice.actions;

export const assignInspectionReducer = siteSlice.reducer;
