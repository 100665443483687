import { combineReducers } from "redux";

// project imports
import { loginReducer } from "../../views/pages/LoginPage/LoginPage.reducer";
import { authReducer } from "../reducers/authReducer";
import { standardsReducer } from "../../views/pages/StandardsPage/StandardsPage.reducer";
import { mainLayoutReducer } from "../../views/layouts/main-layout/MainLayout.reducer";
import { standardQuestionReducer } from "../../views/pages/StandardQuestionsPage/StandardsQuestionsPage.reducer";
import { questionReducer } from "../../views/pages/QuestionsPage/QuestionPage.reducer";
import { standardReducer } from "../../views/pages/StandardPage/StandardPage.reducer";
import { pageRegistrationReducer } from "../reducers/pageRegistrationReducer";
import { standardreferenceReducer } from "../../views/pages/ReferencePage/ReferencePage.reducer";
import { sourcesReducer } from "../../views/pages/SourcesPage/SourcesPage.reducer";
import { popupReducer } from "../../components/Popups/Popup.reducer";
import { standardSystemReducer } from "../../views/pages/SystemPage/SystemPage.reducer";
import { standardSubSystemReducer } from "../../views/pages/StandardSubSystemPage/StandardSubSystemPage.reducer";
import { standardSubjectReducer } from "../../views/pages/SubjectPage/SubjectPage.reducer";
import { standardSectionReducer } from "../../views/pages/SectionPage/SectionPage.reducer";
import { locationReducer } from "../../views/pages/LocationPage/LocationPage.reducer";
import { locationsReducer } from "../../views/pages/LocationsPage/LocationsPage.reducer";
import { InspectionSetsReducer } from "../../views/pages/InspectionSetsPage/InspectionSetsPage.reducer";
import { questionnaireStartReducer } from "../../views/pages/QuestionnaireStartPage/QuestionnaireStartPage.reducer";
import { InspectionAllocationReducer } from "../../views/pages/InspectionAllocationPage/InspectionAllocationPage.reducer";
import { siteInspectionStartReducer } from "../../views/pages/SiteInspectionStartPage/SiteInspectionStartPage.reducer";
import { siteInspectionsPageReducer } from "../../views/pages/SiteInspectionsPage/SiteInspectionsPage.reducer";
import { siteInspectionQuestionnaireReducer } from "../../views/pages/QuestionnairePage/QuestionnairePage.reducer";
import { assignInspectionReducer } from "../../views/pages/AssignInspectionPage/AssignInspectionPage.reducer";
import { inspectionSetReducer } from "../../views/pages/InspectionSetPage/InspectionSetPage.reducer";
import { questionnaireSummaryPageReducer } from "../../views/pages/QuestionnaireSummaryPage/QuestionnaireSummaryPage.reducer";
import { reportReducer } from "../../components/ReportViewer/ReportViewer.reducer";
import { protectedLayoutReducer } from "../../views/layouts/protected-layout/ProtectedLayout.reducer";
import { standarReportReducer } from "../../views/Reports/StandardReport/StandardReport.reducer";
import { hprDataGridReducer } from "../../components/common/HPRDataGrid/HRPDataGrid.reducer";
import { remediationTrackerTasksReducer } from "../../views/pages/RemediationTrackerTasksPage/RemediationTrackerTasksPage.reducer";
import { remediationTrackerTaskReducer } from "../../views/pages/RemediationTrackerTaskPage/RemediationTrackerTaskPage.reducer";
import { viewInspectionQuestionReducer } from "../../views/components/ViewInspectionQuestion/ViewInspectionQuestion.reducer";
import { remediationTrackerTasksReportReducer } from "../../views/Reports/RemediationTrackerTasksReport/RemediationTrackerTasksReport.reducer";
import { viewInspectionSummaryReducer } from "../../views/components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import { inspectionReportReducer } from "../../views/Reports/StandardsSummaryReport/StandardsSummaryReport.reducer";
import { standardQuestionsReportReducer } from "../../views/Reports/StandardQuestionsReport/StandardQuestionsReport.reducer";
import { viewRemediationTrackerTaskHistoryReducer } from "../../views/components/ViewRemediationTrackerTaskHistory/ViewRemediationTrackerTaskHistory.reducer";
import { inspectionSetReportReducer } from "../../views/Reports/InspectionSetReport/InspectionSetReport.reducer";
import { viewInspectionQuestionHistoryReducer } from "../../views/components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory.reducer";
import { userRoleManagementReducer } from "../../views/pages/UserRoleManagementPage/UserRoleManagement.reducer";
import { UserRoleManagementScreenReducer } from "../../views/pages/UserRoleManagementPage/UserRoleManagementScreen/UserRoleManagementScreens.reducer";
import { userRoleManagementLocationsReducer } from "../../views/pages/UserRoleManagementPage/UserRoleManagementLocations/UserRoleManagementLocations.reducer";
import { loginSwitchPopupReducer } from "../../components/Popups/LoginSwitchPopup/LoginSwitchPopup.reducer";
import { assessmentTypeWizardReducer } from "../../views/pages/AssessmentTypeWizardPage/AssessmentTypeWizardPage.reducer";
import { assessmentTypeWizardListReducer } from "../../views/pages/AssessmentTypeWizardsPage/AssessmentTypeWizardsPage.reducer";

// ==============================|| REDUX - ROOT REDUCER ||============================== //

const rootReducer = combineReducers({
  pageRegistrationReducer,
  loginReducer,
  authReducer,
  protectedLayoutReducer,
  standardsReducer,
  mainLayoutReducer,
  standardQuestionReducer,
  questionReducer,
  standardReducer,
  standardreferenceReducer,
  sourcesReducer,
  popupReducer,
  standardSystemReducer,
  standardSubSystemReducer,
  standardSubjectReducer,
  standardSectionReducer,
  locationReducer,
  locationsReducer,
  InspectionSetsReducer,
  questionnaireStartReducer,
  InspectionAllocationReducer,
  siteInspectionStartReducer,
  siteInspectionsPageReducer,
  siteInspectionQuestionnaireReducer,
  assignInspectionReducer,
  inspectionSetReducer,
  questionnaireSummaryPageReducer,
  reportReducer,
  standarReportReducer,
  hprDataGridReducer,
  remediationTrackerTasksReducer,
  remediationTrackerTaskReducer,
  viewInspectionQuestionReducer,
  remediationTrackerTasksReportReducer,
  viewInspectionSummaryReducer,
  inspectionReportReducer,
  standardQuestionsReportReducer,
  viewRemediationTrackerTaskHistoryReducer,
  inspectionSetReportReducer,
  viewInspectionQuestionHistoryReducer,
  UserRoleManagementScreenReducer,
  userRoleManagementReducer,
  userRoleManagementLocationsReducer,
  loginSwitchPopupReducer,
  assessmentTypeWizardReducer,
  assessmentTypeWizardListReducer,
});

export default rootReducer;
