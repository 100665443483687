import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  SearchParameterDTO,
  SiteInspectionDTO,
} from "../../../common/types/common.dto.types";
import {
  HPRListAuditPaginationType,
  PaginationListAuditItemType,
} from "../../../components/common/HPRListAuditPagination/HPRListAuditPagination.types";
import locationIco from "../../../icons/siteInspection.svg";
import {
  GETSITEINSPECTIONSLIST,
  GETSITEINSPECTIONSTATAUS,
} from "./SiteInspectionsPage.action";
import {
  getSiteInspectionsCurrentStatus,
  getSiteInspectionsList,
} from "./SiteInspectionsPage.api";
import { siteInspectionsPageReducerActions } from "./SiteInspectionsPage.reducer";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";

const getSiteInspectionsListWatcher = function* (): any {
  yield takeEvery(GETSITEINSPECTIONSLIST, function* (action: AnyAction): any {
    yield put(siteInspectionsPageReducerActions.setLoading(true));

    const searchPara: SearchParameterDTO = action.payload;

    const result: SiteInspectionsPageInitialState = yield call(() =>
      getSiteInspectionsList(searchPara)
    );

    if (result != null) {
      yield put(
        siteInspectionsPageReducerActions.setPagination({
          ItemList: result.Data?.Data?.map(
            (siteInspection: SiteInspectionDTO) => {
              return {
                Icon: locationIco,
                Id: siteInspection.Id,
                Site: siteInspection.Site,
                ItemHeading: siteInspection.Name,
                CreatedDate: siteInspection.CreatedDate,
                PlannedDate: siteInspection.PlannedDate,
                StatusID: siteInspection.Status,
                InspectionStatusId: siteInspection.InspectionStatusId,
              } as PaginationListAuditItemType;
            }
          ),
          ItemShowCount: searchPara.ListPageSize,
          TotalRecordsCount: result.Data?.TotalRecordsCount,
          StatusSummaryCount: result.Data?.StatusSummaryCount,
          SelectedPage: result.Data?.SelectedPage,
        } as HPRListAuditPaginationType)
      );
      yield put(
        siteInspectionsPageReducerActions.setSiteInspectionsList(result)
      );
    }

    yield put(siteInspectionsPageReducerActions.setLoading(false));
  });
};

const checkSiteInspectionsStatusWatcher = function* (): any {
  yield takeEvery(GETSITEINSPECTIONSTATAUS, function* (action: AnyAction): any {
    const searchPara: Array<number> = action.payload;

    const result: SiteInspectionsPageInitialState = yield call(() =>
      getSiteInspectionsCurrentStatus(searchPara)
    );

    if (result?.Data?.Data !== null) {
      yield put(
        siteInspectionsPageReducerActions.setUpdatedPaginationList(result.Data)
      );
    }
  });
};

const siteInspectionsListSaga = function* () {
  yield all([
    getSiteInspectionsListWatcher(),
    checkSiteInspectionsStatusWatcher(),
  ]);
};

export default siteInspectionsListSaga;
