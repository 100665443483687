import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  HPRListAuditPaginationType,
  PaginationListAuditItemType,
} from "./HPRListAuditPagination.types";
import { useState } from "react";
import { Grid } from "@mui/material";
import {
  EnumInspectionStatus,
  EnumPageType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { ArrowRight2 } from "iconsax-react";
import dayjs from "dayjs";
import locationIco from "../../../icons/siteInspection.svg";

const HPRListAuditPagination = (props: HPRListAuditPaginationType) => {
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.PaginationOnClickFunction(value);
  };

  return (
    <div>
      {props.ItemList?.map((item: PaginationListAuditItemType, index) => (
        <div className="standrads-cards-contain-wrap d-flex p-0" key={index}>
          <Grid item xs={12}>
            <div
              className={`item-wrapper ${
                item.StatusID === EnumStatus.Preparing
                  ? "inprogress-inspection-set"
                  : ""
              }`}
            >
              <div className="item-content flex-container">
                {/*Site Inspections List*/}
                {props.PageType === EnumPageType.SiteInspections ? (
                  <>
                    <div className="inspection-icon">
                      <img src={locationIco} />
                    </div>
                    <div className="inspection-info-container">
                      <h2>{item.ItemHeading}</h2>

                      <span className="item-lbl-val align-left">
                        <label className="audit-sub-lbl">Site: </label>
                        <label className="audit-sub-val gray-text">
                          {item.Site.SiteName}
                        </label>
                      </span>

                      <span className="item-lbl-val align-left">
                        <label className="audit-sub-lbl">Planned date: </label>

                        <label className="audit-sub-val gray-text">
                          {dayjs(item.PlannedDate).format("DD-MMM-YYYY")}
                        </label>
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="currstatus">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <label
                    className={`curr-status-lbl ${
                      item.StatusID === EnumStatus.Preparing
                        ? "light-blue-text"
                        : item.InspectionStatusId ===
                          EnumInspectionStatus.NotPlanned
                        ? "not-planned-text"
                        : item.InspectionStatusId ===
                          EnumInspectionStatus.Scheduled
                        ? "scheduled-text"
                        : item.InspectionStatusId ===
                          EnumInspectionStatus.InProgress
                        ? "inprogress-text"
                        : item.InspectionStatusId ===
                          EnumInspectionStatus.Completed
                        ? "completed-text"
                        : item.InspectionStatusId ===
                          EnumInspectionStatus.Overdue
                        ? "overdue-text"
                        : ""
                    }`}
                  >
                    {item.StatusID === EnumStatus.Preparing
                      ? "Preparing"
                      : item.InspectionStatusId ===
                        EnumInspectionStatus.NotPlanned
                      ? "Not planned"
                      : item.InspectionStatusId ===
                        EnumInspectionStatus.Scheduled
                      ? "Scheduled"
                      : item.InspectionStatusId ===
                        EnumInspectionStatus.InProgress
                      ? "In progress"
                      : item.InspectionStatusId ===
                        EnumInspectionStatus.Completed
                      ? "Completed"
                      : item.InspectionStatusId === EnumInspectionStatus.Overdue
                      ? "Overdue"
                      : ""}
                  </label>
                </div>

                <div className="inspection-navigate-section">
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                      props.ItemOnClickFunction(item.Id);
                    }}
                  >
                    <ArrowRight2
                      variant="Outline"
                      size={40}
                      className="gray-text"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      ))}

      {props?.ItemList?.length ? (
        <div className="fx-middle pagination-wrapper site-inspection-pagination-wrapper width100-p">
          <Stack spacing={3}>
            {props.ItemList && props.ItemList?.length > 0 ? (
              <Pagination
                count={
                  parseInt(`${props.TotalRecordsCount / props.ItemShowCount}`) +
                  (props.TotalRecordsCount % props.ItemShowCount > 0 ? 1 : 0)
                }
                showFirstButton
                showLastButton
                onChange={handleChange}
                page={props.SelectedPage}
              />
            ) : (
              <></>
            )}
          </Stack>
        </div>
      ) : null}
    </div>
  );
};
export default HPRListAuditPagination;
