import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSubjectDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { SubjectPageType } from "./SubjectPage.types";

// ==============================|| VIEWS - PAGES - STANDARDSubject PAGE - REDUCER ||============================== //

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  Data: {} as PaginationDTO,
  StandaradSubject: {
    Id: 0,
    Name: "",
    StandardId: 0,
    Version: 1,
    Status: EnumStatus.Published,
  } as StandardSubjectDTO,
  StandardSubjectSearchParameter: {
    StandardSubjectSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardSubjectDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  SubjectModalOpen: false,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as SubjectPageType;

export const standardSubjectSlice = createSlice({
  name: "standardSubjectList",
  initialState: initialState,
  reducers: {
    setStandardSubjectInitState: (
      state: SubjectPageType,
      action: { payload: SubjectPageType; type: string }
    ) => {
      return {
        ...state,
        StandaradSubject: {
          ...state.StandaradSubject,
          StandardId: action.payload.StandaradSubject.StandardId,
        },
        StandardSubjectSearchParameter: {
          ...state.StandardSubjectSearchParameter,
          StandardSubjectSearchParams: {
            ...state.StandardSubjectSearchParameter.StandardSubjectSearchParams,
            StandardId: action.payload.StandaradSubject.StandardId,
          },
        },
        Data: action.payload.Data,
      };
    },
    setStandardSubjectList: (
      state: SubjectPageType,
      action: { payload: SubjectPageType; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        Data: {
          Data: action.payload.Data?.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
        } as PaginationDTO,
      };
    },
    setStandardSubjectSearchParameter: (
      state: SubjectPageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardSubjectSearchParameter: {
          ...state.StandardSubjectSearchParameter,
          StandardSubjectSearchParams: {
            ...state.StandardSubjectSearchParameter.StandardSubjectSearchParams,
            StandardId: action.payload.StandardSubjectSearchParams.StandardId,
          }, //action.payload.StandardSubjectSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          SortByNavigation: action.payload.SortByNavigation,
          GridPageSize: action.payload.GridPageSize,
        } as SearchParameterDTO,
      };
    },
    setStandardSubject: (
      state: SubjectPageType,
      action: { payload: StandardSubjectDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        StandaradSubject: {
          ...state.StandaradSubject,
          Id: action.payload.Id ? action.payload.Id : 0,
          Name: action.payload.Name ? action.payload.Name : "",
        },
      };
    },
    setDeleteStandardSubject: (
      state: SubjectPageType,
      action: { payload: StandardSubjectDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        deletestandardSubject: action.payload,
      };
    },
    setPageMode: (
      state: SubjectPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
          },
        };
      }
    },
    setSubjectModalOpen: (
      state: SubjectPageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        SubjectModalOpen: action.payload,
      };
    },
  },
});

export const {
  setStandardSubjectInitState,
  setStandardSubjectList,
  setStandardSubject,
  setDeleteStandardSubject,
  setPageMode,
  setStandardSubjectSearchParameter,
  setSubjectModalOpen,
} = standardSubjectSlice.actions;
export const standardSubjectReducer = standardSubjectSlice.reducer;
