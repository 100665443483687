// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY  - ACTION ||============================== //

export const GETREMEDIATIONTRACKERTASKSHISTORYDETAILS =
  "GETREMEDIATIONTRACKERTASKSHISTORYDETAILS";

export const getRemediationTrackerTaskHistoryDetails = (
  remediationTrackerTaskId: number
) => {
  return {
    type: GETREMEDIATIONTRACKERTASKSHISTORYDETAILS,
    payload: remediationTrackerTaskId,
  };
};
