import {
  BulkApproveRemediationTrackerTaskDTO,
  BulkRejectRemediationTrackerTaskDTO,
  RemediationTrackerTaskQuestionDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - REMEDIATION TRACKER TASKS - ACTION ||============================== //

export const GETREMEDIATIONTRACKERTASKSLIST = "GETREMEDIATIONTRACKERTASKSLIST";
export const GETREMEDIATIONTRACKERTASKSDETAILS =
  "GETREMEDIATIONTRACKERTASKSDETAILS";
export const DELETEREMEDIATIONTRACKERTASK = "DELETEREMEDIATIONTRACKERTASK";
export const BULKAPPROVEREMEDIATIONTRACKERTASK =
  "BULKAPPROVEREMEDIATIONTRACKERTASK";
export const BULKREJECTREMEDIATIONTRACKERTASK =
  "BULKREJECTREMEDIATIONTRACKERTASK";
export const GETREMEDIATIONTRACKERTASINITIALDATA =
  "GETREMEDIATIONTRACKERTASINITIALDATA";

export const GETREMEDIATIONTRACKERTASKCOLUMNWISEFILTERLIST =
  "GETREMEDIATIONTRACKERTASKCOLUMNWISEFILTERLIST";

export const GETREMEDIATIONTRACKERTASKSTATUSSUMMARYFILTEREDLIST =
  "GETREMEDIATIONTRACKERTASKSTATUSSUMMARYFILTEREDLIST";

export const GETREMEDIATIONTRACKERTASKASSESSMENTTYPELIST =
  "GETREMEDIATIONTRACKERTASKASSESSMENTTYPELIST";

export const getRemediationTrackerTaskInitialData = () => {
  return {
    type: GETREMEDIATIONTRACKERTASINITIALDATA,
  };
};

export const getRemediationTrackerTaskList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETREMEDIATIONTRACKERTASKSLIST,
    payload: {
      searchParameter: searchParameter,
    },
  };
};

export const getRemediationTrackerTasksColumnWiseFilter = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETREMEDIATIONTRACKERTASKCOLUMNWISEFILTERLIST,
    payload: {
      searchParameter: searchParameter,
    },
  };
};

export const getRemediationTrackerTaskDetails = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETREMEDIATIONTRACKERTASKSDETAILS,
    payload: searchParameter,
  };
};

export const deleteRemediationTrackerTask = (
  remediationTrackerTaskQuestion: RemediationTrackerTaskQuestionDTO
) => {
  return {
    type: DELETEREMEDIATIONTRACKERTASK,
    payload: remediationTrackerTaskQuestion,
  };
};

export const bulkApproveRemediationTrackerTask = (
  selectedRemediationTrackerTask: BulkApproveRemediationTrackerTaskDTO
) => {
  return {
    type: BULKAPPROVEREMEDIATIONTRACKERTASK,
    payload: selectedRemediationTrackerTask,
  };
};

export const bulkRejectRemediationTrackerTask = (
  selectedRemediationTrackerTask: BulkRejectRemediationTrackerTaskDTO
) => {
  return {
    type: BULKREJECTREMEDIATIONTRACKERTASK,
    payload: selectedRemediationTrackerTask,
  };
};

export const getRemediationTrackerTaskAssessmentTypeList = (
  searchParameter: SearchParameterDTO
) => {
  return {
    type: GETREMEDIATIONTRACKERTASKASSESSMENTTYPELIST,
    payload: searchParameter,
  };
};
