import { createSlice } from "@reduxjs/toolkit";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import {
  StandardQuestionsDownloadStatusType,
  StandardQuestionsReportType,
} from "./StandardQuestionsReport.types";

const initialState = {
  StandardQuestionsReport: {} as ReportViewerType,
  StandardQuestionsExcelReportStatus: [],
} as StandardQuestionsReportType;

const standardQuestionsReportPageSlice = createSlice({
  name: "StandardQuestionsReport",
  initialState: initialState,
  reducers: {
    setStandardQuestionsReportData: (
      state,
      action: { payload: StandardQuestionsReportType }
    ) => {
      return {
        ...state,
        StandardQuestionsReport: action.payload?.StandardQuestionsReport,
      };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },

    setExcelReportStatus: (
      state,
      action: { payload: StandardQuestionsDownloadStatusType }
    ) => {
      const tempList = [...state.StandardQuestionsExcelReportStatus];

      const index = tempList.findIndex(
        (standard) => standard.StandardId === action.payload.StandardId
      );

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            StandardId: tempList[index].StandardId,
            IsDownloading: action.payload.IsDownloading,
            ExportFullReport: action.payload.ExportFullReport,
          } as StandardQuestionsDownloadStatusType),
        };
      } else {
        tempList.push({
          StandardId: action.payload.StandardId,
          IsDownloading: action.payload.IsDownloading,
          ExportFullReport: action.payload.ExportFullReport,
        } as StandardQuestionsDownloadStatusType);
      }

      return { ...state, StandardQuestionsExcelReportStatus: tempList };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const standardQuestionsReportReducerActions =
  standardQuestionsReportPageSlice.actions;
export const standardQuestionsReportReducer =
  standardQuestionsReportPageSlice.reducer;
