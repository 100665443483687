// project imports
import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { AssessmentTypeWizardsPageType } from "./AssessmentTypeWizardsPage.types";

// ==============================|| VIEWS - PAGES - ASSESSMENT TYPE WIZARDS - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getAssessmentTypeWizardList = async (
  searchParameter: AssessmentTypeDTO
): Promise<AssessmentTypeWizardsPageType> => {
  try {
    const URL: string = `AssessmentTypeWizard/GetAssessmentTypeWizardList`;
    let data = {} as AssessmentTypeWizardsPageType;
    await client
      .post<ActionResponseType>(URL, searchParameter)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        data = {} as AssessmentTypeWizardsPageType;
      });

    return data;
  } catch (error) {
    throw error;
  }
};
