import { createSlice } from "@reduxjs/toolkit";
import {
  RemediationTrackerTasksDownloadStatusType,
  RemediationTrackerTasksReportType,
} from "./RemediationTrackerTasksReport.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

const initialState = {
  RemediationTrackerTasksReport: {} as ReportViewerType,
  RemediationTrackerTasksReportStatus: [],
};

const remediationTrackerTasksReportPageSlice = createSlice({
  name: "RemediationTrackerTasksReport",
  initialState: initialState,
  reducers: {
    setRemediationTrackerTasksReportData: (
      state,
      action: { payload: RemediationTrackerTasksReportType }
    ) => {
      return {
        ...state,
        RemediationTrackerTasksReport:
          action.payload?.RemediationTrackerTasksReport,
      };
    },
    setIsReportReady: (state, action: { payload: boolean }) => {
      return { ...state, isReady: action.payload };
    },

    setRemediationTrackerTasksReportStatus: (
      state,
      action: { payload: RemediationTrackerTasksDownloadStatusType }
    ) => {
      const tempList = [...state.RemediationTrackerTasksReportStatus];

      const index = tempList.findIndex(
        (task) => task.ExportFullReport === action.payload.ExportFullReport
      );

      if (index !== -1) {
        tempList[index] = {
          ...tempList[index],
          ...({
            IsDownloading: action.payload.IsDownloading,
            ExportFullReport: action.payload.ExportFullReport,
            ReportType: action.payload.ReportType,
          } as RemediationTrackerTasksDownloadStatusType),
        };
      } else {
        tempList.push({
          IsDownloading: action.payload.IsDownloading,
          ExportFullReport: action.payload.ExportFullReport,
          ReportType: action.payload.ReportType,
        } as RemediationTrackerTasksDownloadStatusType);
      }

      return { ...state, RemediationTrackerTasksReportStatus: tempList };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const remediationTrackerTasksReportReducerActions =
  remediationTrackerTasksReportPageSlice.actions;
export const remediationTrackerTasksReportReducer =
  remediationTrackerTasksReportPageSlice.reducer;
