// redux
import { createSlice } from "@reduxjs/toolkit";
import { PageRegistrationProperties } from "./pageRegistrationReducer.types";

// ==============================|| REDUCERS - PAGE REDUCERS ||============================== //

const initialState = {} as PageRegistrationProperties;

export const pageRegistrationSlice = createSlice({
  name: "pageRegistration",
  initialState,
  reducers: {
    setState: (
      state: PageRegistrationProperties,
      action: { payload: PageRegistrationProperties }
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const pageRegistrationReducer = pageRegistrationSlice.reducer;
export const pageRegistrationReducerActions = pageRegistrationSlice.actions;
