// react router
import {
  BrowserRouter,
  Route,
  Routes,
  useBeforeUnload,
} from "react-router-dom";
// styles
import "./App.css";
// project imports(layouts)
// import LoginLayout from "./views/layouts/login-layout/LoginLayout";
// import MainLayout from "./views/layouts/main-layout/MainLayout";

// project imports(pages)
// import LoginPage from "./views/pages/LoginPage/LoginPage";
// import LandingPage from "./views/pages/LandingPage/LandingPage";
import StandardsPage from "./views/pages/StandardsPage/StandardsPage";
import StandardPage from "./views/pages/StandardPage/StandardPage";
import StandardsQuestionsPage from "./views/pages/StandardQuestionsPage/StandardsQuestionsPage";
import QuestionPage from "./views/pages/QuestionsPage/QuestionsPage";
import { createTheme } from "@mui/material/styles";
import ReferencePage from "./views/pages/ReferencePage/ReferencePage";
import LocationPage from "./views/pages/LocationPage/LocationPage";
import SystemPage from "./views/pages/SystemPage/SystemPage";
import StandardSubSystemPage from "./views/pages/StandardSubSystemPage/StandardSubSystemPage";
import SubjectPage from "./views/pages/SubjectPage/SubjectPage";
import SectionPage from "./views/pages/SectionPage/SectionPage";
import QuestionnaireStartPage from "./views/pages/QuestionnaireStartPage/QuestionnaireStartPage";
// import AuditLayout from "./views/layouts/audit-layout/AuditLayout";
import LocationsPage from "./views/pages/LocationsPage/LocationsPage";
import SiteInspectionsPage from "./views/pages/SiteInspectionsPage/SiteInspectionsPage";
import InspectionAllocationPage from "./views/pages/InspectionAllocationPage/InspectionAllocationPage";
import SiteInspectionStartPage from "./views/pages/SiteInspectionStartPage/SiteInspectionStartPage";
import QuestionnairePage from "./views/pages/QuestionnairePage/QuestionnairePage";
import InspectionSetsPage from "./views/pages/InspectionSetsPage/InspectionSetsPage";
import InspectionSetPage from "./views/pages/InspectionSetPage/InspectionSetPage";
import QuestionnaireSummaryPage from "./views/pages/QuestionnaireSummaryPage/QuestionnaireSummaryPage";
import AssignInspectionPage from "./views/pages/AssignInspectionPage/AssignInspectionPage";
import { EnumPageType } from "./common/enums/common.enums";
// import LogoutPage from "./views/pages/LogoutPage/LogoutPage";
// import ProtectedLayout from "./views/layouts/protected-layout/ProtectedLayout";
import {
  CheckSystemScreenAccessRights,
  GetAppOptions,
} from "./common/functions/common.functions";
import RemediationTrackerTasksPage from "./views/pages/RemediationTrackerTasksPage/RemediationTrackerTasksPage";
import RemediationTrackerTaskPage from "./views/pages/RemediationTrackerTaskPage/RemediationTrackerTaskPage";
import React, { Suspense, lazy, useEffect } from "react";
import UnauthorizedPage from "./views/pages/ErrorHandlingPages/UnauthorizedPage";
import { useAppSelector } from "./core/hooks";
import { AuthType } from "./core/reducers/auth.types";
import { appInsights } from "./core/azure/ApplicationInsightsService";
import UserRoleManagement from "./views/pages/UserRoleManagementPage/UserRoleManagement";
import UserRoleManagementLocations from "./views/pages/UserRoleManagementPage/UserRoleManagementLocations/UserRoleManagementLocations";
import UserRoleManagementScreen from "./views/pages/UserRoleManagementPage/UserRoleManagementScreen/UserRoleManagementScreen";
import { AppOptions } from "./common/types/common.types";
import AssessmentTypeWizardPage from "./views/pages/AssessmentTypeWizardPage/AssessmentTypeWizardPage";
import AssessmentTypeWizardsPage from "./views/pages/AssessmentTypeWizardsPage/AssessmentTypeWizardsPage";
// ===================================|| Lazy Loadings ||======================= //
const LoginPage = lazy(() => import("./views/pages/LoginPage/LoginPage"));
const MainLayout = lazy(() => import("./views/layouts/main-layout/MainLayout"));
const LoginLayout = lazy(
  () => import("./views/layouts/login-layout/LoginLayout")
);
const AuditLayout = lazy(
  () => import("./views/layouts/audit-layout/AuditLayout")
);
const ProtectedLayout = lazy(
  () => import("./views/layouts/protected-layout/ProtectedLayout")
);
const LandingPage = lazy(() => import("./views/pages/LandingPage/LandingPage"));
const LogoutPage = lazy(() => import("./views/pages/LogoutPage/LogoutPage"));
// const StandardsPage = lazy(
//   () => import("./views/pages/StandardsPage/StandardsPage")
// );
// const StandardPage = lazy(
//   () => import("./views/pages/StandardPage/StandardPage")
// );
// const StandardsQuestionsPage = lazy(
//   () => import("./views/pages/StandardQuestionsPage/StandardsQuestionsPage")
// );
// const QuestionPage = lazy(
//   () => import("./views/pages/QuestionsPage/QuestionsPage")
// );
// const SourcesPage = lazy(() => import("./views/pages/SourcesPage/SourcesPage"));
// const ReferencePage = lazy(
//   () => import("./views/pages/ReferencePage/ReferencePage")
// );
// const SamplePage = lazy(() => import("./views/pages/SamplePage/SamplePage"));
// const LocationPage = lazy(
//   () => import("./views/pages/LocationPage/LocationPage")
// );
// const SystemPage = lazy(() => import("./views/pages/SystemPage/SystemPage"));
// const StandardSubSystemPage = lazy(
//   () => import("./views/pages/StandardSubSystemPage/StandardSubSystemPage")
// );
// const SubjectPage = lazy(() => import("./views/pages/SubjectPage/SubjectPage"));
// const SectionPage = lazy(() => import("./views/pages/SectionPage/SectionPage"));
// const QuestionnaireStartPage = lazy(
//   () => import("./views/pages/QuestionnaireStartPage/QuestionnaireStartPage")
// );

// const LocationsPage = lazy(
//   () => import("./views/pages/LocationsPage/LocationsPage")
// );
// const SiteInspectionsPage = lazy(
//   () => import("./views/pages/SiteInspectionsPage/SiteInspectionsPage")
// );
// const InspectionAllocationPage = lazy(
//   () =>
//     import("./views/pages/InspectionAllocationPage/InspectionAllocationPage")
// );
// const SiteInspectionStartPage = lazy(
//   () => import("./views/pages/SiteInspectionStartPage/SiteInspectionStartPage")
// );
// const QuestionnairePage = lazy(
//   () => import("./views/pages/QuestionnairePage/QuestionnairePage")
// );
// const InspectionSetsPage = lazy(
//   () => import("./views/pages/InspectionSetsPage/InspectionSetsPage")
// );
// const InspectionSetPage = lazy(
//   () => import("./views/pages/InspectionSetPage/InspectionSetPage")
// );
// const QuestionnaireSummaryPage = lazy(
//   () =>
//     import("./views/pages/QuestionnaireSummaryPage/QuestionnaireSummaryPage")
// );
// const AssignInspectionPage = lazy(
//   () => import("./views/pages/AssignInspectionPage/AssignInspectionPage")
// );

// const RemediationTrackerTasksPage = lazy(
//   () =>
//     import(
//       "./views/pages/RemediationTrackerTasksPage/RemediationTrackerTasksPage"
//     )
// );
// const RemediationTrackerTaskPage = lazy(
//   () =>
//     import(
//       "./views/pages/RemediationTrackerTaskPage/RemediationTrackerTaskPage"
//     )
// );
// const UnauthorizedPage = lazy(
//   () => import("./views/pages/ErrorHandlingPages/UnauthorizedPage")
// );
// const PageNotFoundPage = lazy(
//   () => import("./views/pages/ErrorHandlingPages/PageNotFoundPage")
// );

// ===================================|| Lazy Loadings ||======================= //

// ===================================|| APP =================================== //

const CustomFontTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          fontFamily: "Assistant-regular",
        },
      },
    },
  },
});

const App: React.FC = () => {
  // reducer manage fucntions
  const AuthState: AuthType = useAppSelector((state) => state.authReducer);

  //check version & reload page if version are not match
  const currentAppVersion = GetAppOptions().Version;
  if (!localStorage.getItem("version")) {
    localStorage.setItem("version", currentAppVersion);
  } else {
    if (
      localStorage.getItem("version").toString() !==
      currentAppVersion.toString()
    ) {
      //clear caches
      // if (caches) {
      //   caches.keys().then((names) => {
      //     for (const name of names) {
      //       caches.delete(name);
      //     }
      //   });
      // }
      // localStorage.removeItem("version");
      // window.location.reload();
    }
  }

  useEffect(() => {
    appInsights.trackPageView({ name: window.location.pathname });
  }, [window.location.pathname]);

  const WithAccessRights = (
    WrappedComponent,
    pageType: EnumPageType,
    pageTitle: string,
    authPageType: EnumPageType
  ) => {
    // Generate dynamic props here
    const dynamicProps = {
      PageType: pageType,
      PageTitle: pageTitle,
    };

    // Combine dynamic props with the original props
    const mergedProps = {
      ...dynamicProps,
    };

    // const isAuthinticated = AuthState?.SystemFunctions?.find(
    //   (path) =>
    //     path?.SystemScreen?.Id === pageType &&
    //     path?.Type?.Id === EnumSystemFunctionTypes.AccessPage
    // );

    const isAuthinticated = CheckSystemScreenAccessRights(authPageType);

    if (isAuthinticated) {
      return <WrappedComponent {...mergedProps} />;
    } else {
      // Redirect the user to another page if they don't have access
      return <UnauthorizedPage />;
    }
  };

  useBeforeUnload(() => {
    if (localStorage.getItem("autoLoggedOut") === "true") {
      localStorage.setItem("loggedIn", "true");
    } else {
      if (localStorage.getItem("loggedIn") === "false") {
        localStorage.removeItem("loggedIn");
      } else {
        localStorage.setItem("loggedIn", "false");
      }
    }
    localStorage.removeItem("autoLoggedOut");
  });

  const appOption: AppOptions = GetAppOptions();
  // replace console.* for disable log on production
  if (appOption.Environment === "PROD" || appOption.Environment === "QA") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <Suspense fallback={""}>
              <LoginLayout />
            </Suspense>
          }
        >
          <Route
            path="/login"
            element={
              <Suspense fallback={""}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route path="/logout" element={<LogoutPage />} />
        </Route>
        <Route
          element={
            <Suspense fallback={""}>
              <ProtectedLayout>
                <MainLayout />
              </ProtectedLayout>
            </Suspense>
          }
        >
          <Route path="/" element={<LandingPage />} />

          <Route
            path="/standards"
            element={
              WithAccessRights(
                StandardsPage,
                EnumPageType.Standards,
                "Standards",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <StandardsPage
              //   PageType={EnumPageType.Standards}
              //   PageTitle="Standards"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/AssessmentTypeWizard"
            element={
              // WithAccessRights(
              //   StandardsPage,
              //   EnumPageType.Standards,
              //   "Standards",
              //   EnumPageType.Standards
              // )
              <Suspense fallback={<></>}>
                <AssessmentTypeWizardPage
                  PageType={EnumPageType.AssessmentTypeWizard}
                  PageTitle="Assessment Type Wizard"
                />
              </Suspense>
            }
          />
          <Route
            path="/AssessmentTypeWizards"
            element={
              // WithAccessRights(
              //   StandardsPage,
              //   EnumPageType.Standards,
              //   "Standards",
              //   EnumPageType.Standards
              // )
              <Suspense fallback={<></>}>
                <AssessmentTypeWizardsPage
                  PageType={EnumPageType.AssessmentTypeWizard}
                  PageTitle="Assessment Type Wizards"
                />
              </Suspense>
            }
          />
          <Route
            path="/standard"
            element={
              WithAccessRights(
                StandardPage,
                EnumPageType.Standard,
                "Standard",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <StandardPage
              //   PageType={EnumPageType.Standard}
              //   PageTitle="standard"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/standardsQuestions"
            element={
              WithAccessRights(
                StandardsQuestionsPage,
                EnumPageType.StandardQuestions,
                "standardsQuestions",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <StandardsQuestionsPage
              //   PageType={EnumPageType.StandardQuestions}
              //   PageTitle="standardsQuestions"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/question"
            element={
              WithAccessRights(
                QuestionPage,
                EnumPageType.Question,
                "question",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <QuestionPage
              //   PageType={EnumPageType.Question}
              //   PageTitle="question"
              // />
              // </Suspense>
            }
          />

          <Route
            path="/standard/reference"
            element={
              WithAccessRights(
                ReferencePage,
                EnumPageType.Reference,
                "reference",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <ReferencePage
              //   PageType={EnumPageType.Reference}
              //   PageTitle="reference"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/standard/system"
            element={
              WithAccessRights(
                SystemPage,
                EnumPageType.System,
                "System",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <SystemPage PageType={EnumPageType.System} PageTitle="System" />
              // </Suspense>
            }
          />

          <Route
            path="/standard/subsystem"
            element={
              WithAccessRights(
                StandardSubSystemPage,
                EnumPageType.SubSystem,
                "Sub-system",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <StandardSubSystemPage
              //   PageType={EnumPageType.SubSystem}
              //   PageTitle="Sub-system"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/standard/subject"
            element={
              WithAccessRights(
                SubjectPage,
                EnumPageType.Subject,
                "Subject",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <SubjectPage
              //   PageType={EnumPageType.Subject}
              //   PageTitle="Subject"
              // />
              // </Suspense>
            }
          />
          <Route
            path="/standard/section"
            element={
              WithAccessRights(
                SectionPage,
                EnumPageType.Section,
                "Section",
                EnumPageType.Standards
              )
              // <Suspense fallback={<></>}>
              // <SectionPage
              //   PageType={EnumPageType.Section}
              //   PageTitle="Section"
              // />
              // </Suspense>
            }
          />

          {/* <Route
            path="/sources"
            element={
              WithAccessRights(SourcesPage, EnumPageType.Sources, "Sources")
              // <Suspense fallback={<></>}>
              // <SourcesPage
              //   PageType={EnumPageType.Sources}
              //   PageTitle="Sources"
              // />
              // </Suspense>
            }
          /> */}
          {/* <Route
            path="/uitest"
            element={
              WithAccessRights(UITest, EnumPageType.UITest, "uitest")
              // <UITest PageType={EnumPageType.UITest} PageTitle="uitest" />
            }
          /> */}

          <Route
            path="/locations"
            element={
              WithAccessRights(
                LocationsPage,
                EnumPageType.Locations,
                "Locations",
                EnumPageType.Locations
              )
              // <Suspense fallback={<></>}>
              // <LocationsPage
              //   PageType={EnumPageType.Locations}
              //   PageTitle="Locations"
              // />
              // </Suspense>
            }
          />

          <Route
            path="/location"
            element={
              WithAccessRights(
                LocationPage,
                EnumPageType.Location,
                "Location",
                EnumPageType.Locations
              )
              // <Suspense fallback={<></>}>
              // <LocationPage
              //   PageType={EnumPageType.Location}
              //   PageTitle={"Location"}
              // />
              // </Suspense>
            }
          />

          {/* <Route path="/samplePage" element={<SamplePage />} /> */}

          <Route
            path="/inspectionsets"
            element={
              WithAccessRights(
                InspectionSetsPage,
                EnumPageType.InspectionSets,
                "Inspection Set Templates",
                EnumPageType.InspectionSets
              )
              // <Suspense fallback={<></>}>
              // <InspectionSetsPage
              //   PageType={EnumPageType.InspectionSets}
              //   PageTitle={"Inspection Set Templates"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/inspectionSet"
            element={
              WithAccessRights(
                InspectionSetPage,
                EnumPageType.InspectionSet,
                "Inspection Set",
                EnumPageType.InspectionSets
              )
              // <Suspense fallback={<></>}>
              // <InspectionSetPage
              //   PageType={EnumPageType.InspectionSet}
              //   PageTitle={"Inspection Set"}
              // />
              // </Suspense>
            }
          />

          <Route
            path="/inspectionAssignment"
            element={
              WithAccessRights(
                InspectionAllocationPage,
                EnumPageType.InspectionAllocation,
                "Inspection Assignment",
                EnumPageType.AssignInspection
              )
              // <Suspense fallback={<></>}>
              // <InspectionAllocationPage
              //   PageType={EnumPageType.InspectionAllocation}
              //   PageTitle={"Inspection Assignment"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/assignInspection"
            element={
              WithAccessRights(
                AssignInspectionPage,
                EnumPageType.AssignInspection,
                "Sub-system",
                EnumPageType.AssignInspection
              )
              // <Suspense fallback={<></>}>
              // <AssignInspectionPage
              //   PageType={EnumPageType.AssignInspection}
              //   PageTitle={"Assign Inspection"}
              // />
              // </Suspense>
            }
          />

          <Route
            path="/remediationTrackerTasks"
            element={
              WithAccessRights(
                RemediationTrackerTasksPage,
                EnumPageType.RemediationTrackerTasks,
                "Remediation Tracker Tasks",
                EnumPageType.RemediationTrackerTasks
              )
              // <Suspense fallback={<></>}>
              // <RemediationTrackerTasksPage
              //   PageType={EnumPageType.RemediationTrackerTasks}
              //   PageTitle={"Remediation Tracker Tasks"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/remediationTrackerTask"
            element={
              WithAccessRights(
                RemediationTrackerTaskPage,
                EnumPageType.RemediationTrackerTask,
                "Remediation Tracker Task",
                EnumPageType.RemediationTrackerTasks
              )
              // <Suspense fallback={<></>}>
              // <RemediationTrackerTaskPage
              //   PageType={EnumPageType.RemediationTrackerTask}
              //   PageTitle={"Remediation Tracker Task"}
              // />
              // </Suspense>
            }
          />
          {/* <Route
            path="/remediationTrackerTasks"
            element={WithAccessRights(
              RemediationTrackerTasksPage,
              EnumPageType.RemediationTrackerTasks,
              "Remediation Tracker Tasks Page"
            )}
          />
          <Route
            path="/remediationTrackerTask"
            element={WithAccessRights(
              RemediationTrackerTaskPage,
              EnumPageType.RemediationTrackerTask,
              "Remediation Tracker Task Page"
            )}
          /> */}
          <Route
            path="/unauthorizedPage"
            element={
              // <Suspense fallback={<></>}>
              <UnauthorizedPage />
              // </Suspense>
            }
          />

          {/* UserRole routes*/}
          <Route
            path="/userRoleManagement"
            element={
              WithAccessRights(
                UserRoleManagement,
                EnumPageType.UserRoleManagement,
                "User Role Management",
                EnumPageType.UserRoleManagement
              )
              // <UserRoleManagement
              //   PageType={EnumPageType.UserRoleManagement}
              //   PageTitle={"User Role Management"}
              // />
            }
          />
          <Route
            path="/userRoleManagementLocations"
            element={
              WithAccessRights(
                UserRoleManagementLocations,
                EnumPageType.UserRoleManagementLocations,
                "User Role Management Locations",
                EnumPageType.UserRoleManagement
              )
              // <UserRoleManagementLocations
              //   PageType={EnumPageType.UserRoleManagementLocations}
              //   PageTitle={"User Role Management Locations"}
              // />
            }
          />
          <Route
            path="/userRoleManagementScreen"
            element={
              WithAccessRights(
                UserRoleManagementScreen,
                EnumPageType.UserRoleManagementScreens,
                "User Role Management Screen",
                EnumPageType.UserRoleManagement
              )
              // <UserRoleManagementScreen
              //   PageType={EnumPageType.UserRoleManagementScreens}
              //   PageTitle={"User Role Management Screen"}
              // />
            }
          />
        </Route>
        <Route
          element={
            <Suspense fallback={""}>
              <ProtectedLayout>
                <AuditLayout />
              </ProtectedLayout>
            </Suspense>
          }
        >
          <Route
            path="/siteInspections"
            element={
              WithAccessRights(
                SiteInspectionsPage,
                EnumPageType.SiteInspections,
                "Site Inspections",
                EnumPageType.SiteInspections
              )
              // <Suspense fallback={<></>}>
              // <SiteInspectionsPage
              //   PageType={EnumPageType.SiteInspections}
              //   PageTitle={"Site Inspections"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/siteInspectionStart"
            element={
              WithAccessRights(
                SiteInspectionStartPage,
                EnumPageType.SiteInspectionStart,
                "Site Inspection Start",
                EnumPageType.SiteInspections
              )
              // <Suspense fallback={<></>}>
              // <SiteInspectionStartPage
              //   PageType={EnumPageType.SiteInspectionStart}
              //   PageTitle={"Site Inspection Start"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/questionnaireStart"
            element={
              WithAccessRights(
                QuestionnaireStartPage,
                EnumPageType.QuestionnaireStart,
                "Questionnaire Start",
                EnumPageType.SiteInspections
              )
              // <Suspense fallback={<></>}>
              // <QuestionnaireStartPage
              //   PageType={EnumPageType.QuestionnaireStart}
              //   PageTitle={"Questionnaire Start"}
              // />
              // </Suspense>
            }
          />

          <Route
            path="/questionnaire"
            element={
              WithAccessRights(
                QuestionnairePage,
                EnumPageType.Questionnaire,
                "Questionnaire",
                EnumPageType.SiteInspections
              )
              // <Suspense fallback={<></>}>
              // <QuestionnairePage
              //   PageType={EnumPageType.Questionnaire}
              //   PageTitle={"Questionnaire"}
              // />
              // </Suspense>
            }
          />
          <Route
            path="/questionnaireSummary"
            element={
              WithAccessRights(
                QuestionnaireSummaryPage,
                EnumPageType.QuestionnaireSummary,
                "Questionnaire Summary",
                EnumPageType.SiteInspections
              )
              // <Suspense fallback={<></>}>
              // <QuestionnaireSummaryPage
              //   PageType={EnumPageType.QuestionnaireSummary}
              //   PageTitle={"Questionnaire Summary"}
              // />
              // </Suspense>
            }
          />
        </Route>

        {/* <Route path="/report" element={<ReportViewer />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
