import { all, call, put, takeEvery } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { QuestionnaireStartDTO } from "../../../common/types/common.dto.types";
import {
  GETQUESTIONNAIRSTARTDROPDOWNS,
  STARTQUESTIONNAIRE,
} from "./QuestionnaireStartPage.action";
import {
  getQuestionnaireStartDetails,
  startQuestionnaire,
} from "./QuestionnaireStartPage.api";
import {
  changePageMode,
  navigateNext,
  setDropdownList,
  setQuestionnaireAlreadyStarted,
  setReadOnlyMode,
  setStartQuestionnaire,
} from "./QuestionnaireStartPage.reducer";
import { ActionResponseType } from "../../../common/types/common.types";
import {
  EnumInspectionStandardStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";

const getDropDownListWatcher = function* (): any {
  yield takeEvery(
    GETQUESTIONNAIRSTARTDROPDOWNS,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const questionnaireStartDTO: QuestionnaireStartDTO = action.payload;

      // console.log(questionnaireStartDTO);
      const result: QuestionnaireStartDTO = yield call(() =>
        getQuestionnaireStartDetails(questionnaireStartDTO)
      );

      if (result == null && !result) {
      } else {
        yield put(setDropdownList(result));
      }
      yield put(setLoading(false));
    }
  );
};

// watcher for Start Questionnaire
const startQuestionnaireWatcher = function* (): any {
  yield takeEvery(STARTQUESTIONNAIRE, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let questionnaireStart: QuestionnaireStartDTO = action.payload;

    // get data from backend
    try {
      const latestState: QuestionnaireStartDTO = yield call(() =>
        getQuestionnaireStartDetails(questionnaireStart)
      );
      if (latestState == null && !latestState) {
      } else {
        yield put(setDropdownList(latestState));
      }
      if (
        latestState.InspectionStandard.InspectionStandardStatusId ===
          EnumInspectionStandardStatus.Intial ||
        latestState.InspectionStandard.InspectionStandardStatusId ===
          EnumInspectionStandardStatus.Inprogress ||
        latestState.InspectionStandard.InspectionStandardStatusId ===
          EnumInspectionStandardStatus.Paused
      ) {
        let result: ActionResponseType = yield call(() =>
          startQuestionnaire(questionnaireStart)
        );

        // console.log(result);
        if (result.IsSuccess) {
          questionnaireStart = result.Data;

          yield put(setStartQuestionnaire(questionnaireStart));

          // change page mode (change disable and button show hide)
          yield put(changePageMode(EnumPageMode.ViewMode));

          yield put(setReadOnlyMode(true));

          /*yield put(
                        openPopup({
                            Open: true,
                            BodyText: "Questionnaire Start saved successfully",
                            HeaderText: "Success",
                            PopupType: EnumPopupType.SuccessPopup,
                        } as PopupProps)
                    );*/
          yield put(navigateNext());
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "Questionnaire Start not saved successfully",
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      } else {
        yield put(
          /*openPopup({
                        Open: true,
                        BodyText:  "This questionnaire is already started",
                        HeaderText: "Warning",
                        PopupType: EnumPopupType.WarningPopup,
                        OkBtnClick(){
                            navigateNext();
                            // console.log("This questionnaire is already started")
                        }
                    } as PopupProps)*/
          setQuestionnaireAlreadyStarted(true)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving Questionnaire Start",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

const questionnaireStartSaga = function* () {
  yield all([startQuestionnaireWatcher(), getDropDownListWatcher()]);
};

export default questionnaireStartSaga;
