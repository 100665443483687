import { QuestionDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - QUESTION - ACTIONS ||============================== //
export const GETLANGUAGELIST = "GETLANGUAGELIST";
export const SAVEQUESTION = "SAVEQUESTION";
export const GETQUESTIONBYID = "GETQUESTIONBYID";
export const QUESTIONPAGEGETSTANDARD = "QUESTIONPAGEGETSTANDARD";
export const QUESTIONPAGEGETQUESTIONINITIALDATA =
  "QUESTIONPAGEGETQUESTIONINITIALDATA";
export const QUESTIONPAGEDEACTIVATEQUESTION = "QUESTIONPAGEDEACTIVATEQUESTION";
export const QUESTIONPAGEDECHECKAVAILABLEINSPECTIONSETSBEFOREUPDATEQUESTION =
  "QUESTIONPAGEDECHECKAVAILABLEINSPECTIONSETSBEFOREUPDATEQUESTION";
export const GETNAVIGATIONQUESTION = "GETNAVIGATIONQUESTION";
export const CHECKQUESTIONBEFOREDEACTIVATE = "CHECKQUESTIONBEFOREDEACTIVATE";

export const getLanguageList = () => {
  return {
    type: GETLANGUAGELIST,
  };
};

export const getNavigateQuestion = (
  Id: number,
  StandardId: number,
  IsPrev: boolean
) => {
  return {
    type: GETNAVIGATIONQUESTION,
    payload: {
      Id,
      StandardId,
      IsPrev,
    },
  };
};

export const getQuestionById = (id: number) => {
  return {
    type: GETQUESTIONBYID,
    payload: id,
  };
};

export const saveQuestion = (SaveQuestionRequest: QuestionDTO) => {
  return {
    type: SAVEQUESTION,
    payload: SaveQuestionRequest,
  };
};

export const getStandardById = (id: number) => {
  return {
    type: QUESTIONPAGEGETSTANDARD,
    payload: id,
  };
};

export const getQuestionPageInitialData = (question: QuestionDTO) => {
  return {
    type: QUESTIONPAGEGETQUESTIONINITIALDATA,
    payload: question,
  };
};

export const deactivateQuestion = (question: QuestionDTO) => {
  return {
    type: QUESTIONPAGEDEACTIVATEQUESTION,
    payload: question,
  };
};

export const checkAvailableInspectionSetsBeforeUpdateQuestion = (
  question: QuestionDTO
) => {
  return {
    type: QUESTIONPAGEDECHECKAVAILABLEINSPECTIONSETSBEFOREUPDATEQUESTION,
    payload: question,
  };
};

export const checkQuestionBeforeDeactivate = (question: QuestionDTO) => {
  return {
    type: CHECKQUESTIONBEFOREDEACTIVATE,
    payload: question,
  };
};
