import React, { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { UsePromptTypes } from "./use-prompt.types";
import { routeConfirm } from "./ConfirmationBox/createConfirmation";

function useConfirmExit(confirmExit: () => Promise<boolean>, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }
    const push = navigator.push;
    navigator.push = async (...args: Parameters<typeof push>) => {
      const result: boolean = await confirmExit();
      if (result) {
        push(...args);
      }
    };
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(props: UsePromptTypes) {
  useEffect(() => {
    if (props.When) {
      window.onbeforeunload = function () {
        return null;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [props.Message, props.When]);

  const confirmExit = useCallback(async () => {
    const confirmedExit: boolean = Boolean(
      await routeConfirm({
        Confirmation: props.Message,
        YesBtnClick: props.YesBtnClick,
        NoBtnClick: props.NoBtnClick,
      })
    );
    return confirmedExit;
  }, [props.Message]);
  useConfirmExit(confirmExit, props.When);
}
