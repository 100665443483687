import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - ASSESSMENT TYPE WIZARDS - ACTION ||============================== //

export const GETASSESSMENTTYPEWIZARDSLIST = "GETASSESSMENTTYPEWIZARDSLIST";

export const getAssessmentTypeWizardList = (
  searchParameter: AssessmentTypeDTO
) => {
  return {
    type: GETASSESSMENTTYPEWIZARDSLIST,
    payload: {
      searchParameter: searchParameter,
    },
  };
};
