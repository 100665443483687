// redux
import { createSlice } from "@reduxjs/toolkit";

// ==============================|| REDUCERS - AUTH REDUCERS ||============================== //

const initialState = {
    ShouldPrompt: false,
    RemainingTime: 0
 } as {ShouldPrompt: boolean; RemainingTime: number};

const protectedLayoutSlice = createSlice({
  name: "protectedLayout",
  initialState,
  reducers: {
    setShouldPrompt: (state, action: {payload: {ShouldPrompt: boolean; RemainingTime?: number}}) => {
        return {...state, ShouldPrompt: action.payload.ShouldPrompt, RemainingTime: action.payload.RemainingTime ?? 0 }
    },
    setRemainingTime: (state, action: {payload: number}) => {
        return {...state, RemainingTime: action.payload };
    }
  },
});

export const protectedLayoutReducer = protectedLayoutSlice.reducer;
export const protectedLayoutReducerActions = protectedLayoutSlice.actions;
