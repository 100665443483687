import {
  SearchParameterDTO,
  SystemGroupSaveDTO,
} from "../../../../common/types/common.dto.types";

export const GET_ADD_USERROLE_SCREEN_DATA = "GET_ADD_USERROLE_SCREEN_DATA";
export const SAVE_USERROLE_SCREEN_DATA = "SAVE_USERROLE_SCREEN_DATA";
export const FETCHSYSTEMGROUPBYID = "FETCHSYSTEMGROUPBYID";

export const getAddUserRoleScreenData = (group: SearchParameterDTO) => {
  return {
    type: GET_ADD_USERROLE_SCREEN_DATA,
    payload: group,
  };
};

export const saveUserRoleScreenData = (group: SystemGroupSaveDTO) => {
  return {
    type: SAVE_USERROLE_SCREEN_DATA,
    payload: group,
  };
};

export const fetchSystemGroupById = (groupId: number) => {
  return {
    type: FETCHSYSTEMGROUPBYID,
    payload: groupId,
  };
};
