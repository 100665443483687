import {
  DropDownDTO,
  InspectionMemberDTO,
  InspectionStandardDTO,
  QuestionnaireStartDTO,
  StandardReferenceDTO,
  StandardSmeDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { QuestionnaireStartPageType } from "./QuestionnaireStartPage.types";
import SimpleReactValidator from "simple-react-validator";
import { EnumPageMode } from "../../../common/enums/common.enums";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  QuestionnaireStart: {
    SelectedBuildingId: 0,
    Location: "",
    RelatedEntity: "",
    TeamMembers: "",
    GlobalPrimarySme: {} as StandardSmeDTO,
    GlobalSecondarySmes: [] as StandardSmeDTO[],
    References: [] as StandardReferenceDTO[],
    InspectionMember: {} as InspectionMemberDTO,
    InspectionStandard: {} as InspectionStandardDTO,
    BuildingList: [] as Array<DropDownDTO>,
    QuestionnaireStarted: false,
    QuestionnaireAlreadyStarted: false,
    ReferenceID: 0,
  } as QuestionnaireStartDTO,
  IsLoading: false,
  PageMode: {
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    PageMode: EnumPageMode.Default,
  } as PageModeType,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  IsEditableFieldVisible: true,
} as QuestionnaireStartPageType;

export const questionnaireStartSlice = createSlice({
  name: "questionnaireStart",
  initialState,
  reducers: {
    setInitState: (
      state: QuestionnaireStartPageType,
      action: { payload: QuestionnaireStartPageType; type: string }
    ) => {
      return { ...state, QuestionnaireStart: initialState.QuestionnaireStart };
    },
    setReadOnlyMode: (
      state: QuestionnaireStartPageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsDisable: action.payload };
    },
    setDropdownList: (
      state: QuestionnaireStartPageType,
      action: { payload: QuestionnaireStartDTO; type: string }
    ) => {
      return { ...state, QuestionnaireStart: action.payload };
    },
    resetPage: (state: QuestionnaireStartPageType) => {
      return {
        ...state,
        QuestionnaireStart: {
          SelectedBuildingId: 0,
          Location: "",
          RelatedEntity: "",
          TeamMembers: "",
          QuestionnaireStarted: false,
          QuestionnaireAlreadyStarted: false,
        } as QuestionnaireStartDTO,
        IsLoading: false,
        PageMode: {
          IsDisable: false,
          IsShowSaveBtn: false,
          IsShowCloseBtn: false,
          IsShowUpdateBtn: false,
          IsShowCancelBtn: false,
          IsShowDraftBtn: false,
          IsShowEditBtn: false,
          IsShowPublishedBtn: false,
          PageMode: EnumPageMode.Default,
        } as PageModeType,

        Validator: new SimpleReactValidator(),
        ShowErrorMsg: false,
      };
    },
    setStartQuestionnaire: (
      state: QuestionnaireStartPageType,
      action: { payload: QuestionnaireStartDTO; type: string }
    ) => {
      return {
        ...state,
        QuestionnaireStart: {
          ...state.QuestionnaireStart,
        },
      };
    },
    changePageMode: (
      state: QuestionnaireStartPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowCancelBtn: true,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowUpdateBtn: true,
            IsShowCancelBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowDeactivateBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: true,
            IsShowCancelBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
          },
          QuestionnaireStart: initialState.QuestionnaireStart,
        };
      }
    },
    navigateNext: (state) => {
      state.QuestionnaireStart.QuestionnaireStarted = true;
    },
    pausePage: (state) => {
      state.QuestionnaireStart.QuestionnaireStarted = false;
    },
    setQuestionnaireAlreadyStarted: (state, action) => {
      state.QuestionnaireStart.QuestionnaireAlreadyStarted = action.payload;
    },
  },
});

export const {
  setInitState,
  setReadOnlyMode,
  changePageMode,
  setDropdownList,
  setStartQuestionnaire,
  resetPage,
  navigateNext,
  pausePage,
  setQuestionnaireAlreadyStarted,
} = questionnaireStartSlice.actions;

export const questionnaireStartReducer = questionnaireStartSlice.reducer;
