import { SearchParameterDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - SOURCES - ACTION ||============================== //

export const GETSOURCELIST = "GETSOURCELIST";

export const getSourceList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETSOURCELIST,
    payload: searchParameter,
  };
};