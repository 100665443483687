import { AnyAction } from "redux";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  FETCHSYSTEMGROUPBYID,
  GET_ADD_USERROLE_SCREEN_DATA,
  SAVE_USERROLE_SCREEN_DATA,
} from "./UserRoleManagementScreens.action";

import {
  getAddUserRoleScreenData,
  getSystemGroupById,
  saveUserRoleScreenData,
} from "./UserRoleManagementScreens.api";
import { Functionality, Pages } from "./UserRoleManagementScreens.types";
import {
  setCurrentGroup,
  setFunctionalities,
  setGroupScreens,
  setPageMode,
  setScopes,
  setSuperGroupName,
} from "./UserRoleManagementScreens.reducer";
import { setLoading } from "../../../layouts/main-layout/MainLayout.reducer";
import { ActionResponseType } from "../../../../common/types/common.types";
import { openPopup } from "../../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../../components/Popups/Popup.enum";
import {
  DropDownDTO,
  SystemGroupGetDTO,
  SystemGroupScopeDTO,
  SystemScreenDTO,
} from "../../../../common/types/common.dto.types";
import { EnumPageMode } from "../../../../common/enums/common.enums";

const getAddUserRoleScreenDataWatcher = function* (): any {
  yield takeEvery(GET_ADD_USERROLE_SCREEN_DATA, function* (action: AnyAction) {
    yield put(setLoading(true));
    try {
      const result: ActionResponseType = yield call(() =>
        getAddUserRoleScreenData(action.payload)
      );

      if (result === null) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const data: SystemGroupGetDTO = result.Data;
        const screens: SystemScreenDTO[] = data?.SystemScreenDTOS ?? [];
        const scopes: SystemGroupScopeDTO[] = data?.ScopeDTOS ?? [];

        if (screens) {
          const screenList: Pages[] = [];
          const functionlist: Functionality[] = [];
          for (let i = 0; i < screens.length; i++) {
            const screen: Pages = {
              Screen: screens[i],
              IsSelected: false,
              IsChecked: false,
              SelectAllFunctionalities: false,
              IsCheckFromFunctionalities: false,
              Functionalities: screens[i].SystemScreenFunctions?.map(
                (functionality) => {
                  return {
                    Functionality: functionality,
                    IsChecked: false,
                  } as Functionality;
                }
              ),
            };
            screenList.push(screen);
            for (let j = 0; j < screens[i].SystemScreenFunctions?.length; j++) {
              const functionality: Functionality = {
                Functionality: screens[i].SystemScreenFunctions![j],
                IsChecked: false,
              };
              functionlist.push(functionality);
            }
          }
          yield put(setGroupScreens(screenList));
          // yield put(setFunctionalities(functionlist));
        }

        if (scopes) {
          const scopeList: DropDownDTO[] = [];
          for (let i = 0; i < scopes.length; i++) {
            const scope: DropDownDTO = {
              Value: scopes[i].Id.toString(),
              Label: scopes[i].Scope ?? "",
            };
            scopeList.push(scope);
          }
          yield put(setScopes(scopeList));
        }

        yield put(setSuperGroupName(data.SuperGroupName ?? ""));
      }
    } catch (error) {
      throw error;
    }
    yield put(setLoading(false));
  });
};

const saveUserRoleScreenDataWatcher = function* (): any {
  yield takeEvery(SAVE_USERROLE_SCREEN_DATA, function* (action: AnyAction) {
    yield put(setLoading(true));
    try {
      const result: ActionResponseType = yield call(() =>
        saveUserRoleScreenData(action.payload)
      );

      let isNewTask = action.payload?.CurrentGroup?.Id == 0 ? true : false;

      if (!result.IsSuccess) {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occurred saving User Role";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else if (result.Data === null) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else if (result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: isNewTask
              ? "User Role saved successfully"
              : "User Role updated successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        let newObj = { ...action.payload?.CurrentGroup, Id: result.Data.Id };
        yield put(setCurrentGroup(newObj));
        yield put(setPageMode(EnumPageMode.ViewMode));
      }
    } catch (error) {
      throw error;
    }
    yield put(setLoading(false));
  });
};

const fetchSystemGroupByIdWatcher = function* (): any {
  yield takeEvery(FETCHSYSTEMGROUPBYID, function* (action: AnyAction): any {
    const groupId: number = action.payload;
    yield put(setLoading(true));

    try {
      const result: ActionResponseType = yield call(() =>
        getSystemGroupById(groupId)
      );

      if (result.IsSuccess) {
        yield put(setCurrentGroup(result.Data));
      }
    } catch (error) {
      throw error;
    }
    yield put(setLoading(false));
  });
};

const UserRoleManagementScreensSaga = function* (): any {
  yield all([
    getAddUserRoleScreenDataWatcher(),
    saveUserRoleScreenDataWatcher(),
    fetchSystemGroupByIdWatcher(),
  ]);
};
export default UserRoleManagementScreensSaga;
