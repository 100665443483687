import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import StandardQuestionsReportApi from "./StandardQuestionsReport.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { GET_STANDARD_QUESTIONS_EXCEL_REPORT_DATA } from "./StandardQuestionsReport.actions";
import { standardQuestionsReportReducerActions } from "./StandardQuestionsReport.reducer";
import {
  StandardQuestionsDownloadStatusType,
  StandardQuestionsReportType,
} from "./StandardQuestionsReport.types";
import dayjs from "dayjs";
import store from "../../../core/store/index";

const excelWorkerStandardsReport: Worker = new Worker(
  new URL("./ExcelWorkerStandardQuestionsReport.ts", import.meta.url)
);

excelWorkerStandardsReport.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  let downloadFileName = `${event.data.name} - Questions - ${dayjs(
    new Date()
  ).format("DD-MMM-YYYY HH_mm")}`;
  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension != null && extension != "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    standardQuestionsReportReducerActions.setExcelReportStatus({
      StandardId: event.data.standardId,
      IsDownloading: false,
      ExportFullReport: event.data.exportFullReport,
    } as StandardQuestionsDownloadStatusType)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerStandardsReport.terminate();
  };
};

const standardQuestionsReportWatcher = function* () {
  yield takeEvery(
    GET_STANDARD_QUESTIONS_EXCEL_REPORT_DATA,
    function* (action: AnyAction): any {
      const data = action.payload as ReportViewerType;
      const standardId =
        data.ReportSearchParameter.StandardQuestionSearchParams.StandardId;

      const exportFullReport =
        data.ReportSearchParameter.StandardQuestionSearchParams
          .ExportFullReport;

      //yield put(setLoading(true));
      try {
        const result: ActionResponseType = yield call(() =>
          StandardQuestionsReportApi.getStandardQuestionsReport(data)
        );
        if (result.IsSuccess) {
          const data = {
            StandardQuestionsReport: {
              ReportData: result.Data,
            },
            ExportFullReport: result.Data.ExportFullReport,
          } as StandardQuestionsReportType;
          excelWorkerStandardsReport.postMessage(data);
        } else {
          yield put(
            standardQuestionsReportReducerActions.setExcelReportStatus({
              StandardId: standardId,
              IsDownloading: false,
              ExportFullReport: exportFullReport,
            } as StandardQuestionsDownloadStatusType)
          );

          yield put(
            openPopup({
              Open: true,
              BodyText:
                "Sorry, An error occured while generating the excel report!",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick() {},
            } as PopupProps)
          );
          excelWorkerStandardsReport.terminate();
        }
      } catch {
        yield put(
          standardQuestionsReportReducerActions.setExcelReportStatus({
            StandardId: standardId,
            IsDownloading: false,
            ExportFullReport: exportFullReport,
          } as StandardQuestionsDownloadStatusType)
        );

        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );

        excelWorkerStandardsReport.terminate();
      }

      //yield put(setLoading(false));
    }
  );
};

const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const standardQuestionsReportSaga = function* () {
  yield all([standardQuestionsReportWatcher()]);
};

export default standardQuestionsReportSaga;
