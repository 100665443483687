import { useState, useEffect } from "react";
import { PageRegistrationProperties } from "../reducers/pageRegistrationReducer.types";
import { pageRegistrationReducerActions } from "../reducers/pageRegistrationReducer";
import { useAppSelector, useAppDispatch } from "./index";
import { useLocation } from "react-router-dom";

export const usePageRegistration = (
  properties?: PageRegistrationProperties | null
) => {
  const state = useAppSelector((s) => s.pageRegistrationReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [pageProperties, setPageProperties] =
    useState<PageRegistrationProperties | null>(() => {
      return state;
    });

  useEffect(() => {
    if (properties) {
      dispatch(pageRegistrationReducerActions.setState(properties));
    }
  }, [properties]);

  useEffect(() => {
    setPageProperties(state);
  }, [state]);

  useEffect(() => {
    setPageProperties(null);
  }, [location]);

  return [pageProperties] as const;
};

export default usePageRegistration;
