// project imports
import {
  SearchParameterDTO,
  StandardSubjectDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

export const getStandardSubjectList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubject/GetStandardSubjectList`;
    let standardSubject = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        standardSubject = response;
      })
      .catch((error) => {
        // console.log(error);
        standardSubject = {} as ActionResponseType;
      });
    return standardSubject;
  } catch (error) {
    throw error;
  }
};

export const saveStandardSubject = async (
  Params: StandardSubjectDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubject/SaveStandardSubject`;
    let standardSubject = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSubject = response;
      })
      .catch((error) => {
        standardSubject = {} as ActionResponseType;
        return error;
      });
    return standardSubject;
  } catch (error) {
    throw error;
  }
};

export const updateStandardSubject = async (
  Params: StandardSubjectDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubject/UpdateStandardSubject`;
    let standardSubject = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSubject = response;
      })
      .catch((error) => {
        standardSubject = {} as ActionResponseType;
        return error;
      });
    return standardSubject;
  } catch (error) {
    throw error;
  }
};

export const deleteStandardSubject = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSubject/DeleteStandardSubjectById/${id}`;
    let standardSubject = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response) => {
        standardSubject = response;
      })
      .catch((error) => {
        return error;
      });
    return standardSubject;
  } catch (error) {
    throw error;
  }
};
