// project imports
import {
  SearchParameterDTO,
  StandardSectionDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// client object for axios calls to backend
const client = new ApiClient();

export const getStandardSectionList = async (
  Params: SearchParameterDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSection/GetStandardSectionList`;
    let standardSection = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        standardSection = response;
      })
      .catch((error) => {
        // console.log(error);
        standardSection = {} as ActionResponseType;
      });
    return standardSection;
  } catch (error) {
    throw error;
  }
};

export const saveStandardSection = async (
  Params: StandardSectionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSection/SaveStandardSection`;
    let standardSection = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSection = response;
      })
      .catch((error) => {
        standardSection = {} as ActionResponseType;
        return error;
      });
    return standardSection;
  } catch (error) {
    throw error;
  }
};

export const updateStandardSection = async (
  Params: StandardSectionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSection/UpdateStandardSection`;
    let standardSection = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response) => {
        standardSection = response;
      })
      .catch((error) => {
        standardSection = {} as ActionResponseType;
        return error;
      });
    return standardSection;
  } catch (error) {
    throw error;
  }
};

export const deleteStandardSection = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `StandardSection/DeleteStandardSectionById/${id}`;
    let standardSection = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, id)
      .then((response) => {
        standardSection = response;
      })
      .catch((error) => {
        return error;
      });
    return standardSection;
  } catch (error) {
    throw error;
  }
};
