import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";

const client = new ApiClient();

export const getReport = async (
  params: ReportViewerType
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Report/GetReport`;

    let reportData = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, params)
      .then((response: ActionResponseType) => {
        reportData = response;
      })
      .catch((error) => {
        // console.log(error);
        reportData = {} as ActionResponseType;
        throw error;
      });
    return reportData;
  } catch (error) {
    throw error;
  }
};

const StandardReportApi = {
  getReport,
};

export default StandardReportApi;
