import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { SAVEPRIVACYNOTICEEMPLOYEE } from "./LandingPage.action";
import { AnyAction } from "redux";
import { TermsOfUseEmployeeDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { savePrivacyNoticeEmployee } from "./LandingPage.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";

const savePrivacuNoticeEmployeeWatcher = function* (): any {
  yield takeEvery(
    SAVEPRIVACYNOTICEEMPLOYEE,
    function* (action: AnyAction): any {
      try {
        const privacyEmployee: TermsOfUseEmployeeDTO = {
          Id: 1,
          EmployeeId: 1,
          StatusId: 1,
          ModifiedBy: null,
          ModifiedById: 1,
          ModifiedDate: null,
          Version: 1,
        } as TermsOfUseEmployeeDTO;

        const result: ActionResponseType = yield call(() =>
          savePrivacyNoticeEmployee(privacyEmployee)
        );
        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          console.log(result.Data);
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
    }
  );
};

const landingPageSaga = function* (): any {
  yield all([savePrivacuNoticeEmployeeWatcher()]);
};
export default landingPageSaga;
