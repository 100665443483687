import { createSlice } from "@reduxjs/toolkit";

import {
  ViewRemediationTrackerTaskHistoryProps,
  ViewRemediationTrackerTaskHistoryType,
} from "./ViewRemediationTrackerTaskHistory.types";

// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY - REDUCER ||============================== //
const initialState = {
  RemediationTrackerTaskId: 0,
  Identifier: "",
  Open: false,
  Ref: null,
  onCloseBtnClick() {},
} as ViewRemediationTrackerTaskHistoryType;

export const viewRemediationTrackerTaskHistorySlice = createSlice({
  name: "viewRemediationTrackerTaskHistory",
  initialState: initialState,
  reducers: {
    viewRemediationTrackerTaskHistoryDetails: (
      state: ViewRemediationTrackerTaskHistoryType,
      action: { payload: ViewRemediationTrackerTaskHistoryProps; type: string }
    ) => {
      return {
        ...state,
        RemediationTrackerTaskId: action.payload.RemediationTrackerTaskId,
        Identifier: action.payload.Identifier,
        Ref: action.payload.Ref,
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    setRemediationTrackerTaskHistoryDetails: (
      state: ViewRemediationTrackerTaskHistoryType,
      action: { payload: ViewRemediationTrackerTaskHistoryType; type: string }
    ) => {
      return {
        ...state,
        RemediationTrackerTaskHistory:
          action.payload.RemediationTrackerTaskHistory,
        Open: true,
      };
    },
    resetRemediationTrackerTaskHistory: () => {
      return initialState;
    },
  },
});

export const viewRemediationTrackerTaskHistorySliceReducerActions =
  viewRemediationTrackerTaskHistorySlice.actions;
export const viewRemediationTrackerTaskHistoryReducer =
  viewRemediationTrackerTaskHistorySlice.reducer;
