import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { GETASSESSMENTTYPEWIZARDSLIST } from "./AssessmentTypeWizardsPage.action";
import { getAssessmentTypeWizardList } from "./AssessmentTypeWizardsPage.api";
import { AssessmentTypeWizardsPageType } from "./AssessmentTypeWizardsPage.types";
import { AnyAction } from "redux";
import { assessmentTypeWizardsReducerActions } from "./AssessmentTypeWizardsPage.reducer";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - ASSESSMENT TYPE WIZARDS - SAGA ||============================== //

const getAssessmentTypeWizardListWatcher = function* (): any {
  yield takeEvery(
    GETASSESSMENTTYPEWIZARDSLIST,
    function* (action: AnyAction): any {
      const state: AssessmentTypeWizardsPageType = yield select(
        (s: RootState) => s.assessmentTypeWizardListReducer
      );

      // set page loading
      if (!state.DataLoading) {
        yield put(
          assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(false)
        );
        yield put(setLoading(true));
      }

      const searchPara: AssessmentTypeDTO = action.payload?.searchParameter;

      const result = yield call(
        async () => await getAssessmentTypeWizardList(searchPara)
      );

      if (result?.Data?.PageData == null && !result?.Data?.PageData) {
        // TODO document why this block is empty
      } else {
        yield put(
          assessmentTypeWizardsReducerActions.setAssessmentTypeWizardList({
            Data: result?.Data?.PageData,
            AssessmentTypeStatusSummary:
              result?.Data?.AssessmentTypeStatusSummary,
          } as AssessmentTypeWizardsPageType)
        );

        yield put(
          assessmentTypeWizardsReducerActions.setAssessmentTypeWizardSearchParameter(
            searchPara
          )
        );
      }

      yield put(
        assessmentTypeWizardsReducerActions.setColumnFilterDataLoading(false)
      );
      yield put(setLoading(false));
    }
  );
};

const assessmentTypeWizardListSaga = function* () {
  yield all([getAssessmentTypeWizardListWatcher()]);
};

export default assessmentTypeWizardListSaga;
