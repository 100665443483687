import dayjs from "dayjs";
import {
  Functionality,
  Pages,
  UserRoleManagementScreensPageType,
} from "./UserRoleManagementScreens.types";
import { createSlice } from "@reduxjs/toolkit";
import {
  DropDownDTO,
  SystemGroupDTO,
  SystemScreenCategoryDTO,
} from "../../../../common/types/common.dto.types";
import SimpleReactValidator from "simple-react-validator";

const initialState: UserRoleManagementScreensPageType = {
  GroupId: 0,
  SuperGroupName: "",
  Scopes: [],
  CurrentGroup: {
    Id: 0,
    ModifiedById: 0,
    ModifiedDate: dayjs(new Date()).format("YYYY-MM-DD"),
    AzureGroupName: "",
    Status: 0,
    IsActive: false,
    ScopeId: 0,
    StatusNavigation: null,
    CreatedByNavigation: null,
    CreatedById: 0,
    CreatedDate: dayjs(new Date()).format("YYYY-MM-DD"),
    GroupFunctions: null,
    GroupScreens: null,
  } as SystemGroupDTO,
  PageMode: 0,
  GroupScreens: [] as Pages[],
  Functionalities: [] as Functionality[],
  SelectAllPages: false,
  SelectAllFunctionality: false,
  GroupNameValidator: new SimpleReactValidator({
    messages: {
      default: "Group Name is required",
    },
  }),
  ScopeValidator: new SimpleReactValidator({
    messages: {
      default: "Scope is required",
    },
  }),
};

export const UserRoleManagementScreensSlice = createSlice({
  name: "UserRoleManagementScreens",
  initialState: initialState,
  reducers: {
    setGroupScreens: (
      state: UserRoleManagementScreensPageType,
      action: { payload: Pages[]; type: string }
    ) => {
      state.GroupScreens = action.payload;
    },
    setCurrentGroup: (
      state: UserRoleManagementScreensPageType,
      action: { payload: any; type: any }
    ) => {
      state.CurrentGroup = action.payload;
    },
    setFunctionalities: (
      state: UserRoleManagementScreensPageType,
      action: { payload: Functionality[]; type: string }
    ) => {
      state.Functionalities = action.payload;
    },
    setSuperGroupName: (
      state: UserRoleManagementScreensPageType,
      action: { payload: string; type: string }
    ) => {
      state.SuperGroupName = action.payload;
    },
    setScopes: (
      state: UserRoleManagementScreensPageType,
      action: { payload: DropDownDTO[]; type: string }
    ) => {
      state.Scopes = action.payload;
    },
    setPageMode: (
      state: UserRoleManagementScreensPageType,
      action: { payload: any; type: any }
    ) => {
      state.PageMode = action.payload;
    },

    resetScreen: (state: UserRoleManagementScreensPageType) => {
      return {
        ...state,
        IsDisable: true,
        CurrentGroup: initialState.CurrentGroup,
      };
    },
  },
});

export const {
  setGroupScreens,
  setFunctionalities,
  setSuperGroupName,
  setScopes,
  setPageMode,
  setCurrentGroup,
  resetScreen,
} = UserRoleManagementScreensSlice.actions;
export const UserRoleManagementScreenReducer =
  UserRoleManagementScreensSlice.reducer;
