import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  Switch,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { GridColDef } from "@mui/x-data-grid";

import { Gps, More, Setting3, Story } from "iconsax-react";
import {
  ViewRemediationTrackerTaskListColumnOptions,
  ViewRemediationTrackerTaskListToolbarColumnProps,
} from "./ViewRemediationTrackerTaskList.types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Target } from "framer-motion";
// ==============================|| HPR - DATAGRID Column Toolbar ||============================== //

const ViewRemediationTrackerTaskListColumnToolbar = (
  props: ViewRemediationTrackerTaskListToolbarColumnProps
) => {
  // Initial state
  const initialState = {
    Columns: [] as Array<GridColDef>,
    ShowColumnOption: false,
  } as ViewRemediationTrackerTaskListToolbarColumnProps;

  // Initiate state
  const [columnOptionState, setColumnOptionState] =
    useState<ViewRemediationTrackerTaskListToolbarColumnProps>(initialState);

  // Reducer manage fucntions
  const hprDataGridState = useAppSelector((state) => state.hprDataGridReducer);
  const dispatch = useAppDispatch();

  // Open toolbar settings
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // When HPRDataGridState changed set the values to page state
  useEffect(() => {
    setColumnOptionState((values) => {
      return {
        ...values,
        // Columns: hprDataGridState?.Columns,
        ShowColumnOption:
          hprDataGridState?.HPRDataGridToolbarProps
            ?.HPRDataGridToolbarColumnProps.ShowColumnOption,
      };
    });
  }, [hprDataGridState]);

  // Open toolbar Popover method
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close toolbar Popover method
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Hide all columns method
  const handleHideAll = () => {
    if (props.onChangeShowAllColumn) {
      props.onChangeShowAllColumn(false);
    }
  };

  // Show all columns method
  const handleShowAll = () => {
    if (props.onChangeShowAllColumn) {
      props.onChangeShowAllColumn(true);
    }
  };

  // Switch onchange method
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (props.OnChangeSwitch) {
      props.OnChangeSwitch(event, checked);
    }
  };

  // Drag And Drop
  const onSelectedColumnDragEnd = (result) => {
    const { destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    if (props.onChangeColumnDisplayOrder) {
      props.onChangeColumnDisplayOrder(startIndex, endIndex);
    }
    // const reorderColumnList = [...props.ListColumns];

    // const [removed] = reorderColumnList.splice(startIndex, 1);
    // reorderColumnList.splice(endIndex, 0, removed);
  };
  return (
    <div className="fx-left ml-10">
      {/* <Button onClick={handleClick} className="colmn-opt mb-10">
        <Setting3 className="m-r-10" style={{ fontSize: 20 }} />
        <span>Column options</span>
      </Button>  */}
      <Button
        className="rem-lined-btn"
        disableElevation
        size="small"
        variant="outlined"
        startIcon={<Setting3 variant="Outline" size={20} />}
        onClick={handleClick}
      >
        Column Options
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "30ch", height: "46ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <div className="body3 gray-text">
              Add or remove columns. To change the column order, drag and drop a
              field.
            </div>
            <Stack direction="row" className="flex--container space-between">
              <Button onClick={handleHideAll}>Hide all</Button>
              <Button onClick={handleShowAll}>Show all</Button>
            </Stack>
            <FormGroup>
              {/* <TextField
                id="standard-basic"
                label="Find column"
                variant="standard"
              /> */}
              <DragDropContext onDragEnd={onSelectedColumnDragEnd}>
                <Droppable droppableId="divSelectedColumnDrop">
                  {(provided) => (
                    <div className="column-option-wrapper">
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="column-option"
                      >
                        {props?.ListColumns.map((column, index: number) => {
                          return (
                            <Draggable
                              key={`D${
                                index + (column.ColumnId ?? column.ColumnName)
                              }`}
                              draggableId={`D_${
                                index +
                                "_" +
                                (column.ColumnId ?? column.ColumnName)
                              }`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="row-option"
                                  key={column.ColumnId ?? column.ColumnName}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Switch
                                        name={column?.ColumnName}
                                        checked={!column?.ColumnHide}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={handleChange}
                                        disabled={column.DisabledColumnHide}
                                      />
                                    }
                                    label={
                                      <>
                                        {column?.ColumnDisplayName}
                                        {column.DisabledColumnHide && (
                                          <Story
                                            size="15"
                                            color="#FF8A65"
                                            className="fixed-ico"
                                          />
                                        )}
                                      </>
                                    }
                                  />

                                  {/* <IconButton>
                                    <div className="rotate90 fx fx-column">
                                      <More size="18" color="#219ebc" /> */}
                                  {/* <More
                            size="18"
                            color="#219ebc"
                            style={{ position: "relative" }}
                          /> */}
                                  {/* </div>
                                  </IconButton> */}
                                  <IconButton className="drag-button">
                                    <More
                                      variant="Outline"
                                      className="ins-standard-drag drag-icon drag-icon-2"
                                    />
                                    <More
                                      variant="Outline"
                                      className="ins-standard-drag drag-icon"
                                    />
                                  </IconButton>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FormGroup>
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default ViewRemediationTrackerTaskListColumnToolbar;
