import { createSlice } from "@reduxjs/toolkit";
import { EnumGridDataShowRowCount } from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  QuestionParameterDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { SourcesPageInitialState } from "./SourcesPage.types";

// ==============================|| VIEWS - PAGES - SOURCES - REDUCER ||============================== //

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  SourceSearchParameter: {
    QuestionParameterSearchParams: {} as QuestionParameterDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
} as SourcesPageInitialState;

export const sourceSlice = createSlice({
  name: "sourceList",
  initialState: initialState,
  reducers: {
    setSourceList: (
      state: SourcesPageInitialState,
      action: { payload: PaginationDTO; type: string }
    ) => {
      return {
        ...state,
        Data: {
          Data: action.payload?.Data,
          TotalRecordsCount: action.payload?.TotalRecordsCount,
        } as PaginationDTO,
      };
    },
    setLoading: (state: SourcesPageInitialState, action) => {
      return { ...state, Loading: action.payload };
    },
    setSourceSearchParameter: (
      state: SourcesPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        SourceSearchParameter: {
          ...state.SourceSearchParameter,
          QuestionParameterSearchParams:
            action.payload.QuestionParameterSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
        } as SearchParameterDTO,
      };
    },
  },
});

export const { setSourceList, setLoading, setSourceSearchParameter } =
  sourceSlice.actions;
export const sourcesReducer = sourceSlice.reducer;
