import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - COMPONENTS - VIEW REMEDIATION TRACKER TASKS HISTORY - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getRemediationTrackerTaskHistoryDetails = async (
  remediationTrackerTaskId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `RemediationTrackerTasks/GetRemediationTrackerTaskHistoryDetails/${remediationTrackerTaskId}`;
    let historyDetailsResponse = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        historyDetailsResponse = response;
      })
      .catch((error) => {
        historyDetailsResponse = {} as ActionResponseType;
      });
    return historyDetailsResponse;
  } catch (error) {
    throw error;
  }
};
