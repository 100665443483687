import {
  SearchParameterDTO,
  SystemGroupDTO,
} from "../../../../common/types/common.dto.types";
import { SaveAzureGroupSiteListDTO } from "./UserRoleManagementLocationstypes";

export const GETAZUREGROUPSITELIST = "GETAZUREGROUPSITELIST";
export const SAVEAZUREGROUPSITELIST = "SAVEAZUREGROUPSITELIST";
export const SAVE_USERROLE_ADGROUP = "SAVE_USERROLE_ADGROUP";
export const FETCHSYSTEMGROUPBYID = "FETCHSYSTEMGROUPBYID";

export const getAzureGroupSiteList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETAZUREGROUPSITELIST,
    payload: searchParameter,
  };
};

export const saveAzureGroupSiteList = (
  azureGroupList: SaveAzureGroupSiteListDTO
) => {
  return {
    type: SAVEAZUREGROUPSITELIST,
    payload: azureGroupList,
  };
};

export const saveUserRoleAdgroup = (systemGroup: SystemGroupDTO) => {
  return {
    type: SAVE_USERROLE_ADGROUP,
    payload: systemGroup,
  };
};

export const fetchSystemGroupById = (groupId: number) => {
  return {
    type: FETCHSYSTEMGROUPBYID,
    payload: groupId,
  };
};
