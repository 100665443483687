import {AssignInspectionDTO} from "../../../common/types/common.dto.types";

export const GETASSIGNINSPECTIONDETAILS = "GETASSIGNINSPECTIONDETAILS";
export const SAVEASSIGNINSPECTIONDETAILS = "SAVEASSIGNINSPECTIONDETAILS";

export const getInspectionDetails = (id: number) => {
    return {
        type: GETASSIGNINSPECTIONDETAILS,
        payload: id,
    };
};

export const saveInspectionDetails = (inspection: AssignInspectionDTO) => {
    return {
        type: SAVEASSIGNINSPECTIONDETAILS,
        payload: inspection,
    };
};
