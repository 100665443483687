import { AssessmentTypeDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

const client = new ApiClient();

export const getAssessmentTypeWizardByIdApi = async (
  assessmentTypeId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `AssessmentTypeWizard/GetAssessmentTypeWizardById/${assessmentTypeId}`;
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveAssessmentTypeWizardApi = async (
  assessmentType: AssessmentTypeDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (
      assessmentType.Id > 0 &&
      assessmentType.CreateNewRecordWithGroupId === false
    ) {
      URL = "AssessmentTypeWizard/UpdateAssessmentTypeWizard";
    } else {
      URL = "AssessmentTypeWizard/AddAssessmentTypeWizard";
    }
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, assessmentType)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const checkBeforeDeactivateAssessmentTypeWizard = async (
  assessmentTypeId: number
): Promise<ActionResponseType> => {
  try {
    let URL: string = `AssessmentTypeWizard/IsAnyInspectionSetAssociatedWithAssesmentType/${assessmentTypeId}`;

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, assessmentTypeId)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
