import { EnumStatus } from "../../../common/enums/common.enums";
import {
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";
import { InspectionSetPageType } from "./InspectionSetPage.types";

export const GETAVAILABLEDATA = "GETAVAILABLEDATA";
export const GETSELECTEDDATA = "GETSELECTEDDATA";
export const SAVESELECTEDDATA = "SAVESELECTEDDATA";
export const DEACTIVATEINSPECTIONSET = "DEACTIVATEINSPECTIONSET";

export const getAvailableData = (pageState: InspectionSetPageType) => {
  return {
    type: GETAVAILABLEDATA,
    payload: pageState,
  };
};

export const saveSelectedData = (
  InspectionSetList: InspectionSetListTypeDTO
) => {
  return {
    type: SAVESELECTEDDATA,
    payload: InspectionSetList,
  };
};

export const deactivateInspectionSetApi = (
  inspectionSet: InspectionSetListTypeDTO
) => {
  return {
    type: DEACTIVATEINSPECTIONSET,
    payload: inspectionSet,
  };
};
