import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";

import {
  InspectionDTO,
  InspectionSetDTO,
  SearchParameterDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  DELETEINSPECTIONALLOCATION,
  GETINSPECTIONSETLISTFORINSPECTIONALLOCATION,
  GETSITEINSPECTIONALLOCATIONLIST,
  SAVEINSPECTIONALLOCATION,
  UPDATEINSPECTIONALLOCATION,
} from "./InspectionAllocationPage.action";
import {
  removeInspectionAllocation,
  setInspectionSetListForInspectionAllocation,
  setSiteInspection,
  setSiteInspectionAllocationList,
  setUpdatedSiteInspection,
} from "./InspectionAllocationPage.reducer";
import {
  deleteInspectionAllocation,
  getInspectionSetListForInspectionAllocation,
  getSiteInspectionAllocationList,
  saveInspectionAllocation,
  updateInspectionAllocation,
} from "./InspectionAllocationPage.api";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { ActionResponseType } from "../../../common/types/common.types";

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION - SAGA ||============================== //

const getInspectionSetListForInspectionAllocationWatcher = function* (): any {
  yield takeEvery(
    GETINSPECTIONSETLISTFORINSPECTIONALLOCATION,
    function* (action: AnyAction): any {
      // set page loading
      //yield put(setLoading(true));

      //Clear state
      yield put(
        setInspectionSetListForInspectionAllocation(
          [] as Array<InspectionSetDTO>
        )
      );
      const searchPara: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getInspectionSetListForInspectionAllocation(searchPara)
      );
      if (result.IsSuccess) {
        yield put(
          setInspectionSetListForInspectionAllocation(result?.Data?.Data)
        );
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
      //yield put(setLoading(false));
    }
  );
};

const getSiteInspectionAllocationListWatcher = function* (): any {
  yield takeEvery(
    GETSITEINSPECTIONALLOCATIONLIST,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      //Clear state
      yield put(setSiteInspectionAllocationList([] as Array<SiteDTO>));

      const searchPara: SearchParameterDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        getSiteInspectionAllocationList(searchPara)
      );
      if (result.IsSuccess) {
        yield put(setSiteInspectionAllocationList(result?.Data?.Data));
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
      yield put(setLoading(false));
    }
  );
};

const saveInspectionAllocationWatcher = function* (): any {
  yield takeEvery(SAVEINSPECTIONALLOCATION, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    // get data from backend
    try {
      const inspection: InspectionDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        saveInspectionAllocation(inspection)
      );
      if (result.IsSuccess) {
        let data: InspectionDTO = result.Data;
        yield put(setSiteInspection(data));
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred adding inspection set",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred adding inspection set",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

const updateInspectionAllocationWatcher = function* (): any {
  yield takeEvery(
    UPDATEINSPECTIONALLOCATION,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      // get data from backend
      try {
        const inspection: InspectionDTO = action.payload;

        const result: ActionResponseType = yield call(() =>
          updateInspectionAllocation(inspection)
        );
        if (result.IsSuccess) {
          let data: InspectionDTO = result.Data;
          yield put(setUpdatedSiteInspection(data));
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred adding planned date",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred adding planned date",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      yield put(setLoading(false));
    }
  );
};

const deleteInspectionAllocationWatcher = function* (): any {
  //
  yield takeEvery(
    DELETEINSPECTIONALLOCATION,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      // save System data from backend
      const inspection: InspectionDTO = action.payload;

      const result: ActionResponseType = yield call(() =>
        deleteInspectionAllocation(inspection.Id)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when deleting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        yield put(removeInspectionAllocation(inspection));
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const InspectionAllocationSaga = function* () {
  yield all([
    getInspectionSetListForInspectionAllocationWatcher(),
    getSiteInspectionAllocationListWatcher(),
    saveInspectionAllocationWatcher(),
    updateInspectionAllocationWatcher(),
    deleteInspectionAllocationWatcher(),
  ]);
};

export default InspectionAllocationSaga;
