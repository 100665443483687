import { takeEvery, put, call, select, fork } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryType,
} from "./ViewInspectionSummary.types";
import {
  InspectionDTO,
  InspectionStandardDTO,
} from "../../../common/types/common.dto.types";
import { viewInspectionSummarySliceReducerActions } from "./ViewInspectionSummary.reducer";
import { getSummaryOfInspection } from "./ViewInspectionSummary.api";
import { GETSUMMARYOFINSPECTION } from "./ViewInspectionSummary.action";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION SUMMARY - SAGA ||============================== //

const getViewInspectionSummaryWatcher = function* (): any {
  yield takeEvery(GETSUMMARYOFINSPECTION, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    const state: ViewInspectionSummaryType = yield select(
      (s: RootState) => s.viewInspectionSummaryReducer
    );

    // get data from backend
    try {
      const inspectionStandard: InspectionStandardDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getSummaryOfInspection(inspectionStandard)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No inspection standard(s) summary details  to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          let inspection: InspectionDTO = result.Data;

          if (
            state.SerachInspectionStandardSummary?.Inspection &&
            state.InspectionSummaryType ===
              EnumInspectionSummaryType.InspectionStandard
          ) {
            const withoutGapsCount =
              state.SerachInspectionStandardSummary?.Inspection
                ?.AnsweredWithoutGapCount ?? 0;
            const gapsCount =
              state.SerachInspectionStandardSummary?.Inspection
                ?.AnsweredWithGapCount ?? 0;
            const answeredAsNACount =
              state.SerachInspectionStandardSummary?.Inspection
                ?.AnsweredAsNACount ?? 0;
            const pendingCount =
              state.SerachInspectionStandardSummary?.Inspection
                ?.PendingQuestionCount ?? 0;
            const skippedCount =
              state.SerachInspectionStandardSummary?.Inspection
                ?.AnsweredWithSkipCount ?? 0;

            const applicableQuestionCount = withoutGapsCount + gapsCount;

            const questionPrecentage =
              applicableQuestionCount == 0
                ? "N/A"
                : Math.round(
                    ((applicableQuestionCount - gapsCount) /
                      applicableQuestionCount) *
                      100
                  ).toString() + "%";

            inspection = {
              ...inspection,
              AnsweredWithoutGapCount: withoutGapsCount,
              AnsweredWithGapCount: gapsCount,
              AnsweredWithSkipCount: skippedCount,
              AnsweredAsNACount: answeredAsNACount,
              PendingQuestionCount: pendingCount,
              ApplicableQuestionCount: applicableQuestionCount,
              QuestionCountPrecentage: questionPrecentage,
              InspectionStandards: [
                ...inspection.InspectionStandards.map((item) => {
                  return {
                    ...item,
                    InspectionStandardSummary: {
                      ...item?.InspectionStandardSummary,
                      WithoutGapsCount: withoutGapsCount,
                      GapsCount: gapsCount,
                      SkippedCount: skippedCount,
                      AnsweredAsNACount: answeredAsNACount,
                      PendingCount: pendingCount,
                      ApplicableQuestionCount: applicableQuestionCount,
                      QuestionPrecentage: questionPrecentage,
                    },
                  } as InspectionStandardDTO;
                }),
              ],
            };
          }

          yield put(
            viewInspectionSummarySliceReducerActions.setSummaryOfInspection({
              ...state,
              InspectionSummary: inspection,
              Open: true,
            })
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading on
    yield put(setLoading(false));
  });
};

const viewInspectionSummarySaga = function* () {
  yield fork(getViewInspectionSummaryWatcher);
};

export default viewInspectionSummarySaga;
