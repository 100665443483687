import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
} from "@redux-saga/core/effects";
import { channel } from "redux-saga";
import { AnyAction } from "redux";
import {
  SAVEREMEDIATIONTRACKERTASK,
  GETQUESTIONSFORREMEDIATIONTRACKERTASKS,
  GETREMEDIATIONTRACKERTASK,
  REMEDIATIONTRACKERTASKFILEUPLOAD,
  DELETEFILESONCANCEL,
  remediationTrackerTaskNavigate,
  REMEDIATIONTRACKERTASKNAVIGATE,
  saveRemediationTrackerTask,
} from "./RemediationTrackerTaskPage.action";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  RemediationTrackerTaskDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { ActionResponseType } from "../../../common/types/common.types";
import {
  saveRemediationTrackerTaskApi,
  getQuestionsForRemediationTrackerTasks,
  getRemediationTrackerTaskById,
  remediationTrackerTaskFileUpload,
  deleteFilesOnCancelClick,
} from "./RemediationTrackerTaskPage.api";
import { remediationTrackerTaskReducerActions } from "./RemediationTrackerTaskPage.reducer";
import { RootState } from "../../../core/store";
import {
  EnumDataTransferStatus,
  EnumPageMode,
  EnumRemediationTrackerTaskStatus,
} from "../../../common/enums/common.enums";
import { RemediationTrackerTaskPageType } from "./RemediationTrackerTaskPage.types";

// Channel for navigation
const navigationChannel = channel();
export function* navigationChannelWatcher() {
  while (true) {
    const action = yield take(navigationChannel);
    yield put(action);
  }
}

const multiUserApprovalChannel = channel();
export function* multiuserApprovalChannelChannelWatcher() {
  while (true) {
    const action = yield take(multiUserApprovalChannel);
    yield put(action);
  }
}

const navigateTaskWatcher = function* (): any {
  yield takeEvery(
    REMEDIATIONTRACKERTASKNAVIGATE,
    function* (action: AnyAction): any {
      yield put(
        remediationTrackerTaskReducerActions.navigateToRemediationTrackerTasks(
          true
        )
      );
    }
  );
};

const getRemediationTrackerTaskWatcher = function* (): any {
  yield takeEvery(
    GETREMEDIATIONTRACKERTASK,
    function* (action: AnyAction): any {
      yield put(setLoading(true));

      let remediationTrackerTaskId: number = action.payload;

      try {
        let result: ActionResponseType = yield call(() =>
          getRemediationTrackerTaskById(remediationTrackerTaskId)
        );

        if (result.IsSuccess) {
          const remediationTrackerTask: RemediationTrackerTaskDTO = result.Data;

          yield put(
            remediationTrackerTaskReducerActions.setRemediationTrackerTask({
              RemediationTrackerTask: remediationTrackerTask,
              SaveByFileUpload: false,
            })
          );
        } else {
          let errorMessage: string = "";
          let navigateOnOk = false;
          if (result.ErrorMessage && result.ErrorMessage !== "") {
            if (result.Data?.Id === 0) {
              navigateOnOk = true;
            }
            errorMessage = result.ErrorMessage;
          } else {
            errorMessage = "An error occurred when getting data";
          }
          yield put(
            openPopup({
              Open: true,
              BodyText: errorMessage,
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
              OkBtnClick: () => {
                if (navigateOnOk) {
                  navigationChannel.put(remediationTrackerTaskNavigate());
                }
              },
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
      yield put(
        remediationTrackerTaskReducerActions.changePageMode(
          EnumPageMode.ViewMode
        )
      );
      yield put(setLoading(false));
    }
  );
};

const getQuestionsForRemediationTrackerTasksWatcher = function* (): any {
  yield takeEvery(
    GETQUESTIONSFORREMEDIATIONTRACKERTASKS,
    function* (action: AnyAction): any {
      yield put(setLoading(true));

      let inspectionQuestionIdList: Array<number> = action.payload;

      try {
        let result: ActionResponseType = yield call(() =>
          getQuestionsForRemediationTrackerTasks(inspectionQuestionIdList)
        );
        if (result.IsSuccess) {
          const remediationTrackerTask: RemediationTrackerTaskDTO = result.Data;

          yield put(
            remediationTrackerTaskReducerActions.setRemediationTrackerTask({
              RemediationTrackerTask: remediationTrackerTask,
              SaveByFileUpload: false,
            })
          );
        } else {
          let errorMessage: string = "";
          if (result.ErrorMessage && result.ErrorMessage !== "") {
            errorMessage = result.ErrorMessage;
          } else {
            errorMessage = "An error occurred when getting data";
          }
          yield put(
            openPopup({
              Open: true,
              BodyText: errorMessage,
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
      yield put(
        remediationTrackerTaskReducerActions.changePageMode(
          EnumPageMode.AddMode
        )
      );
      yield put(setLoading(false));
    }
  );
};

// const getStatusMessage = (
//   statusChange: boolean,
//   statusDescription: string,
//   isInApprovalProcess: boolean,
//   status: EnumRemediationTrackerTaskStatus,
//   prevStatus: EnumRemediationTrackerTaskStatus,
//   isSMEApproved: boolean,
//   numberOfReviewers: number,
//   numberOfReviewersAlreadyApproved: number,
//   isSMEApproval: boolean,
//   isSiteExecutiveApproval: boolean
// ): string => {
//   if (!statusChange) {
//     return "Task saved successfully";
//   } else if (status === EnumRemediationTrackerTaskStatus.PendingReview) {
//     if (isSMEApproval) {
//       return `Pending Review Approved(SME) successfully`;
//     } else if (isSiteExecutiveApproval) {
//       return `Task approved successfully by ${numberOfReviewersAlreadyApproved}/${numberOfReviewers} reviewer(s)`;
//     } else {
//       if (!isSMEApproved) {
//         return `Task status changed to "${statusDescription}(SME)" successfully`;
//       } else {
//         return `Task status changed to "${statusDescription}(Site)" successfully`;
//       }
//     }
//   } else if (
//     status === EnumRemediationTrackerTaskStatus.ActionInProgress &&
//     prevStatus === EnumRemediationTrackerTaskStatus.PendingClosureApproval
//   ) {
//     return `Task approval rejected status changed to "${statusDescription}" successfully`;
//   } else if (status === EnumRemediationTrackerTaskStatus.ReviewCompleted) {
//     return `Task approved and status changed to "${statusDescription}" successfully`;
//   } else if (
//     status === EnumRemediationTrackerTaskStatus.PendingClosureApproval
//   ) {
//     if (isSiteExecutiveApproval) {
//       return `Task approved successfully by ${numberOfReviewersAlreadyApproved}/${numberOfReviewers} reviewer(s)`;
//     } else {
//       return `Task sent for "${statusDescription}(Site)" successfully`;
//     }
//   }
//   return `Task status changed to "${statusDescription}" successfully`;
// };

const getStatusMessage = (
  statusChange: boolean,
  historyMessage: string
): string => {
  if (!statusChange) {
    return "Task saved successfully";
  } else {
    return historyMessage;
  }
};
const saveRemediationTrackerTaskWatcher = function* (): any {
  yield takeEvery(
    SAVEREMEDIATIONTRACKERTASK,
    function* (action: AnyAction): any {
      yield put(setLoading(true));

      let remediationTrackerTask: RemediationTrackerTaskDTO =
        action.payload.remediationTrackerTask;

      let tempSelectedActionOwnerId =
        remediationTrackerTask.SelectedActionOwnerId;

      let statusChange: boolean = action.payload.statusChange;

      const state: RemediationTrackerTaskPageType = yield select(
        (s: RootState) => s.remediationTrackerTaskReducer
      );

      let toBeUploaded: Array<UploadFileDTO> =
        state.RemediationTrackerTask.UploadFiles.filter(
          (file) =>
            file.UploadStatus === EnumDataTransferStatus.NotStarted ||
            file.UploadStatus === EnumDataTransferStatus.InProgress
        );

      if (toBeUploaded.length > 0) {
        yield put(
          remediationTrackerTaskReducerActions.updateTaskSaveRunningState({
            saveTaskRunning: true,
            remediationTrackerTask: remediationTrackerTask,
          })
        );
      } else {
        let priorityList = remediationTrackerTask.PriorityDropDownList;
        let actionOwnerList = remediationTrackerTask.ActionOwnerDropDownList;
        let isCapexRequiredList =
          remediationTrackerTask.IsCapexRequiredDropDownList;
        let capexStatusList = remediationTrackerTask.CapexStatusDropDownList;
        let capexPlannedYearList =
          remediationTrackerTask.CapexPlannedYearDropDownList;

        try {
          let result: ActionResponseType = yield call(() =>
            saveRemediationTrackerTaskApi({
              ...remediationTrackerTask,
              ActionOwnerDropDownList: [],
              PriorityDropDownList: [],
              IsCapexRequiredDropDownList: [],
              CapexStatusDropDownList: [],
              AreaOwnerTypeDropDownList: [],
              CapexPlannedYearDropDownList: [],
            })
          );

          if (result.IsSuccess) {
            if (result.Data.IsSameUserHasMultipleRole) {
              yield put(
                openPopup({
                  Open: true,
                  BodyText:
                    "This task has more than one site executive positions were assigned to the current user. Do you wish to proceed and approve all at once?",
                  HeaderText: "Confirmation",
                  PopupType: EnumPopupType.YesNoConfirmation,
                  YesBtnClick() {
                    multiUserApprovalChannel.put(
                      saveRemediationTrackerTask(
                        {
                          ...remediationTrackerTask,
                          CheckSameUserHasMultipleRole: false,
                          IsSameUserHasMultipleRole: false,
                        } as RemediationTrackerTaskDTO,
                        statusChange
                      )
                    );
                  },
                })
              );
            } else {
              remediationTrackerTask = result.Data;
              if (remediationTrackerTask.Id === 0) {
                yield put(
                  remediationTrackerTaskReducerActions.navigateToRemediationTrackerTasks(
                    true
                  )
                );
              } else {
                remediationTrackerTask.ActionOwnerDropDownList =
                  actionOwnerList;
                remediationTrackerTask.PriorityDropDownList = priorityList;
                remediationTrackerTask.IsCapexRequiredDropDownList =
                  isCapexRequiredList;
                remediationTrackerTask.CapexStatusDropDownList =
                  capexStatusList;
                remediationTrackerTask.CapexPlannedYearDropDownList =
                  capexPlannedYearList;

                let uploadFileList = remediationTrackerTask.UploadFiles.filter(
                  (file) =>
                    !remediationTrackerTask.RemovedFileIdList.includes(file.Id)
                );

                remediationTrackerTask.UploadFiles = uploadFileList;

                yield put(
                  openPopup({
                    Open: true,
                    BodyText: getStatusMessage(
                      statusChange,
                      remediationTrackerTask.HistoryMessage
                    ),
                    HeaderText: "Success",
                    PopupType: EnumPopupType.SuccessPopup,
                  } as PopupProps)
                );

                yield put(
                  remediationTrackerTaskReducerActions.setRemediationTrackerTask(
                    {
                      RemediationTrackerTask: {
                        ...remediationTrackerTask,
                        SelectedActionOwnerId: tempSelectedActionOwnerId,
                      },
                      SaveByFileUpload: false,
                    }
                  )
                );
                yield put(
                  remediationTrackerTaskReducerActions.changePageMode(
                    EnumPageMode.ViewMode
                  )
                );
              }
            }
          } else {
            let errorMessage: string = "";
            if (result.ErrorMessage && result.ErrorMessage !== "") {
              errorMessage = result.ErrorMessage;
            } else {
              errorMessage = "An error occured when saving the task";
            }
            yield put(
              openPopup({
                Open: true,
                BodyText: errorMessage,
                HeaderText: "Error",
                PopupType: EnumPopupType.ErrorPopup,
              } as PopupProps)
            );
          }
        } catch (error) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occured when saving the task",
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }

        yield put(setLoading(false));
      }
    }
  );
};

const deleteFilesOnCancelWatcher = function* (): any {
  yield takeEvery(DELETEFILESONCANCEL, function* (action: AnyAction): any {
    yield put(setLoading(true));
    let remediationTrackerTask: RemediationTrackerTaskDTO = action.payload;

    const state: RemediationTrackerTaskPageType = yield select(
      (s: RootState) => s.remediationTrackerTaskReducer
    );

    // let toBeUploaded: Array<UploadFileDTO> =
    //   state.RemediationTrackerTask.UploadFiles.filter(
    //     (file) =>
    //       file.UploadStatus == EnumDataTransferStatus.NotStarted ||
    //       file.UploadStatus == EnumDataTransferStatus.InProgress
    //   );

    // if (toBeUploaded.length > 0) {
    //   yield put(
    //     remediationTrackerTaskReducerActions.updateTaskSaveRunningState({
    //       saveTaskRunning: true,
    //       remediationTrackerTask: remediationTrackerTask,
    //     })
    //   );
    // } else {
    try {
      let result: ActionResponseType = yield call(() =>
        deleteFilesOnCancelClick(remediationTrackerTask)
      );

      if (result.IsSuccess) {
        let stateRemediationTrackerTask: RemediationTrackerTaskDTO =
          Object.assign({}, state.RemediationTrackerTask);

        let uploadFileList = stateRemediationTrackerTask.UploadFiles.filter(
          (file) => !remediationTrackerTask.RemovedFileIdList.includes(file.Id)
        );
        yield put(
          remediationTrackerTaskReducerActions.setRemediationTrackerTask({
            RemediationTrackerTask: {
              ...state.RemediationTrackerTask,
              UploadFiles: uploadFileList,
              RemovedFileIdList: remediationTrackerTask.RemovedFileIdList,
            },
            SaveByFileUpload: false,
          })
        );
        // yield put(
        //   remediationTrackerTaskReducerActions.changePageMode(
        //     EnumPageMode.ViewMode
        //   )
        // );
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occured when deleting the file(s)";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occured when deleting the file(s)",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    yield put(setLoading(false));
    //}
  });
};

// const saveRemediationTrackerTaskByFileUploadWatcher = function* (): any {
//   yield takeEvery(
//     SAVEREMEDIATIONTRACKERTASKBYFILEUPLOAD,
//     function* (action: AnyAction): any {
//       yield put(setLoading(true));

//       let remediationTrackerTask: RemediationTrackerTaskDTO =
//         action.payload.remediationTrackerTask;
//       let filesToBeUploaded: Array<UploadFileDTO> =
//         action.payload.filesToBeUploaded;
//       let filesToBeRemoved: Array<number> = action.payload.filesToBeRemoved;
//       let priorityList = remediationTrackerTask.PriorityDropDownList;
//       let actionOwnerList = remediationTrackerTask.ActionOwnerDropDownList;
//       remediationTrackerTask.ActionOwnerDropDownList = [];
//       remediationTrackerTask.PriorityDropDownList = [];
//       //remediationTrackerTask.UploadFiles = null;

//       const state: RemediationTrackerTaskPageType = yield select(
//         (s: RootState) => s.remediationTrackerTaskReducer
//       );

//       try {
//         let result: ActionResponseType = yield call(() =>
//           saveRemediationTrackerTask(remediationTrackerTask)
//         );

//         if (result.IsSuccess) {
//           remediationTrackerTask = result.Data;
//           filesToBeUploaded = filesToBeUploaded.map((file) => {
//             return {
//               ...file,
//               RemediationTrackerTaskId: remediationTrackerTask.Id,
//             };
//           });
//           remediationTrackerTask.ActionOwnerDropDownList = actionOwnerList;
//           remediationTrackerTask.PriorityDropDownList = priorityList;
//           remediationTrackerTask.UploadFiles = filesToBeUploaded;
//           remediationTrackerTask.RemovedFileIdList = filesToBeRemoved;

//           // yield put(
//           //   openPopup({
//           //     Open: true,
//           //     BodyText: "Task saved successfully",
//           //     HeaderText: "Success",
//           //     PopupType: EnumPopupType.SuccessPopup,
//           //   } as PopupProps)
//           // );
//           yield put(
//             remediationTrackerTaskReducerActions.setRemediationTrackerTask({
//               RemediationTrackerTask: remediationTrackerTask,
//               SaveByFileUpload: true,
//             })
//           );
//         } else {
//           let errorMessage: string = "";
//           if (result.ErrorMessage && result.ErrorMessage != "") {
//             errorMessage = result.ErrorMessage;
//           } else {
//             errorMessage = "An error occured when saving the task";
//           }
//           yield put(
//             openPopup({
//               Open: true,
//               BodyText: errorMessage,
//               HeaderText: "Error",
//               PopupType: EnumPopupType.ErrorPopup,
//             } as PopupProps)
//           );
//         }
//       } catch (error) {
//         yield put(
//           openPopup({
//             Open: true,
//             BodyText: "An error occured when saving the task",
//             HeaderText: "Error",
//             PopupType: EnumPopupType.ErrorPopup,
//           } as PopupProps)
//         );
//       }

//       yield put(setLoading(false));
//     }
//   );
// };

// const removeRemediationTrackerTaskWatcher = function* (): any {
//   yield takeEvery(
//     REMOVEREMEDIATIONTRACKERTASK,
//     function* (action: AnyAction): any {
//       yield put(setLoading(true));

//       let remediationTrackerTaskId: number = action.payload;
//       const state: RemediationTrackerTaskPageType = yield select(
//         (s: RootState) => s.remediationTrackerTaskReducer
//       );
//       try {
//         let result: ActionResponseType = yield call(() =>
//           removeRemediationTrackerTask(remediationTrackerTaskId)
//         );
//         if (result.IsSuccess) {
//           yield put(
//             remediationTrackerTaskReducerActions.setRemediationTrackerTask({
//               RemediationTrackerTask: state.RemediationTrackerTask,
//               SaveByFileUpload: false,
//             })
//           );
//         } else {
//           let errorMessage: string = "";
//           if (result.ErrorMessage && result.ErrorMessage != "") {
//             errorMessage = result.ErrorMessage;
//           } else {
//             errorMessage = "An error occurred when getting data";
//           }
//           yield put(
//             openPopup({
//               Open: true,
//               BodyText: errorMessage,
//               HeaderText: "Error",
//               PopupType: EnumPopupType.ErrorPopup,
//             } as PopupProps)
//           );
//         }
//       } catch (error) {
//         yield put(
//           openPopup({
//             Open: true,
//             BodyText: "An error occurred when getting data",
//             HeaderText: "Error",
//             PopupType: EnumPopupType.ErrorPopup,
//           } as PopupProps)
//         );
//       }
//       yield put(
//         remediationTrackerTaskReducerActions.changePageMode(
//           EnumPageMode.AddMode
//         )
//       );
//       yield put(setLoading(false));
//     }
//   );
// };

const remediationTrackerTaskFileUploadWatcher = function* (): any {
  yield takeEvery(
    REMEDIATIONTRACKERTASKFILEUPLOAD,
    function* (action: AnyAction): any {
      try {
        let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
        let inprogressUploaded: UploadFileDTO = {} as UploadFileDTO;

        do {
          const state: RemediationTrackerTaskPageType = yield select(
            (s: RootState) => s.remediationTrackerTaskReducer
          );

          inprogressUploaded = state.RemediationTrackerTask.UploadFiles.find(
            (file) => file.UploadStatus === EnumDataTransferStatus.InProgress
          );

          if (!inprogressUploaded) {
            toBeUploaded = state.RemediationTrackerTask.UploadFiles.filter(
              (file) => file.UploadStatus === EnumDataTransferStatus.NotStarted
            );
            if (toBeUploaded && toBeUploaded.length > 0) {
              // create temp upload file list
              let tempUploadFileList: Array<UploadFileDTO> = [
                ...state.RemediationTrackerTask.UploadFiles,
              ] as Array<UploadFileDTO>;

              // select next file index to be uploading
              let nextToBeUploadedFileIndex: number =
                tempUploadFileList.findIndex(
                  (file) =>
                    file.UploadStatus === EnumDataTransferStatus.NotStarted
                );

              // update next file to be uploading status to inprogress
              tempUploadFileList[nextToBeUploadedFileIndex] = {
                ...tempUploadFileList[nextToBeUploadedFileIndex],
                UploadStatus: EnumDataTransferStatus.InProgress,
              };

              // update ui status of next file to be uploading
              yield put(
                remediationTrackerTaskReducerActions.updateUploadFileList(
                  tempUploadFileList
                )
              );

              const result = yield call(
                async () =>
                  await remediationTrackerTaskFileUpload(
                    tempUploadFileList[nextToBeUploadedFileIndex]
                  )
              );

              if (!result.IsSuccess) {
              } else {
                yield put(
                  remediationTrackerTaskReducerActions.updateUploadFileStatus({
                    ...result.Data,
                    UploadStatus: EnumDataTransferStatus.Finished,
                  })
                );
                yield put(
                  remediationTrackerTaskReducerActions.updateTempFileIdList(
                    result.Data?.Id
                  )
                );
              }
            }
          }
        } while (toBeUploaded.length > 0);
        const state: RemediationTrackerTaskPageType = yield select(
          (s: RootState) => s.remediationTrackerTaskReducer
        );

        if (state.SaveTaskRunning) {
          yield put(
            remediationTrackerTaskReducerActions.updateRunSaveTaskState(true)
          );
        }
      } catch (error) {}
    }
  );
};

const remediationTrackerTaskSaga = function* () {
  yield all([
    getRemediationTrackerTaskWatcher(),
    getQuestionsForRemediationTrackerTasksWatcher(),
    saveRemediationTrackerTaskWatcher(),
    deleteFilesOnCancelWatcher(),
    remediationTrackerTaskFileUploadWatcher(),
    navigationChannelWatcher(),
    navigateTaskWatcher(),
    multiuserApprovalChannelChannelWatcher(),
  ]);
};

export default remediationTrackerTaskSaga;
