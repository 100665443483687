/* Meterial */

import { IconButton, Tooltip } from "@mui/material";

/* Icons */
import { CloseCircle, ArrowDown2, ArrowUp2, More } from "iconsax-react";
import { EnumInspectionSetListType } from "../../../common/enums/common.enums";

/* Components */
import HPRCheckBox from "../HPRCheckBox/HPRCheckBox";
import { HPRInspectionSetDragItemProps } from "./HPRInspectionSetDragItem.types";
import { EnumListType } from "../../../views/pages/InspectionSetPage/InspectionSetPage.types";

{
  /* Questions */
}

const HPRInspectionSetDragItem = (props: HPRInspectionSetDragItemProps) => {
  const handleCheck = () => {
    props.CheckBoxOnClickFunction(
      props.Index,
      props.Id,
      props.ListType,
      props.SelectedItemType,
      !props.Checked
    );
  };

  const setListItemColor = () => {
    let color = "";

    if (props.SelectedItemType === EnumInspectionSetListType.Standard) {
      color = "standard";
    } else if (props.SelectedItemType === EnumInspectionSetListType.Reference) {
      color = "reference";
    } else if (props.SelectedItemType === EnumInspectionSetListType.System) {
      color = "system";
    } else if (props.SelectedItemType === EnumInspectionSetListType.SubSystem) {
      color = "sub-system";
    } else if (props.SelectedItemType === EnumInspectionSetListType.Subject) {
      color = "subject";
    } else if (props.SelectedItemType === EnumInspectionSetListType.Section) {
      color = "section";
    } else if (props.SelectedItemType === EnumInspectionSetListType.Question) {
      color = "question";
    }

    return color;
  };
  return (
    <div
      className="ins-standard-item-wrap cursor-pointer  flex--container"
      key={props.Id + props.ListType}
    >
      <div
        className={`ins-standard-mark ${setListItemColor()} flex__item--inherit`}
      ></div>

      <div className="ins-standard-chkbx flex__item--inherit">
        <HPRCheckBox
          Id={`Chk_${props.ListType}_${props.Id}`}
          Name={`Chk_${props.ListType}_${props.Id}`}
          ClassName={""}
          onCheckBoxChange={handleCheck}
          checked={props.Checked}
          Disabled={props.CheckedDisabled}
        />
      </div>
      <Tooltip title={props.Name} placement="bottom-start">
        <div
          className="ins-standard-text flex__item limit-2"
          onClick={() =>
            props.ItemOnClickFunction(
              props.Id,
              props.ListType,
              props.SelectedItemType,
              props.Checked
            )
          }
        >
          {props.SelectedItemType === EnumInspectionSetListType.Question ? (
            <span>Q {props.Code}</span>
          ) : (
            ""
          )}
          {props.Name}
        </div>
      </Tooltip>

      <div className="ins-standard-controls  flex__item--inherit">
        <div
          className="d-flex valign-middle"
          onClick={() =>
            props.ItemOnClickFunction(
              props.Id,
              props.ListType,
              props.SelectedItemType,
              props.Checked
            )
          }
        >
          {/* <div className="view-more-text">More</div> */}

          <div
            className="d-flex"
            style={{
              display:
                props.SelectedItemType !== EnumInspectionSetListType.Question
                  ? "flex"
                  : "none",
            }}
          >
            <span className="ins-standard-q-count">{props.QuestionCount} </span>
            <span>Question(s)</span>
          </div>

          {/* <span className="ins-standard-q-count">6 </span>
    <span>Quetion(s)</span> */}
        </div>

        {/* <IconButton className="more-button">
          <span>More</span>
          <ArrowUp2 variant="Outline" className="more-icon" />

          <ArrowDown2 variant="Outline" className="more-icon" />
        </IconButton> */}
        {props.SelectedItemType !== EnumInspectionSetListType.Standard &&
          props.ListType !== EnumListType.Available && (
            <IconButton className="drag-button">
              <More
                variant="Outline"
                className="ins-standard-drag drag-icon drag-icon-2"
              />
              <More variant="Outline" className="ins-standard-drag drag-icon" />
            </IconButton>
          )}
        {props.IsShowClose ? (
          <IconButton>
            <CloseCircle
              variant="Outline"
              className="ins-standard-drag close"
            />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default HPRInspectionSetDragItem;
