import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardSectionDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { RootState } from "../../../core/store";
import { SectionPageType } from "./SectionPage.types";
import {
  DELETESTANDARDSECTION,
  GETSTANDRDSECTIONLIST,
  SAVESTANDARDRESECTION,
  UPDATESTANDARDSECTION,
} from "./SectionPage.action";
import {
  setPageMode,
  setSectionModalOpen,
  setStandardSection,
  setStandardSectionList,
} from "./SectionPage.reducer";
import {
  deleteStandardSection,
  getStandardSectionList,
  saveStandardSection,
  updateStandardSection,
} from "./SectionPage.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";

// ==============================|| VIEWS - PAGES - STANDARDSubject - SAGA ||============================== //

const getStandardSectionListWatcher = function* (): any {
  yield takeEvery(GETSTANDRDSECTIONLIST, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));
    // get data from backend

    try {
      const SectionSearchParam: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getStandardSectionList(SectionSearchParam)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No section data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
        } else {
          const paginationtype: SectionPageType = {
            Data: result.Data,
          } as SectionPageType;
          yield put(
            setStandardSectionList({
              ...paginationtype,
              Data: {
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading on
    yield put(setLoading(false));
  });
};

const saveStandardSectionWatcher = function* (): any {
  yield takeEvery(SAVESTANDARDRESECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save Subject data from backend
      const sectionPageType: SectionPageType = action.payload;

      const result: ActionResponseType = yield call(() =>
        saveStandardSection(sectionPageType.StandardSection)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //yield put(setStandardSubject(result));
        if (sectionPageType.ButtonModeType === EnumButtonModeType.Save) {
          yield put(setStandardSection(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          sectionPageType.ButtonModeType === EnumButtonModeType.SaveAndContinue
        ) {
          yield put(
            setStandardSection({
              Id: 0,
              Name: "",
              StandardId: sectionPageType.StandardSection.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSectionDTO)
          );
          yield put(setPageMode(EnumPageMode.AddMode));
        } else if (
          sectionPageType.ButtonModeType === EnumButtonModeType.SaveAndClose
        ) {
          yield put(
            setStandardSection({
              Id: 0,
              Name: "",
              StandardId: sectionPageType.StandardSection.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSectionDTO)
          );

          yield put(setPageMode(EnumPageMode.Default));
          yield put(setSectionModalOpen(false));
        }
        //get Subject List
        const standardSectionState: SectionPageType = yield select(
          (s: RootState) => s.standardSectionReducer
        );
        const listResult: ActionResponseType = yield call(() =>
          getStandardSectionList({
            ...sectionPageType.StandardSectionSearchParameter,
            SelectedPage: 1,
          })
        );
        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          // if (
          //   sectionPageType.ButtonModeType ===
          //   EnumButtonModeType.SaveAndContinue
          // ) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully saved",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
          // }
          const paginationtype: SectionPageType = {
            Data: listResult.Data,
          } as SectionPageType;
          yield put(
            setStandardSectionList({
              ...paginationtype,
              Data: {
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: 1,
              } as PaginationDTO,
            })
          );
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const updateStandardSectionWatcher = function* (): any {
  //
  yield takeEvery(UPDATESTANDARDSECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save reference data from backend

      const sectionPageType: SectionPageType = action.payload;
      const result: ActionResponseType = yield call(() =>
        updateStandardSection(sectionPageType.StandardSection)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when updating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (sectionPageType.ButtonModeType === EnumButtonModeType.Update) {
          yield put(setStandardSection(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          sectionPageType.ButtonModeType === EnumButtonModeType.UpdateAndClose
        ) {
          yield put(
            setStandardSection({
              Id: 0,
              Name: "",
              StandardId: sectionPageType.StandardSection.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardSectionDTO)
          );
          yield put(setPageMode(EnumPageMode.Default));
          yield put(setSectionModalOpen(false));
        }
        //get Subject List
        const standardSectionState: SectionPageType = yield select(
          (s: RootState) => s.standardSectionReducer
        );
        const listResult: ActionResponseType = yield call(() =>
          getStandardSectionList({
            ...sectionPageType.StandardSectionSearchParameter,
            SelectedPage:
              standardSectionState.StandardSectionSearchParameter.SelectedPage,
            GridPageSize:
              standardSectionState.StandardSectionSearchParameter.GridPageSize,
          } as SearchParameterDTO)
        );
        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          const paginationtype: SectionPageType = {
            Data: listResult.Data,
          } as SectionPageType;
          yield put(
            setStandardSectionList({
              ...paginationtype,
              Data: {
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when updating data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const deleteStandardSectionWatcher = function* (): any {
  //
  yield takeEvery(DELETESTANDARDSECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save Subject data from backend
      const sectionPageType: SectionPageType = action.payload;

      const result: ActionResponseType = yield call(() =>
        deleteStandardSection(sectionPageType.StandardSection.Id)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when deactivating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //get Subject List
        const standardSectionState: SectionPageType = yield select(
          (s: RootState) => s.standardSectionReducer
        );
        yield put(
          setStandardSection({
            Id: 0,
            Name: "",
            StandardId: standardSectionState.StandardSection.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardSectionDTO)
        );
        yield put(setPageMode(EnumPageMode.Default));
        yield put(setSectionModalOpen(false));

        const listResult: ActionResponseType = yield call(() =>
          getStandardSectionList({
            ...sectionPageType.StandardSectionSearchParameter,
            SelectedPage: 1,
            GridPageSize:
              standardSectionState.StandardSectionSearchParameter.GridPageSize,
          } as SearchParameterDTO)
        );
        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          const paginationtype: SectionPageType = {
            Data: listResult.Data,
          } as SectionPageType;
          yield put(
            setStandardSectionList({
              ...paginationtype,
              Data: {
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO,
            })
          );
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when deleting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }
    // set page loading
    yield put(setLoading(false));
  });
};

const standardSectionSaga = function* () {
  yield all([
    getStandardSectionListWatcher(),
    saveStandardSectionWatcher(),
    updateStandardSectionWatcher(),
    deleteStandardSectionWatcher(),
  ]);
};

export default standardSectionSaga;
