import { SearchParameterDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - STANDARD - ACTION ||============================== //

export const GETSTANDARDLIST = "GETSTANDARDLIST";

export const getStandardList = (searchParameter: SearchParameterDTO) => {
  return {
    type: GETSTANDARDLIST,
    payload: searchParameter,
  };
};
