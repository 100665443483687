/** @format */

//-------common imports-----//

import { Box, Button, Grid, Modal, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import LinearProgress from "@mui/material/LinearProgress";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Document } from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  ArrowLeft2,
  CardTick,
  CloseCircle,
  CloseSquare,
  Edit2,
} from "iconsax-react";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import {
  DropDownDTO,
  InspectionDTO,
  InspectionMemberDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  StandardDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import {
  CopyInspectionStandardDTO,
  QuestionMainDataType,
  ReopenInspectionStandardData,
  SiteInspectionStartDataType,
  SiteInspectionStartPageParametersType,
} from "./SiteInspectionStartPage.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  completeInspection,
  completeInspectionStandard,
  copyInspectionStandard,
  getSiteInspectionStartData,
  reOpenInspectionStandard,
  reopenCompleteInspection,
  resumeInspectionStandard,
  saveInspectionSiteTeamMember,
} from "./SiteInspectionStartPage.action";
import {
  clearPageModeRedirectMode,
  setPageMode,
} from "./SiteInspectionStartPage.reducer";
import {
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumInspectionStatus,
  EnumPageMode,
  EnumPageType,
  EnumReportType,
} from "../../../common/enums/common.enums";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRLinedTextArea from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea";
import { QuestionnaireStartPageParametersTypes } from "../QuestionnaireStartPage/QuestionnaireStartPage.types";
import { EnumLinedTextAreaType } from "../../../components/common/HPRLinedTextArea/HPRLinedTextArea.enum";
import { QuestionnaireSummaryParameterDTO } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import dayjs from "dayjs";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import StandardExcelReportDownload from "../../Reports/StandardReport/StandardReport.Excel";
import InspectionExcelReportDownload from "../../Reports/StandardsSummaryReport/StandardsSummaryReport.Excel";
import ReportViewerNew from "../../../components/ReportViewer/ReportViewerNew";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { QuestionnaireParametersType } from "../QuestionnairePage/QuestionnairePage.types";
import { viewInspectionSummarySliceReducerActions } from "../../components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryProps,
} from "../../components/ViewInspectionSummary/ViewInspectionSummary.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import HPRReportMenu from "../../../components/common/HPRReportMenu/HPRReportMenu";
import {
  EnumReportFormatType,
  HPRReportMenuType,
} from "../../../components/common/HPRReportMenu/HPRReportMenu.types";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: true,
    IsShowAddBtn: false,
    IsShowEditBtn: true,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Inspection: {} as InspectionDTO,
  SiteTeamMemberName: "",
  InspectionName: "",
  InspectonDescription: "",
  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
  QuestionMainData: [] as Array<QuestionMainDataType>,
  InspectionMembers: [] as Array<InspectionMemberDTO>,
  AllowResumption: true,
  InspectionStandardIdToResume: [],
  ReopenUpdateData: [] as Array<ReopenInspectionStandardData>,
} as SiteInspectionStartDataType;

const SiteInspectionStartPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for StandardPageType with initialState values
  const [siteInspectionStartPageState, setSiteInspectionStartPageState] =
    useState(initialState);

  // pass parameters to page
  // use for page navigation
  const navigate = useNavigate();
  const authState = useAppSelector((state) => state.authReducer);
  const location = useLocation();
  const parameter: SiteInspectionStartPageParametersType = location.state;

  const FetchedRef = useRef(false);

  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
  } as UsePromptTypes;
  usePrompt(prompt);

  const initCopyModalState: CopyInspectionStandardDTO = {
    Id: 0,
    SelectedBuildingId: 0,
    Suffix: "",
    Location: "",
    RelatedEntity: "",
    Title: "",
  };
  const [copyModalState, setCopyModalState] =
    useState<CopyInspectionStandardDTO>(initCopyModalState);

  const dispatch = useAppDispatch();
  const siteInspectionStartState = useAppSelector(
    (state) => state.siteInspectionStartReducer
  );

  const [buildingList, setBuildingList] = useState<Array<DropDownDTO>>();

  //Page onload
  useEffect(() => {
    if (FetchedRef.current) return;
    FetchedRef.current = true;
    if (parameter.InspectionId > 0) {
      dispatch(getSiteInspectionStartData(parameter.InspectionId));
    } else {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          Inspection: initialState.Inspection,
        };
      });
    }
  }, []);

  // every time when reducer state change set values to page state
  useEffect(() => {
    if (!FetchedRef.current) return;

    let questionMainData = siteInspectionStartState.QuestionMainData;
    let inspectionQuestionList =
      siteInspectionStartState.Inspection.InspectionQuestions;
    let questionMainDataTemp = [];
    let tempQuestionList = [];
    if (
      siteInspectionStartState.AllowResumption &&
      siteInspectionStartState.InspectionStandardIdToResume.length > 0
    ) {
      questionMainDataTemp = questionMainData.map((item) => {
        var temp = Object.assign({}, item);
        if (
          siteInspectionStartState.InspectionStandardIdToResume.includes(
            temp.InspectionStandardId
          )
        ) {
          let reOpenedObject = siteInspectionStartState.ReopenUpdateData.find(
            (x) => x.InspectionStandardId === temp.InspectionStandardId
          );

          return {
            ...item,
            InspectionStandardStatus: EnumInspectionStandardStatus.Inprogress,
            InspectionStandardReOpenedBy: reOpenedObject?.ReopenedBy,
            InspectionStandardReOpenedDate: reOpenedObject?.ReopenedDate,
            InspectionStandardStatusName: reOpenedObject?.StatusName,
            PendingCount: temp.SkippedCount,
            SkippedCount: 0,
          };
        }
        return item;
      });

      questionMainData = questionMainDataTemp;
      tempQuestionList =
        siteInspectionStartState.Inspection.InspectionQuestions.map((x) => {
          if (
            siteInspectionStartState.InspectionStandardIdToResume.includes(
              x.InspectionStandardId
            ) &&
            x.InspectionQuestionStatusId ===
              EnumInspectionQuestionStatus.Skipped
          ) {
            return {
              ...x,
              InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
            };
          }
          return x;
        });
      inspectionQuestionList = tempQuestionList;
    }

    setSiteInspectionStartPageState((values) => {
      return {
        ...values,
        Inspection: siteInspectionStartState.Inspection,
        InspectionName: siteInspectionStartState.Inspection.Name,
        InspectonDescription: siteInspectionStartState.Inspection.Description,
        QuestionMainData: questionMainData,
        InspectionMembers: siteInspectionStartState.InspectionMembers,
        SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
        PageMode: siteInspectionStartState.PageMode,
        InspectionQuestionList: inspectionQuestionList,

        AllowResumption: siteInspectionStartState.AllowResumption,
        InspectionStandardIdToResume:
          siteInspectionStartState.InspectionStandardIdToResume,
      };
    });

    //NAVIGATION
    if (siteInspectionStartState?.PageMode?.RedirctOption?.NavigateURl !== "") {
      if (
        siteInspectionStartState?.PageMode?.RedirctOption?.NavigateURl ===
        "/remediationTrackerTasks"
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: `Inspection "${siteInspectionStartState.Inspection.Name}" closed and tasks were created in Remediation Tracker`,
            HeaderText: `Closing inspection - ${siteInspectionStartState.Inspection.Name}`,
            PopupType: EnumPopupType.SuccessPopup,
            OkButtonText: "Close",
            NoButtonText: "Go to Remediation Tracker",
            NoBtnClick() {
              navigate("/remediationTrackerTasks");
            },
          } as PopupProps)
        );
      } else {
        navigate(
          siteInspectionStartState.PageMode.RedirctOption.NavigateURl,
          siteInspectionStartState.PageMode.RedirctOption.NavigateObject
        );
      }
      dispatch(clearPageModeRedirectMode());
    }

    if (siteInspectionStartState.PageMode.PageMode !== EnumPageMode.Default) {
      if (siteInspectionStartState.Inspection.Site.Buildings) {
        const dropDownDTOList: DropDownDTO[] =
          siteInspectionStartState.Inspection.Site.Buildings.map(
            (building) =>
              ({
                Value: building.Id.toString(),
                Label: building.BuildingName,
              } as DropDownDTO)
          );
        setBuildingList(dropDownDTOList);
      }
    }
  }, [siteInspectionStartState]);

  const OnTextAreaChange = (name: string, value: any) => {
    if (siteInspectionStartPageState?.InspectionMembers.length > 0) {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          SiteTeamMemberName: value,
          InspectionMembers:
            siteInspectionStartPageState?.InspectionMembers.map(
              (member: InspectionMemberDTO, index) => {
                return {
                  ...member,
                  MemberName: value,
                } as InspectionMemberDTO;
              }
            ),
        };
      });
    } else {
      setSiteInspectionStartPageState((values) => {
        return {
          ...values,
          SiteTeamMemberName: value,
          InspectionMembers: [
            ...values.InspectionMembers,
            {
              MemberName: value,
              SiteInspectionId: siteInspectionStartPageState.Inspection.Id,
            } as InspectionMemberDTO,
          ],
        };
      });
    }

    setIsDirty(true);
  };

  const onClickSaveBtn = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to save?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            saveInspectionSiteTeamMember(
              siteInspectionStartPageState?.InspectionMembers
            )
          );
          setIsDirty(false);
        },
      } as PopupProps)
    );
  };

  const checkSiteMemberAdd = () => {
    let prviousSiteMemberCount = 0;
    let newSiteMemberCount = 0;
    let isValidate = false;

    if (siteInspectionStartState.InspectionMembers.length > 0) {
      prviousSiteMemberCount =
        siteInspectionStartState.InspectionMembers[0].MemberName.split(
          "@@"
        ).length;
    }
    if (siteInspectionStartPageState?.InspectionMembers.length > 0) {
      newSiteMemberCount =
        siteInspectionStartPageState.InspectionMembers[0].MemberName.split(
          "@@"
        ).length;
    }

    if (prviousSiteMemberCount !== newSiteMemberCount) {
      isValidate = true;
    }

    return isValidate;
  };

  const onClickCompleteBtn = (
    saveStatus?: EnumInspectionStandardStatus,
    standardId?: number,
    inspectionId?: number,
    inspectionStandardId?: number
  ): void => {
    CompleteInspection(
      saveStatus,
      standardId,
      inspectionId,
      inspectionStandardId
    );
  };

  function CompleteInspection(
    saveStatus?: EnumInspectionStandardStatus,
    standardId?: number,
    inspectionId?: number,
    inspectionStandardId?: number
  ): void {
    let questionPendingList =
      siteInspectionStartPageState.InspectionQuestionList.filter(
        (obj: InspectionQuestionDTO) => {
          if (
            obj.StandardId === standardId &&
            obj.SiteInspectionId === inspectionId &&
            obj.InspectionStandardId === inspectionStandardId &&
            obj.InspectionQuestionStatusId ===
              EnumInspectionQuestionStatus.Pending
          ) {
            return obj;
          }
        }
      );

    dispatch(
      completeInspectionStandard({
        InspectionQuestionList: questionPendingList,
        SiteInspectionID: inspectionId,
        StandardID: standardId,
        InspectionStandardStatus: saveStatus,
        InspectionStandardId: inspectionStandardId,
        UploadedFileList: [] as Array<UploadFileDTO>,
      } as QuestionnaireDTO)
    );
  }

  function backOnClick(): void {
    if (checkSiteMemberAdd()) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            navigate("/siteInspections", {});
          },
        } as PopupProps)
      );
    } else {
      navigate("/siteInspections", {});
    }
  }

  const onClickCloseInspection = (): void => {
    if (checkSiteMemberAdd()) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                InspectionMembers: [],
                SiteTeamMemberName: "",
              };
            });
            setIsDirty(false);
            getInspectionSummary();
          },
        } as PopupProps)
      );
    } else if (
      siteInspectionStartPageState.SiteTeamMemberName !==
      siteInspectionStartState.SiteTeamMemberName
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            setIsDirty(false);
            getInspectionSummary();
          },
        } as PopupProps)
      );
    } else {
      setIsDirty(false);
      getInspectionSummary();
    }
  };

  const onClickInspectionStatus = (): void => {
    getInspectionStatus();
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
    //setIsDirty(true);
  };

  const onClickCloseBtn = () => {
    if (checkSiteMemberAdd()) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                InspectionMembers: [],
                SiteTeamMemberName: "",
              };
            });
            setIsDirty(false);
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else if (
      siteInspectionStartPageState.SiteTeamMemberName !==
      siteInspectionStartState.SiteTeamMemberName
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard site team member changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            setIsDirty(false);
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else {
      setIsDirty(false);
      dispatch(setPageMode(EnumPageMode.ViewMode));
    }
  };

  const onClickReOpen = (mainData: QuestionMainDataType) => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: `Are you sure you want to re-open "${
          mainData.InspectionStandardName
        }${mainData.Suffix ? " - " + mainData.Suffix : ""}" standard`,
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            reOpenInspectionStandard({
              SiteInspectionId: mainData.InspectionId,
              StandardId: mainData.StandardId,
              InspectionStandardId: mainData.InspectionStandardId,
              InspectionStandardName: mainData.Suffix
                ? mainData.InspectionStandardName + " - " + mainData.Suffix
                : mainData.InspectionStandardName,
            } as QuestionnaireSummaryParameterDTO)
          );
        },
      } as PopupProps)
    );
  };
  // modal popup
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  //------------------------------------

  function handleOpenInNewTab() {
    const searchParams = new URLSearchParams({
      ReportType: EnumReportType.SummeryReport.toString(),
      InspectionID: siteInspectionStartPageState.Inspection.Id.toString(),
    });

    window.open(`/report?${searchParams}`, "_blank");
  }

  function handleOpenCopyModal(inspectionStandard: QuestionMainDataType) {
    if (isDirty) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard the changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setIsDirty(false);
            // console.log(inspectionStandard);
            setCopyModalState((prevState) => ({
              ...prevState,
              Id: inspectionStandard.InspectionStandardId,
              InspectionId: parameter.InspectionId,

              Suffix: "copy",
              Title: inspectionStandard.InspectionStandardName,
            }));
            setOpen(true);
          },
        } as PopupProps)
      );
    } else {
      // console.log(inspectionStandard);
      setCopyModalState((prevState) => ({
        ...prevState,
        Id: inspectionStandard.InspectionStandardId,
        InspectionId: parameter.InspectionId,

        Suffix: "copy",
        Title: inspectionStandard.InspectionStandardName,
      }));
      setOpen(true);
    }
  }

  function onClickCreateCopy() {
    dispatch(copyInspectionStandard(copyModalState));
    setOpen(false);
  }

  function onChangeBuilding(
    selectOption: DropDownDTO[],
    selectedValue: string,
    name?: string
  ) {
    // console.log(selectOption);
    setCopyModalState((prevState) => ({
      ...prevState,
      SelectedBuildingId: parseInt(selectedValue),
    }));
  }

  const OnGenaratingReport = (blob: any): void => {
    if (blob._INTERNAL__LAYOUT__DATA_.children.length > 2) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }
  };

  function onClickResume(mainData: QuestionMainDataType) {
    {
      dispatch(resumeInspectionStandard(mainData.InspectionStandardId));
      //navigate("/questionnaire", {
      //    state: {
      //        SiteInspectionID: mainData.InspectionId,
      //        StandardID: mainData.StandardId,
      //        InspectionStandardId: mainData.InspectionStandardId,
      //    } as QuestionnaireParametersType,
      //});

      navigate("/questionnaireStart", {
        state: {
          InspectionStandardId: mainData.InspectionStandardId,
          StandardId: mainData.StandardId,
          InspectionId: mainData.InspectionId,
          Title: mainData.Suffix
            ? mainData.InspectionStandardName + " - " + mainData.Suffix
            : mainData.InspectionStandardName,
          IsContinueButtonVisible: true,
        } as QuestionnaireStartPageParametersTypes,
      });
    }
  }

  const getInspectionSummary = () => {
    // dispatch(
    //   getSummaryOfInspectionBeforeClose({
    //     InspectionId: siteInspectionStartPageState.Inspection?.Id,
    //   } as InspectionStandardDTO)
    // );
    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.Inspection,
        SerachInspectionStandard: {
          InspectionId: siteInspectionStartPageState.Inspection?.Id,
        } as InspectionStandardDTO,
        onCompleteBtnClick(inspectionSummary: InspectionDTO) {
          onClickCompleteInspectionBtn(inspectionSummary);
        },
      } as ViewInspectionSummaryProps)
    );
  };

  const getInspectionStatus = () => {
    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.InspectionStatus,
        SerachInspectionStandard: {
          InspectionId: siteInspectionStartPageState.Inspection?.Id,
        } as InspectionStandardDTO,
      } as ViewInspectionSummaryProps)
    );
  };

  const onClickCompleteInspectionBtn = (
    inspectionSummary: InspectionDTO
  ): void => {
    dispatch(completeInspection(inspectionSummary));
  };

  //standard wise close
  const getInspectionStandardSummary = (inspectionStandardId: number) => {
    if (isDirty) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Do you want to discard the changes?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setIsDirty(false);
            setSiteInspectionStartPageState((values) => {
              return {
                ...values,
                SiteTeamMemberName: siteInspectionStartState.SiteTeamMemberName,
              };
            });
            dispatch(
              viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails(
                {
                  InspectionSummaryType:
                    EnumInspectionSummaryType.InspectionStandard,
                  SerachInspectionStandard: {
                    Id: inspectionStandardId,
                    InspectionId: siteInspectionStartPageState.Inspection?.Id,
                  } as InspectionStandardDTO,
                  onCompleteBtnClick(inspectionSummary: InspectionDTO) {
                    if (inspectionSummary?.InspectionStandards?.length > 0) {
                      const standardId =
                        inspectionSummary?.InspectionStandards[0].Standard?.Id;
                      const inspectionId =
                        inspectionSummary?.InspectionStandards[0].InspectionId;
                      const inspectionStandardId =
                        inspectionSummary?.InspectionStandards[0].Id;

                      onClickCompleteBtn(
                        EnumInspectionStandardStatus.Completed,
                        standardId,
                        inspectionId,
                        inspectionStandardId
                      );
                    }
                  },
                } as ViewInspectionSummaryProps
              )
            );
            dispatch(setPageMode(EnumPageMode.ViewMode));
          },
        } as PopupProps)
      );
    } else {
      dispatch(
        viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
          InspectionSummaryType: EnumInspectionSummaryType.InspectionStandard,
          SerachInspectionStandard: {
            Id: inspectionStandardId,
            InspectionId: siteInspectionStartPageState.Inspection?.Id,
          } as InspectionStandardDTO,
          onCompleteBtnClick(inspectionSummary: InspectionDTO) {
            if (inspectionSummary?.InspectionStandards?.length > 0) {
              const standardId =
                inspectionSummary?.InspectionStandards[0].Standard?.Id;
              const inspectionId =
                inspectionSummary?.InspectionStandards[0].InspectionId;
              const inspectionStandardId =
                inspectionSummary?.InspectionStandards[0].Id;

              onClickCompleteBtn(
                EnumInspectionStandardStatus.Completed,
                standardId,
                inspectionId,
                inspectionStandardId
              );
            }
          },
        } as ViewInspectionSummaryProps)
      );
      dispatch(setPageMode(EnumPageMode.ViewMode));
    }
  };

  const onClickReopenInspectionBtn = (): void => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to re-open inspection?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(
            reopenCompleteInspection({
              SiteInspectionID: siteInspectionStartPageState.Inspection.Id,
            } as QuestionnaireDTO)
          );
        },
      } as PopupProps)
    );
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.SiteInspectionStart,
      functionId,
      functionTypeId
    );
  };

  return (
    <>
      <div className="content-area-wrapper">
        <Grid container columnSpacing={2}>
          <Grid item xs={1}>
            <a
              style={{ display: "inline-flex" }}
              className="cursor-pointer audit-back v-align"
              aria-label="back"
              onClick={backOnClick}
              title={"Back to inspection page"}
            >
              {/* <ArrowLeft2 size={40} /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color flex__item--vcenter">
                Back
              </span>
            </a>
          </Grid>
          <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
            {/* <Grid container columnSpacing={2}>
              <Grid item xs={7}> */}
            <div className="fx">
              <div className="width100-p">
                <h1>
                  {siteInspectionStartPageState.InspectionName
                    ? siteInspectionStartPageState.InspectionName
                    : ""}
                </h1>
              </div>

              {/* <p>{""}</p> */}
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={1} xl={1}></Grid> */}
              {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="justify-space v-align"
              > */}
              <Grid container spacing={1} className="fx-right" mb={2}>
                {siteInspectionStartPageState?.QuestionMainData?.length > 0 ? (
                  <Grid item>
                    {/* <InspectionExcelReportDownload
                        InspectionId={
                          siteInspectionStartPageState.Inspection?.Id
                        }
                      /> */}
                    <HPRReportMenu
                      ReportList={
                        [
                          {
                            ReportName: "UpToDate Inspection Summary",
                            ReportFormatType: EnumReportFormatType.EXCEL,
                            ReportComponent: (
                              <InspectionExcelReportDownload
                                InspectionId={
                                  siteInspectionStartPageState.Inspection?.Id
                                }
                              />
                            ),
                            HideReport: !checkSystemFunctionRight(
                              EnumSystemFunctions.SiteInspectionPage_New_Summary,
                              EnumSystemFunctionTypes.ButtonAccess
                            ),
                          },
                          {
                            ReportName: "Inspection Summary Report",
                            ReportFormatType: EnumReportFormatType.PDF,
                            ReportComponent: (
                              <ReportViewerNew
                                ReportType={EnumReportType.SummeryReport}
                                Inspection={
                                  {
                                    Id: siteInspectionStartPageState.Inspection
                                      .Id,
                                  } as InspectionDTO
                                }
                                ReportLinkName="Inspection Summary Report"
                                ReportDownloadName="SummaryReport"
                                OnRendering={OnGenaratingReport}
                              >
                                <div className={`colmn-opt pdf`}>
                                  <span className="">
                                    <Document
                                      className="m-r-10"
                                      style={{ fontSize: 20 }}
                                      color="#f44336"
                                    />
                                  </span>
                                  <span>Inspection Summary Report</span>
                                </div>
                              </ReportViewerNew>
                            ),
                            HideReport: !(
                              siteInspectionStartPageState.Inspection &&
                              siteInspectionStartPageState.Inspection
                                ?.InspectionStatusId ===
                                EnumInspectionStatus.Completed &&
                              checkSystemFunctionRight(
                                EnumSystemFunctions.SiteInspectionPage_Download_InspectionSummary,
                                EnumSystemFunctionTypes.ButtonAccess
                              )
                            ),
                          },
                        ] as Array<HPRReportMenuType>
                      }
                    ></HPRReportMenu>
                  </Grid>
                ) : (
                  <></>
                )}

                {checkSystemFunctionRight(
                  EnumSystemFunctions.SiteInspectionPage_ViewStatistics,
                  EnumSystemFunctionTypes.ButtonAccess
                ) &&
                  siteInspectionStartPageState?.QuestionMainData?.length >
                    0 && (
                    <>
                      <Grid item>
                        <Button
                          className="primary small-btn"
                          variant="contained"
                          disableElevation
                          onClick={onClickInspectionStatus}
                        >
                          Inspection Statistics
                        </Button>
                      </Grid>
                    </>
                  )}

                {checkSystemFunctionRight(
                  EnumSystemFunctions.SiteInspectionPage_CloseInspection,
                  EnumSystemFunctionTypes.ButtonAccess
                ) &&
                  siteInspectionStartPageState?.QuestionMainData?.length > 0 &&
                  siteInspectionStartPageState?.Inspection
                    ?.InspectionStatusId !== EnumInspectionStatus.Completed && (
                    <>
                      <Grid item>
                        <Button
                          className="primary small-btn"
                          variant="contained"
                          disableElevation
                          onClick={
                            onClickCloseInspection // onClickCompleteInspectionBtn()
                          }
                        >
                          Close Inspection
                        </Button>
                      </Grid>
                    </>
                  )}
                {checkSystemFunctionRight(
                  EnumSystemFunctions.SiteInspectionPage_ReopenInspection,
                  EnumSystemFunctionTypes.ButtonAccess
                ) &&
                  siteInspectionStartPageState?.QuestionMainData?.length > 0 &&
                  siteInspectionStartPageState?.Inspection
                    ?.InspectionStatusId === EnumInspectionStatus.Completed && (
                    <>
                      <Grid item>
                        <Button
                          className="primary small-btn"
                          variant="contained"
                          disableElevation
                          onClick={() => onClickReopenInspectionBtn()}
                        >
                          Re-open inspection
                        </Button>
                      </Grid>
                    </>
                  )}
              </Grid>
              {/* </Grid> */}
              {/* </Grid> */}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          {siteInspectionStartPageState?.QuestionMainData?.length > 0 ? (
            <>
              <Grid item xl={1} lg={1} md={1} sm={12} xs={12}></Grid>
              <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                <Grid container spacing={2} wrap={"wrap-reverse"}>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                    {siteInspectionStartPageState.QuestionMainData.map(
                      (mainData, index) => {
                        return (
                          <div key={index} className="item-wrapper">
                            <div className="item-header">
                              <h2>
                                {mainData.InspectionStandardName}{" "}
                                {mainData.Suffix ? "- " + mainData.Suffix : ""}
                                {checkSystemFunctionRight(
                                  EnumSystemFunctions.SiteInspectionPage_CreateCopy,
                                  EnumSystemFunctionTypes.ButtonAccess
                                ) &&
                                siteInspectionStartPageState.Inspection
                                  .InspectionStatusId !==
                                  EnumInspectionStatus.Completed ? (
                                  <a
                                    className="link-button-type1 align-right"
                                    onClick={() =>
                                      handleOpenCopyModal(mainData)
                                    }
                                  >
                                    <ContentCopyIcon
                                      className="copy-ico"
                                      style={{ marginRight: 10 }}
                                    />
                                    Create a copy
                                  </a>
                                ) : null}
                              </h2>
                            </div>
                            <div className="item-content">
                              <Grid container>
                                <div className="site-ins-fist">
                                  <span className="item-lbl-val sub-info-stat flex--container">
                                    <label className="audit-sub-lbl flex__item--inherit">
                                      Status:{" "}
                                    </label>

                                    {mainData.InspectionStandardStatus ===
                                    EnumInspectionStandardStatus.Intial ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val gray-text flex__item">
                                          {
                                            mainData.InspectionStandardStatusName /* Not started */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Inprogress ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val flex__item inprogress-text">
                                          {
                                            mainData.InspectionStandardStatusName /* Started */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val green-text-audit flex__item">
                                          {
                                            mainData.InspectionStandardStatusName /* Completed */
                                          }
                                        </label>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title={
                                          mainData.InspectionStandardStatusName
                                        }
                                      >
                                        <label className="audit-sub-val orange-text">
                                          {
                                            mainData.InspectionStandardStatusName /* Paused */
                                          }
                                        </label>
                                      </Tooltip>
                                    )}
                                  </span>
                                </div>
                                <div className="site-ins-second second">
                                  <span className="item-lbl-val sm-left sub-info flex--container">
                                    <label className="audit-sub-lbl flex__item--inherit no-wrap">
                                      Global primary SME:{" "}
                                    </label>
                                    <Tooltip
                                      title={
                                        mainData.GlobalPrimarySME != null
                                          ? mainData.GlobalPrimarySME
                                          : ""
                                      }
                                    >
                                      <label className="audit-sub-val short gray-text flex__item">
                                        {mainData.GlobalPrimarySME != null
                                          ? mainData.GlobalPrimarySME
                                          : "N/A"}
                                      </label>
                                    </Tooltip>
                                  </span>
                                </div>
                                <div className="site-ins-second">
                                  <span className="item-lbl-val sm-left sub-info flex--container no-wrap">
                                    <label className="audit-sub-lbl flex__item--inherit">
                                      Site primary SME(s):{" "}
                                    </label>
                                    <Tooltip
                                      title={
                                        mainData?.SitePrimarySME
                                          ? mainData?.SitePrimarySME
                                          : ""
                                      }
                                    >
                                      <label className="audit-sub-val gray-text flex__item">
                                        {mainData?.SitePrimarySME
                                          ? mainData?.SitePrimarySME
                                          : "N/A"}
                                      </label>
                                    </Tooltip>
                                  </span>
                                </div>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <span className="audit-qty-lbl audit-progress-item-qty">
                                    <span className="light-blue-text">
                                      ({mainData.AllQuestionCount}){" "}
                                    </span>
                                    Questions
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div className="progress-bar-wrapper">
                                    {mainData.InspectionStandardStatus ===
                                    EnumInspectionStandardStatus.Completed ? (
                                      <LinearProgress
                                        variant="determinate"
                                        value={
                                          mainData.ProgressBarValue
                                            ? Math.round(
                                                ((mainData.AnsweredCount +
                                                  mainData.AnsweredAsNA +
                                                  mainData.GapCount +
                                                  mainData.SkippedCount) /
                                                  mainData.AllQuestionCount) *
                                                  100
                                              )
                                            : 0
                                        }
                                      />
                                    ) : (
                                      <LinearProgress
                                        variant="determinate"
                                        value={
                                          mainData.ProgressBarValue
                                            ? Math.round(
                                                ((mainData.AnsweredCount +
                                                  mainData.AnsweredAsNA +
                                                  mainData.GapCount) /
                                                  mainData.AllQuestionCount) *
                                                  100
                                              )
                                            : 0
                                        }
                                      />
                                    )}
                                    {mainData.InspectionStandardStatus ===
                                    EnumInspectionStandardStatus.Completed ? (
                                      <label className="audit-progress-quantity">
                                        {mainData.AnsweredCount +
                                          mainData.AnsweredAsNA +
                                          mainData.GapCount +
                                          mainData.SkippedCount}
                                        /{mainData.AllQuestionCount}{" "}
                                      </label>
                                    ) : (
                                      <label className="audit-progress-quantity">
                                        {mainData.AnsweredCount +
                                          mainData.AnsweredAsNA +
                                          mainData.GapCount}
                                        /{mainData.AllQuestionCount}{" "}
                                      </label>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className="audit-item-footer">
                              <Grid container>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  className="d-flex mb-10 no-of-item"
                                >
                                  <span className="audit-qty-lbl">
                                    <span className="green-text-audit">
                                      ({mainData.AnsweredCount}){" "}
                                    </span>
                                    Answered without Gaps
                                  </span>
                                  <span className="audit-qty-lbl ">
                                    <span className="red-text">
                                      ({mainData.GapCount}){" "}
                                    </span>
                                    Answered with Gaps
                                  </span>
                                  <span className="audit-qty-lbl ">
                                    <span className="na-text">
                                      ({mainData.AnsweredAsNA}){" "}
                                    </span>
                                    Answered as N/A
                                  </span>
                                  <span className="audit-qty-lbl ">
                                    <span className="orange-text">
                                      ({mainData.SkippedCount}){" "}
                                    </span>
                                    Skipped
                                  </span>
                                  {mainData.InspectionStandardStatus ===
                                  EnumInspectionStandardStatus.Completed ? (
                                    <span className="audit-qty-lbl ">
                                      <span className="red-text">
                                        (
                                        {mainData.SkippedCount +
                                          mainData.GapCount}
                                        ){" Total Gaps"}
                                      </span>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {mainData.InspectionStandardStatus !==
                                  EnumInspectionStandardStatus.Completed ? (
                                    <span className="audit-qty-lbl">
                                      <span className="pending-text">
                                        ({mainData.PendingCount}){" "}
                                      </span>
                                      Pending
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {/* {/* <a href="" className="link-button-type1">
                                    <PictureAsPdfOutlinedIcon className="download-ico" />
                                    Download preface
                                  </a> */}
                                </Grid>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                    >
                                      {mainData.InspectionStandardStatus !==
                                        EnumInspectionStandardStatus.Completed &&
                                      mainData.InspectionStandardReOpenedBy ==
                                        null ? (
                                        <span className="item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Started by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardStartedBy
                                                ? mainData.InspectionStandardStartedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardStartedDate !=
                                              null
                                                ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardStartedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus !==
                                        EnumInspectionStandardStatus.Completed &&
                                      mainData.InspectionStandardReOpenedBy !=
                                        null ? (
                                        <span className="item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Reopened by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardReOpenedBy
                                                ? mainData.InspectionStandardReOpenedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardReOpenedDate !=
                                              null
                                                ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardReOpenedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}

                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                        <span className="item-lbl-val time-stamp align-left">
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-lbl"
                                              style={{ fontSize: 14 }}
                                            >
                                              Completed by:{" "}
                                            </label>
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {mainData.InspectionStandardCompletedBy
                                                ? mainData.InspectionStandardCompletedBy
                                                : "N/A"}
                                            </label>
                                          </span>
                                          <span className="item-lbl-val align-left">
                                            <label
                                              className="audit-sub-val gray-text"
                                              style={{ fontSize: 14 }}
                                            >
                                              {""}

                                              {mainData.InspectionStandardCompletedDate !=
                                              null
                                                ? "| " +
                                                  dayjs(
                                                    new Date(
                                                      mainData.InspectionStandardCompletedDate
                                                    )
                                                  ).format("DD-MMM-YYYY HH:mm")
                                                : ""}
                                            </label>
                                          </span>
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    {/* <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={3}
                                    >
                                      <a
                                        className="link-button-type1 align-left"
                                        onClick={() =>
                                          handleOpenCopyModal(mainData)
                                        }
                                      >
                                        <FileDownloadOutlinedIcon className="download-ico" />
                                        PDF
                                      </a>
                                      <a
                                        className="link-button-type1 align-left"
                                        onClick={() =>
                                          handleOpenCopyModal(mainData)
                                        }
                                      >
                                        <FileDownloadOutlinedIcon className="download-ico" />
                                        Excel
                                      </a>
                                    </Grid> */}
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={4}
                                    >
                                      <span
                                        className="link-button-type1 download-doc black-text align-left"
                                        style={{
                                          visibility: checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_Download_Standard_Summary,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          )
                                            ? "visible"
                                            : "hidden",
                                        }}
                                      >
                                        {/* Download summary{" "}
                                        <a
                                          href=""
                                          className="disabled"
                                          title={"PDF"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <PictureAsPdfOutlinedIcon className="download-ico" />
                                        </a>{" "} */}
                                        Download summary{" "}
                                        <ReportViewerNew
                                          ReportType={
                                            EnumReportType.StandardReport
                                          }
                                          Inspection={
                                            {
                                              Id: mainData.InspectionId,
                                            } as InspectionDTO
                                          }
                                          Standard={
                                            {
                                              Id: mainData.StandardId,
                                              Name: mainData.StandardName,
                                            } as StandardDTO
                                          }
                                          InspectionStandardId={
                                            mainData.InspectionStandardId
                                          }
                                          ReportLinkName="Download Summary"
                                          ReportDownloadName="SummaryReport"
                                          OnRendering={OnGenaratingReport}
                                        >
                                          <a
                                            href=""
                                            title={"PDF"}
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                            className={`colmn-opt pdf`}
                                          >
                                            {/* <PictureAsPdfOutlinedIcon className="download-ico" /> */}
                                            <Document
                                              className="m-r-10"
                                              color="#f44336"
                                              style={{ fontSize: 20 }}
                                            />
                                          </a>
                                        </ReportViewerNew>
                                        <StandardExcelReportDownload
                                          Title="Excel"
                                          StandardId={mainData.StandardId}
                                          StandardName={mainData.StandardName}
                                          InspectionId={mainData.InspectionId}
                                          InspectionStandardId={
                                            mainData.InspectionStandardId
                                          }
                                        />
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={8}
                                    >
                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Intial ? (
                                        <>
                                          <Button
                                            className="primary small-btn"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              navigate("/questionnaire", {
                                                state: {
                                                  SiteInspectionID:
                                                    mainData.InspectionId,
                                                  StandardID:
                                                    mainData.StandardId,
                                                  IsSiteInspectionViewButtonClick:
                                                    true,
                                                  InspectionStandardId:
                                                    mainData.InspectionStandardId,
                                                } as QuestionnaireParametersType,
                                              });
                                            }}
                                            // style={{
                                            //   visibility:
                                            //     checkSystemFunctionRight(
                                            //       EnumSystemFunctions.SiteInspectionPage_View,
                                            //       EnumSystemFunctionTypes.ButtonAccess
                                            //     )
                                            //       ? "visible"
                                            //       : "hidden",
                                            // }}
                                          >
                                            View
                                          </Button>

                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_StartStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary small-btn"
                                              variant="contained"
                                              disableElevation
                                              onClick={() => {
                                                navigate(
                                                  "/questionnaireStart",
                                                  {
                                                    state: {
                                                      InspectionStandardId:
                                                        mainData.InspectionStandardId,
                                                      StandardId:
                                                        mainData.StandardId,
                                                      InspectionId:
                                                        mainData.InspectionId,
                                                      Title: mainData.Suffix
                                                        ? mainData.InspectionStandardName +
                                                          " - " +
                                                          mainData.Suffix
                                                        : mainData.InspectionStandardName,
                                                    } as QuestionnaireStartPageParametersTypes,
                                                  }
                                                );
                                              }}
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_StartStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Start
                                            </Button>
                                          )}

                                          {/* <ButtonGroup
                                            variant="contained"
                                            aria-label="split button"
                                            className="primary small-btn align-right"
                                          >
                                            <Button className="split-val">
                                              Download
                                            </Button>
                                            <Button
                                              size="small"
                                              className="split-caret"
                                            >
                                              <KeyboardArrowDownOutlinedIcon />
                                            </Button>
                                          </ButtonGroup> */}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Paused ||
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Inprogress ? (
                                        <>
                                          <Button
                                            className="primary small-btn"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              navigate("/questionnaire", {
                                                state: {
                                                  SiteInspectionID:
                                                    mainData.InspectionId,
                                                  StandardID:
                                                    mainData.StandardId,
                                                  IsSiteInspectionViewButtonClick:
                                                    true,
                                                  InspectionStandardId:
                                                    mainData.InspectionStandardId,
                                                } as QuestionnaireParametersType,
                                              });
                                            }}
                                            // style={{
                                            //   visibility:
                                            //     checkSystemFunctionRight(
                                            //       EnumSystemFunctions.SiteInspectionPage_View,
                                            //       EnumSystemFunctionTypes.ButtonAccess
                                            //     )
                                            //       ? "visible"
                                            //       : "hidden",
                                            // }}
                                          >
                                            View
                                          </Button>

                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_CompleteStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary small-btn"
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                // onClickCompleteBtn(
                                                //   EnumInspectionStandardStatus.Completed,
                                                //   mainData.StandardId,
                                                //   mainData.InspectionId,
                                                //   mainData.InspectionStandardId
                                                // )
                                                getInspectionStandardSummary(
                                                  mainData.InspectionStandardId
                                                )
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_CompleteStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Complete
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Paused ||
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Inprogress ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_ResumeStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary small-btn"
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                onClickResume(mainData)
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_ResumeStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Resume
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {mainData.InspectionStandardStatus ===
                                      EnumInspectionStandardStatus.Completed ? (
                                        <>
                                          <Button
                                            className="primary small-btn"
                                            variant="contained"
                                            disableElevation
                                            onClick={() => {
                                              navigate("/questionnaire", {
                                                state: {
                                                  SiteInspectionID:
                                                    mainData.InspectionId,
                                                  StandardID:
                                                    mainData.StandardId,
                                                  IsSiteInspectionViewButtonClick:
                                                    true,
                                                  InspectionStandardId:
                                                    mainData.InspectionStandardId,
                                                } as QuestionnaireParametersType,
                                              });
                                            }}
                                            // style={{
                                            //   visibility:
                                            //     checkSystemFunctionRight(
                                            //       EnumSystemFunctions.SiteInspectionPage_View,
                                            //       EnumSystemFunctionTypes.ButtonAccess
                                            //     )
                                            //       ? "visible"
                                            //       : "hidden",
                                            // }}
                                          >
                                            View
                                          </Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {siteInspectionStartPageState?.Inspection
                                        ?.InspectionStatusId !==
                                        EnumInspectionStatus.Completed &&
                                      mainData.InspectionStandardStatus ===
                                        EnumInspectionStandardStatus.Completed ? (
                                        <>
                                          {checkSystemFunctionRight(
                                            EnumSystemFunctions.SiteInspectionPage_ReopenStandard,
                                            EnumSystemFunctionTypes.ButtonAccess
                                          ) && (
                                            <Button
                                              className="primary small-btn"
                                              variant="contained"
                                              disableElevation
                                              onClick={() =>
                                                onClickReOpen(mainData)
                                              }
                                              // style={{
                                              //   visibility:
                                              //     checkSystemFunctionRight(
                                              //       EnumSystemFunctions.SiteInspectionPage_ReopenStandard,
                                              //       EnumSystemFunctionTypes.ButtonAccess
                                              //     )
                                              //       ? "visible"
                                              //       : "hidden",
                                              // }}
                                            >
                                              Re-open
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Grid>
                  <Grid item xl={1} lg={1} md={12} sm={12} xs={12}></Grid>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    {siteInspectionStartPageState.Inspection
                      .InspectionStatusId !== EnumInspectionStatus.Completed ? (
                      <>
                        <div className="item-wrapper">
                          <div className="item-header">
                            <h2>
                              Site Team Members
                              {siteInspectionStartPageState.PageMode
                                .IsShowSaveBtn ? (
                                <a
                                  title="Save"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <CardTick
                                    variant="Outline"
                                    className="green-text-audit"
                                    onClick={onClickSaveBtn}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                              {siteInspectionStartPageState.PageMode
                                .IsShowCancelBtn ? (
                                <a
                                  title="Close"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <CloseCircle
                                    variant="Outline"
                                    className="primary-blue-text"
                                    onClick={onClickCloseBtn}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                              {siteInspectionStartPageState.PageMode
                                .IsShowEditBtn ? (
                                <a
                                  title="Edit"
                                  className="link-button-type1 icon-link-btn cursor-pointer"
                                >
                                  <Edit2
                                    variant="Outline"
                                    className="link-blue-text "
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      onClickEditBtn();
                                    }}
                                    style={{
                                      visibility: checkSystemFunctionRight(
                                        EnumSystemFunctions.SiteInspectionPage_Edit_TeamMembers,
                                        EnumSystemFunctionTypes.ButtonAccess
                                      )
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                            </h2>
                          </div>
                          <div className="item-content">
                            <div className="FormGroup sme-set">
                              <HPRLinedTextArea
                                Id=""
                                Name="SiteTeamMember"
                                Label={""}
                                Value={
                                  siteInspectionStartPageState?.SiteTeamMemberName
                                }
                                Disabled={
                                  siteInspectionStartPageState.PageMode
                                    .IsDisable
                                }
                                onTextAreaChange={OnTextAreaChange}
                                Type={EnumLinedTextAreaType.Text}
                                ClassName={"hpr-textarea"}
                                InputProps={{ maxLength: 250 }}
                                TextLength={250}
                                ShowTextCounter
                              ></HPRLinedTextArea>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="item-wrapper complete-set">
                          <div className="item-content">
                            {/* <a
                              
                              className="link-button-type1 align-right mb-15 cursor-pointer"
                              // onClick={handleOpenInNewTab}
                            >
                              {siteInspectionStartPageState.Inspection &&
                              checkSystemFunctionRight(
                                EnumSystemFunctions.SiteInspectionPage_Download_InspectionSummary,
                                EnumSystemFunctionTypes.ButtonAccess
                              ) ? (
                                // <ReportViewer
                                //   ReportType={EnumReportType.SummeryReport}
                                //   Inspection={
                                //     {
                                //       Id: siteInspectionStartPageState
                                //         .Inspection.Id,
                                //     } as InspectionDTO
                                //   }
                                //   ReportLinkName="Download Summary"
                                //   ReportDownloadName="InspectionSummaryReport"
                                //   OnRendering={OnGenaratingReport}
                                // ></ReportViewer>

                                <ReportViewerNew
                                  ReportType={EnumReportType.SummeryReport}
                                  Inspection={
                                    {
                                      Id: siteInspectionStartPageState
                                        .Inspection.Id,
                                    } as InspectionDTO
                                  }
                                  ReportLinkName="Download Summary"
                                  ReportDownloadName="SummaryReport"
                                  OnRendering={OnGenaratingReport}
                                >
                                  <a
                                    className="align-center-all download-text"
                                    href=""
                                    title={"PDF"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <FileDownloadOutlinedIcon
                                      className="copy-ico"
                                      style={{ color: "#561ff8" }}
                                    />
                                    {"Download Summary"}
                                  </a>
                                </ReportViewerNew>
                              ) : (
                                // <HPRReportMenu
                                //   ReportList={
                                //     [
                                //       {
                                //         ReportName:
                                //           "UpToDate Inspection Summary (in Excel)",
                                //         OnClickReport() {},
                                //       },
                                //       {
                                //         ReportName: "Download Summary (in PDF)",
                                //         OnClickReport() {},
                                //       },
                                //     ] as Array<HPRReportMenuType>
                                //   }
                                // ></HPRReportMenu>
                                <></>
                              )}
                            </a> */}
                            <span className="complete-label">
                              Inspection completed
                            </span>
                            {/* <span className="completed-date body3">
                              <span className="black-text">
                                Planned on:&nbsp;
                              </span>
                              {siteInspectionStartPageState.Inspection
                                .PlannedDate != null
                                ? dayjs(
                                    new Date(
                                      siteInspectionStartPageState.Inspection.PlannedDate
                                    )
                                  ).format("DD-MMM-YYYY")
                                : ""}
                            </span> */}
                            <span className="completed-date body3">
                              <span className="black-text">
                                Started By and Date:&nbsp;
                              </span>
                              {
                                siteInspectionStartPageState.Inspection
                                  .StartedBy
                              }{" "}
                              {" | "}{" "}
                              {siteInspectionStartPageState.Inspection
                                .StartedDate
                                ? dayjs(
                                    new Date(
                                      siteInspectionStartPageState.Inspection.StartedDate
                                    )
                                  ).format("DD-MMM-YYYY HH:mm")
                                : ""}
                            </span>
                            <span className="completed-date body3">
                              <span className="black-text">
                                Completed By and Date:&nbsp;
                              </span>
                              {
                                siteInspectionStartPageState.Inspection
                                  .CompletedBy
                              }{" "}
                              {" | "}{" "}
                              {siteInspectionStartPageState.Inspection
                                .CompletedDate
                                ? dayjs(
                                    new Date(
                                      siteInspectionStartPageState.Inspection.CompletedDate
                                    )
                                  ).format("DD-MMM-YYYY HH:mm")
                                : ""}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Modal
                  className="modal-outter modal-md"
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="modal-header">
                      <h1>
                        Create copy
                        <a className="cursor-pointer">
                          <CloseSquare
                            size="25"
                            color="#697689"
                            onClick={() => setOpen(false)}
                            style={{
                              visibility: checkSystemFunctionRight(
                                EnumSystemFunctions.SiteInspectionPage_CreateCopy,
                                EnumSystemFunctionTypes.ButtonAccess
                              )
                                ? "visible"
                                : "hidden",
                            }}
                          />
                        </a>
                      </h1>
                      <div></div>
                    </div>
                    <div className="modal-content">
                      {/* <Grid container>
                        <Grid item xs={12}>
                          <h2>
                            {copyModalState.Title}
                          </h2>
                          <br></br>
                        </Grid>
                      </Grid> */}
                      <Grid container spacing={2}>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Standard"
                              Name="Standard"
                              Value={copyModalState.Title}
                              Type={EnumTextBoxType.Text}
                              Disabled={true}
                            ></HPRTextBox>
                          </div>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Suffix"
                              Name="suffix"
                              Value={copyModalState.Suffix}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  Suffix: text,
                                }))
                              }
                              Type={EnumTextBoxType.Text}
                            ></HPRTextBox>
                          </div>
                          {/* <div className="form-group">
                            <HPRDropDownSingleSelect
                              Id=""
                              Label="Building"
                              Name="Building"
                              Select={
                                copyModalState.SelectedBuildingId
                                  ? copyModalState.SelectedBuildingId
                                  : 0
                              }
                              Items={buildingList}
                              onDropDownChange={onChangeBuilding}
                            ></HPRDropDownSingleSelect>
                          </div>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Location(Floor, column, room, space ID, other)"
                              Name="location"
                              Value={copyModalState.Location}
                              Type={EnumTextBoxType.Text}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  Location: text,
                                }))
                              }
                            ></HPRTextBox>
                          </div>
                          <div className="form-group">
                            <HPRTextBox
                              Id=""
                              Label="Equipment, Application, System, other"
                              Name="RelatedEntity"
                              Value={copyModalState.RelatedEntity}
                              onTextBoxChange={(name, text) =>
                                setCopyModalState((prevState) => ({
                                  ...prevState,
                                  RelatedEntity: text,
                                }))
                              }
                              Type={EnumTextBoxType.Text}
                            ></HPRTextBox>
                          </div> */}
                        </Grid>
                      </Grid>
                    </div>
                    <div className="modal-button-bar">
                      <Button
                        className="primary small-btn"
                        variant="contained"
                        disableElevation
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="primary small-btn"
                        variant="contained"
                        disableElevation
                        onClick={onClickCreateCopy}
                      >
                        Create a copy
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </>
  );
};
export default SiteInspectionStartPage;
