import { EnumReportType } from "../../../common/enums/common.enums";
import RemediationTrackerTasksExcelDownload from "../../Reports/RemediationTrackerTasksReport/RemediationTrackerTasksReport.Excel";
import { ViewRemediationTrackerTaskListToolBarReportProps } from "./ViewRemediationTrackerTaskList.types";

const ViewRemediationTrackerTaskListReportToolbar = (
  props: ViewRemediationTrackerTaskListToolBarReportProps
) => {
  return (
    <>
      <div className="fx-left">
        {props.ShowReport &&
          props.ReportViewer?.ReportType ===
            EnumReportType.RemediationTrackerTasksReport && (
            <RemediationTrackerTasksExcelDownload
              ReportViewer={props.ReportViewer}
              RemediationTrackerTasksExcelReportFilter={
                props.ReportViewer?.ReportSearchParameter
              }
            />
          )}

        {/* <Button className="colmn-opt mb-10">
        <Stickynote className="m-r-10" style={{ fontSize: 20 }} />
        <span> Report</span>
      </Button> */}
      </div>
    </>
  );
};

export default ViewRemediationTrackerTaskListReportToolbar;
