/* ==============================|| VIEWS - PAGES - STANDRARD - ACTION ||==============================  */

import { StandardDTO } from "../../../common/types/common.dto.types";

// Action types
export const GETSTANDARD = "GETSTANDARD";
export const SAVESTANDARD = "SAVESTANDARD";
export const CHECKSTANDARDBEFOREDEACTIVATE = "CHECKSTANDARDBEFOREDEACTIVATE";
export const DEACTIVATESTANDARD = "DEACTIVATESTANDARD";
export const NAVIGATESTANDARD = "NAVIGATESTANDARD";

// Action methods

export const getStandardById = (id: number) => {
  return {
    type: GETSTANDARD,
    payload: id,
  };
};

export const saveStandard = (standard: StandardDTO) => {
  return {
    type: SAVESTANDARD,
    payload: standard,
  };
};

export const checkStandardBeforeDeactivate = (standard: StandardDTO) => {
  return {
    type: CHECKSTANDARDBEFOREDEACTIVATE,
    payload: standard,
  };
};

export const deactivateStandard = (standard: StandardDTO) => {
  return {
    type: DEACTIVATESTANDARD,
    payload: standard,
  };
};
