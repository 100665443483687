/** @format */
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import { Button, Grid, IconButton, RadioGroup, Tooltip } from "@mui/material";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import "./QuestionnairePage.scss";
import HPRFileUploader from "../../../components/common/HPRFileUploader/HPRFileUploader";
import { FileUploaderErrorType } from "../../../components/common/HPRFileUploader/HPRFileUploader.types";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  EnumDataTransferStatus,
  EnumFileType,
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
  EnumPageType,
  EnumQuestionTypesType,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionQuestionChoiceDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import {
  QuestionnaireDataType,
  QuestionnaireParametersType,
} from "./QuestionnairePage.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getSiteInspectionQuestiontData,
  inspectionFileUpload,
  saveInspectionQuestionAnswer,
  saveInspectionStandardStatus,
} from "./QuestionnairePage.action";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { motion } from "framer-motion";
import {
  setSiteInspectionStandardStatus,
  changeUploadFileListData,
  clearPageModeRedirectMode,
  clearPageState,
} from "./QuestionnairePage.reducer";
import { QuestionnaireSummaryPageParametersType } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { viewInspectionSummarySliceReducerActions } from "../../components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryProps,
} from "../../components/ViewInspectionSummary/ViewInspectionSummary.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import attachIco1 from "../../../icons/attachment-l1.svg";
import attachIco3 from "../../../icons/attachment.svg";
import { viewInspectionQuestionHistorySliceReducerActions } from "../../components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory.reducer";
import { ViewInspectionQuestionHistoryProps } from "../../components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory.types";
import ViewInspectionQuestionHistory from "../../components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory";
import { set } from "date-fns";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowAddBtn: false,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: true,
    IsShowUpdateBtn: false,
    IsPauseBtn: false,
    IsCompleteBtn: false,
    IsShowProgress: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Questionnaire: {
    InspectionQuestion: {
      Comment: "",
    },
    StandardID: 0,
    SiteInspectionID: 0,
    CurrentQuestionNumber: 0,
    AllQuestionCount: 0,
    AnsweredCount: 0,
    PendingCount: 0,
    GapCount: 0,
    SkippedCount: 0,
    InspectionStandardName: "",
    QuestionProgressBarValue: 0,
    QuestionText: "",
    InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
    InspectionStandardStatus: 0,
    InspectionStandardStatusId: EnumInspectionStandardStatus.Completed,
    ReadOnlyMode: false,
    FinalQuestionAnswered: false,
    SaveAnswerBeforeClickPrevious: false,
    UploadedFileList: [] as Array<UploadFileDTO>,
    IsFromQuestionnairePge: false,
    ImageFile: {} as any,
    DocumentFile: [] as any,
    RemovedFileIdList: [] as Array<number>,
    IsSiteInspectionViewButtonClick: false,
    ReferenceID: 0,
  } as QuestionnaireDTO,
  YesNoAnswer: "",
  IsHideSkipButton: false,
  QuestionnaireSaveActionName: "",
  IsQuestionnaireSaveRunning: false,
  QuestionnaireSaveCopy: {} as QuestionnaireDTO,
} as QuestionnaireDataType;

const QuestionnairePage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for QuestionnairePage with initialState values
  const [questionnairePagePageState, setQuestionnairePageState] =
    React.useState(initialState);
  const [numberList, setNumberList] = useState<InspectionQuestionDTO[] | null>(
    null
  );
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isScrollableStartPrev, setIsScrollableStartPrev] =
    useState<boolean>(true);
  const [isScrollableEndPrev, setIsScrollableEndPrev] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAppSelector((state) => state.authReducer);
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
  } as UsePromptTypes;
  usePrompt(prompt);

  // const parameter = {
  //   SiteInspectionID: 4,
  //   StandardID: 1,
  // } as QuestionnaireDTO; //QuestionnaireDTO = location.state;

  const parameter: QuestionnaireParametersType = location.state;

  const FetchedRef = useRef(false);

  // Horizontal ScrollBar Navigation
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(true);
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const container = questionContainerRef.current
    ? questionContainerRef.current
    : null;
  const ScrollWidth = container ? container.scrollWidth : 0;
  const ClientWidth = container ? container.clientWidth : 0;
  const ScrollLeft = container ? container.scrollLeft : 0;

  const handleScroll = (direction: number) => {
    checkScrollState(direction);
    if (direction === 1) {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: 325,
        behavior: "smooth",
      });
      setIsStart(false);
    } else {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: -325,
        behavior: "smooth",
      });
    }
  };

  const checkScrollState = (direction?: number) => {
    try {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const isAtStart = scrollLeft - 325 <= 0;
      const isAtEnd = Math.ceil(scrollLeft + clientWidth + 325) >= scrollWidth;
      setIsStart(isAtStart);
      setIsEnd(isAtEnd);
      isAtStart
        ? setIsScrollableStartPrev(isAtStart)
        : setIsScrollableStartPrev(null);
      isAtEnd ? setIsScrollableEndPrev(isAtEnd) : setIsScrollableEndPrev(null);

      if (isAtStart === true && isScrollableStartPrev === true) {
        if (direction === 1) {
          setIsStart(false);
        } else {
          setIsStart(true);
        }
      }
      if (isAtEnd === true && isScrollableEndPrev === true) {
        if (direction === 0) {
          setIsEnd(false);
        } else {
          setIsEnd(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (ScrollWidth > ClientWidth) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
    if (ScrollLeft > 65) {
      setIsStart(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft <= 65) {
      setIsStart(true);
      setIsScrollableStartPrev(true);
    }
    if (ScrollLeft + ClientWidth + 65 <= ScrollWidth) {
      setIsEnd(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft + ClientWidth + 65 > ScrollWidth) {
      setIsEnd(true);
      setIsScrollableStartPrev(true);
    }
  }, [ScrollLeft, ClientWidth, ScrollWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollLeft } = container;

      if (!container) return;

      if (scrollLeft >= 300) {
        setIsStart(false);
        setIsScrollableStartPrev(false);
      } else if (scrollLeft > 0 && scrollLeft <= 150) {
        return;
      } else if (scrollLeft <= 0) {
        setIsStart(true);
        setIsScrollableStartPrev(true);
      }
      // else {
      //   setIsStart(true);
      //   setIsScrollableStartPrev(true);
      // }
      // if (ScrollLeft + ClientWidth  < scrollWidth ) {
      //   setIsEnd(false);
      //   setIsScrollableStartPrev(false);
      // }
      // else if (scrollLeft + clientWidth > scrollWidth-325) {
      //   setIsEnd(true);
      //   setIsScrollableStartPrev(true);
      // }
    };
    setTimeout(() => {
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, 100);

    // Clear the timeout
    //clearTimeout(timeoutId);
  }, [handleScroll]);

  const dispatch = useAppDispatch();
  const questionnaireState = useAppSelector(
    (state) => state.siteInspectionQuestionnaireReducer
  );

  window.addEventListener("popstate", (ev) => {
    navigate("/questionnaire", {
      state: {
        SiteInspectionID: parameter?.SiteInspectionID,
        StandardID: parameter?.StandardID,
        InspectionStandardId: parameter?.InspectionStandardId,
        ReferenceID: parameter?.ReferenceID,
        InspectionStandardName: parameter?.InspectionStandardName,
      } as QuestionnaireParametersType,
    });
  });

  const backwardTransition = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  const forwardTransition = {
    initial: {
      opacity: 0,
      x: "100%", // swap with '100%'
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "-100%", // swap with '-100%'
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  type Transition = typeof backwardTransition | typeof forwardTransition;
  const [questionTransition, setQuestionTransition] =
    useState<Transition>(null);

  const initCountList = {
    All: 0,
    AnsweredWithoutGaps: 0,
    AnsweredWithGaps: 0,
    Skipped: 0,
    Pending: 0,
    AnsweredAsNA: 0,
    InspectionStandardQuestionList: [] as Array<InspectionQuestionDTO>,
  };
  type CountListType = typeof initCountList;
  const [countList, setCountList] = useState<CountListType>(initCountList);

  //-----standard list heigh ------
  const slider = useRef(null);
  const legend = useRef(null);
  const header = useRef(null);
  const footer = useRef(null);

  //Set Hight
  const [qsectionheight, setQsectioHeight] = useState(0);

  //-----Page load-----
  const heightCal = () => {
    let qsectionheight =
      window.innerHeight -
      slider?.current?.offsetHeight -
      legend?.current?.offsetHeight -
      header?.current?.offsetHeight -
      footer?.current?.offsetHeight -
      149;
    setQsectioHeight(qsectionheight);

    function handleResize() {
      let qsectionheight =
        window.innerHeight -
        slider?.current?.offsetHeight -
        legend?.current?.offsetHeight -
        header?.current?.offsetHeight -
        footer?.current?.offsetHeight -
        149;
      setQsectioHeight(qsectionheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };
  //------------------------------------

  const handleQuestionRadioChange = (name: string, value: any) => {
    if (parseInt(value) !== -1) {
      let qIndex: number = parseInt(name.split("_")[1]);

      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire.InspectionQuestion.InspectionQuestionChoices.map(
          (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
            let updateQuestionChoice: InspectionQuestionChoiceDTO =
              Object.assign({} as InspectionQuestionChoiceDTO, questionChoice);
            if (index === qIndex) {
              if (parseInt(value) === 1 || parseInt(value) === 0) {
                updateQuestionChoice.IsCorrect = true;
              } else {
                updateQuestionChoice.IsCorrect = null;
              }

              return updateQuestionChoice;
            } else {
              updateQuestionChoice.IsCorrect = null;
              return updateQuestionChoice;
            }
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              NotApplicableAnswer: null,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          YesNoAnswer: value,
        };
      });

      CheckQuestionAnswered(
        questionnairePagePageState.Questionnaire,
        editedList,
        false
      );
    } else {
      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire.InspectionQuestion.InspectionQuestionChoices.map(
          (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
            let updateQuestionChoice: InspectionQuestionChoiceDTO =
              Object.assign({} as InspectionQuestionChoiceDTO, questionChoice);
            updateQuestionChoice.IsCorrect = null;
            return updateQuestionChoice;
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              NotApplicableAnswer: true,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          YesNoAnswer: value,
        };
      });
      CheckQuestionAnswered(
        questionnairePagePageState.Questionnaire,
        editedList,
        true
      );
    }
    setIsDirty(true);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.Questionnaire,
      functionId,
      functionTypeId
    );
  };

  const CheckQuestionAnswered = (
    questionnaireDTO: QuestionnaireDTO,
    editedList: any,
    NotApplicableAnswer: boolean
  ) => {
    let yesnoAnswer = editedList?.filter((obj: InspectionQuestionChoiceDTO) => {
      if (obj.IsCorrect && obj.IsCorrect != null) {
        return obj;
      }
    });

    if (
      questionnaireDTO.CurrentQuestionNumber ===
      questionnaireDTO.InspectionQuestionList?.length
    ) {
      if (yesnoAnswer?.length > 0 || NotApplicableAnswer !== false) {
        setQuestionnairePageState((values) => {
          return {
            ...values,
            IsHideSkipButton: true,
          };
        });
      }
    }
  };

  const handleProductImpactRadioChange = (value: any) => {
    if (value != null) {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire.InspectionQuestion,
              ProductImpactAnswer: parseInt(value) === 1 ? true : false,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          IsHideSkipButton:
            questionnairePagePageState.Questionnaire.CurrentQuestionNumber ===
            questionnairePagePageState.Questionnaire.InspectionQuestionList
              ?.length
              ? true
              : false,
        };
      });
    }
  };

  const OnTextBoxChange = (name: string, value: any) => {
    if (value != null) {
      // dispatch(
      //   setSiteInspectionQuestionnaire({ ...questionnaireState.Questionnaire })
      // );
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire.InspectionQuestion,
              Comment: value,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
          IsHideSkipButton:
            questionnairePagePageState.Questionnaire.CurrentQuestionNumber ===
              questionnairePagePageState.Questionnaire.InspectionQuestionList
                ?.length &&
            value != null &&
            value !== ""
              ? true
              : false,
        };
      });
      setIsDirty(true);
    }
  };

  //Page onload
  useEffect(() => {
    // heightCal();
    if (FetchedRef.current) return;
    FetchedRef.current = true;
    //dispatch(clearPageState());
    if (parameter?.SiteInspectionID > 0 && parameter?.StandardID > 0) {
      dispatch(
        getSiteInspectionQuestiontData({
          StandardID: parameter?.StandardID,
          SiteInspectionID: parameter?.SiteInspectionID,
          IsSiteInspectionViewButtonClick:
            parameter?.IsSiteInspectionViewButtonClick,
          InspectionStandardId: parameter?.InspectionStandardId,
          QuestionnaireStarted: parameter?.QuestionnaireStarted,
          ReferenceID: parameter?.ReferenceID,
          InspectionStandardName: parameter?.InspectionStandardName,
        } as QuestionnaireDTO)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: initialState.Questionnaire,
          PageMode: initialState.PageMode,
        };
      });
    }
  }, []);

  useEffect(() => {
    setNumberList(questionnaireState?.Questionnaire?.InspectionQuestionList);
  }, [questionnaireState.Questionnaire?.InspectionQuestionList]);

  useEffect(() => {
    if (numberList && numberList.length > 0) {
      heightCal();
    }
  }, [numberList]);

  // every time when reducer state change set values to page state
  useEffect(() => {
    if (!FetchedRef.current) return;
    if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion?.Id !==
      questionnaireState?.Questionnaire?.InspectionQuestion.Id
    ) {
      setIsDirty(false);
      setTimeout(function () {
        let scrollto = questionnaireState.Questionnaire?.CurrentQuestionNumber;
        let diff = fieldRef.current?.length - scrollto;
        if (
          questionnairePagePageState?.Questionnaire?.CurrentQuestionNumber >
          questionnaireState.Questionnaire?.CurrentQuestionNumber
        ) {
          window.scrollTo(0, 0);
          if (questionnaireState.Questionnaire?.CurrentQuestionNumber <= 5) {
            fieldRef.current[
              questionnaireState.Questionnaire?.CurrentQuestionNumber -
                questionnaireState.Questionnaire?.CurrentQuestionNumber
            ]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 6]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        } else {
          window.scrollTo(0, 0);
          if (diff >= 5) {
            fieldRef.current[scrollto + 4]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 2 && diff <= 5) {
            fieldRef.current[scrollto + 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 0 && diff <= 2) {
            fieldRef.current[scrollto]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }
      }, 100);
    }

    let InspectionQuestionChoiceDTO: Array<InspectionQuestionChoiceDTO> =
      questionnairePagePageState
        ? questionnairePagePageState.Questionnaire
          ? questionnairePagePageState.Questionnaire?.InspectionQuestion
            ? questionnairePagePageState.Questionnaire?.InspectionQuestion
                ?.Id !==
              questionnaireState.Questionnaire?.InspectionQuestion?.Id
              ? questionnaireState.Questionnaire.InspectionQuestion
                  .InspectionQuestionChoices
              : questionnairePagePageState.Questionnaire.InspectionQuestion
                  .InspectionQuestionChoices
            : ([] as Array<InspectionQuestionChoiceDTO>)
          : ([] as Array<InspectionQuestionChoiceDTO>)
        : ([] as Array<InspectionQuestionChoiceDTO>);

    let notApplicableAnswer: boolean = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire.InspectionQuestion
          ? questionnairePagePageState.Questionnaire.InspectionQuestion.Id !==
            questionnaireState.Questionnaire.InspectionQuestion.Id
            ? questionnaireState.Questionnaire.InspectionQuestion
                .NotApplicableAnswer
            : questionnairePagePageState.Questionnaire.InspectionQuestion
                .NotApplicableAnswer
          : false
        : false
      : false;

    let yesnoAnswer = InspectionQuestionChoiceDTO?.filter(
      (obj: InspectionQuestionChoiceDTO) => {
        if (obj.IsCorrect && obj.IsCorrect != null) {
          return obj;
        }
      }
    );

    let answerVal = "";
    let isSkipBtnHide = false;
    if (
      yesnoAnswer !== undefined &&
      yesnoAnswer != null &&
      yesnoAnswer?.length > 0
    ) {
      if (
        yesnoAnswer[0].ChoiceText != null &&
        yesnoAnswer[0].ChoiceText.DefaultText === "Yes"
      ) {
        answerVal = "1";
      } else {
        answerVal = "0";
      }
    } else if (notApplicableAnswer !== null && notApplicableAnswer) {
      answerVal = "-1";
    }

    if (
      questionnaireState.Questionnaire.CurrentQuestionNumber ===
        questionnaireState.Questionnaire.InspectionQuestionList?.length &&
      (answerVal !== "" ||
        questionnaireState.Questionnaire.InspectionQuestion.Comment != null ||
        questionnaireState.Questionnaire.InspectionQuestion
          .ProductImpactAnswer != null ||
        questionnaireState.Questionnaire.InspectionQuestion.UploadFiles
          ?.length > 0)
    ) {
      isSkipBtnHide = true;
    } else {
      isSkipBtnHide = false;
    }

    let comment: string = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire.InspectionQuestion
          ? questionnairePagePageState.Questionnaire.InspectionQuestion.Id !==
            questionnaireState.Questionnaire.InspectionQuestion.Id
            ? questionnaireState.Questionnaire.InspectionQuestion.Comment
            : questionnairePagePageState.Questionnaire.InspectionQuestion
                .Comment
          : ""
        : ""
      : "";

    let ProductImpactAnswer: boolean = questionnairePagePageState
      ? questionnairePagePageState.Questionnaire
        ? questionnairePagePageState.Questionnaire.InspectionQuestion
          ? questionnairePagePageState.Questionnaire.InspectionQuestion.Id !==
            questionnaireState.Questionnaire.InspectionQuestion.Id
            ? questionnaireState.Questionnaire.InspectionQuestion
                .ProductImpactAnswer
            : questionnairePagePageState.Questionnaire.InspectionQuestion
                .ProductImpactAnswer
          : false
        : false
      : false;

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...questionnaireState.Questionnaire,
          InspectionQuestion: {
            ...questionnaireState.Questionnaire.InspectionQuestion,
            Comment: comment,
            InspectionQuestionChoices: InspectionQuestionChoiceDTO,
            ProductImpactAnswer: ProductImpactAnswer,
            NotApplicableAnswer: notApplicableAnswer,
          },
          SaveAnswerBeforeClickPrevious:
            questionnairePagePageState.Questionnaire.InspectionQuestion.Id !==
            questionnaireState.Questionnaire.InspectionQuestion.Id
              ? false
              : questionnaireState.Questionnaire.SaveAnswerBeforeClickPrevious,
        },
        YesNoAnswer: answerVal,
        PageMode: questionnaireState.PageMode,
        IsHideSkipButton: isSkipBtnHide,
      };
    });

    if (questionnaireState?.PageMode?.RedirctOption?.NavigateURl !== "") {
      navigate(
        questionnaireState.PageMode.RedirctOption.NavigateURl,
        questionnaireState.PageMode.RedirctOption.NavigateObject
      );
      dispatch(clearPageModeRedirectMode());
      FetchedRef.current = false;
      dispatch(clearPageState());
    }
    setCountList((prevState) => ({
      ...prevState,
      All: questionnaireState.Questionnaire.AllQuestionCount,
      AnsweredWithGaps: questionnaireState.Questionnaire.GapCount,
      AnsweredWithoutGaps: questionnaireState.Questionnaire.AnsweredCount,
      Skipped: questionnaireState.Questionnaire.SkippedCount,
      Pending: questionnaireState.Questionnaire.PendingCount,
      AnsweredAsNA: questionnaireState.Questionnaire.AnsweredAsNA,
    }));
  }, [questionnaireState]);

  const getSelectedImageFiles = (fileList: Array<UploadFileDTO>) => {
    // select files to be removed
    let removefileIdList: Array<number> =
      questionnairePagePageState.Questionnaire.RemovedFileIdList != null
        ? [...questionnairePagePageState.Questionnaire.RemovedFileIdList]
        : [];

    questionnairePagePageState.Questionnaire.UploadedFileList.forEach(
      (item, index: number) => {
        let found = fileList.filter((obj) => {
          return obj.Id === item.Id;
        });

        if (found?.length === 0) {
          removefileIdList.push(item.Id);
        } else {
          // dispatch(updateUploadFilesList(fileList));
        }
      }
    );

    //
    let toBeAddList: Array<UploadFileDTO> = fileList.filter(
      (file) => file.UploadStatus === EnumDataTransferStatus.Default
    );

    fileList = fileList.map((file) => {
      return {
        ...file,
        InspectionQuestionId:
          questionnairePagePageState.Questionnaire.InspectionQuestion.Id,
      };
    });

    dispatch(
      changeUploadFileListData({
        UploadedFileList: fileList,
        RemovedFileIdList: removefileIdList,
        SaveAnswerBeforeClickPrevious: true,
      } as QuestionnaireDTO)
    );

    setIsDirty(true);
    // setQuestionnairePageState((values) => {
    //   return {
    //     ...values,
    //     Questionnaire: {
    //       ...values.Questionnaire,
    //       UploadedFileList: fileList,
    //       RemovedFileIdList: removefileIdList,
    //       SaveAnswerBeforeClickPrevious: true,
    //     },
    //     IsHideSkipButton:
    //       questionnairePagePageState.Questionnaire.CurrentQuestionNumber ==
    //         questionnairePagePageState.Questionnaire.InspectionQuestionList
    //           ?.length && fileList?.length > 0
    //         ? true
    //         : false,
    //   };
    // });
  };

  const uploadFilesToBackend = () => {
    let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
    let inpogressUploaded: UploadFileDTO = {} as UploadFileDTO;

    inpogressUploaded =
      questionnairePagePageState.Questionnaire.UploadedFileList.find(
        (file) => file.UploadStatus === EnumDataTransferStatus.InProgress
      );

    if (!inpogressUploaded) {
      toBeUploaded =
        questionnairePagePageState.Questionnaire.UploadedFileList.filter(
          (file) => file.UploadStatus === EnumDataTransferStatus.NotStarted
        );
      if (toBeUploaded?.length > 0) {
        dispatch(inspectionFileUpload());
      }
    }
  };

  useEffect(() => {
    uploadFilesToBackend();
  }, [questionnairePagePageState.Questionnaire.UploadedFileList]);

  const onClickSaveBtn = (saveStatus?: EnumInspectionQuestionStatus): void => {
    setIsDirty(false);
    if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId === EnumQuestionTypesType.YesNo ||
      questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.NotApplicable
    ) {
      if (
        questionnairePagePageState.YesNoAnswer === "" &&
        !questionnairePagePageState.Questionnaire.ReadOnlyMode
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              // dispatch(
              //   saveInspectionQuestionAnswer(
              //     questionnairePagePageState?.Questionnaire
              //   )
              // );
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                  },
                })
              );
            },
          } as PopupProps)
        );
      } else if (saveStatus === EnumInspectionQuestionStatus.Skipped) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    NotApplicableAnswer: null,
                    InspectionQuestionChoices: [
                      ...questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.InspectionQuestionChoices,
                    ].map((item) => {
                      return {
                        ...item,
                        IsCorrect: null,
                      };
                    }),
                  },
                })
              );
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          saveInspectionQuestionAnswer(
            questionnairePagePageState?.Questionnaire
          )
        );
      }
    } else {
      dispatch(
        saveInspectionQuestionAnswer(questionnairePagePageState?.Questionnaire)
      );
    }
  };

  /*  useBeforeUnload(() => {
    // console.log(questionnairePagePageState.PageMode);
    if (
      questionnairePagePageState.PageMode.PageMode === EnumPageMode.AddMode ||
      questionnairePagePageState.PageMode.PageMode === EnumPageMode.EditMode
    ) {
      let SaveClickPrevious = questionnairePagePageState.Questionnaire
        .ReadOnlyMode
        ? false
        : questionnairePagePageState.Questionnaire
            .SaveAnswerBeforeClickPrevious;
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionStandardStatus: EnumInspectionStandardStatus.Paused,
            FinalQuestionAnswered: SaveClickPrevious,
          },
        };
      });
      dispatch(
        saveInspectionStandardStatus({
          ...questionnairePagePageState.Questionnaire,
          InspectionStandardStatus: EnumInspectionStandardStatus.Paused,
          FinalQuestionAnswered: SaveClickPrevious,
        })
      );
    }
  });*/
  const onClickPausedBtn = (
    saveStatus?: EnumInspectionStandardStatus,
    isFromPauseBtn?: Boolean
  ): void => {
    setIsDirty(false);
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();

    setAnswerCountList();

    if (isFromPauseBtn) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to pause?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  InspectionStandardStatus: saveStatus,
                  FinalQuestionAnswered: SaveClickPrevious,
                  IsReferenceButtonClick: false,
                },
              };
            });
            dispatch(
              saveInspectionStandardStatus({
                ...questionnairePagePageState.Questionnaire,
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: SaveClickPrevious,
                IsReferenceButtonClick: false,
              })
            );
          },
          NoBtnClick() {
            reSetAnswerCountListToOriginal();
          },
        } as PopupProps)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionStandardStatus: saveStatus,
            FinalQuestionAnswered: SaveClickPrevious,
            IsReferenceButtonClick: true,
          },
        };
      });
      dispatch(
        saveInspectionStandardStatus({
          ...questionnairePagePageState.Questionnaire,
          InspectionStandardStatus: saveStatus,
          FinalQuestionAnswered: SaveClickPrevious,
          IsReferenceButtonClick: true,
        })
      );
    }
  };

  const onClickCompleteBtn = (
    saveStatus?: EnumInspectionStandardStatus
  ): void => {
    setIsDirty(false);
    if (
      questionnairePagePageState.Questionnaire.InspectionQuestionList
        ?.length ===
      questionnairePagePageState.Questionnaire.InspectionQuestion.SequenceNumber
    ) {
      let yesnoAnswer =
        questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );

      if (
        questionnairePagePageState.Questionnaire.InspectionQuestion
          .ProductImpactAnswer != null ||
        questionnairePagePageState.Questionnaire.InspectionQuestion
          .NotApplicableAnswer != null ||
        yesnoAnswer?.length > 0
      ) {
        //if (questionnairePagePageState.Questionnaire.PendingCount - 1 > 0) {
        validateAndComplete(
          // questionnairePagePageState.Questionnaire.PendingCount - 1,
          saveStatus,
          true
        );
        // } else {
        //   dispatch(
        //     openPopup({
        //       Open: true,
        //       BodyText: "Are you sure you want to complete?",
        //       HeaderText: "Confirmation",
        //       PopupType: EnumPopupType.YesNoConfirmation,
        //       YesBtnClick() {
        //         setQuestionnairePageState((values) => {
        //           return {
        //             ...values,
        //             Questionnaire: {
        //               ...values.Questionnaire,
        //               InspectionStandardStatus: saveStatus,
        //               FinalQuestionAnswered: true,
        //             },
        //           };
        //         });
        //         dispatch(
        //           saveInspectionStandardStatus({
        //             ...questionnairePagePageState.Questionnaire,
        //             InspectionStandardStatus: saveStatus,
        //             FinalQuestionAnswered: true,
        //           })
        //         );
        //       },
        //     } as PopupProps)
        //   );
        // }
      } else {
        validateAndComplete(
          // questionnairePagePageState.Questionnaire.PendingCount,
          saveStatus,
          true
        );
      }
    } else {
      let pendingCount = questionnairePagePageState.Questionnaire.PendingCount;
      let questionSaveStatus = true;

      let yesnoAnswerPrevious =
        questionnaireState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );

      let yesnoAnswer =
        questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
          (obj: InspectionQuestionChoiceDTO) => {
            if (obj.IsCorrect && obj.IsCorrect != null) {
              return obj;
            }
          }
        );
      if (
        (pendingCount > 0 &&
          yesnoAnswer?.length > 0 &&
          yesnoAnswerPrevious?.length === 0) ||
        (questionnairePagePageState.Questionnaire.InspectionQuestion
          .NotApplicableAnswer != null &&
          questionnaireState.Questionnaire.InspectionQuestion
            .NotApplicableAnswer == null) ||
        (yesnoAnswer?.length > 0 &&
          yesnoAnswerPrevious?.length === 0 &&
          questionnairePagePageState.Questionnaire.UploadedFileList?.length > 0)
      ) {
        pendingCount = pendingCount - 1;
        questionSaveStatus = true;
      } else if (
        questionnairePagePageState.Questionnaire.UploadedFileList?.length > 0
      ) {
        questionSaveStatus = true;
      } else if (
        questionnairePagePageState.Questionnaire.InspectionQuestion.Comment
      ) {
        if (
          questionnairePagePageState.Questionnaire.InspectionQuestion.Comment
            ?.length > 0
        ) {
          questionSaveStatus = true;
        }
      }

      // validateAndComplete(pendingCount, saveStatus, questionSaveStatus);
      validateAndComplete(saveStatus, questionSaveStatus);
    }
  };

  const setAnswerCountList = (): CountListType => {
    let inspectionQuestionStatusId =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId;
    let notApplicableAnswer = false;

    questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
      (item) => {
        if (item.IsGap && item.IsCorrect != null && item.IsCorrect) {
          inspectionQuestionStatusId = EnumInspectionQuestionStatus.Gap;
        } else if (!item.IsGap && item.IsCorrect != null && item.IsCorrect) {
          inspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
        }
      }
    );

    if (
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.NotApplicableAnswer != null &&
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.NotApplicableAnswer
    ) {
      inspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
      notApplicableAnswer = true;
    }

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId: inspectionQuestionStatusId,
          NotApplicableAnswer: notApplicableAnswer,
        };
      }
      return item;
    });
    setNumberList(numberlist);

    let answeredWithoutGaps =
      questionnairePagePageState.Questionnaire?.AnsweredCount;
    let answeredWithGaps = questionnairePagePageState.Questionnaire?.GapCount;
    let skipped = questionnairePagePageState.Questionnaire?.SkippedCount;
    let answeredAsNA = questionnairePagePageState.Questionnaire?.AnsweredAsNA;
    let PendingCount = questionnairePagePageState.Questionnaire?.PendingCount;

    const previousAnswer =
      questionnaireState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId;
    const isPreviousAnswerNotApplicable =
      questionnaireState.Questionnaire?.InspectionQuestion?.NotApplicableAnswer;

    // set Answered without Gaps Count
    answeredWithoutGaps =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      !notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        !isPreviousAnswerNotApplicable
      )
        ? answeredWithoutGaps + 1
        : answeredWithoutGaps;

    if (
      !(
        inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        !notApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      !isPreviousAnswerNotApplicable &&
      answeredWithoutGaps > 0
    ) {
      answeredWithoutGaps = answeredWithoutGaps - 1;
    }

    // set Answered with Gaps Count
    answeredWithGaps =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap &&
      !notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Gap &&
        !isPreviousAnswerNotApplicable
      )
        ? answeredWithGaps + 1
        : answeredWithGaps;

    if (
      inspectionQuestionStatusId !== EnumInspectionQuestionStatus.Gap &&
      previousAnswer === EnumInspectionQuestionStatus.Gap &&
      answeredWithGaps > 0
    ) {
      answeredWithGaps = answeredWithGaps - 1;
    }

    // set Answered as N/A Count
    answeredAsNA =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      notApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        isPreviousAnswerNotApplicable
      )
        ? answeredAsNA + 1
        : answeredAsNA;

    if (
      !(
        inspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        notApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      isPreviousAnswerNotApplicable &&
      answeredAsNA > 0
    ) {
      answeredAsNA = answeredAsNA - 1;
    }

    // set Skipped Count
    skipped =
      inspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      !notApplicableAnswer
        ? skipped + 1
        : skipped;

    if (
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.InspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      skipped > 0 &&
      previousAnswer === EnumInspectionQuestionStatus.Skipped
    ) {
      skipped = skipped - 1;
    }

    // set Pending Count
    // PendingCount =
    //   inspectionQuestionStatusId === EnumInspectionQuestionStatus.Pending &&
    //   !notApplicableAnswer
    //     ? PendingCount + 1
    //     : PendingCount;

    if (
      previousAnswer === EnumInspectionQuestionStatus.Pending &&
      inspectionQuestionStatusId !== EnumInspectionQuestionStatus.Pending &&
      PendingCount > 0
    ) {
      PendingCount = PendingCount - 1;
    }

    setCountList((prevState) => ({
      ...prevState,
      AnsweredWithoutGaps: answeredWithoutGaps,
      AnsweredWithGaps: answeredWithGaps,
      Skipped: skipped,
      Pending: PendingCount,
      AnsweredAsNA: answeredAsNA,
    }));

    return {
      AnsweredWithoutGaps: answeredWithoutGaps,
      AnsweredWithGaps: answeredWithGaps,
      Skipped: skipped,
      Pending: PendingCount,
      AnsweredAsNA: answeredAsNA,
      InspectionStandardQuestionList: numberlist,
    } as CountListType;
  };

  const reSetAnswerCountListToOriginal = () => {
    setCountList((prevState) => ({
      ...prevState,
      Pending: questionnaireState.Questionnaire?.PendingCount,
      // questionnairePagePageState.Questionnaire.PendingCount ==
      // PendingCount
      //   ? questionnairePagePageState.Questionnaire.PendingCount
      //   : PendingCount + 1,
      AnsweredWithoutGaps: questionnaireState.Questionnaire?.AnsweredCount,
      AnsweredWithGaps: questionnaireState.Questionnaire?.GapCount,
      Skipped: questionnaireState.Questionnaire?.SkippedCount,
      AnsweredAsNA: questionnaireState.Questionnaire?.AnsweredAsNA,
    }));

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.InspectionQuestionStatusId,
          NotApplicableAnswer:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.NotApplicableAnswer,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  const validateAndComplete = (
    //PendingCount?: number,
    saveStatus?: EnumInspectionStandardStatus,
    FinalQuestionAnswered?: boolean
  ) => {
    // setCountList((prevState) => ({
    //   ...prevState,
    //   Pending: PendingCount,
    // }));

    const answerCount = setAnswerCountList();

    // let allPendingSkippedCount =
    //   PendingCount + questionnairePagePageState.Questionnaire.SkippedCount;
    // let bodyText =
    //   PendingCount > 0 &&
    //   questionnairePagePageState.Questionnaire.SkippedCount > 0
    //     ? "There are " +
    //       PendingCount +
    //       " pending question(s). All pending question(s) will be marked as skipped. Are you sure you want to complete with " +
    //       allPendingSkippedCount +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : questionnairePagePageState.Questionnaire.SkippedCount > 0
    //     ? "Are you sure you want to complete with " +
    //       questionnairePagePageState.Questionnaire.SkippedCount +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : PendingCount > 0 &&
    //       questionnairePagePageState.Questionnaire.SkippedCount == 0
    //     ? "There are " +
    //       PendingCount +
    //       " pending question(s). All pending question(s) will be marked as skipped. Are you sure you want to complete with " +
    //       allPendingSkippedCount +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : "Are you sure you want to complete?";

    // let allPendingSkippedCount = answerCount?.Pending + answerCount?.Skipped;
    // let bodyText =
    //   answerCount?.Pending > 0 && answerCount?.Skipped > 0
    //     ? "There are " +
    //       answerCount?.Pending +
    //       " pending question(s). All pending question(s) will be marked as skipped. Are you sure you want to complete with " +
    //       allPendingSkippedCount +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : answerCount?.Skipped > 0
    //     ? "Are you sure you want to complete with " +
    //       answerCount?.Skipped +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : answerCount?.Pending > 0 && answerCount?.Skipped == 0
    //     ? "There are " +
    //       answerCount?.Pending +
    //       " pending question(s). All pending question(s) will be marked as skipped. Are you sure you want to complete with " +
    //       allPendingSkippedCount +
    //       " skipped question(s) which will be marked as Gap(s)?"
    //     : "Are you sure you want to complete?";

    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.InspectionStandard,
        SerachInspectionStandard: {
          Id: questionnairePagePageState.Questionnaire?.InspectionQuestion
            ?.InspectionStandardId,
          InspectionId:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              ?.SiteInspectionId,
          Inspection: {
            InspectionQuestions: answerCount.InspectionStandardQuestionList,
            AnsweredWithoutGapCount: answerCount?.AnsweredWithoutGaps,
            AnsweredWithGapCount: answerCount?.AnsweredWithGaps,
            AnsweredWithSkipCount: answerCount?.Skipped,
            AnsweredAsNACount: answerCount?.AnsweredAsNA,
            PendingQuestionCount: answerCount?.Pending,
          } as InspectionDTO,
        } as InspectionStandardDTO,
        onCompleteBtnClick(inspectionSummary: InspectionDTO) {
          setQuestionnairePageState((values) => {
            return {
              ...values,
              Questionnaire: {
                ...values.Questionnaire,
                InspectionQuestion: {
                  ...values.Questionnaire.InspectionQuestion,
                  InspectionQuestionStatusId:
                    values.Questionnaire.InspectionQuestion
                      .InspectionQuestionStatusId ===
                    EnumInspectionQuestionStatus.Pending
                      ? EnumInspectionQuestionStatus.Skipped
                      : values.Questionnaire.InspectionQuestion
                          .InspectionQuestionStatusId,
                },
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: FinalQuestionAnswered,
              },
            };
          });
          dispatch(
            saveInspectionStandardStatus({
              ...questionnairePagePageState.Questionnaire,
              InspectionQuestion: {
                ...questionnairePagePageState.Questionnaire.InspectionQuestion,
                InspectionQuestionStatusId:
                  questionnairePagePageState.Questionnaire.InspectionQuestion
                    .InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Pending
                    ? EnumInspectionQuestionStatus.Skipped
                    : questionnairePagePageState.Questionnaire
                        .InspectionQuestion.InspectionQuestionStatusId,
              },
              InspectionStandardStatus: saveStatus,
              FinalQuestionAnswered: FinalQuestionAnswered,
            })
          );
        },
        onCloseBtnClick() {
          reSetAnswerCountListToOriginal();
        },
      } as ViewInspectionSummaryProps)
    );

    // dispatch(
    //   openPopup({
    //     Open: true,
    //     BodyText: bodyText,
    //     HeaderText: "Confirmation",
    //     PopupType: EnumPopupType.YesNoConfirmation,
    //     YesBtnClick() {
    //       setQuestionnairePageState((values) => {
    //         return {
    //           ...values,
    //           Questionnaire: {
    //             ...values.Questionnaire,
    //             InspectionQuestion: {
    //               ...values.Questionnaire.InspectionQuestion,
    //               InspectionQuestionStatusId:
    //                 values.Questionnaire.InspectionQuestion
    //                   .InspectionQuestionStatusId ==
    //                 EnumInspectionQuestionStatus.Pending
    //                   ? EnumInspectionQuestionStatus.Skipped
    //                   : values.Questionnaire.InspectionQuestion
    //                       .InspectionQuestionStatusId,
    //             },
    //             InspectionStandardStatus: saveStatus,
    //             FinalQuestionAnswered: FinalQuestionAnswered,
    //           },
    //         };
    //       });
    //       dispatch(
    //         saveInspectionStandardStatus({
    //           ...questionnairePagePageState.Questionnaire,
    //           InspectionQuestion: {
    //             ...questionnairePagePageState.Questionnaire.InspectionQuestion,
    //             InspectionQuestionStatusId:
    //               questionnairePagePageState.Questionnaire.InspectionQuestion
    //                 .InspectionQuestionStatusId ==
    //               EnumInspectionQuestionStatus.Pending
    //                 ? EnumInspectionQuestionStatus.Skipped
    //                 : questionnairePagePageState.Questionnaire
    //                     .InspectionQuestion.InspectionQuestionStatusId,
    //           },
    //           InspectionStandardStatus: saveStatus,
    //           FinalQuestionAnswered: FinalQuestionAnswered,
    //         })
    //       );
    //     },
    //     NoBtnClick() {
    //       // setCountList((prevState) => ({
    //       //   ...prevState,
    //       //   Pending: questionnaireState.Questionnaire?.PendingCount,
    //       //   // questionnairePagePageState.Questionnaire.PendingCount ==
    //       //   // PendingCount
    //       //   //   ? questionnairePagePageState.Questionnaire.PendingCount
    //       //   //   : PendingCount + 1,
    //       //   AnsweredWithoutGaps:
    //       //     questionnaireState.Questionnaire?.AnsweredCount,
    //       //   AnsweredWithGaps: questionnaireState.Questionnaire?.GapCount,
    //       //   Skipped: questionnaireState.Questionnaire?.SkippedCount,
    //       //   AnsweredAsNA: questionnaireState.Questionnaire?.AnsweredAsNA,
    //       // }));

    //       // const numberlist = [...numberList].map((item) => {
    //       //   if (
    //       //     item.SequenceNumber ===
    //       //     questionnairePagePageState.Questionnaire?.InspectionQuestion
    //       //       ?.SequenceNumber
    //       //   ) {
    //       //     return {
    //       //       ...item,
    //       //       InspectionQuestionStatusId:
    //       //         questionnaireState?.Questionnaire?.InspectionQuestion
    //       //           ?.InspectionQuestionStatusId,
    //       //       NotApplicableAnswer:
    //       //         questionnaireState?.Questionnaire?.InspectionQuestion
    //       //           ?.NotApplicableAnswer,
    //       //     };
    //       //   }
    //       //   return item;
    //       // });
    //       // setNumberList(numberlist);
    //       reSetAnswerCountListToOriginal();
    //     },
    //   } as PopupProps)
    // );
  };

  const navigationQuestion = (sequence?: number): void => {
    //ignore navigate qustion when click current selection
    if (
      questionnairePagePageState.Questionnaire.CurrentQuestionNumber ===
      sequence
    ) {
      return;
    }

    setIsDirty(false);
    if (
      questionnairePagePageState.Questionnaire.CurrentQuestionNumber > sequence
    ) {
      setQuestionTransition(backwardTransition);
    } else {
      setQuestionTransition(forwardTransition);
    }
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();

    if (
      questionnairePagePageState.YesNoAnswer === "" &&
      !questionnairePagePageState.Questionnaire.ReadOnlyMode
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to skip?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  PendingCount: 0,
                  AnsweredCount: 0,
                  AllQuestionCount: 0,
                  GapCount: 0,
                  SkippedCount: 0,
                  AnsweredAsNA: 0,
                  InspectionQuestion: {
                    ...values.Questionnaire.InspectionQuestion,
                    //SequenceNumber: sequence,
                    Comment:
                      questionnairePagePageState.Questionnaire
                        .InspectionQuestion.Comment,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    //NotApplicableAnswer: null,
                  },
                  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                  SaveAnswerBeforeClickPrevious: true,
                  IsFromQuestionnairePge: false,
                },
              };
            });
            dispatch(
              getSiteInspectionQuestiontData({
                ...questionnairePagePageState.Questionnaire,

                PendingCount: 0,
                AllQuestionCount: 0,
                AnsweredCount: 0,
                GapCount: 0,
                SkippedCount: 0,
                AnsweredAsNA: 0,
                InspectionQuestion: {
                  ...questionnairePagePageState.Questionnaire
                    .InspectionQuestion,
                  SequenceNumber: sequence,
                  Comment:
                    questionnairePagePageState.Questionnaire.InspectionQuestion
                      .Comment,
                  InspectionQuestionStatusId:
                    EnumInspectionQuestionStatus.Skipped,
                  //NotApplicableAnswer: null,
                },
                InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                SaveAnswerBeforeClickPrevious: true,
                IsFromQuestionnairePge: false,
              })
            );
          },
        } as PopupProps)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            PendingCount: 0,
            AnsweredCount: 0,
            AllQuestionCount: 0,
            GapCount: 0,
            SkippedCount: 0,
            AnsweredAsNA: 0,
            InspectionQuestion: {
              ...values.Questionnaire.InspectionQuestion,
              // SequenceNumber: sequence,
              Comment:
                questionnairePagePageState.Questionnaire.InspectionQuestion
                  .Comment,
              //NotApplicableAnswer: null,
            },
            InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
            SaveAnswerBeforeClickPrevious: SaveClickPrevious,
            IsFromQuestionnairePge: false,
          },
        };
      });
      // let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
      // dispatch(setLoading(true));
      // do {
      //   toBeUploaded =
      //     questionnairePagePageState.Questionnaire.UploadedFileList.filter(
      //       (file) =>
      //         file.UploadStatus &&
      //         (file.UploadStatus == EnumDataTransferStatus.NotStarted ||
      //           file.UploadStatus == EnumDataTransferStatus.InProgress)
      //     );
      // } while (toBeUploaded?.length > 0);
      dispatch(
        getSiteInspectionQuestiontData({
          ...questionnairePagePageState.Questionnaire,

          PendingCount: 0,
          AllQuestionCount: 0,
          AnsweredCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          AnsweredAsNA: 0,
          InspectionQuestion: {
            ...questionnairePagePageState.Questionnaire.InspectionQuestion,
            SequenceNumber: sequence,
            Comment:
              questionnairePagePageState.Questionnaire.InspectionQuestion
                .Comment,
            //NotApplicableAnswer: null,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveClickPrevious,
          IsFromQuestionnairePge: false,
        })
      );
    }
    // if (fieldRef.current) {
    //   setTimeout(function () {
    //     let scrollto = sequence;
    //     let diff = fieldRef.current?.length - scrollto;
    //     if (diff >= 5) {
    //       fieldRef.current[scrollto + 4].scrollIntoView({
    //         behavior: "smooth",
    //         block: "end",
    //         inline: "nearest",
    //       });
    //     } else if (diff > 2 && diff <= 5) {
    //       fieldRef.current[scrollto + 1].scrollIntoView({
    //         behavior: "smooth",
    //         block: "end",
    //         inline: "nearest",
    //       });
    //     } else if (diff > 0 && diff <= 2) {
    //       fieldRef.current[scrollto].scrollIntoView({
    //         behavior: "smooth",
    //         block: "end",
    //         inline: "nearest",
    //       });
    //     } else {
    //       fieldRef.current[scrollto - 1].scrollIntoView({
    //         behavior: "smooth",
    //         block: "end",
    //         inline: "nearest",
    //       });
    //     }
    //   }, 1000);
    // }
  };

  // const SaveAnswerCheck = () => {
  //   if (!questionnairePagePageState?.Questionnaire?.ReadOnlyMode) {
  //     let yesnolatestAnswer =
  //       questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
  //         (obj: InspectionQuestionChoiceDTO) => {
  //           if (obj.IsCorrect && obj.IsCorrect != null) {
  //             return obj;
  //           }
  //         }
  //       );

  //     let yesnoAnswerPrevious =
  //       questionnaireState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.filter(
  //         (obj: InspectionQuestionChoiceDTO) => {
  //           if (obj.IsCorrect && obj.IsCorrect != null) {
  //             return obj;
  //           }
  //         }
  //       );

  //     let answerChanged: boolean;
  //     let notApplicableAnswer = null;

  //     if (
  //       yesnolatestAnswer??.length > 0 &&
  //       yesnoAnswerPrevious??.length > 0 &&
  //       yesnolatestAnswer[0]?.ChoiceText.DefaultText !=
  //         yesnoAnswerPrevious[0]?.ChoiceText.DefaultText
  //     ) {
  //       answerChanged = true;
  //     } else {
  //       if (yesnolatestAnswer??.length > 0 && yesnoAnswerPrevious??.length == 0) {
  //         answerChanged = true;
  //       } else {
  //         answerChanged = false;
  //       }
  //     }

  //     if (
  //       (questionnairePagePageState.Questionnaire.InspectionQuestion
  //         .ProductImpactAnswer != null &&
  //         questionnairePagePageState.Questionnaire.InspectionQuestion
  //           .ProductImpactAnswer !=
  //           questionnaireState.Questionnaire.InspectionQuestion
  //             .ProductImpactAnswer) ||
  //       (questionnairePagePageState.Questionnaire.InspectionQuestion
  //         .NotApplicableAnswer != null &&
  //         questionnairePagePageState.Questionnaire.InspectionQuestion
  //           .NotApplicableAnswer !=
  //           questionnaireState.Questionnaire.InspectionQuestion
  //             .NotApplicableAnswer) ||
  //       answerChanged ||
  //       questionnairePagePageState.Questionnaire.InspectionQuestion.Comment !=
  //         questionnaireState.Questionnaire.InspectionQuestion.Comment ||
  //       JSON.stringify(
  //         questionnairePagePageState.Questionnaire.UploadedFileList
  //       ) !== JSON.stringify(questionnaireState.Questionnaire.UploadedFileList)
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  const getPreviousInspectionQuestion = (
    Currentsequence?: number,
    SaveQuestion?: boolean,
    questionStatus?: EnumInspectionQuestionStatus
  ) => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          PendingCount: 0,
          AnsweredCount: 0,
          AllQuestionCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          InspectionQuestion: {
            ...values.Questionnaire.InspectionQuestion,
            //SequenceNumber: Currentsequence - 1,
            Comment:
              questionnairePagePageState.Questionnaire.InspectionQuestion
                .Comment,
            InspectionQuestionStatusId: questionStatus,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveQuestion,
          IsFromQuestionnairePge: false,
        },
      };
    });
    dispatch(
      // saveInspectionQuestionAnswer(questionnairePagePageState?.Questionnaire)
      getSiteInspectionQuestiontData({
        ...questionnairePagePageState.Questionnaire,
        PendingCount: 0,
        AllQuestionCount: 0,
        AnsweredCount: 0,
        GapCount: 0,
        SkippedCount: 0,
        InspectionQuestion: {
          ...questionnairePagePageState.Questionnaire.InspectionQuestion,
          SequenceNumber: Currentsequence - 1,
          Comment:
            questionnairePagePageState.Questionnaire.InspectionQuestion.Comment,
          InspectionQuestionStatusId: questionStatus,
        },
        InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
        SaveAnswerBeforeClickPrevious: SaveQuestion,
        IsFromQuestionnairePge: false,
      })
    );
  };

  const onClickBackBtn = (
    Currentsequence?: number,
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    setQuestionTransition(backwardTransition);
    setIsDirty(false);
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();
    if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId === EnumQuestionTypesType.YesNo ||
      questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.NotApplicable
    ) {
      if (
        questionnairePagePageState.YesNoAnswer === "" &&
        !questionnairePagePageState.Questionnaire.ReadOnlyMode
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              getPreviousInspectionQuestion(
                Currentsequence,
                true,
                EnumInspectionQuestionStatus.Skipped
              );
            },
          } as PopupProps)
        );
      } else {
        getPreviousInspectionQuestion(
          Currentsequence,
          SaveClickPrevious,
          questionnairePagePageState.Questionnaire.InspectionQuestion
            .InspectionQuestionStatusId
        );
      }
    } else {
      getPreviousInspectionQuestion(
        Currentsequence,
        false,
        questionnairePagePageState.Questionnaire.InspectionQuestion
          .InspectionQuestionStatusId
      );
    }
  };

  const getError = (error: FileUploaderErrorType) => {
    /* TODO document why this arrow function is empty */
  };

  const fieldRef = useRef([]);

  // fieldRef.current = [];
  const addToRefs: (el) => void = (el) => {
    if (el && !fieldRef.current.includes(el)) {
      fieldRef.current.push(el);
    }
  };

  // list.InspectionQuestionStatusId ===
  // EnumInspectionQuestionStatus.Answered
  //   ? "green-indicator" + questionBoxStyle
  //   : list.InspectionQuestionStatusId ===
  //     EnumInspectionQuestionStatus.Gap
  //   ? "red-indicator" + questionBoxStyle
  //   : list.InspectionQuestionStatusId ===
  //     EnumInspectionQuestionStatus.Skipped
  //   ? "orange-indicator" + questionBoxStyle
  //   : "light-blue-indicator" + questionBoxStyle

  function getIndicator(
    inspectionQuestionStatus: number,
    notApplicableAnswer: boolean,
    questionBoxStyle: string
  ) {
    if (
      inspectionQuestionStatus === EnumInspectionQuestionStatus.Answered &&
      (notApplicableAnswer === false || notApplicableAnswer == null)
    ) {
      return "green-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestionStatus === EnumInspectionQuestionStatus.Answered &&
      notApplicableAnswer === true
    ) {
      return "na-indicator" + questionBoxStyle;
    } else if (inspectionQuestionStatus === EnumInspectionQuestionStatus.Gap) {
      return "red-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestionStatus === EnumInspectionQuestionStatus.Skipped
    ) {
      return "orange-indicator" + questionBoxStyle;
    } else {
      return "light-blue-indicator" + questionBoxStyle;
    }
  }

  const onClickClearAnswerBtn = (
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    let editedList: Array<InspectionQuestionChoiceDTO> =
      questionnairePagePageState.Questionnaire.InspectionQuestion.InspectionQuestionChoices.map(
        (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
          let updateQuestionChoice: InspectionQuestionChoiceDTO = Object.assign(
            {} as InspectionQuestionChoiceDTO,
            questionChoice
          );
          updateQuestionChoice.IsCorrect = null;
          return updateQuestionChoice;
        }
      );

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire.InspectionQuestion,
            InspectionQuestionChoices: editedList,
            NotApplicableAnswer: null,
            InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
          },
          SaveAnswerBeforeClickPrevious: true,
          PendingCount: values.Questionnaire.PendingCount + 1,
        },
        YesNoAnswer: "",
      };
    });

    dispatch(
      setSiteInspectionStandardStatus({
        ...questionnaireState.Questionnaire,
        InspectionQuestion: {
          ...questionnaireState.Questionnaire.InspectionQuestion,
          InspectionQuestionChoices: editedList,
          NotApplicableAnswer: null,
          InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
        },
        SaveAnswerBeforeClickPrevious: true,
        PendingCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId !==
          EnumInspectionQuestionStatus.Pending
            ? questionnaireState.Questionnaire.PendingCount + 1
            : questionnaireState.Questionnaire.PendingCount,
        SkippedCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Skipped
            ? questionnaireState.Questionnaire.SkippedCount - 1
            : questionnaireState.Questionnaire.SkippedCount,
        AnsweredCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer === null
            ? questionnaireState.Questionnaire.AnsweredCount - 1
            : questionnaireState.Questionnaire.AnsweredCount,
        GapCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap
            ? questionnaireState.Questionnaire.GapCount - 1
            : questionnaireState.Questionnaire.GapCount,
        AnsweredAsNA:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer
            ? questionnaireState.Questionnaire.AnsweredAsNA - 1
            : questionnaireState.Questionnaire.AnsweredAsNA,
      })
    );

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId: saveStatus,
          NotApplicableAnswer: null,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  return (
    <>
      {questionnairePagePageState?.Questionnaire?.InspectionQuestion
        .QuestionId ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container ref={slider}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="custom-question-nav-wrap">
                  <div className="">
                    {isScrollable && !isStart && (
                      // <Tooltip title="Previous Question">
                      <div
                        className="q-nav button"
                        onClick={() => {
                          handleScroll(0);
                        }}
                        style={{ marginLeft: "-14px" }}
                      >
                        <ArrowLeft2 className="prev" />
                        <span className="mr-10 prev">
                          Previous <br />
                          QN(s)
                        </span>
                        {/* <div className="next">
                          <ArrowLeft2 />
                        </div>
                        <span className="mr-10">
                          Next <br />
                          QN(s)
                        </span> */}
                      </div>
                      // </Tooltip>
                    )}
                  </div>

                  <div
                    className={`question-container ${
                      isScrollable ? "question-container" : ""
                    }`}
                    ref={questionContainerRef}
                  >
                    {numberList &&
                      numberList.map((list, index) => {
                        let questionBoxStyle: string =
                          list.SequenceNumber ===
                          questionnairePagePageState.Questionnaire
                            .CurrentQuestionNumber
                            ? " current-item-blue-border"
                            : "";

                        return (
                          <div
                            key={index}
                            className={"question-item-wrapper"}
                            onClick={() =>
                              navigationQuestion(list.SequenceNumber)
                            }
                            ref={addToRefs}
                          >
                            <span
                              className={getIndicator(
                                list.InspectionQuestionStatusId,
                                list.NotApplicableAnswer,
                                questionBoxStyle
                              )}
                            >
                              {list.SequenceNumber}
                              <span
                                className="attachment-indicator"
                                style={{
                                  display: list.IsAttachmentAvailable
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                {/* <AttachFileIcon></AttachFileIcon> */}
                                <img src={attachIco3} height={20} />
                              </span>
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="m-l-3">
                    {isScrollable && !isEnd && (
                      // <Tooltip title="Next Question">
                      <div
                        className="q-nav"
                        onClick={() => handleScroll(1)}
                        style={{ marginRight: "-14px" }}
                      >
                        <span className="ml-10">
                          Next <br />
                          QN(s)
                        </span>
                        <div className="next">
                          <ArrowRight2 />
                        </div>
                      </div>
                      // </Tooltip>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <Grid container>
            <Grid item xs={12}>
              <div className="item-wrapper">
                <LinearProgress
                  variant="determinate"
                  value={Math.round(
                    ((questionnairePagePageState.Questionnaire.AnsweredCount +
                      questionnairePagePageState.Questionnaire.SkippedCount) /
                      questionnairePagePageState.Questionnaire
                        .AllQuestionCount) *
                      100
                  )}
                />
              </div>
            </Grid>
          </Grid> */}

            {/*Counts bar start*/}

            <Grid container ref={legend}>
              <div className="legend-wrapper">
                <ul>
                  <li>
                    <a>
                      <span className="color-code-legend green-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithoutGaps})
                        </span>
                        Answered without Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend red-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithGaps})
                        </span>
                        Answered with Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend na-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredAsNA})
                        </span>
                        Answered as N/A
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend orange-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.Skipped})
                        </span>
                        Skipped
                      </label>
                    </a>
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion?.SiteInspection
                      ?.InspectionStandards[0]?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend blue-indicator"></span>
                        <label className="legend-description">Current</label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion?.SiteInspection
                      ?.InspectionStandards[0]?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend light-blue-indicator"></span>
                        <label className="legend-description">
                          <span className="q-quantity">
                            ({countList.Pending})
                          </span>
                          Pending
                        </label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                </ul>
              </div>

              <div className="flex-right valign-middle site-ins-progress-test">
                {/* {questionnairePagePageState?.PageMode.IsShowProgress ? ( */}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID === 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}

                {questionnairePagePageState?.Questionnaire?.InspectionQuestion
                  ?.SiteInspection?.InspectionStandards[0]
                  ?.InspectionStandardStatusId ===
                EnumInspectionStandardStatus.Completed ? (
                  <label className="audit-qty-lbl red-text align-right">
                    <span></span>
                    {questionnairePagePageState.Questionnaire.SkippedCount +
                      questionnairePagePageState.Questionnaire.GapCount +
                      " Total Gaps"}
                  </label>
                ) : (
                  <></>
                )}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID > 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Selection Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}
              </div>

              {/* <Grid
                item
                xl={4}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                className="flex-right valign-middle"
              >
               
              </Grid> */}
            </Grid>

            {/*Counts bar end*/}

            <Grid container>
              <Grid item xs={12}>
                <div className="item-wrapper questionaire-wrap mb-0">
                  <div className="item-header" ref={header}>
                    <Grid container>
                      <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                        <h1>
                          {
                            questionnairePagePageState.Questionnaire
                              .InspectionStandardName
                          }{" "}
                        </h1>
                      </Grid>
                      <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                        {questionnairePagePageState?.Questionnaire
                          ?.ReferenceID > 0 ? (
                          <>
                            <Button
                              className="light-blue small-btn  align-right"
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                onClickPausedBtn(
                                  EnumInspectionStandardStatus.Paused,
                                  false
                                )
                              }
                            >
                              Reference selection
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                        {questionnairePagePageState?.PageMode.IsPauseBtn &&
                        questionnairePagePageState?.PageMode.IsCompleteBtn ? (
                          // &&
                          // questionnairePagePageState?.Questionnaire?.ReferenceID ==
                          //   0
                          <>
                            {questionnairePagePageState?.Questionnaire
                              ?.ReferenceID === 0 &&
                              checkSystemFunctionRight(
                                EnumSystemFunctions.QuestionnairePage_CompleteStandard,
                                EnumSystemFunctionTypes.ButtonAccess
                              ) && (
                                <Button
                                  className="light-blue small-btn  align-right"
                                  variant="contained"
                                  disableElevation
                                  onClick={() =>
                                    onClickCompleteBtn(
                                      EnumInspectionStandardStatus.Completed
                                    )
                                  }
                                  // style={{
                                  //   visibility: checkSystemFunctionRight(
                                  //     EnumSystemFunctions.QuestionnairePage_CompleteStandard,
                                  //     EnumSystemFunctionTypes.ButtonAccess
                                  //   )
                                  //     ? "visible"
                                  //     : "hidden",
                                  // }}
                                >
                                  Complete
                                </Button>
                              )}

                            <Button
                              className="light-blue small-btn align-right"
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                onClickPausedBtn(
                                  EnumInspectionStandardStatus.Paused,
                                  true
                                )
                              }
                            >
                              Back to site inspection
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                        {(questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.SiteInspection
                          ?.InspectionStandards[0]
                          ?.InspectionStandardStatusId ===
                          EnumInspectionStandardStatus.Completed &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0) ||
                        (questionnairePagePageState?.Questionnaire
                          .IsSiteInspectionViewButtonClick &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0) ? (
                          <>
                            <Button
                              className="light-blue small-btn  align-right"
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                navigate("/siteInspectionStart", {
                                  state: {
                                    InspectionId:
                                      questionnairePagePageState.Questionnaire
                                        .SiteInspectionID,
                                  },
                                })
                              }
                            >
                              Back to site inspection
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.SiteInspection
                          ?.InspectionStandards[0]
                          ?.InspectionStandardStatusId ===
                          EnumInspectionStandardStatus.Completed &&
                        questionnairePagePageState?.Questionnaire
                          ?.ReferenceID === 0 ? (
                          <>
                            <Button
                              className="light-blue small-btn  align-right"
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                navigate("/questionnaireSummary", {
                                  state: {
                                    StandardId:
                                      questionnairePagePageState.Questionnaire
                                        .StandardID,
                                    SiteInspectionId:
                                      questionnairePagePageState.Questionnaire
                                        .SiteInspectionID,
                                    InspectionStandardId:
                                      questionnairePagePageState.Questionnaire
                                        .InspectionStandardId,
                                    ReAssess: false,
                                  } as QuestionnaireSummaryPageParametersType,
                                })
                              }
                            >
                              Summary
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    {/* <IconButton
                        id="history-btn"
                        className="viewInspectionQuestionHistory"
                        onClick={(event) => {
                          dispatch(
                            viewInspectionQuestionHistorySliceReducerActions.viewInspectionQuestionHistoryDetails(
                              {
                                Ref: event.currentTarget,
                                InspectionQuestionId:
                                  questionnairePagePageState?.Questionnaire
                                    ?.InspectionQuestion?.Id,
                              } as ViewInspectionQuestionHistoryProps
                            )
                          );
                        }}
                      >
                        <Clock size="18" color="#FF8A65" />
                      </IconButton> */}
                  </div>
                  {/*Start of question*/}
                  <>
                    <motion.div
                      key={
                        questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion.QuestionId
                      }
                      className="item-content question-conent"
                      variants={questionTransition}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      style={{ height: qsectionheight, overflow: "auto" }}
                    >
                      {/*Start of question*/}
                      <div className="questionair-outer-wrapper">
                        <Grid container>
                          <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                            <label className="question-label">
                              <span className="question-num">
                                {questionnairePagePageState
                                  ? questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.SequenceNumber
                                  : ""}
                                {questionnairePagePageState &&
                                questionnairePagePageState.Questionnaire
                                  .InspectionQuestion.SequenceNumber
                                  ? "."
                                  : ""}
                              </span>
                              <span className="question-text">
                                {
                                  questionnairePagePageState?.Questionnaire
                                    ?.QuestionText
                                }
                              </span>
                            </label>
                          </Grid>
                          {/* <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                            {!questionnairePagePageState?.PageMode.IsDisable ? (
                              <Button
                                className="disabled primary large-btn align-right"
                                variant="contained"
                                disableElevation
                              >
                                Create a copy
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Grid> */}
                        </Grid>

                        <Grid container className="mb-15">
                          <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
                            <div className="audit-sub-section width-auto">
                              {questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.QuestionTypeId ===
                              EnumQuestionTypesType.YesNo ? (
                                questionnairePagePageState?.Questionnaire
                                  ?.InspectionQuestion
                                  ?.InspectionQuestionChoices?.length > 0 ? (
                                  <RadioGroup
                                    name="QuestionAnswer"
                                    className="max-content"
                                    value={
                                      questionnairePagePageState.YesNoAnswer
                                    }
                                    onChange={(event) =>
                                      handleQuestionRadioChange(
                                        event.target.name,
                                        event.target.value
                                      )
                                    }
                                  >
                                    {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
                                      (choiceData, index) => {
                                        return (
                                          <HPRRadioButton
                                            key={index}
                                            Name={"QuestionAnswer_" + index}
                                            Disabled={
                                              questionnairePagePageState
                                                ?.PageMode?.IsDisable
                                            }
                                            Value={
                                              choiceData.ChoiceText
                                                .DefaultText === "Yes"
                                                ? "1"
                                                : "0"
                                            }
                                            Label={
                                              choiceData.ChoiceText.DefaultText
                                            }
                                            ClassName={"default-label"}
                                          ></HPRRadioButton>
                                        );
                                      }
                                    )}
                                    {questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.NotApplicable ? (
                                      <HPRRadioButton
                                        Name={"QuestionAnswer"}
                                        Value={"-1"}
                                        Label={"Not Applicable"}
                                        ClassName={"default-label"}
                                        Disabled={
                                          questionnairePagePageState?.PageMode
                                            ?.IsDisable
                                        }
                                      ></HPRRadioButton>
                                    ) : (
                                      <></>
                                    )}
                                  </RadioGroup>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            style={{ display: "flex" }}
                          >
                            {!questionnairePagePageState?.PageMode.IsDisable &&
                            (questionnairePagePageState.YesNoAnswer !== "" ||
                              questionnairePagePageState.Questionnaire
                                .InspectionQuestion.NotApplicableAnswer) ? (
                              <>
                                <Button
                                  className="primary small-btn"
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    display: "flex",
                                    alignSelf: "end",
                                    visibility: checkSystemFunctionRight(
                                      EnumSystemFunctions.QuestionnairePage_ClearAnswer,
                                      EnumSystemFunctionTypes.ButtonAccess
                                    )
                                      ? "visible"
                                      : "hidden",
                                  }}
                                  onClick={() => {
                                    onClickClearAnswerBtn(
                                      EnumInspectionQuestionStatus.Pending
                                    );
                                  }}
                                >
                                  Clear answer
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {/* {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.ProductImpact ? (
                          <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
                            <div className="audit-sub-section mb-15 width-auto">
                              <h2> Product Impact</h2>

                              <RadioGroup
                                className="hpr-radio max-content"
                                aria-labelledby="demo-error-radios"
                                name="ProductImpact"
                                value={
                                  questionnairePagePageState.Questionnaire
                                    .InspectionQuestion.ProductImpactAnswer ===
                                  true
                                    ? "1"
                                    : questionnairePagePageState.Questionnaire
                                        .InspectionQuestion
                                        .ProductImpactAnswer === false
                                    ? "0"
                                    : ""
                                }
                                onChange={(event) =>
                                  handleProductImpactRadioChange(
                                    event.target.value
                                  )
                                }
                              >
                                <HPRRadioButton
                                  Name={"ProductImpact"}
                                  Value={"1"}
                                  Label={"Yes"}
                                  ClassName={"default-label"}
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                ></HPRRadioButton>
                                <HPRRadioButton
                                  Name={"ProductImpact"}
                                  Value={"0"}
                                  Label={"No"}
                                  ClassName={"default-label"}
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                ></HPRRadioButton>
                              </RadioGroup>
                            </div>
                          </Grid>
                        ) : (
                          <></>
                        )} */}
                        </Grid>
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion.AdditionalComments ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="audit-sub-section">
                                <div className="form-group site-inspec-textarea">
                                  {/* <HPRTextBox
                                    Id={""}
                                    Label={"Comment"}
                                    Name={"QuestionComment"}
                                    Value={
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion?.Comment
                                        ? questionnairePagePageState
                                            ?.Questionnaire?.InspectionQuestion
                                            ?.Comment
                                        : ""
                                    }
                                    Type={EnumTextBoxType.Text}
                                    ClassName={"site-inspec-textarea"}
                                    InputProps={{ maxLength: 1000 }}
                                    ShowTextCounter
                                    TextLength={1000}
                                    MultiLine
                                    onTextBoxChange={OnTextBoxChange}
                                    Disabled={
                                      questionnairePagePageState?.PageMode
                                        ?.IsDisable
                                    }
                                  /> */}
                                  <HPRTextBoxAutoResize
                                    Id={""}
                                    Label={"Comment"}
                                    Name={"QuestionComment"}
                                    Value={
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion?.Comment
                                        ? questionnairePagePageState
                                            ?.Questionnaire?.InspectionQuestion
                                            ?.Comment
                                        : ""
                                    }
                                    Type={EnumTextBoxType.Text}
                                    ClassName={"site-inspec-textarea"}
                                    InputProps={{ maxLength: 1000 }}
                                    ShowTextCounter
                                    TextLength={1000}
                                    MultiLine
                                    onTextBoxChange={OnTextBoxChange}
                                    Disabled={
                                      questionnairePagePageState?.PageMode
                                        ?.IsDisable
                                    }
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : null}
                        <Grid container spacing={2}>
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion.ImageUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <div className="item-desc-wrap">
                                    <div className={"selected-item-wrapper"}>
                                      <HPRFileUploader
                                        Label={"Upload image"}
                                        FileType={EnumFileType.Image}
                                        Name={"Image"}
                                        GetselectedFiles={getSelectedImageFiles}
                                        GetError={getError}
                                        PreviewImage={true}
                                        UploadedFileList={
                                          questionnairePagePageState
                                            .Questionnaire.UploadedFileList
                                        }
                                        Multiple
                                        Disabled={
                                          questionnairePagePageState?.PageMode
                                            ?.IsDisable
                                        }
                                        ShowComment
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              {/* <Grid container className="item-desc-wrap">
                          <Grid item xs={6}>
                            <div className="selected-item-wrapper">
                              <a href="">
                                <DocumentText size="50" className="doc-icon" />
                                <label className="doc-name">
                                  Doc-122002123.jpg
                                </label>
                              </a>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="audit-sub-section">
                              <div className="FormGroup">
                                <HPRTextArea
                                  Id={""}
                                  Label={"Add comment"}
                                  Name={""}
                                  Value={undefined}
                                  Type={EnumTextAreaType.Text}
                                  ClassName={""}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid> */}
                            </Grid>
                          ) : null}
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion.ImageUpload === 3 &&
                          questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion.DocumentUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} />
                          ) : null}
                          {questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion.DocumentUpload !== 3 ? (
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div
                                className={
                                  "selected-item-wrapper align-right upload-button"
                                }
                              >
                                <HPRFileUploader
                                  Label={"Upload doc"}
                                  FileType={EnumFileType.Doc}
                                  Name={"doc"}
                                  GetselectedFiles={getSelectedImageFiles}
                                  GetError={getError}
                                  // PreviewImage={true}
                                  UploadedFileList={
                                    questionnairePagePageState.Questionnaire
                                      .UploadedFileList
                                  }
                                  Multiple
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                  ShowComment
                                />
                                {/* <Button
                            className="primary large-btn align-right"
                            variant="contained"
                            disableElevation
                          >
                            Upload doc
                          </Button> */}
                              </div>
                              {/* <Grid container className="item-desc-wrap">
                          <Grid item xs={6}>
                            <div className="selected-item-wrapper">
                              <a href="">
                                <DocumentText size="50" className="doc-icon" />
                                <label className="doc-name">
                                  Doc-122002123.jpg
                                </label>
                              </a>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="audit-sub-section">
                              <div className="FormGroup">
                                <HPRTextArea
                                  Id={""}
                                  Label={"Add comment"}
                                  Name={""}
                                  Value={undefined}
                                  Type={EnumTextAreaType.Text}
                                  ClassName={""}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid> */}
                            </Grid>
                          ) : null}
                        </Grid>

                        <Grid container>
                          <div className="audit-sub-section">
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={2}>
                                  <div className="grid-item-header">
                                    Reference
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div className="grid-item-header">System</div>
                                </Grid>
                                <Grid item xs={3}>
                                  <div className="grid-item-header">
                                    Sub-system
                                  </div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className="grid-item-header">
                                    Subject
                                  </div>
                                </Grid>
                                <Grid item xs={2}>
                                  <div className="grid-item-header">
                                    Section
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>

                            {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionParameters?.map(
                              (questionparams, index: number) => {
                                return (
                                  <Grid item xs={12} key={index}>
                                    <Grid container spacing={1} sx={{ mb: 1 }}>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper q-page-ref">
                                          <a
                                            href={
                                              questionparams.StandardReference
                                                .Url
                                            }
                                            target="_blank"
                                          >
                                            {questionparams.StandardReference
                                              .Name
                                              ? questionparams.StandardReference
                                                  .Name
                                              : "N/A"}
                                          </a>
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSystem.Name
                                            ? questionparams.StandardSystem.Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSubSystem.Name
                                            ? questionparams.StandardSubSystem
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSubject.Name
                                            ? questionparams.StandardSubject
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="grid-item-wrapper">
                                          {questionparams.StandardSection.Name
                                            ? questionparams.StandardSection
                                                .Name
                                            : "N/A"}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )}
                          </div>
                        </Grid>
                      </div>
                    </motion.div>
                  </>
                  {/* End of question */}
                  <div className="audit-item-footer" ref={footer}>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length > 1 &&
                        questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length !==
                          questionnairePagePageState?.Questionnaire
                            ?.InspectionQuestion?.SequenceNumber ? (
                          <Button
                            className="primary small-btn"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setQuestionTransition(forwardTransition);
                              onClickSaveBtn(
                                EnumInspectionQuestionStatus.Pending
                              );
                            }}
                          >
                            Next
                          </Button>
                        ) : (
                          <>
                            {/* {questionnairePagePageState?.Questionnaire
                              ?.ReferenceID > 0 ? (
                              <Button
                                className="primary small-btn"
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                  setQuestionTransition(forwardTransition);
                                  onClickSaveBtn(
                                    EnumInspectionQuestionStatus.Pending
                                  );
                                }}
                              >
                                Next
                              </Button>
                            ) : (
                              <></>
                            )} */}
                          </>
                        )}
                        {!questionnairePagePageState?.PageMode.IsDisable &&
                        !questionnairePagePageState?.IsHideSkipButton &&
                        questionnairePagePageState?.Questionnaire
                          ?.ReferenceID === 0 ? (
                          <Button
                            className="primary small-btn"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setQuestionTransition(forwardTransition);
                              onClickSaveBtn(
                                EnumInspectionQuestionStatus.Skipped
                              );
                            }}
                          >
                            Skip
                          </Button>
                        ) : (
                          <>
                            {!questionnairePagePageState?.PageMode.IsDisable &&
                            questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestionList?.length > 1 &&
                            questionnairePagePageState?.Questionnaire
                              ?.ReferenceID > 0 ? (
                              <Button
                                className="primary small-btn"
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                  setQuestionTransition(forwardTransition);
                                  onClickSaveBtn(
                                    EnumInspectionQuestionStatus.Skipped
                                  );
                                }}
                              >
                                Skip
                              </Button>
                            ) : (
                              <>
                                {!questionnairePagePageState?.PageMode
                                  .IsDisable &&
                                questionnairePagePageState.YesNoAnswer === "" &&
                                questionnairePagePageState.Questionnaire
                                  .InspectionQuestion.NotApplicableAnswer ==
                                  null ? (
                                  <Button
                                    className="primary small-btn"
                                    variant="contained"
                                    disableElevation
                                    onClick={() => {
                                      setQuestionTransition(forwardTransition);
                                      onClickSaveBtn(
                                        EnumInspectionQuestionStatus.Skipped
                                      );
                                    }}
                                  >
                                    Skip
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestionList?.length > 1 &&
                        questionnairePagePageState.Questionnaire
                          .CurrentQuestionNumber > 1 ? (
                          <Button
                            className="primary small-btn align-left"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickBackBtn(
                                questionnairePagePageState.Questionnaire
                                  .CurrentQuestionNumber,
                                EnumInspectionQuestionStatus.Pending
                              )
                            }
                          >
                            Previous
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <ViewInspectionQuestionHistory />
    </>
  );
};

export default QuestionnairePage;
