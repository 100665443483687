import { createSlice } from "@reduxjs/toolkit";
import {
  EnumInspectionSortType,
  EnumListShowRowCountList,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  PaginationDTO,
  SearchParameterDTO,
} from "../../../common/types/common.dto.types";
import { HPRListAuditPaginationType } from "../../../components/common/HPRListAuditPagination/HPRListAuditPagination.types";
import { PaginationListItemType } from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { SiteInspectionsPageInitialState } from "./SiteInspectionsPage.types";

const initialState = {
  Data: {} as PaginationDTO,
  Loading: false,
  ListPagination: {
    ItemList: [] as Array<PaginationListItemType>,
    ItemShowCount: 2,
    TotalRecordsCount: 0,
    SelectedPage: 1,
  } as HPRListAuditPaginationType,
  SiteInspectionSearchParameter: {
    InspectionSearchParams: {
      Name: "",
      Description: "",
      Status: EnumStatus.Published,
      SortBy: EnumInspectionSortType.Scheduling,
      CreatedBy: "",
      CreatedDate: "0001-01-01",
    } as InspectionDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_2,
  } as SearchParameterDTO,
} as SiteInspectionsPageInitialState;

const siteInspectionsSlice = createSlice({
  name: "siteInspections",
  initialState: initialState,
  reducers: {
    setSiteInspectionListSearchParameter: (
      state: SiteInspectionsPageInitialState,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        SiteInspectionSearchParameter: {
          ...state.SiteInspectionSearchParameter,
          InspectionSearchParams: action.payload.InspectionSearchParams,
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          ListPageSize: action.payload.ListPageSize,
        } as SearchParameterDTO,
      };
    },
    setLoading: (state: SiteInspectionsPageInitialState, action) => {
      return { ...state, Loading: action.payload };
    },
    setSiteInspectionsList: (
      state: SiteInspectionsPageInitialState,
      action: { payload: SiteInspectionsPageInitialState; type: string }
    ) => {
      return {
        ...state,
        Data: action.payload.Data,
      };
    },
    setPagination: (
      state: SiteInspectionsPageInitialState,
      action: { payload: HPRListAuditPaginationType; type: string }
    ) => {
      return {
        ...state,
        ListPagination: action.payload,
      };
    },
    setUpdatedPaginationList: (
      state: SiteInspectionsPageInitialState,
      action: { payload: PaginationDTO; type: string }
    ) => {
      let itemsList = state.ListPagination.ItemList.map((item) => {
        let isExsit = action.payload.Data?.find(
          (filter) => filter.Id.toString() === item.Id.toString()
        );
        if (isExsit !== undefined) {
          item.StatusID = isExsit.Status;
        }
        return item;
      });
      state.ListPagination.ItemList = itemsList;
    },
  },
});

export const siteInspectionsPageReducerActions = siteInspectionsSlice.actions;
export const siteInspectionsPageReducer = siteInspectionsSlice.reducer;
