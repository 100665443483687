import {
  InspectionDTO,
  InspectionSetDTO,
  SearchParameterDTO,
  SiteDTO,
} from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - INSPECTIONALLOCATION- TYPES ||============================== //

export interface InspectionAllocationPageInitialState {
  InspectionSetList: Array<InspectionSetDTO>;
  SiteInspectionAllocationList: Array<SiteDTO>;
  InspectionSetSearchParameter: SearchParameterDTO;
  AllocationYearList: Array<number>;
  SiteSearchParameter: SearchParameterDTO;
  Inspection: InspectionDTO;
}

export enum InspectionAssignmentPageSortType {
  ByScheduling = 1,
  ByGaps = 2,
  ByInspectionSetName =3,
  ByStatus = 4,
  ByLocation = 5
}
