import { createSlice } from "@reduxjs/toolkit";
import SimpleReactValidator from "simple-react-validator";
import {
  EnumButtonModeType,
  EnumGridDataShowRowCount,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardReferenceDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { ReferencePageType } from "./ReferencePage.types";

// ==============================|| VIEWS - PAGES - STANDARDREFERENCE PAGE - REDUCER ||============================== //

// export interface ReferenceGridDataInitialState {
//   IsLoading: boolean,
//   Data: PaginationDTO;
//   PageMode: PageModeType;
//   StandardReferenceSearchParameter: ReferenceSearchParameterType;
//   StandardReference:ReferencePageType;
// }

const initialState = {
  PageMode: {
    IsDisable: true,
    IsShowAddBtn: true,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    IsShowCloseBtn: false,
  } as PageModeType,
  StandaradReference: {
    Name: "",
    Url: "",
  } as StandardReferenceDTO,
  Data: {} as PaginationDTO,
  StandardReferenceSearchParameter: {
    StandardReferenceSearchParams: {
      Name: "",
      StandardId: 0,
    } as StandardReferenceDTO,
    IsAdvanceSearch: false,
    SelectedPage: 1,
    GridPageSize: EnumGridDataShowRowCount.GridDataShowRowCount,
  } as SearchParameterDTO,
  ReferenceModalOpen: false,
  Validator: new SimpleReactValidator(),
  ShowErrorMsg: false,
  ButtonModeType: EnumButtonModeType.Save,
} as ReferencePageType;

export const standardReferenceSlice = createSlice({
  name: "standardReferenceList",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: ReferencePageType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setStandardReferenceInitState: (
      state: ReferencePageType,
      action: { payload: ReferencePageType; type: string }
    ) => {
      return {
        ...state,
        StandaradReference: {
          ...state.StandaradReference,
          StandardId:
            action.payload !== undefined
              ? action.payload.StandaradReference.StandardId
              : initialState.StandaradReference.StandardId,
        },
        StandardReferenceSearchParameter: {
          ...state.StandardReferenceSearchParameter,
          StandardReferenceSearchParams: {
            ...state.StandardReferenceSearchParameter
              .StandardReferenceSearchParams,
            StandardId: action.payload.StandaradReference.StandardId,
            Name: action.payload.StandaradReference.Name,
          },
        },
        Data: action.payload.Data,
      };
    },
    setStandardReferenceList: (
      state: ReferencePageType,
      action: { payload: ReferencePageType; type: string }
    ) => {
      return { ...state, IsEnable: false, Data: action.payload.Data };
    },
    setPagination: (
      state: ReferencePageType,
      action: { payload: PaginationDTO; type: string }
    ) => {
      return {
        ...state,
        Data: {
          Data: action.payload.Data,
          TotalRecordsCount: action.payload.TotalRecordsCount,
          SelectedPage: action.payload.SelectedPage,
        } as PaginationDTO,
      };
    },
    setStandardReferenceSearchParameter: (
      state: ReferencePageType,
      action: { payload: SearchParameterDTO; type: string }
    ) => {
      return {
        ...state,
        StandardReferenceSearchParameter: {
          ...state.StandardReferenceSearchParameter,
          StandardReferenceSearchParams: {
            ...state.StandardReferenceSearchParameter
              .StandardReferenceSearchParams,
            StandardId: action.payload.StandardReferenceSearchParams.StandardId,
          },
          IsAdvanceSearch: action.payload.IsAdvanceSearch,
          SelectedPage: action.payload.SelectedPage,
          SortByNavigation: action.payload.SortByNavigation,
          GridPageSize: action.payload.GridPageSize,
        } as SearchParameterDTO,
      };
    },
    setStandardReference: (
      state: ReferencePageType,
      action: { payload: StandardReferenceDTO; type: string }
    ) => {
      return { ...state, IsEnable: false, StandaradReference: action.payload };
    },
    setDeleteStandardReference: (
      state: ReferencePageType,
      action: { payload: StandardReferenceDTO; type: string }
    ) => {
      return {
        ...state,
        IsEnable: false,
        deletestandardReference: action.payload,
      };
    },
    changePageMode: (
      state: ReferencePageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            IsDisable: false,
            IsShowCloseBtn: true,
            IsShowEditBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: true,
          },
        };
      }
    },
    setPageMode: (
      state: ReferencePageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: true,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: true,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: true,
            IsShowCancelBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: true,
            IsShowCloseBtn: false,
          },
        };
      }
    },
    setReferenceModalOpen: (
      state: ReferencePageType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        ReferenceModalOpen: action.payload,
      };
    },
  },
});

export const {
  setStandardReferenceInitState,
  setStandardReferenceList,
  setStandardReference,
  setDeleteStandardReference,
  changePageMode,
  setPagination,
  setStandardReferenceSearchParameter,
  setLoading,
  setPageMode,
  setReferenceModalOpen,
} = standardReferenceSlice.actions;
export const standardreferenceReducer = standardReferenceSlice.reducer;
