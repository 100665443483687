import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { setLoading } from "../StandardsPage/StandardsPage.reducer";
import { GETSOURCELIST } from "./SourcesPage.action";
import { getSourceList } from "./SourcesPage.api";
import { setSourceList } from "./SourcesPage.reducer";
import { SourcesPageInitialState } from "./SourcesPage.types";

// ==============================|| VIEWS - PAGES - SOURCES - SAGA ||============================== //

const getSourceListWatcher = function* (): any {
  yield takeEvery(GETSOURCELIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    const searchPara: SearchParameterDTO = action.payload;
    const result: SourcesPageInitialState = yield call(() =>
      getSourceList(searchPara)
    );
    if (result == null && !result) {
    } else {
      yield put(setSourceList(result.Data));
    }
    yield put(setLoading(false));
  });
};

const sourcesSaga = function* () {
  yield all([getSourceListWatcher()]);
};

export default sourcesSaga;
