import { ApiClient } from "../../../core/api/apiClient.service";
import { SearchParameterDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { LocationsPageInitialState } from "./LocationsPage.types";

const client = new ApiClient();

export const getLocationsList = async (
  Params: SearchParameterDTO
): Promise<LocationsPageInitialState> => {
  try {
    const URL: string = `Location/GetLocationsList`;
    let data = {} as LocationsPageInitialState;
    await client
      .post<ActionResponseType>(URL, Params)
      .then((response: ActionResponseType) => {
        data.Data = response.Data;
      })
      .catch((error) => {
        // console.log(error);
        data = {} as LocationsPageInitialState;
      });

    return data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};
