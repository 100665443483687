import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumButtonModeType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  PaginationDTO,
  SearchParameterDTO,
  StandardReferenceDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  DELETESTANDARDREFERENCE,
  GETSTANDRDREFERENCELIST,
  SAVESTANDARDREREFERENCE,
  UPDATESTANDARDREFERENCE,
} from "./ReferencePage.action";
import {
  getStandardReferenceList,
  saveStandardReference,
  deleteStandardReference,
  updateStandardReference,
} from "./ReferencePage.api";
import {
  setPageMode,
  setPagination,
  setReferenceModalOpen,
  setStandardReference,
  setStandardReferenceList,
} from "./ReferencePage.reducer";
import { ReferencePageType } from "./ReferencePage.types";

// ==============================|| VIEWS - PAGES - STANDARDREFERENCE - SAGA ||============================== //

const getStandardReferenceListWatcher = function* (): any {
  yield takeEvery(GETSTANDRDREFERENCELIST, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    // get data from backend
    try {
      const standardId: number = action.payload;
      const referenceSearchParam: SearchParameterDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        getStandardReferenceList(referenceSearchParam)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (result === null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No reference data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
        } else {
          const paginationtype: ReferencePageType = {
            Data: result.Data,
          } as ReferencePageType;
          yield put(
            setPagination({
              Data: paginationtype.Data.Data,
              TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
              SelectedPage: paginationtype.Data.SelectedPage,
            } as PaginationDTO)
          );
          yield put(setStandardReferenceList(paginationtype));
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const saveStandardReferenceWatcher = function* (): any {
  yield takeEvery(SAVESTANDARDREREFERENCE, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save reference data from backend
      const referencePageType: ReferencePageType = action.payload;
      const result: ActionResponseType = yield call(() =>
        saveStandardReference(referencePageType.StandaradReference)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        //set saved data
        if (referencePageType.ButtonModeType === EnumButtonModeType.Save) {
          yield put(setStandardReference(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          referencePageType.ButtonModeType ===
          EnumButtonModeType.SaveAndContinue
        ) {
          yield put(
            setStandardReference({
              Id: 0,
              Name: "",
              Url: "",
              StandardId: referencePageType.StandaradReference.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardReferenceDTO)
          );
          yield put(setPageMode(EnumPageMode.AddMode));
        } else if (
          referencePageType.ButtonModeType === EnumButtonModeType.SaveAndClose
        ) {
          yield put(
            setStandardReference({
              Id: 0,
              Name: "",
              Url: "",
              StandardId: referencePageType.StandaradReference.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardReferenceDTO)
          );

          yield put(setPageMode(EnumPageMode.Default));
          yield put(setReferenceModalOpen(false));
        }

        //get reference List
        const standardReferenceState: ReferencePageType = yield select(
          (s: RootState) => s.standardreferenceReducer
        );
        const listResult: ActionResponseType = yield call(() =>
          getStandardReferenceList(
            referencePageType.StandardReferenceSearchParameter
          )
        );

        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No reference data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            yield put(
              openPopup({
                Open: true,
                BodyText: "Successfully saved",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );

            const paginationtype: ReferencePageType = {
              Data: listResult.Data,
            } as ReferencePageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardReferenceList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const updateStandardReferenceWatcher = function* (): any {
  yield takeEvery(UPDATESTANDARDREFERENCE, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save reference data from backend
      const referencePageType: ReferencePageType = action.payload;
      const result: ActionResponseType = yield call(() =>
        updateStandardReference(referencePageType.StandaradReference)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when updating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        if (referencePageType.ButtonModeType === EnumButtonModeType.Update) {
          yield put(setStandardReference(result.Data));
          yield put(setPageMode(EnumPageMode.ViewMode));
        } else if (
          referencePageType.ButtonModeType === EnumButtonModeType.UpdateAndClose
        ) {
          yield put(
            setStandardReference({
              Id: 0,
              Name: "",
              Url: "",
              StandardId: referencePageType.StandaradReference.StandardId,
              Version: 1,
              Status: EnumStatus.Published,
            } as StandardReferenceDTO)
          );
          yield put(setPageMode(EnumPageMode.Default));
          yield put(setReferenceModalOpen(false));
        }
        //get reference List
        const standardReferenceState: ReferencePageType = yield select(
          (s: RootState) => s.standardreferenceReducer
        );

        const listResult: ActionResponseType = yield call(() =>
          getStandardReferenceList(
            referencePageType.StandardReferenceSearchParameter
          )
        );

        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No reference data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            // yield put(
            //   openPopup({
            //     Open: true,
            //     BodyText: "Successfully Updated",
            //     HeaderText: "Success",
            //     PopupType: EnumPopupType.SuccessPopup,
            //     // okBtnClick:  ()=>navigate("/standards", {}),
            //   } as PopupProps)
            // );

            const paginationtype: ReferencePageType = {
              Data: listResult.Data,
            } as ReferencePageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardReferenceList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when updating data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const deleteStandardReferenceWatcher = function* (): any {
  yield takeEvery(DELETESTANDARDREFERENCE, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save reference data from backend
      const referencePageType: ReferencePageType = action.payload;
      const result: ActionResponseType = yield call(() =>
        deleteStandardReference(referencePageType.StandaradReference.Id)
      );
      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when deactivating data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const standardReferenceState: ReferencePageType = yield select(
          (s: RootState) => s.standardreferenceReducer
        );
        yield put(
          setStandardReference({
            Id: 0,
            Name: "",
            Url: "",
            StandardId: standardReferenceState.StandaradReference.StandardId,
            Version: 1,
            Status: EnumStatus.Published,
          } as StandardReferenceDTO)
        );
        yield put(setPageMode(EnumPageMode.Default));
        yield put(setReferenceModalOpen(false));

        //get reference List

        const listResult: ActionResponseType = yield call(() =>
          getStandardReferenceList({
            ...referencePageType.StandardReferenceSearchParameter,
            SelectedPage: 1,
            GridPageSize:
              standardReferenceState.StandardReferenceSearchParameter
                .GridPageSize,
          } as SearchParameterDTO)
        );
        if (!listResult.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when deleting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No reference data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const paginationtype: ReferencePageType = {
              Data: listResult.Data,
            } as ReferencePageType;
            yield put(
              setPagination({
                Data: paginationtype.Data.Data,
                TotalRecordsCount: paginationtype.Data.TotalRecordsCount,
                SelectedPage: paginationtype.Data.SelectedPage,
              } as PaginationDTO)
            );
            yield put(setStandardReferenceList(paginationtype));
          }
        }
      }
    } catch (error) {
      // const navigate = useNavigate();
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when deleting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const standardReferenceSaga = function* () {
  yield all([
    getStandardReferenceListWatcher(),
    saveStandardReferenceWatcher(),
    deleteStandardReferenceWatcher(),
    updateStandardReferenceWatcher(),
  ]);
};

export default standardReferenceSaga;
